import { type GetFundByIdResponse, getFundById } from "@/api/FundManagement";
import { Sort } from "@mui/icons-material";
import dayjs from "dayjs";

import { PRIMARY_LABELING, SECONDARY_LABELING } from "./constants";

type FundType = GetFundByIdResponse["data"];
class Fund {
	private data: FundType;
	public entityId: string;
	public name: string;
	public investmentFocus: string;
	public vintageYear: number;
	public deploymentPeriodDesign: number;
	public unitsOfRiskPrimary: number;
	public unitsOfRiskSecondary: number;
	public totalFundSize: number;
	public generalPartnerCommitment: number;
	public feeAndCarryLpCommitments: number;
	public ancillaryBucketAmount: number;
	public ancillaryReserveAmount: number;
	public feeOnlyLpCommitments: number;
	public carryOnlyLpCommitments: number;
	public noFeeNoCarryLpCommitments: number;
	public totalInvestedCapital: number;
	public projectedGrossMoic: number;
	public totalCapitalCalled: number;
	public lifeOfFundExpensesEstimate: number;
	public lifeOfFundFeeEstimate: number;
	public feePayingCommitmentsMinusEstimateFundExpenses: number;
	public commitmentsAvailableForInvestmentAndFundExpenses: number;
	public nonFeePayingCommitmentFundExpenseReduction: number;
	public capitalAvailableForInvestmentIncludingPostInvestmentReserves: number;
	public totalInvestableCapital: number;
	public postInvestmentPeriodReserves: number;
	public investableCapitalInInvestmentPeriod: number;
	public icApprovedUnderTsPrimary: number;
	public icApprovedUnderTsSecondary: number;
	public primaryAllocation: number;
	// public secondaryAllocation: number;
	public placeholderAllocation: number;
	public placeholderPercentOfInvestableCapital: number;
	public primaryReserves: number;
	public primaryReservesInUnitsOfRisk: number;
	private _quarters?: FundType["quarters"][number];
	public asOfDate: string;

	constructor(
		data: FundType,
		overrides: { [key: string]: string }[],
		asOfDate: string = null,
	) {
		this.data = data;
		this.entityId = data.entityId;
		this.name = data.name;
		this.investmentFocus = data.investmentFocus;
		this.vintageYear = Number(data.vintageYear);
		this.deploymentPeriodDesign = Number(data.deploymentPeriodDesign);
		this.unitsOfRiskPrimary = data.unitsOfRiskPrimary;
		this.unitsOfRiskSecondary = data.unitsOfRiskSecondary;
		this.totalFundSize = data.totalFundSize;
		this.generalPartnerCommitment = data.generalPartnerCommitment;
		this.feeAndCarryLpCommitments = data.feeAndCarryLpCommitments;
		this.feeOnlyLpCommitments = data.feeOnlyLpCommitments;
		this.carryOnlyLpCommitments = data.carryOnlyLpCommitments;
		this.noFeeNoCarryLpCommitments = data.noFeeNoCarryLpCommitments;
		this.totalInvestedCapital = data.totalInvestedCapital;
		this.projectedGrossMoic = data.projectedGrossMoic;
		this.totalCapitalCalled = data.totalCapitalCalled;
		this.lifeOfFundExpensesEstimate = data.lifeOfFundExpensesEstimate;
		this.lifeOfFundFeeEstimate = data.lifeOfFundFeeEstimate;
		this.feePayingCommitmentsMinusEstimateFundExpenses =
			data.feePayingCommitmentsMinusEstimateFundExpenses;
		this.commitmentsAvailableForInvestmentAndFundExpenses =
			data.commitmentsAvailableForInvestmentAndFundExpenses;
		this.nonFeePayingCommitmentFundExpenseReduction =
			data.nonFeePayingCommitmentFundExpenseReduction;
		this.capitalAvailableForInvestmentIncludingPostInvestmentReserves =
			data.capitalAvailableForInvestmentIncludingPostInvestmentReserves;

		this.totalInvestableCapital = data.totalInvestableCapital;
		this.ancillaryBucketAmount = data.ancillaryBucketAmount;
		this.ancillaryReserveAmount = data.ancillaryReserveAmount;
		this.postInvestmentPeriodReserves = data.postInvestmentPeriodReserves;
		this.investableCapitalInInvestmentPeriod =
			data.capitalAvailableForInvestmentIncludingPostInvestmentReserves -
			data.ancillaryBucketAmount -
			data.postInvestmentPeriodReserves;

		this.primaryAllocation = data.primaryAllocation;
		// this.secondaryAllocation = data.secondaryAllocation;

		this.totalAllocation = data.primaryAllocation + data.secondaryAllocation;
		this.placeholderPercentOfInvestableCapital =
			data.placeholderPercentOfInvestableCapital;

		this.placeholderAllocation =
			this.placeholderPercentOfInvestableCapital *
			this.investableCapitalInInvestmentPeriod;

		this.history = data.history;
		this.latestCommentary = data.history?.[0]?.commentary || "";

		const getOverride = (defaultValue, overrides, key) => {
			const overrideValue = overrides?.find(({ key: k }) => k === key);
			return overrideValue?.value || defaultValue;
		};

		const safeNumber = (val) => (!Number.isNaN(val) ? Number(val) : 0);

		this.primaryReserves = safeNumber(
			getOverride(data.primaryReserves, overrides, "primary_reserves"),
		);

		this.primaryReservesInUnitsOfRisk =
			this.primaryReserves / this.unitsOfRiskPrimary;

		this.icApprovedUnderTsPrimary = safeNumber(
			getOverride(
				data.icApprovedUnderTsPrimary,
				overrides,
				"ic_approved_under_ts_primary",
			),
		);
		this.icApprovedUnderTsSecondary = safeNumber(
			getOverride(
				data.icApprovedUnderTsSecondary,
				overrides,
				"ic_approved_under_ts_secondary",
			),
		);
		this._quarters = data.quarters;
		this._clientQuarters = data.clientQuarters;
		this.asOfDate = asOfDate;
	}

	get primaryInvestmentLabel(): string {
		return PRIMARY_LABELING[this.entityId] || PRIMARY_LABELING.default;
	}

	get secondaryInvestmentLabel(): string {
		return SECONDARY_LABELING[this.entityId] || SECONDARY_LABELING.default;
	}

	get secondaryAllocation(): number {
		return (
			this.ancillaryBucketAmount +
			this.placeholderAllocation -
			this.ancillaryReserveAmount
		);
	}
	get plannedPrimaryInvestedCapital(): number {
		return (
			this.capitalAvailableForInvestmentIncludingPostInvestmentReserves -
			// this.ancillaryBucketAmount - // This is good you keep this because it makes 'sense'
			this.postInvestmentPeriodReserves -
			this.secondaryAllocation -
			this.ancillaryReserveAmount
		);
	}

	get maxPrimaryUnits(): number {
		return (
			(this.investableCapitalInInvestmentPeriod - this.placeholderAllocation) /
			this.unitsOfRiskPrimary
		);
	}

	get maxSecondaryUnits(): number {
		return this.secondaryAllocation / this.unitsOfRiskSecondary;
	}

	get investablePrimaryUnits(): number {
		return this.investableCapitalInInvestmentPeriod / this.unitsOfRiskPrimary;
	}

	get investableSecondaryUnits(): number {
		return this.secondaryAllocation / this.unitsOfRiskSecondary;
	}

	get plannedInvestmentDeployments(): {
		year: number;
		amount: number;
		primaryAmount: number;
		secondaryAmount: number;
		primaryAmountsInUnitsOfRisk: number;
		secondaryAmountsInUnitsOfRisk: number;
	}[] {
		const denominator = Number(this.deploymentPeriodDesign);
		const wholeYears = Math.floor(denominator);
		const remainder = denominator - wholeYears;
		const annualAmount =
			(this.plannedPrimaryInvestedCapital + this.secondaryAllocation) /
			denominator;

		const years = Array.from({ length: wholeYears }, (_, i) => ({
			year: this.vintageYear + i,
			amount: annualAmount,
			primaryAmount: annualAmount - this.secondaryAllocation / denominator,
			secondaryAmount: this.secondaryAllocation / denominator,
		}));

		if (remainder > 0) {
			years.push({
				year: this.vintageYear + wholeYears,
				amount: annualAmount * remainder,
				primaryAmount:
					remainder * (annualAmount - this.secondaryAllocation / denominator),

				secondaryAmount: remainder * (this.secondaryAllocation / denominator),
			});
		}
		const yearsWithUnitsOfRisk = years.map((year) => ({
			...year,
			primaryAmountsInUnitsOfRisk: year.primaryAmount / this.unitsOfRiskPrimary,
			secondaryAmountsInUnitsOfRisk:
				year.secondaryAmount / this.unitsOfRiskSecondary,
		}));

		return yearsWithUnitsOfRisk;
	}

	get feePayingCapitalCommitted() {
		return this.feeAndCarryLpCommitments + this.feeOnlyLpCommitments;
	}

	get nonFeePayingCapitalCommitted() {
		return this.totalFundSize - this.feePayingCapitalCommitted;
	}

	get quarters() {
		let asOfDate = this.asOfDate;
		if (!this._quarters) return [];

		// set to end of current quarter if asOfDate is null or invalid
		if (!asOfDate || dayjs(asOfDate).year() < this.vintageYear) {
			asOfDate = dayjs().endOf("quarter");
		}

		const asOfDateObj = dayjs(asOfDate);
		const filteredQuarters = this._quarters.filter((q) => {
			// Create a date from the quarter (end of quarter)
			const quarterDate = dayjs(`${q.year}-${q.quarter * 3}-01`).endOf(
				"quarter",
			);
			return (
				quarterDate.isBefore(asOfDateObj) ||
				quarterDate.isSame(asOfDateObj, "quarter")
			);
		});

		const sorted = filteredQuarters.toSorted((a, b) => {
			if (a.year === b.year) {
				return b.quarter - a.quarter;
			}
			return b.year - a.year;
		});

		// find the first 3 quarters where netIRR is not null
		// update their value to null if value is negative
		//  If net + gross abs > 100 then both gross and net to null
		// PEK requested we cook the books here due to some
		//  byzantine logic about front loaded capital calls idk.
		let firstIndexWithData = null;

		const reversed = [...sorted].reverse();
		const final = reversed.map((quarter, index) => {
			const copy = { ...quarter };
			if (quarter.grossIrr !== null && firstIndexWithData === null)
				firstIndexWithData = index;
			if (firstIndexWithData === null) {
				return quarter;
			}
			if (index <= firstIndexWithData + 4) {
				if (quarter.netIrr < 0) {
					copy.netIrr = null;
				}
				if (Math.abs(quarter.netIrr) > 1) {
					copy.netIrr = null;
				}

				if (Math.abs(quarter.grossIrr) > 1) {
					copy.grossIrr = null;
				}
			}
			return copy;
		});

		return [...final.reverse()].filter(({ year }) => year >= this.vintageYear);
	}

	get clientQuarters() {
		if (!this._clientQuarters) {
			return [];
		}
		return this._clientQuarters.toSorted((a, b) => {
			if (a.year === b.year) {
				return b.quarter - a.quarter;
			}
			return b.year - a.year;
		});
	}

	get latestQuarter() {
		return this.quarters?.[0];
	}

	get grossMoic() {
		return this.latestQuarter?.grossMoic;
	}

	get netMoic() {
		return this.latestQuarter?.netMoic;
	}

	get grossIrr() {
		return this.latestQuarter?.grossIrr;
	}

	get netIrr() {
		return this.latestQuarter?.netIrr;
	}

	get impairmentRatio() {
		return this.latestQuarter?.impairmentRatio;
	}

	get totalFeePayingCapitalCalled() {
		if (!this.clientQuarters) {
			return 0;
		}
		return this.clientQuarters.reduce((acc, curr) => {
			if (curr.client.feePaying) {
				return acc + Number(curr.totalCapitalCalled);
			}
			return acc;
		}, 0);
	}

	get totalCapitalCalledAsPercentage() {
		if (this.totalFundSize <= 0) return "N/A";
		return (this.totalCapitalCalled / this.totalFundSize) * 100;
	}

	get totalCapitalCalledFeePayingAsPercentage() {
		const numerator = this.totalFeePayingCapitalCalled;
		const denominator = this.feePayingCapitalCommitted;
		return (numerator / denominator) * 100;
	}

	get totalRealizedValue() {
		if (!this.quarters) {
			return 0;
		}

		return this.quarters.reduce((sum, quarter) => {
			return sum + (quarter.realizedValue || 0);
		}, 0);
	}
}

export default Fund;

import { formatAsMillions } from "@/utils/numberFormat";
import { Grid, Typography } from "@mui/material";
import React from "react";

import type Fund from "../Fund";
import type FundInvestments from "../FundInvestments";
import { PRIMARY_LABELING, SECONDARY_LABELING } from "../constants";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../constants";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";
import WaterfallChart from "./shared/WaterfallChart";

interface WaterfallData {
	[key: string]: number;
}

const prepareWaterfallData = (
	rawData: Omit<WaterfallData, "spacer">[],
	initialSpacer = 0,
): WaterfallData[] => {
	const result: WaterfallData[] = [];
	rawData.forEach((item, index) => {
		if (index === 0) {
			result.push({ ...item, spacer: initialSpacer });
		} else if (index === 1) {
			const spacer = result[0].total - item.total;
			result.push({ ...item, spacer });
		} else {
			const previousItem = result[index - 1];
			const spacer = previousItem.spacer - item.total;
			result.push({ ...item, spacer });
		}
	});
	return result;
};

function Slide11({
	fund,
	investments,
}: {
	fund: Fund;
	investments: FundInvestments;
}) {
	const { actualDeployments } = investments ?? { actualDeployments: [] };
	const { entityId } = fund;
	const primaryLabel = PRIMARY_LABELING[entityId];
	const secondaryLabel = SECONDARY_LABELING[entityId];

	const waterfallColors = {
		[primaryLabel]: PRIMARY_COLOR,
		[secondaryLabel]: SECONDARY_COLOR,
		Reserves: "#00BB04",
	};

	const plannedDeploymentsData = prepareWaterfallData([
		{
			xLabel: "Planned Invested Capital",
			[primaryLabel]: fund.plannedPrimaryInvestedCapital,
			[secondaryLabel]: fund.secondaryAllocation,
			total: fund.plannedPrimaryInvestedCapital + fund.secondaryAllocation,
		},
		...fund.plannedInvestmentDeployments.map((deployment) => ({
			xLabel: deployment.year,
			[primaryLabel]: deployment.primaryAmount,
			[secondaryLabel]: deployment.secondaryAmount,
			total: deployment.amount,
		})),
	]);
	const deploymentsWithSpacer = [];

	actualDeployments.forEach((deployment, idx) => {
		if (idx === 0) {
			deploymentsWithSpacer.push({
				spacer:
					fund.plannedPrimaryInvestedCapital +
					fund.secondaryAllocation -
					actualDeployments[0].amount,
				xLabel: deployment.year,
				[primaryLabel]: deployment.primaryAmount,
				[secondaryLabel]: deployment.secondaryAmount,
				Reserves: 0,
				total: deployment.amount,
			});
		} else if (idx === 1) {
			deploymentsWithSpacer.push({
				spacer: deploymentsWithSpacer[0].spacer - actualDeployments[1].amount,
				xLabel: deployment.year,
				[primaryLabel]: deployment.primaryAmount,
				[secondaryLabel]: deployment.secondaryAmount,
				Reserves: 0,
				total: deployment.amount,
			});
		} else {
			deploymentsWithSpacer.push({
				spacer:
					deploymentsWithSpacer[idx - 1].spacer - actualDeployments[idx].amount,
				xLabel: deployment.year,
				[primaryLabel]: deployment.primaryAmount,
				[secondaryLabel]: deployment.secondaryAmount,
				Reserves: 0,
				total: deployment.amount,
			});
		}
	});

	const actualDeploymentData = [
		{
			xLabel: "Planned Invested Capital",
			[primaryLabel]: fund.plannedPrimaryInvestedCapital,
			[secondaryLabel]: fund.secondaryAllocation,
			total: fund.plannedPrimaryInvestedCapital + fund.secondaryAllocation,
			...(fund.primaryReserves !== 0 && { Reserves: 0 }), // This is spaghetti necessary to keep total labels rendering above
		},
		...deploymentsWithSpacer,
		{
			xLabel: "Remaining Investable Capital",
			[primaryLabel]: investments.remainingPrimaryCapitalToDeploy,
			[secondaryLabel]: investments.remainingSecondaryCapitalToDeploy,
			Reserves: 0,
			spacer: 0,
			total: investments.remainingCapitalToDeploy,
		},
		{
			xLabel: "Reserves (committed)",
			[primaryLabel]: 0,
			[secondaryLabel]: 0,
			Reserves: fund.primaryReserves,
			total: fund.primaryReserves,
			spacer: investments.remainingCapitalToDeploy - fund.primaryReserves,
		},
		{
			xLabel: "Remaining Investable Capital",
			[primaryLabel]:
				investments.remainingPrimaryCapitalToDeploy - fund.primaryReserves,
			[secondaryLabel]: investments.remainingSecondaryCapitalToDeploy,
			Reserves: 0,
			total: investments.remainingCapitalToDeploy - fund.primaryReserves,
			spacer: 0,
		},
	];

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide>
					<ContentSlide.Title>
						{fund.name}: Investment Period Deployment Pacing ($)
					</ContentSlide.Title>
					<ContentSlide.Content>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant="h3" textAlign="center">
									{`Planned Investment Deployments – ${primaryLabel}${
										secondaryLabel !== "error" ? ` & ${secondaryLabel}` : ""
									}`}
								</Typography>
								<WaterfallChart
									data={plannedDeploymentsData}
									colors={waterfallColors}
									showTotal
									height={350}
									formatter={(value) => `$${formatAsMillions(value, 0)}`}
								/>
								<Typography variant="h3" textAlign="center">
									{`Actual Investment Deployments – ${primaryLabel}${
										secondaryLabel !== "error" ? ` & ${secondaryLabel}` : ""
									}`}
								</Typography>
								<WaterfallChart
									data={actualDeploymentData}
									colors={waterfallColors}
									height={350}
									showTotal
									formatter={(value) => `$${formatAsMillions(value, 0)}`}
								/>
							</Grid>
						</Grid>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>

			<SlideToggle.Data>
				<div style={{ marginBottom: "30px" }}>
					<h4
						style={{
							marginBottom: "10px",
							borderBottom: "1px solid #ddd",
							paddingBottom: "5px",
						}}
					>
						Planned Investment Deployments - {fund.primaryInvestmentLabel} &amp;{" "}
						{fund.secondaryInvestmentLabel}
					</h4>
					<table style={{ width: "100%" }}>
						<thead>
							<tr>
								<th
									style={{
										textAlign: "left",
										padding: "8px 4px",
										width: "30%",
									}}
								/>
								<th
									style={{
										textAlign: "right",
										padding: "8px 4px",
										width: "23%",
									}}
								>
									{fund.primaryInvestmentLabel}
								</th>
								<th
									style={{
										textAlign: "right",
										padding: "8px 4px",
										width: "23%",
									}}
								>
									{fund.secondaryInvestmentLabel}
								</th>
								<th
									style={{
										textAlign: "right",
										padding: "8px 4px",
										width: "24%",
									}}
								>
									Total Amount
								</th>
							</tr>
						</thead>
						<tbody>
							<tr style={{ backgroundColor: "#f5f5f5" }}>
								<td style={{ padding: "8px 4px", fontWeight: "bold" }}>
									Planned Investable Capital
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(fund.primaryAllocation)}
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(fund.secondaryAllocation)}
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(
										fund.primaryAllocation + fund.secondaryAllocation,
									)}
								</td>
							</tr>
							{fund.plannedInvestmentDeployments.map((deployment) => (
								<tr key={deployment.year}>
									<td style={{ padding: "8px 4px" }}>{deployment.year}</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										{formatAsMillions(deployment.primaryAmount)}
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										{formatAsMillions(deployment.secondaryAmount)}
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										{formatAsMillions(deployment.amount)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				<div>
					<h4
						style={{
							marginBottom: "10px",
							borderBottom: "1px solid #ddd",
							paddingBottom: "5px",
						}}
					>
						Actual Investment Deployments - {fund.primaryInvestmentLabel} &amp;{" "}
						{fund.secondaryInvestmentLabel}
					</h4>
					<table style={{ width: "100%" }}>
						<thead>
							<tr>
								<th
									style={{
										textAlign: "left",
										padding: "8px 4px",
										width: "30%",
									}}
								></th>
								<th
									style={{
										textAlign: "right",
										padding: "8px 4px",
										width: "23%",
									}}
								>
									{fund.primaryInvestmentLabel}
								</th>
								<th
									style={{
										textAlign: "right",
										padding: "8px 4px",
										width: "23%",
									}}
								>
									{fund.secondaryInvestmentLabel}
								</th>
								<th
									style={{
										textAlign: "right",
										padding: "8px 4px",
										width: "24%",
									}}
								>
									Total Amount
								</th>
							</tr>
						</thead>
						<tbody>
							<tr style={{ backgroundColor: "#f5f5f5" }}>
								<td style={{ padding: "8px 4px", fontWeight: "bold" }}>
									Investable Capital
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(fund.primaryAllocation)}
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(fund.secondaryAllocation)}
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(
										fund.primaryAllocation + fund.secondaryAllocation,
									)}
								</td>
							</tr>
							{actualDeployments.map((deployment) => (
								<tr key={deployment.year}>
									<td style={{ padding: "8px 4px" }}>{deployment.year}</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										{formatAsMillions(deployment.primaryAmount)}
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										{formatAsMillions(deployment.secondaryAmount)}
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										{formatAsMillions(deployment.amount)}
									</td>
								</tr>
							))}
							<tr style={{ borderTop: "1px solid #ddd" }}>
								<td style={{ padding: "8px 4px", fontWeight: "bold" }}>
									Remaining excl Reserves
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(
										investments.remainingPrimaryCapitalToDeploy,
									)}
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(
										investments.remainingSecondaryCapitalToDeploy,
									)}
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(investments.remainingCapitalToDeploy)}
								</td>
							</tr>
							<tr>
								<td style={{ padding: "8px 4px" }}>Reserves (Committed)</td>
								<td style={{ textAlign: "right", padding: "8px 4px" }}>
									{formatAsMillions(fund.primaryReserves)}
								</td>
								<td style={{ textAlign: "right", padding: "8px 4px" }}></td>
								<td style={{ textAlign: "right", padding: "8px 4px" }}></td>
							</tr>
							<tr style={{ backgroundColor: "#f5f5f5" }}>
								<td style={{ padding: "8px 4px", fontWeight: "bold" }}>
									Remaining
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(
										investments.remainingPrimaryCapitalToDeploy -
											fund.primaryReserves,
									)}
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(
										investments.remainingSecondaryCapitalToDeploy,
									)}
								</td>
								<td
									style={{
										textAlign: "right",
										padding: "8px 4px",
										fontWeight: "bold",
									}}
								>
									{formatAsMillions(
										investments.remainingCapitalToDeploy - fund.primaryReserves,
									)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide11;

import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";
import { Table, useFundManagementTable } from "./shared/Table";

import { formatNumber } from "@/utils/numberFormat";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import CustomLegend from "./shared/Legend";
import CustomTooltip from "./shared/Tooltip";

import { PRIMARY_COLOR, SECONDARY_COLOR } from "../constants";

const SectionTitle = styled(Typography)(({ theme }) => ({
	fontWeight: "bold",
	marginBottom: theme.spacing(2),
	marginTop: theme.spacing(2),
	textDecoration: "underline",
	textAlign: "center",
}));

const ChartContainer = styled(Box)(({ theme }) => ({
	backgroundColor: "white",
	borderRadius: theme.shape.borderRadius,
	px: theme.spacing(1),

	height: 250,
}));

function getDataMinMax(dataset, keys = ["Gross", "Net"]) {
	// Filter out "N/A" values and convert strings to numbers
	const validValues = [];

	dataset.forEach((item) => {
		keys.forEach((key) => {
			if (item[key] !== "N/A" && item[key] !== undefined) {
				const numValue = Number.parseFloat(item[key]);
				if (!isNaN(numValue)) {
					validValues.push(numValue);
				}
			}
		});
	});

	// If no valid values found, return default
	if (validValues.length === 0) {
		return { min: 0, max: 0 };
	}

	return {
		min: Math.min(...validValues),
		max: Math.max(...validValues),
	};
}

function Slide2({ fund }) {
	const { columns } = useFundManagementTable({ data: fund.quarters });

	// Sort quarters chronologically
	const sortedQuarters = fund.quarters.toSorted(
		(a, b) => a.year - b.year || a.quarter - b.quarter,
	);

	// Function to sample every second quarter, starting from the most recent
	const sampleQuarters = (quarters) => {
		if (quarters.length <= 5) return quarters;

		const result = [];

		const cadence = Math.min(Math.round(quarters.length / 8), 4);
		// Start at the end (most recent quarter) and work backwards
		for (let i = quarters.length - 1; i >= 0; i -= cadence) {
			// Add quarters to the result array
			result.push(quarters[i]);

			// // If we've collected enough samples, stop
			// if (result.length >= 5) break;
		}

		// Make sure the first quarter is included if it's not already in the results
		const firstQuarter = quarters[0];
		if (!result.includes(firstQuarter)) {
			result.push(firstQuarter);
		}

		// Return samples in chronological order (earliest to latest)
		return result.sort((a, b) => a.year - b.year || a.quarter - b.quarter);
	};

	const sampledQuarters = sampleQuarters(sortedQuarters);

	// Format data for Recharts
	const formatQuarterLabel = (quarter) => `Q${quarter.quarter}/${quarter.year}`;

	const moicData = sampledQuarters.map((quarter) => ({
		name: formatQuarterLabel(quarter),
		Gross: quarter.grossMoic,
		Net: quarter.netMoic,
	}));

	const irrData = sampledQuarters.map((quarter) => ({
		name: formatQuarterLabel(quarter),
		Gross:
			quarter.grossIrr === null ||
			quarter.grossIrr === undefined ||
			Number.isNaN(quarter.grossIrr)
				? "N/A"
				: (quarter.grossIrr * 100).toFixed(1), // Convert to percentage
		Net:
			quarter.netIrr === null ||
			quarter.netIrr === undefined ||
			Number.isNaN(quarter.netIrr)
				? "N/A"
				: (quarter.netIrr * 100).toFixed(1), // Convert to percentage
	}));
	const irrDataMinMax = getDataMinMax(irrData, ["Gross", "Net"]);

	const dpiData = sampledQuarters.map((quarter) => ({
		name: formatQuarterLabel(quarter),
		DPI: quarter.netDpi,
	}));

	const impairmentData = sampledQuarters.map((quarter) => ({
		name: formatQuarterLabel(quarter),
		"Impairment Ratio": quarter.impairmentRatio * 100, // Convert to percentage
	}));

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide>
					<ContentSlide.Title>
						{fund.name}: Fund Metrics - Fund Life to Date
					</ContentSlide.Title>

					<ContentSlide.Content>
						{/* Content Grid */}
						<Grid container sx={{ margin: "auto", width: "95%" }}>
							{/* MOIC Chart */}
							<Grid item xs={6}>
								<SectionTitle variant="h6">
									Gross & Net MOIC by Quarter
								</SectionTitle>
								<ChartContainer>
									<ResponsiveContainer width="100%" height="100%">
										<BarChart
											data={moicData}
											margin={{ top: 10, right: 30, left: 0, bottom: 30 }}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<Tooltip
												content={
													<CustomTooltip
														formatter={(value) => `${formatNumber(value)}x`}
													/>
												}
											/>
											<XAxis
												dataKey="name"
												angle={-45}
												textAnchor="end"
												height={60}
											/>
											<YAxis padding={{ top: 20 }} />
											<Legend content={<CustomLegend />} />
											<Bar
												barSize={20}
												dataKey="Gross"
												fill={PRIMARY_COLOR}
												name="Gross MOIC"
											>
												<LabelList
													dataKey="Gross"
													position="top"
													formatter={(value) => `${formatNumber(value)}x`}
												/>
											</Bar>
											<Bar
												barSize={20}
												dataKey="Net"
												fill={SECONDARY_COLOR}
												name="Net MOIC"
											>
												<LabelList
													formatter={(value) => `${formatNumber(value)}x`}
													position="top"
												/>
											</Bar>
											T
										</BarChart>
									</ResponsiveContainer>
								</ChartContainer>
							</Grid>

							{/* IRR Chart */}
							<Grid item xs={6}>
								<SectionTitle variant="h6">
									Gross & Net IRR by Quarter
								</SectionTitle>
								<ChartContainer>
									<ResponsiveContainer width="100%" height="100%">
										<BarChart
											data={irrData}
											margin={{ top: 10, right: 30, left: 0, bottom: 30 }}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis
												dataKey="name"
												angle={-45}
												textAnchor="end"
												height={60}
											/>
											<YAxis
												padding={{ top: 20 }}
												domain={[irrDataMinMax.min, irrDataMinMax.max]} // Extends top of range by 20%
											/>
											<Tooltip
												content={
													<CustomTooltip
														formatter={(value) => `${formatNumber(value, 1)}%`}
													/>
												}
											/>
											<Legend content={<CustomLegend />} />
											<Bar
												barSize={20}
												dataKey="Gross"
												fill={PRIMARY_COLOR}
												name="Gross IRR"
											>
												<LabelList
													dataKey="Gross"
													position="top"
													formatter={(value) =>
														value === "N/A"
															? "N/A"
															: `${formatNumber(value, 1)}%`
													}
												/>
											</Bar>
											<Bar
												barSize={20}
												dataKey="Net"
												fill={SECONDARY_COLOR}
												name="Net IRR"
											>
												<LabelList
													dataKey="Net"
													position="top"
													formatter={(value) =>
														value === "N/A"
															? "N/A"
															: `${formatNumber(value, 1)}%`
													}
												/>
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</ChartContainer>
							</Grid>

							{/* DPI Chart */}
							<Grid item xs={6}>
								<SectionTitle variant="h6">DPI by Quarter</SectionTitle>
								<ChartContainer>
									<ResponsiveContainer width="100%" height="100%">
										<BarChart
											data={dpiData}
											margin={{ top: 10, right: 30, left: 0, bottom: 30 }}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis
												dataKey="name"
												angle={-45}
												textAnchor="end"
												height={60}
											/>
											<YAxis padding={{ top: 20 }} />
											<Tooltip
												content={
													<CustomTooltip
														formatter={(value) => `${formatNumber(value)}x`}
													/>
												}
											/>
											<Bar dataKey="DPI" fill={PRIMARY_COLOR}>
												<LabelList
													dataKey="DPI"
													position="top"
													formatter={(value) => `${formatNumber(value)}x`}
												/>
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</ChartContainer>
							</Grid>

							{/* Impairment Ratio Chart */}
							<Grid item xs={6}>
								<SectionTitle variant="h6">
									Impairment Ratio by Quarter
								</SectionTitle>
								<ChartContainer>
									<ResponsiveContainer width="100%" height="100%">
										<BarChart
											data={impairmentData}
											margin={{ top: 10, right: 30, left: 0, bottom: 30 }}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis
												dataKey="name"
												angle={-45}
												textAnchor="end"
												height={60}
											/>
											<YAxis padding={{ top: 20, bottom: 20 }} />
											<Tooltip
												content={
													<CustomTooltip
														formatter={(value) => `${formatNumber(value)}%`}
													/>
												}
											/>
											<Bar dataKey="Impairment Ratio" fill={PRIMARY_COLOR}>
												<LabelList
													dataKey="Impairment Ratio"
													position="top"
													formatter={(value) => `${formatNumber(value)}%`}
												/>
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</ChartContainer>
							</Grid>
						</Grid>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>
			<SlideToggle.Data>
				<h2>Quarterly Values</h2>
				fund?.quarters && (
				<Table
					columns={columns}
					data={fund.quarters}
					fundName={fund.name}
					title={`${fund.name || "Fund"} - Quarterly Fund Metrics`}
				/>
				);
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide2;

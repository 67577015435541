import { Box, ClickAwayListener } from "@mui/material";
import type React from "react";
import { useCallback, useRef, useState } from "react";
import OverflowBox from "./OverflowBox";

export default function TextCell({ value }: { value: string }) {
	const [showOverflow, setShowOverflow] = useState(false);
	const anchorRef = useRef<null | HTMLElement>(null);

	const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setShowOverflow(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowOverflow(false);
	}, []);
	if (value === undefined || value === null) return <>-</>;
	return (
		<ClickAwayListener onClickAway={() => setShowOverflow(false)}>
			<Box position="relative" display="flex" height="100%" alignItems="center">
				<Box
					ref={anchorRef}
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						cursor: "pointer",
						textAlign: "start",
					}}
					onClick={handleClick}
				>
					{value}
				</Box>
				<OverflowBox
					open={showOverflow}
					anchorEl={anchorRef.current}
					onClose={handleClose}
				>
					{value}
				</OverflowBox>
			</Box>
		</ClickAwayListener>
	);
}

import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import HumanCapitalSlide from "../HumanCapital";
import LeanGrowthUpdates from "../LeanGrowthUpdates/OneFirm";
import ResearchUpdates from "../ResearchUpdates";
import RevGen from "../RevGen";

export default function ScaleGroupApprovals() {
	const navigate = useNavigate();
	const params = useParams<{ page?: string; subPage?: string }>();
	const { subPage } = params;
	const tabs = [
		{ key: "lean-growth", label: "Lean/Growth" },
		{ key: "rev-gen", label: "Revenue Generation" },
		{ key: "people-ops", label: "Human Capital", disabled: true },
		{ key: "research", label: "Research" },
	];

	const linkLookup = {
		"rev-gen":
			"/weekly-ic/weekly-ic/requested-scale-group-approvals/rev-gen/portco-update",
	};

	const active = subPage || tabs[0].key;

	const options = tabs.map((tab) => ({
		key: tab.key,
		label: tab.label,
		disabled: tab.disabled,
	}));
	return (
		<Box>
			<Box mb={1}>
				<ButtonTabs
					width="480px"
					tabLabel="Scale Group Dept."
					options={options}
					activeKey={active}
					disableTest={(o) => o?.disabled ?? false}
					onClick={(option) => {
						if (linkLookup[option.key]) {
							navigate(linkLookup[option.key]);
						} else {
							navigate(
								`/weekly-ic/weekly-ic/requested-scale-group-approvals/${option.key}`,
								// { relative: "path" },
							);
						}
					}}
				/>
			</Box>

			{active === "lean-growth" && <LeanGrowthUpdates />}
			{active === "rev-gen" && <RevGen />}
			{active === "people-ops" && <HumanCapitalSlide />}
			{active === "research" && <ResearchUpdates />}
		</Box>
	);
}

import { formatAsMillions } from "@/utils/numberFormat";
import { Box, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import type Fund from "../Fund";
import { PRIMARY_COLOR, SECONDARY_COLOR, colors } from "../constants";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";
import renderCustomizedLabel from "./shared/Label";
import CustomLegend from "./shared/Legend";
import CustomTooltip from "./shared/Tooltip";

import type { getFundInvestments } from "@/api/FundManagement";

type GetFundInvestmentsResponse = ReturnType<typeof getFundInvestments>;
type InvestmentsResponse = Awaited<GetFundInvestmentsResponse>;
type Investment = InvestmentsResponse["data"][number];

const CustomReferenceLabel = ({ x, y, text, moic, fill, stroke }) => (
	<g>
		<rect
			x={x - 70}
			y={y - 30}
			width={140}
			height={50}
			fill="white"
			stroke={stroke}
			strokeWidth={2}
		/>
		<text x={x} y={y - 10} textAnchor="middle" fill={fill} fontSize={12}>
			{text}
		</text>
		<text x={x} y={y + 10} textAnchor="middle" fill={fill} fontSize={12}>
			MOIC: {moic}x
		</text>
	</g>
);

const InvestmentBarChart = ({
	totalsByDesignation,
	fund,
	projectedPrimaryMOIC,
	projectedSecondaryMOIC,
}: {
	totalsByDesignation: Record<
		string,
		{ investedCapital: number; projectedValue: number }
	>;
	fund: Fund;
	projectedPrimaryMOIC: number;
	projectedSecondaryMOIC: number;
}) => {
	const primaryLabel = fund.primaryInvestmentLabel;
	const secondaryLabel = fund.secondaryInvestmentLabel;
	const chartData = [
		{
			name: "Invested Capital",
			primary: totalsByDesignation.primary?.investedCapital || 0,
			secondary: totalsByDesignation.secondary?.investedCapital || 0,
			total:
				(totalsByDesignation.primary?.investedCapital || 0) +
				(totalsByDesignation.secondary?.investedCapital || 0),
		},
		{
			name: "Projected Value",
			primary: totalsByDesignation.primary?.projectedValue || 0,
			secondary: totalsByDesignation.secondary?.projectedValue || 0,
			total:
				(totalsByDesignation.primary?.projectedValue || 0) +
				(totalsByDesignation.secondary?.projectedValue || 0),
		},
	];

	// Calculate MOIC-based values for positioning
	const primaryMOICValue =
		(totalsByDesignation.primary?.investedCapital || 0) * projectedPrimaryMOIC;
	const secondaryMOICValue =
		(totalsByDesignation.secondary?.investedCapital || 0) *
		projectedSecondaryMOIC;

	return (
		<div style={{ width: "100%", height: 600, marginBottom: "30px" }}>
			<ResponsiveContainer>
				<BarChart
					data={chartData}
					margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
				>
					<CartesianGrid strokeDasharray="3 3" />

					<XAxis dataKey="name" />
					<YAxis
						padding={{ top: 20 }}
						tickFormatter={(value) => `$${formatAsMillions(value, 0)}`}
					/>
					<Tooltip
						content={
							<CustomTooltip
								formatter={(value) => `$${formatAsMillions(value, 0)}`}
							/>
						}
					/>

					<Bar
						dataKey="primary"
						name={primaryLabel}
						stackId="stack"
						fill={PRIMARY_COLOR}
						minPointSize={20}
					>
						<LabelList
							dataKey="primary"
							position="inside"
							formatter={(value) => `$${formatAsMillions(value, 0)}`}
							style={{ fill: "white" }}
						/>
					</Bar>
					{secondaryLabel === "error" ? null : (
						<Bar
							dataKey="secondary"
							name={secondaryLabel}
							stackId="stack"
							fill={SECONDARY_COLOR}
							minPointSize={20}
						>
							<LabelList
								dataKey="secondary"
								position="inside"
								formatter={(value) => `$${formatAsMillions(value, 0)}`}
								style={{ fill: "white" }}
							/>
							<LabelList
								dataKey="total"
								position="top"
								fill="#000"
								fontSize={14}
								formatter={(value) => `$${formatAsMillions(value, 0)}`}
								style={{ fontWeight: "bold" }}
							/>
						</Bar>
					)}

					{/* Custom Reference Labels */}
					<ReferenceLine
						y={primaryMOICValue}
						stroke="transparent"
						label={
							<CustomReferenceLabel
								x={200}
								y={200}
								text={`${primaryLabel} Proj.`}
								moic={projectedPrimaryMOIC}
								fill="black"
								stroke={PRIMARY_COLOR}
							/>
						}
					/>
					<ReferenceLine
						y={secondaryMOICValue}
						stroke="transparent"
						label={
							<CustomReferenceLabel
								x={200}
								y={100}
								text={`${secondaryLabel} Proj.`}
								moic={projectedSecondaryMOIC}
								fill="black"
								stroke={SECONDARY_COLOR}
							/>
						}
					/>

					<Legend content={<CustomLegend />} />
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};
const CompanyBarChart = ({ companies }: { companies: any[] }) => {
	const allOthers = companies.find((company) => company.name === "All Others");
	const otherCompanies = companies.filter(
		(company) => company.name !== "All Others",
	);

	const sortedInvested = [...otherCompanies]
		.sort(
			(a, b) => (a.totalInvestedCapital || 0) - (b.totalInvestedCapital || 0),
		)
		.reverse();
	const sortedProjected = [...otherCompanies]
		.sort(
			(a, b) =>
				(a.projectedRealizedValue || 0) - (b.projectedRealizedValue || 0),
		)
		.reverse();

	const finalCompaniesInvested = allOthers
		? [...sortedInvested, allOthers]
		: sortedInvested;
	const finalCompaniesProjected = allOthers
		? [...sortedProjected, allOthers]
		: sortedProjected;

	const totalInvested = finalCompaniesInvested.reduce(
		(sum, company) => sum + (company.totalInvestedCapital || 0),
		0,
	);
	const totalProjected = finalCompaniesProjected.reduce(
		(sum, company) => sum + (company.projectedRealizedValue || 0),
		0,
	);

	const chartData = [
		{
			name: "Invested Capital",
			...finalCompaniesInvested.reduce(
				(acc, company) => ({
					...acc,
					[company.name]: company.totalInvestedCapital || 0,
					[`${company.name}_percent`]: totalInvested
						? ((company.totalInvestedCapital || 0) / totalInvested) * 100
						: 0,
				}),
				{},
			),
		},
		{
			name: "Projected Value",
			...finalCompaniesProjected.reduce(
				(acc, company) => ({
					...acc,
					[company.name]: company.projectedRealizedValue || 0,
					[`${company.name}_percent`]: totalProjected
						? ((company.projectedRealizedValue || 0) / totalProjected) * 100
						: 0,
				}),
				{},
			),
		},
	].map((x) => {
		let total = 0;
		Object.entries(x).map(([key, val]) => {
			if (key !== "name" && !key.endsWith("_percent")) {
				total += val;
			}
		});
		return {
			...x,
			total,
		};
	});

	return (
		<div style={{ width: "100%", height: 600 }}>
			<ResponsiveContainer>
				<BarChart data={chartData}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis
						padding={{ top: 30 }}
						ticks={[0, 20, 40, 60, 80, 100]}
						domain={[0, 100]}
						tickFormatter={(value) => `${value}%`}
					/>
					<Tooltip
						content={
							<CustomTooltip
								formatter={(value, name, props) =>
									`$${formatAsMillions(
										chartData.find((x) => x.name === props.label)[name],
									)}`
								}
							/>
						}
					/>

					{finalCompaniesInvested.map((company, idx) => {
						const isAllOthers = company.name === "All Others";

						const color = colors[idx % colors.length];
						return (
							<Bar
								key={company.name}
								dataKey={`${company.name}_percent`}
								name={company.name}
								stackId="stack"
								fill={isAllOthers ? "#FFF" : color.fill}
								stroke={isAllOthers ? "#000000" : undefined}
							>
								<LabelList
									dataKey={company.name}
									position="inside"
									fill={isAllOthers ? "000" : color.text}
									content={(prop) =>
										renderCustomizedLabel(
											prop,
											(value) =>
												`${company.name} $${formatAsMillions(value, 0)}`,
										)
									}
								/>
								{idx === finalCompaniesInvested.length - 1 && (
									<LabelList
										dataKey="total"
										position="top"
										offset={8}
										fill="#000"
										fontSize={14}
										formatter={(value) => `$${formatAsMillions(value, 0)}`}
										style={{ fontWeight: "bold" }}
									/>
								)}
							</Bar>
						);
					})}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};
// Fund Life to Date Invested & Projected Gross Value
function Slide6({
	fund,
	investments,
	investmentCompanies,
}: {
	fund: Fund;
	investments: FundInvestment;
	investmentCompanies: InvestmentOrganization[];
}) {
	const tbd = useMemo(() => {
		if (!investments?.length) return {};

		return investments.reduce(
			(acc, inv) => {
				const designation =
					inv.fundInternalDesignation?.toLowerCase() || "unknown";
				const organization = investmentCompanies.find(
					(org) => inv.organization?.valorId === org.valorId,
				);

				return {
					...acc,
					[designation]: {
						investedCapital:
							(acc[designation]?.investedCapital || 0) +
							(inv.investedCapital || 0),
						projectedValue: {
							...acc[designation]?.projectedValue,
							[inv.organization?.valorId]:
								organization?.projectedRealizedValue || 0,
						},
					},
				};
			},
			{} as Record<
				string,
				{
					investedCapital: number;
					projectedValue: {
						[valorId]: number;
					};
				}
			>,
		);
	}, [investments, investmentCompanies]);

	// finally sum everything in projected value
	const totalsByDesignation = useMemo(() => {
		const sumProjected = (projectedValue) =>
			Object.values(projectedValue).reduce(
				(sum, curr) => Number(sum) + Number(curr || 0),
				0,
			);
		return {
			primary: {
				investedCapital: tbd.primary?.investedCapital || 0,
				projectedValue: sumProjected(tbd.primary?.projectedValue || {}),
			},
			secondary: {
				investedCapital: tbd.secondary?.investedCapital || 0,
				projectedValue: sumProjected(tbd.secondary?.projectedValue || {}),
			},
			unknown: {
				investedCapital: tbd.unknown?.investedCapital || 0,
				projectedValue: sumProjected(tbd.unknown?.projectedValue || {}),
			},
		};
	}, [tbd]);

	const projectedPrimaryMOIC =
		(
			totalsByDesignation.primary?.projectedValue /
			totalsByDesignation.primary?.investedCapital
		).toFixed(2) || 0;
	const projectedSecondaryMOIC =
		(
			totalsByDesignation.secondary?.projectedValue /
			totalsByDesignation.secondary?.investedCapital
		).toFixed(2) || 0;

	// calculate capital invested up to last quarter from investments
	const bucketedCompanies = useMemo(() => {
		if (!investments?.length) return [];

		const sortedCompanies = investments.companies.toSorted(
			(a, b) => a.projectedRealizedValue - b.projectedRealizedValue,
		);
		const totalValue = sortedCompanies.reduce(
			(sum, company) => sum + company.projectedRealizedValue,
			0,
		);
		let runningSum = 0;
		const cutoffIndex = sortedCompanies.findIndex((company) => {
			runningSum += company.projectedRealizedValue;
			return runningSum > totalValue * 0.2;
		});
		const bottom20Percent = sortedCompanies.slice(0, cutoffIndex);
		const top80Percent = sortedCompanies.slice(cutoffIndex).map((org) => ({
			name: org.name,
			valorId: org.valorId,
			projectedRealizedValue: org.projectedRealizedValue,
			totalInvestedCapital: org.totalInvestedCapitalForFund,
			ownershipPercent: org.ownershipPercent,
			totalSharesOwned: org.totalSharesOwned,
			totalSharesOutstanding: org.totalSharesOutstanding,
			effectiveExitValuation: org.effectiveExitValuation,
			effectiveOwnershipPercent: org.effectiveOwnershipPercent,
			internalDesignationForFund: org.internalDesignationForFund,
			internalDesignations: org.internalDesignations,
		}));
		const bottom20Aggregated = {
			name: "All Others",
			valorId: "bottom-20",
			projectedRealizedValue: bottom20Percent.reduce(
				(sum, company) => sum + company.projectedRealizedValue,
				0,
			),
			totalInvestedCapital: bottom20Percent.reduce(
				(sum, company) => sum + company.totalInvestedCapitalForFund,
				0,
			),
			ownershipPercent: bottom20Percent.reduce(
				(sum, company) => sum + company.ownershipPercent,
				0,
			),
			totalSharesOwned: bottom20Percent.reduce(
				(sum, company) => sum + company.totalSharesOwned,
				0,
			),
			totalSharesOutstanding: bottom20Percent.reduce(
				(sum, company) => sum + company.totalSharesOutstanding,
				0,
			),
			effectiveExitValuation: bottom20Percent.reduce(
				(sum, company) => sum + company.effectiveExitValuation,
				0,
			),
			effectiveOwnershipPercent: bottom20Percent.reduce(
				(sum, company) => sum + company.effectiveOwnershipPercent,
				0,
			),
		};

		const result = [bottom20Aggregated, ...top80Percent];
		return result;
	}, [investments]);

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide>
					<ContentSlide.Title>
						{fund.name}: Fund Life to Date Invested & Projected Gross Value
					</ContentSlide.Title>
					<ContentSlide.Content>
						<Grid container spacing={2}>
							<Grid item xs={12} display="flex" justifyContent="center">
								<Box
									mt={2}
									py={1}
									sx={{
										backgroundColor: "primary.main",
										color: "white",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										width: "400px",
									}}
								>
									<Typography
										variant="h3"
										color="white !important"
										textAlign="center"
										width="100%"
									>
										Total Fund Projected MOIC:{" "}
										{investments.projectedGrossMoic?.toFixed(2)}x
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
								>
									<Typography
										variant="h3"
										style={{ width: "100%", textAlign: "center" }}
									>
										{`Fund Life to Date ${fund.primaryInvestmentLabel} & ${fund.secondaryInvestmentLabel} Investments`}
									</Typography>
									<InvestmentBarChart
										totalsByDesignation={totalsByDesignation}
										fund={fund}
										projectedPrimaryMOIC={projectedPrimaryMOIC}
										projectedSecondaryMOIC={projectedSecondaryMOIC}
									/>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
									width="100%"
								>
									<Typography
										variant="h3"
										style={{ width: "100%", textAlign: "center" }}
									>
										Projected Gross Realized Value by Company
									</Typography>

									<CompanyBarChart companies={bucketedCompanies} />
								</Box>
							</Grid>
						</Grid>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>

			<SlideToggle.Data>
				<h2>Fund Life to Date Invested & Projected Gross Value</h2>
				<div>
					<h3>
						Total Fund Projected MOIC:{" "}
						{investments.projectedGrossMoic?.toFixed(2)}x
					</h3>

					<h3>Investment Breakdown by Type</h3>
					<table>
						<thead>
							<tr>
								<th>Investment Type</th>
								<th>Invested Capital</th>
								<th>Projected Value</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(totalsByDesignation).map(
								([type, { investedCapital, projectedValue }]) => (
									<tr key={type}>
										<td>{type}</td>
										<td>{formatAsMillions(investedCapital)}</td>
										<td>{formatAsMillions(projectedValue)}</td>
									</tr>
								),
							)}
						</tbody>
					</table>

					<h3>Company Breakdown</h3>
					<table className="investment-table">
						<thead>
							<tr>
								<th>Company</th>
								<th>Invested Capital</th>
								<th>Projected Value</th>
								<th>Ownership</th>
								<th>Projected Calc</th>
								<th>Company Allocation</th>
							</tr>
						</thead>
						<tbody>
							{bucketedCompanies.map((company) => {
								const hash = company.name
									.split("")
									.reduce((acc, char) => acc + char.charCodeAt(0), 0);
								const color = `hsl(${hash % 360}, 50%, 50%)`;
								return (
									<tr key={company.valorId}>
										<td>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													gap: "8px",
												}}
											>
												<span
													style={{
														display: "inline-block",
														width: "16px",
														height: "16px",
														borderRadius: "50%",
														backgroundColor: color,
													}}
												></span>
												<span>{company.name}</span>
											</div>
										</td>
										<td>{formatAsMillions(company.totalInvestedCapital)}</td>
										<td>{formatAsMillions(company.projectedRealizedValue)}</td>
										<td>
											{company.ownershipPercent} = ({company.totalSharesOwned} /{" "}
											{company.totalSharesOutstanding})
										</td>
										<td>
											{company.projectedRealizedValue} = (
											{company.effectiveExitValuation} *{" "}
											{company.effectiveOwnershipPercent})
										</td>
										<td>
											{company.internalDesignationForFund} = (
											{company.internalDesignations})
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide6;

import { type RefObject, useEffect, useState } from "react";

export function useVisibility<T extends HTMLElement>(
	ref: RefObject<T>,
): boolean {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		const currentRef = ref.current;
		const observer = new IntersectionObserver(
			([entry]: IntersectionObserverEntry[]) => {
				if (entry.isIntersecting) {
					setIsVisible(true); // Mark visible when it enters
				} else if (entry.intersectionRatio === 0) {
					setIsVisible(false); // Mark invisible only when fully out
				}
			},
			{ threshold: [0, 0.1] }, // Trigger at 0% (fully out) and 10% (entering)
		);

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, [ref]);

	return isVisible;
}

export default useVisibility;

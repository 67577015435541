import { getAvailableFrameworkSurveys } from "@/api/FrameworkSurvey";
import { createTask } from "@/api/Process";
import { saytForOrg } from "@/api/Search";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import { useAuth } from "@/hooks/useAuth";
import { useLocalStorageState } from "@/hooks/useLocalStorageState";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import Progress from "@/ui/atoms/Progress";
import { destinations } from "@/utils/destinations";
import { ArrowBack, Poll, Search } from "@mui/icons-material";
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Modal,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import debounce from "lodash/debounce";
import type React from "react";
import {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

const StyledModal = styled(Modal)(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
	width: 640,
	height: 480,
	backgroundColor: theme.palette.background.paper,
	borderRadius: theme.shape.borderRadius,
	boxShadow: theme.shadows[24],
	padding: theme.spacing(2),
	display: "flex",
	flexDirection: "column",
}));

const OptionBox = styled(Paper)(({ theme }) => ({
	marginTop: theme.spacing(2),
	flex: 1,
	overflowY: "auto",
}));

const fuzzyMatch = (str, pattern) => {
	const string = str.toLowerCase();
	const compare = pattern.toLowerCase();
	let patternIdx = 0;
	let strIdx = 0;
	let score = 0;
	let lastMatchingCharIdx = -1;

	while (strIdx < string.length) {
		if (string[strIdx] === compare[patternIdx]) {
			score += 1;
			if (lastMatchingCharIdx !== -1 && strIdx - lastMatchingCharIdx === 1) {
				score += 2;
			}
			lastMatchingCharIdx = strIdx;
			patternIdx += 1;
			if (patternIdx === compare.length) {
				return score;
			}
		}
		strIdx += 1;
	}
	return 0;
};

const MAX_HISTORY_LENGTH = 3;
export default function CmdK({
	initialApplet = null,
	onExternalClose = () => {},
}: {
	initialApplet?: string | null;
	onExternalClose?: Dispatch<SetStateAction<boolean>>;
}) {
	const { user } = useAuth();
	const [busy, setBusy] = useState(false);
	const [open, setOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [currentApplet, setCurrentApplet] = useState(initialApplet);
	const [historyStack, setHistoryStack] = useLocalStorageState(
		[],
		"historyStack",
	);
	const inputRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [currentStep, setCurrentStep] = useState(0);
	const [stepListItems, setStepListItems] = useState([]);
	const [stepAnswer, setStepAnswer] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	// should be fine.
	const { data: surveys } = useQuery(
		"surveys",
		() => getAvailableFrameworkSurveys({ valorId: user?.id }),
		{
			enabled: !!user?.id,
		},
	);

	const applets = useMemo(
		() => ({
			writeNote: {
				name: "Write Note",
				canSee: destinations["Write a Note"].canSee,
				Icon: destinations["Write a Note"].Icon,
				steps: [
					{
						type: "search",
						placeholder: "Search for a Company to write a Note",
						searchAction: async (query) => {
							const response = await saytForOrg(query);
							const results = response.map((org) => ({
								id: org.valorId,
								name: `${org.name} - ${org.domain}`,
								path: `/org/${org.valorId}/notes/new`,
								Icon: (
									<CompanyAvatar src={org.logoUrl} name={org.name} size="40" />
								),
							}));
							return results;
						},
						action: ({ path }) => navigate(path),
					},
				],
			},

			takeSurvey: {
				name: "Take Survey",
				canSee: () => true,
				finalAction: async ([org, survey]) =>
					navigate(`/org/${org.id}/surveys/${survey.id}`),
				Icon: Poll,
				steps: [
					{
						type: "search",
						placeholder: "Search for Company",
						searchAction: async (query) => {
							const response = await saytForOrg(query);
							const results = response.map((org) => ({
								id: org.valorId,
								name: `${org.name} - ${org.domain}`,
								path: `/org/${org.valorId}`,
								Icon: (
									<CompanyAvatar src={org.logoUrl} name={org.name} size="40" />
								),
							}));
							return results;
						},
						action: (x) => x,
					},
					{
						type: "search",
						placeholder: "Select Survey",
						searchAction: async (query) => {
							let filteredSurveys = surveys
								.map((survey) => ({
									...survey,
									name: `${survey.name} - ${InvestmentProductMappings[survey.department]}`,
								}))
								.map((option) => ({
									...option,
									score: fuzzyMatch(option.name, query),
								}))
								.filter((x) => {
									if (query.length) {
										return x.score > 0;
									}
									return true;
								})
								.sort((a, b) => {
									if (query.length) {
										return b.score - a.score || a.position - b.position;
									}
									return a.name.localeCompare(b.name);
								});
							if (query.length) {
								filteredSurveys = filteredSurveys.slice(0, 5);
							}
							return filteredSurveys.map((survey) => ({
								id: survey.id,
								name: survey.name,
							}));
						},
						action: (x) => x,
					},
				],
			},

			createOpp: {
				name: "Create Opportunity",
				canSee: destinations["Create Opportunity"].canSee,
				Icon: destinations["Create Opportunity"].Icon,
				finalAction: async () => {
					setBusy(true);
					const task = await createTask("deals", {});
					setBusy(false);
					navigate(`/process-management/deals/new-task/deals-${task.id}`);
				},
			},

			createLeangrowth: {
				name: "Create Lean Growth Project",
				canSee: destinations["Create Lean Growth Project"].canSee,
				Icon: destinations["Create Lean Growth Project"].Icon,
				finalAction: async () => {
					setBusy(true);
					const task = await createTask("leangrowth", {});
					setBusy(false);
					navigate(
						`/process-management/leangrowth/new-task/leangrowth-${task.id}`,
					);
				},
			},

			createRevgen: {
				name: "Create Rev Gen Project",
				canSee: destinations["Create Rev Gen Project"].canSee,
				Icon: destinations["Create Rev Gen Project"].Icon,
				finalAction: async () => {
					setBusy(true);
					const task = await createTask("rev-gen", {});
					setBusy(false);
					navigate(`/process-management/rev-gen/new-task/rev-gen-${task.id}`);
				},
			},

			searchCompany: {
				name: "Search Organization",
				canSee: destinations["Deal Search"].canSee,
				Icon: destinations["Deal Search"].Icon,
				steps: [
					{
						type: "search",
						placeholder: "Search for an Organization",
						searchAction: async (query) => {
							const response = await saytForOrg(query);
							const results = response.map((org) => ({
								id: org.valorId,
								name: `${org.name} - ${org.domain}`,
								path: `/org/${org.valorId}`,
								Icon: (
									<CompanyAvatar src={org.logoUrl} name={org.name} size="40" />
								),
							}));
							return results;
						},
						action: ({ path }) => navigate(path),
					},
				],
			},
			// Add more applets here as needed
		}),
		[surveys, navigate],
	);

	const destinationsWithId = useMemo(() => {
		const ids = Object.keys(destinations);
		return ids.reduce(
			(acc, id) => ({
				...acc,
				[id]: {
					...destinations[id],
					id,
				},
			}),
			{},
		);
	}, []);

	const choices = useMemo(
		() => [
			...Object.values(destinationsWithId).map((dest) => ({
				...dest,
				isApplet: false,
				id: dest.name,
			})),
			...Object.entries(applets).map(([key, applet]) => ({
				id: key,
				isApplet: true,
				...applet,
			})),
		],
		[destinationsWithId, applets],
	);

	const defaultOptions = useMemo(
		() => [
			{
				id: "searchCompany",
				name: "Search Organization",
				Icon: Search,
				isApplet: true,
			},
			{
				id: "writeNote",
				name: "Write Note",
				Icon: destinationsWithId["Write a Note"].Icon,
				isApplet: true,
			},
			{
				id: "takeSurvey",
				name: "Take Survey",
				Icon: Poll,
				isApplet: true,
			},
		],
		[destinationsWithId],
	);

	const sortedOptions = useMemo(
		() =>
			choices
				.filter(
					(x) =>
						(x.isApplet || x?.path?.length > 0) &&
						x.canSee?.(user) &&
						x.disableCmdK !== true,
				)
				.map((option) => ({
					...option,
					score: fuzzyMatch(option.name, searchValue),
				}))
				.filter((x) => x.score > 0 || searchValue === "")
				.sort((a, b) => b.score - a.score)
				.slice(0, 5),
		[searchValue, user, choices],
	);

	const debouncedSearch = useMemo(
		() =>
			debounce(async (value) => {
				if (
					currentApplet &&
					applets[currentApplet].steps[currentStep].type === "search"
				) {
					setIsLoading(true);
					try {
						const results =
							await applets[currentApplet].steps[currentStep].searchAction(
								value,
							);
						setStepListItems(results);
					} catch (error) {
						console.error("Search error:", error);
						setStepListItems([]);
					} finally {
						setIsLoading(false);
					}
				}
			}, 300),
		[currentApplet, currentStep, applets],
	);

	useEffect(() => {
		// Update history stack when location changes
		const currentDestination = Object.values(destinationsWithId).find(
			(dest) => dest.path === location.pathname,
		);
		if (currentDestination) {
			setHistoryStack((prevStack) => {
				//  if the current destination is already in the stack, remove it
				const newStack = prevStack.filter(
					(dest) => dest.path !== currentDestination.path,
				);
				return [
					{
						id: currentDestination.id,
						name: currentDestination.name,
						path: currentDestination.path,
						isApplet: currentDestination.isApplet,
					},
					...newStack,
				].slice(0, MAX_HISTORY_LENGTH);
			});
		}
	}, [location, setHistoryStack, destinationsWithId]);

	useEffect(() => {
		if (initialApplet) {
			setCurrentApplet(initialApplet);
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [initialApplet]);

	const handleClose = useCallback(() => {
		setOpen(false);
		setSearchValue("");
		setCurrentApplet(null);
		setCurrentStep(0);
		setStepListItems([]);
		setStepAnswer([]);
		onExternalClose(false);
	}, [onExternalClose]);

	const handleSearchChange = (e) => {
		setSearchValue(e.target.value);
		resetActiveItemIndex();
	};

	const goTo = useCallback(
		async (option) => {
			if (applets[option.id]) {
				setCurrentApplet(option.id);

				// if no steps, run final action if it exists
				if (!applets[option.id].steps?.length) {
					if (applets[option.id].finalAction) {
						await applets[option.id].finalAction();
						setOpen(false);
					}
				} else {
					inputRef.current.focus();
					setCurrentStep(0);
					setStepListItems([]);
					setStepAnswer([]);
					setSearchValue("");
				}
			} else {
				navigate(option.path);
				setOpen(false);
				setSearchValue("");
			}
		},
		[applets, navigate],
	);

	const handleBack = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
			setStepAnswer(stepAnswer.slice(0, -1));
		} else {
			setCurrentApplet(null);
			setCurrentStep(0);
			setStepListItems([]);
		}
		setSearchValue("");
	};

	const handleStepAction = useCallback(
		async (selectedResult) => {
			const applet = applets[currentApplet];
			const step = applet.steps[currentStep];
			const result = await step.action(selectedResult);
			setStepAnswer((old) => [...old, result]);

			if (currentStep < applet.steps.length - 1) {
				setCurrentStep(currentStep + 1);
				setSearchValue("");
			} else {
				if (applet.finalAction) {
					await applet.finalAction([...stepAnswer, result]);
				}
				handleClose();
			}
		},
		[currentApplet, currentStep, stepAnswer, handleClose, applets],
	);

	const renderListItem = (id, name, Icon, onClick, type, active) => (
		<ListItem
			key={id}
			button
			onClick={() => {
				onClick();
			}}
			sx={{
				backgroundColor: active ? "action.hover" : "transparent",
				"&:hover": {
					backgroundColor: "action.hover",
				},
			}}
		>
			<ListItemIcon>{Icon}</ListItemIcon>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					width: "100%",
				}}
			>
				<ListItemText primary={name} />
				{type && (
					<Typography
						variant="caption"
						sx={{ color: "text.secondary", fontStyle: "italic" }}
					>
						{type}
					</Typography>
				)}
			</Box>
		</ListItem>
	);

	type ActionItem = [string, string, React.ReactNode, () => void, string];
	const listItems: Array<ActionItem | React.ReactNode> = useMemo(() => {
		if (currentApplet) {
			return stepListItems.map((item) => [
				item.id,
				item.name,
				item.Icon,
				() => handleStepAction(item),
				null,
			]);
		}
		if (searchValue.length === 0) {
			const options = [];
			if (historyStack.length) {
				options.push(
					<Typography pl={2} variant="subtitle2">
						Recent
					</Typography>,
					...historyStack.map(({ id, name, Icon, isApplet, path }) => [
						id,
						name,
						Icon && <Icon />,
						() => goTo({ path, id }),
						isApplet ? "Command" : "Quick Link",
					]),
					<Divider />,
				);
			}
			options.push(
				...defaultOptions.map(({ id, name, Icon, isApplet, path }) => [
					id,
					name,
					Icon && <Icon />,
					() => goTo({ path, id }),
					isApplet ? "Command" : "Quick Link",
				]),
				<Divider />,
			);
			return options;
		}
		return sortedOptions.map(({ id, name, Icon, isApplet, path }) => [
			id,
			name,
			Icon && <Icon />,
			() => goTo({ path, id }),
			isApplet ? "Command" : "Quick Link",
		]);
	}, [
		currentApplet,
		stepListItems,
		searchValue,
		historyStack,
		sortedOptions,
		handleStepAction,
		goTo,
		defaultOptions,
	]);

	const [activeListItemIndex, setActiveListItemIndex] = useState(0);
	const resetActiveItemIndex = useCallback(() => setActiveListItemIndex(0), []);
	const currentListItem = useMemo(
		() => listItems[activeListItemIndex],
		[listItems, activeListItemIndex],
	);

	const handleNavigation = useCallback(
		(e) => {
			const currentListItems = listItems;

			// if arrow down, move down the list
			if (e.key === "ArrowDown") {
				// set index to next item that is an array
				setActiveListItemIndex((prev) => {
					const nextIndex = currentListItems.findIndex((item, index) => {
						if (index <= prev) {
							return false;
						}
						return Array.isArray(item);
					});
					return nextIndex === -1 ? prev : nextIndex;
				}); // if no next item, keep the current index
			}

			// if arrow up, move up the list
			if (e.key === "ArrowUp") {
				// find previous item that is an array
				setActiveListItemIndex((prev) => {
					const prevIndex =
						currentListItems
							.slice(0, prev)
							.reverse()
							.findIndex((item) => Array.isArray(item)) + 1;
					return prevIndex === -1 ? prev : prev - prevIndex;
				});
			}
		},
		[listItems],
	);

	useEffect(() => {
		if (currentApplet) {
			debouncedSearch(searchValue);
		}
	}, [searchValue, currentApplet, debouncedSearch]);

	useEffect(() => {
		const handleKeyDown = (e) => {
			if ((e.ctrlKey || e.metaKey) && e.key === "k") {
				e.preventDefault();
				e.stopPropagation();
				setOpen(true);
				resetActiveItemIndex();
			}
			if (e.key === "Escape") {
				setOpen(false);
				resetActiveItemIndex();
			}

			if (open) {
				const navigationKeys = ["ArrowUp", "ArrowDown"];
				if (navigationKeys.includes(e.key)) {
					handleNavigation(e);
				}

				if (e.key === "Enter") {
					if (currentListItem) {
						if (Array.isArray(currentListItem)) {
							currentListItem[3]();
						}
					}
				}
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		return () => document.removeEventListener("keydown", handleKeyDown);
	}, [open, resetActiveItemIndex, currentListItem, handleNavigation]);

	return (
		<StyledModal open={open} onClose={handleClose}>
			<ModalContent>
				<Box display="flex" alignItems="center">
					{(currentApplet || currentStep > 0) && (
						<IconButton onClick={handleBack}>
							<ArrowBack />
						</IconButton>
					)}
					<TextField
						autoFocus
						fullWidth
						disabled={busy}
						value={searchValue}
						onChange={handleSearchChange}
						placeholder={
							currentApplet
								? applets[currentApplet].steps?.[currentStep]?.placeholder
								: "Search..."
						}
						InputProps={{
							startAdornment: (
								<Box
									component="span"
									sx={{ color: "action.active", mr: 1, my: 0.5 }}
								>
									<Search />
								</Box>
							),
							endAdornment: isLoading && <Progress />,
						}}
						inputRef={inputRef}
					/>
				</Box>
				<OptionBox>
					<List
						sx={{
							"& .MuiListItem-root:not(:last-child)": {
								borderBottom: "1px solid",
								borderColor: "divider",
							},
						}}
					>
						{listItems.map((item) => {
							// if array, render list item
							if (Array.isArray(item)) {
								return renderListItem(...item, item[0] === currentListItem[0]);
							}
							// if not, render as is
							return item;
						})}
					</List>
				</OptionBox>
			</ModalContent>
		</StyledModal>
	);
}

import React from "react";
import Editor, { type RichTextEditorProps } from "./Editor";
import EditorContextProvider from "./Editor/EditorContext";
import Serializer from "./Serializer";

function EditorWithContext({
	initialValue,
	onChange,
	uploadImage,
	// options,
	hideToolbar = false,
	error = false,
	label = "",
	autoFocus = false,
}: RichTextEditorProps) {
	return (
		<EditorContextProvider>
			<Editor
				initialValue={initialValue}
				onChange={onChange}
				uploadImage={uploadImage}
				// options={options}
				hideToolbar={hideToolbar}
				error={error}
				label={label}
				autoFocus={autoFocus}
			/>
		</EditorContextProvider>
	);
}

export default EditorWithContext;
export const RichTextSerializer = Serializer;

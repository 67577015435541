import Affinity from "@/ui/atoms/Icons/Affinity";
import Crunchbase from "@/ui/atoms/Icons/Crunchbase";
import Diffbot from "@/ui/atoms/Icons/Diffbot";
import Linkedin from "@/ui/atoms/Icons/Linkedin";
import Pitchbook from "@/ui/atoms/Icons/Pitchbook";
import Synaptic from "@/ui/atoms/Icons/Synaptic";
import X from "@/ui/atoms/Icons/X";
import { Box, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
/* eslint-disable react/destructuring-assignment */
import React from "react";

const ResourceButton = styled(ButtonBase)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		marginRight: theme.spacing(1),
	},
	marginRight: theme.spacing(0.5),
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
	color: theme.palette.primary.main,
	border: `1px solid ${theme.palette.primary.main}`,
	backgroundColor: theme.palette.background.paper,
	"&:hover": {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.blue,
		border: `1px solid ${theme.palette.primary.main}`,
	},
}));

interface Props {
	crunchbase?: string;
	pitchbook?: string;
	affinity?: string;
	linkedin?: string;
	synaptic?: string;
	diffbot?: string;
	x?: string;
}

export function ResourceLinks(props: Props) {
	const { crunchbase, x, pitchbook, linkedin, synaptic, diffbot, affinity } =
		props;
	return (
		<Box display="flex">
			{x && (
				<a
					href={`https://www.x.com/intent/user?user_id=${x}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<ResourceButton>
						<X />
					</ResourceButton>
				</a>
			)}
			{pitchbook && (
				<a
					href={`https://my.pitchbook.com/profile/${pitchbook}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<ResourceButton>
						<Pitchbook />
					</ResourceButton>
				</a>
			)}
			{linkedin && (
				<a
					href={`https://www.${linkedin}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<ResourceButton>
						<Linkedin />
					</ResourceButton>
				</a>
			)}
			{synaptic && (
				<a
					href={`https://app.synaptic.com/companies/${synaptic}/`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<ResourceButton>
						<Synaptic />
					</ResourceButton>
				</a>
			)}
			{crunchbase && (
				<a
					href={`https://www.crunchbase.com/organization/${crunchbase}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<ResourceButton>
						<Crunchbase />
					</ResourceButton>
				</a>
			)}
			{diffbot && (
				<a
					href={`https://app.diffbot.com/entity/${diffbot}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<ResourceButton>
						<Diffbot />
					</ResourceButton>
				</a>
			)}
			{affinity && (
				<a
					href={`https://valor.affinity.co/companies/${affinity}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<ResourceButton>
						<Affinity />
					</ResourceButton>
				</a>
			)}
		</Box>
	);
}

export default ResourceLinks;

// Helper functions for portfolio construction and data transformation
import { get, groupBy } from "lodash";

export const groupInvestmentsByTwoProperties = (
	investments,
	primaryProperty,
	secondaryProperty,
) => {
	const validInvestments = investments.filter(
		(item) =>
			get(item, primaryProperty) != null &&
			get(item, secondaryProperty) != null,
	);

	const primaryGroups = groupBy(validInvestments, primaryProperty);

	return Object.fromEntries(
		Object.entries(primaryGroups).map(([key, group]) => [
			key,
			groupBy(group, secondaryProperty),
		]),
	);
};

/**
 * Flattens grouped data into an array of items with category property
 * @param {Array<Object>} data - Array of grouped data objects
 * @returns {Array} Flattened array of items with category property
 */
export const flattenGroupedData = (data) => {
	const copy = [...data].filter((x) => x);
	const flattenedItems = [];
	copy.forEach((obj) => {
		Object.keys(obj).forEach((key) => {
			obj[key].forEach((item) => {
				flattenedItems.push({
					...item,
					category: key,
				});
			});
		});
	});
	return flattenedItems;
};

/**
 * Transforms investment data for table display
 * @param {Object} params - Parameters
 * @param {Array<Object>} params.data - Grouped investment data
 * @returns {Array} Array of transformed table items
 */
export const transformForTable = ({
	data,
	primaryUnitsOfRisk,
	secondaryUnitsOfRisk,
}) => {
	const flattenedItems = flattenGroupedData(data);
	const tableItems = [];
	flattenedItems.forEach((item) => {
		const designation = item.fundInternalDesignation;
		const category = item.category;
		const investedCapital =
			Math.round(Number.parseFloat(item.investedCapital)) || 0;

		let unitsOfRisk =
			designation === "Primary"
				? investedCapital / primaryUnitsOfRisk
				: investedCapital / secondaryUnitsOfRisk;
		unitsOfRisk = Number(unitsOfRisk.toFixed(2));
		tableItems.push({
			companyName: item.organization.name,
			name: item.name,
			designation,
			category,
			investedCapital,
			unitsOfRisk,
		});
	});
	return tableItems;
};

export const transformByDesignation = ({
	data,
	group = "invested_capital",
	primaryUnitOfRisk = null,
	secondaryUnitOfRisk = null,
}) => {
	// Flatten grouped data and deduplicate by valorId for count grouping
	const flattenedItems = flattenGroupedData(data);

	// fundInternalDesignation when deduping preserve the one where x.fundInternalDesignation === "Primary"
	const uniqueItems =
		group === "count"
			? Array.from(
					new Map(flattenedItems.map((item) => [item.valorId, item])).values(),
				)
			: flattenedItems;

	// Get all unique categories
	const allCategories = [...new Set(uniqueItems.map((item) => item.category))];

	// Group and aggregate values by designation and category
	const groupedByDesignation = uniqueItems.reduce((acc, item) => {
		const { category, investedCapital = 0 } = item;

		const designation = item.organization.internalDesignationForFund;
		const value = calculateGroupedValue(
			group,
			designation,
			investedCapital,
			primaryUnitOfRisk,
			secondaryUnitOfRisk,
		);

		// Initialize designation group with all categories set to 0 if it doesn't exist
		acc[designation] = acc[designation] || {
			designation,
			...Object.fromEntries(allCategories.map((category) => [category, 0])),
		};

		// Add value to category total with rounding
		acc[designation][category] = Number(
			(acc[designation][category] + value).toFixed(2),
		);

		return acc;
	}, {});

	// Convert to array for return
	return Object.values(groupedByDesignation);
};

const calculateGroupedValue = (
	group,
	designation,
	investedCapital,
	primaryUnitOfRisk,
	secondaryUnitOfRisk,
) => {
	if (group === "count") return 1;
	if (group === "units_of_risk") {
		return designation === "Primary"
			? investedCapital / primaryUnitOfRisk
			: investedCapital / secondaryUnitOfRisk;
	}
	return investedCapital;
};

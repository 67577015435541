import React from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import CustomTooltip from "./Tooltip";

interface WaterfallData {
	[key: string]: number;
}

const DEFAULT_WIDTH = 1050;
const DEFAULT_HEIGHT = 300;
const SPACER = "spacer";
const XLABEL = "xLabel";
const TOTAL = "total";

interface WaterfallChartProps {
	data: WaterfallData[];
	colors: { [key: string]: string };
	width?: number;
	height?: number;
	rotateLabels?: boolean;
	showTotal?: boolean;
	showInsideLabels?: boolean;
	formatter: (value: number, key?: string, props?: any) => string;
}

export const renderCustomAxisTick = (props) => {
	const { x, y, payload, shouldRotate = true } = props;
	if (!payload?.value) return null;
	const words = String(payload.value).split(" ");
	const lineHeight = 14;
	const maxWidth = 100;

	return (
		<g transform={`translate(${x},${y + 10})`}>
			{words
				.reduce((lines, word) => {
					const lastLine = lines[lines.length - 1] || [];
					const testLine = [...lastLine, word].join(" ");
					return testLine.length * 6 > maxWidth
						? [...lines, [word]]
						: [...lines.slice(0, -1), [...lastLine, word]];
				}, [])
				.map((line, index) => (
					<text
						key={index}
						textAnchor={shouldRotate ? "end" : "middle"}
						fontSize={12}
						fontWeight={"normal"}
						transform={shouldRotate ? "rotate(-45)" : ""}
						dy={index * lineHeight + 10}
						fill="black"
					>
						{line.join(" ")}
					</text>
				))}
		</g>
	);
};

export const WaterfallChart = ({
	data,
	colors,
	width = DEFAULT_WIDTH,
	height = DEFAULT_HEIGHT,
	rotateLabels = true,
	showTotal = false,
	showInsideLabels = true,
	formatter = (value, key) => value.toString(),
}: WaterfallChartProps) => {
	const enrichedData = data.map((item) => ({
		...item,
		...Object.keys(colors).reduce(
			(acc, key) => ({
				...acc,
				[`${key}_color`]: colors[key],
			}),
			{},
		),
	}));

	const barKeys = Object.keys(data[0]).filter(
		(key) => key !== TOTAL && key !== SPACER && key !== XLABEL,
	);

	return (
		<BarChart
			width={width}
			height={height}
			data={enrichedData}
			margin={{ bottom: 90, top: 40, left: 40, right: 40 }}
		>
			<CartesianGrid strokeDasharray="6 9" vertical={false} />
			<XAxis
				dataKey={XLABEL}
				angle={10}
				dy={20}
				interval={0}
				type="category"
				fontWeight={"normal"}
				tick={(props) =>
					renderCustomAxisTick({ ...props, shouldRotate: rotateLabels })
				}
			/>
			<YAxis
				padding={{ top: 40 }}
				domain={[0, "dataMax"]}
				scale="linear"
				fontSize={14}
				tickFormatter={(value) => formatter(value)}
			/>
			<Tooltip
				content={
					<CustomTooltip
						formatter={(value, name, props) => {
							if (name === SPACER) return null;
							return formatter(value, name, props);
						}}
					/>
				}
			/>

			<Bar dataKey={SPACER} stackId="stack" fill="transparent" />
			{barKeys.map((key, index) => (
				<Bar
					key={key}
					dataKey={key}
					stackId="stack"
					fill={colors[key]}
					stroke={colors[key] === "#fff" ? "#000" : ""}
					hide={!data.some((item) => item[key] !== 0)}
				>
					{showInsideLabels && (
						<LabelList
							dataKey={key}
							position="inside"
							fill="#fff"
							content={(props) =>
								renderCustomAxisTick(props, (value) => formatter(value, key))
							}
							style={{ pointerEvents: "none", fontSize: "14px" }}
						/>
					)}
					{/* Attach total LabelList to the last Bar in the stack */}
					{showTotal && index === barKeys.length - 1 && (
						<LabelList
							dataKey={TOTAL}
							position="top"
							offset={8}
							fill="#000"
							fontSize={12}
							fontWeight="bold"
							formatter={(value, name, props) =>
								value ? formatter(value, name, props) : null
							}
						/>
					)}
				</Bar>
			))}
		</BarChart>
	);
};

export default WaterfallChart;

import { Box, Paper, type Ref, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import ContentSlide from "./ContentSlide";

interface TitleSlideProps {
	children: React.ReactNode;
	pageNumber?: number;
	ref?: Ref<HTMLDivElement>;
}

const TitleSlide = React.forwardRef<HTMLDivElement, TitleSlideProps>(
	({ children, pageNumber }, ref) => {
		return (
			<ContentSlide pageNumber={pageNumber}>
				<Box
					ref={ref}
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "400px",
					}}
				>
					{children}
				</Box>
			</ContentSlide>
		);
	},
);

const HeaderPaper = styled(Paper)(({ theme }) => ({
	backgroundColor: "#ccd5ff",
	padding: theme.spacing(2),
	border: "1px solid #3f51b5",
	width: "100%",
	maxWidth: 550,
	margin: "0 auto",
	marginBottom: theme.spacing(6),
}));

const BlueTitle = styled(Typography)(({ theme }) => ({
	color: "#3f51b5",
	fontWeight: "bold",
	textAlign: "center",
}));

const MainContent = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	minHeight: "50%",
}));

const Footer = styled(Box)(({ theme }) => ({
	backgroundColor: "#3f51b5",
	color: "white",
	padding: theme.spacing(1),
	width: "100%",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	position: "fixed",
	bottom: 0,
	left: 0,
}));

const Logo = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
}));

const Circle = styled(Box)(({ theme }) => ({
	width: 24,
	height: 24,
	borderRadius: "50%",
	border: "2px solid white",
	marginRight: theme.spacing(1),
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

TitleSlide.Title = ({ children }: { children: React.ReactNode }) => {
	return (
		<HeaderPaper elevation={0}>
			<BlueTitle variant="h3">{children}</BlueTitle>
		</HeaderPaper>
	);
};

TitleSlide.Subtitle = ({ children }: { children: React.ReactNode }) => {
	return (
		<MainContent>
			<Typography variant="h3" align="center" sx={{ fontWeight: "bold" }}>
				{children}
			</Typography>
		</MainContent>
	);
};

export default TitleSlide;

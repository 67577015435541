import {
	PRIMARY_LABELING,
	SECONDARY_LABELING,
} from "@/pages/FundManagement/constants";
import { formatAsMillions } from "@/utils/numberFormat";
import { Grid, Typography } from "@mui/material";
import {
	ReferenceLine,
	ResponsiveContainer,
	Scatter,
	ScatterChart,
	Tooltip,
	XAxis,
	YAxis,
	ZAxis,
} from "recharts";
import ContentSlide from "../ContentSlide";
import Slide from "../Slide";
import SlideToggle from "../SlideToggle";

import { Table, useFundManagementTable } from "../shared/Table";

function formatLabel(text) {
	if (text.length > 10) {
		const splitPoints = [...text.matchAll(/[\s\/\(]/g)];
		if (splitPoints.length > 0) {
			const middle = text.length / 2;
			let bestSplitIndex = splitPoints[0].index;
			let bestDistance = Math.abs(middle - bestSplitIndex);
			for (const match of splitPoints) {
				const distance = Math.abs(middle - match.index);
				if (distance < bestDistance) {
					bestDistance = distance;
					bestSplitIndex = match.index;
				}
			}
			const firstPart = text.substring(0, bestSplitIndex).trim();
			const secondPart = text.substring(bestSplitIndex).trim();
			return [firstPart, secondPart];
		}
	}
	return [text];
}

function transformPortfolioRiskData(
	portfolioData,
	unitsOfRiskPrimary,
	unitsOfRiskSecondary,
) {
	// Sum the totalValorInvested Capital for value where riskGroup = positive
	// Do same for Negative
	// Y Axis Value = NetUnits of risk = (Positive - negative)/  units of risk
	// value in white box on chart = (Positive - negative)
	// value in blue box is (totalValorInvestedCapital where riskGroup in ['positive', 'negative')]
	const riskFactors = [
		{ key: "inflation", displayName: "Inflation" },
		{ key: "risingInterestRates", displayName: "Rising Interest Rates" },
		{ key: "labor", displayName: "Labor" },
		{ key: "chinaRisk", displayName: "China Risk (Supply Chain)" },
		{
			key: "regulatoryGovernmentPolicy",
			displayName: "Regulatory / Gov't Policy",
		},
		{ key: "geopolitical", displayName: "Geopolitical" },
		{ key: "pandemic", displayName: "Pandemic" },
	];

	const result = {
		Primary: [],
		Secondary: [],
	};

	const riskData = {
		Primary: {},
		Secondary: {},
	};
	riskFactors.forEach((factor) => {
		riskData["Primary"][factor.key] = {
			positiveSum: 0,
			negativeSum: 0,
		};
		riskData["Secondary"][factor.key] = {
			positiveSum: 0,
			negativeSum: 0,
		};
	});

	const tableData = portfolioData.map((investment) => {
		const riskValues = {};

		const invCapital = investment.investedCapital;
		const designation = investment.fundInternalDesignation;
		const companyDesignation =
			investment.organization.internalDesignationForFund;
		const companyName = investment.organization.name;
		const name = investment.name;

		riskFactors.forEach((factor) => {
			const riskValue = investment.organization[factor.key];
			riskValues[factor.key] = riskValue;
		});

		return {
			companyName,
			name,
			designation,
			companyDesignation,
			investedCapital: invCapital,
			...riskValues,
		};
	});

	portfolioData.forEach((investment) => {
		riskFactors.forEach((factor) => {
			// this should be Primary already.
			// ░░░░░░░░▄▄▄▀▀▀▄▄███▄░░░░░░░░░░░░░░
			// ░░░░░▄▀▀░░░░░░░▐░▀██▌░░░░░░░░░░░░░
			// ░░░▄▀░░░░▄▄███░▌▀▀░▀█░░░░░░░░░░░░░
			// ░░▄█░░▄▀▀▒▒▒▒▒▄▐░░░░█▌░░░░░░░░░░░░
			// ░▐█▀▄▀▄▄▄▄▀▀▀▀▌░░░░░▐█▄░░░░░░░░░░░
			// ░▌▄▄▀▀░░░░░░░░▌░░░░▄███████▄░░░░░░
			// ░░░░░░░░░░░░░▐░░░░▐███████████▄░░░
			// ░░░░░le░░░░░░░▐░░░░▐█████████████▄
			// ░░░░toucan░░░░░░▀▄░░░▐█████████████▄
			// ░░░░░░has░░░░░░░░▀▄▄███████████████
			// ░░░░░arrived░░░░░░░░░░░░█▀██████░░
			let investmentDesignation =
				investment.organization.internalDesignationForFund === "Seed"
					? "Primary"
					: investment.organization.internalDesignationForFund;
			investmentDesignation =
				investmentDesignation === "Co-Invest" || investmentDesignation === null
					? "Secondary"
					: investmentDesignation;
			const investmentFactorValue = investment.organization[factor.key];
			const invCapital = investment.investedCapital;
			if (investmentFactorValue === "Positive") {
				riskData[investmentDesignation][factor.key].positiveSum += invCapital;
			} else if (investmentFactorValue === "Negative") {
				riskData[investmentDesignation][factor.key].negativeSum += invCapital;
			}
		});
	});

	["Primary", "Secondary"].forEach((designation) => {
		riskFactors.forEach((factor) => {
			const data = riskData[designation][factor.key];
			const netUnits = (
				(data.positiveSum - data.negativeSum) /
				(designation === "Primary" ? unitsOfRiskPrimary : unitsOfRiskSecondary)
			).toFixed(1);
			const whiteValue = data.positiveSum - data.negativeSum;
			const blueValue = data.positiveSum + data.negativeSum;

			result[designation].push({
				key: factor.displayName,
				netUnits,
				whiteValue,
				blueValue,
			});
		});
	});

	return [result, tableData];
}

const MIN_CIRCLE_DIAMETER = 20;
const MAX_CIRCLE_DIAMETER = 80;
const FILL_COLOR = "#0042ed";

const generateYTicks = (yDomain) => {
	const [min, max] = yDomain;
	const step = Math.ceil(Math.max(Math.abs(min), Math.abs(max)) / 3);
	const ticks = [];

	for (let i = -step * 3; i <= step * 3; i += step) {
		ticks.push(i);
	}

	return ticks;
};

const CustomCell = ({ cx, cy, payload, minValue, maxValue }) => {
	const { netUnits, whiteValue } = payload;
	const valueRange = maxValue - minValue || 1; // Prevent division by zero
	const sizeRange = MAX_CIRCLE_DIAMETER - MIN_CIRCLE_DIAMETER;
	const size =
		MIN_CIRCLE_DIAMETER +
		((Math.abs(whiteValue) - minValue) / valueRange) * sizeRange;

	if (netUnits === 0) return renderNeutralExposure(cx, cy);
	return netUnits < 0 ? (
		<circle
			cx={cx}
			cy={cy}
			r={size / 2}
			stroke="#000"
			fill="#fff"
			strokeWidth={3}
		/>
	) : (
		<circle cx={cx} cy={cy} r={size / 2} fill={FILL_COLOR} />
	);
};

const renderNeutralExposure = (cx: number, cy: number) => {
	const RECT_WIDTH = 108;
	const RECT_HEIGHT = 40;
	const rectX = cx - RECT_WIDTH / 2;
	const rectY = cy - RECT_HEIGHT / 2;

	return (
		<g>
			<rect
				x={rectX}
				y={rectY}
				width={RECT_WIDTH}
				height={RECT_HEIGHT}
				fill="#fff"
				stroke="#000"
				strokeWidth={1}
			/>
			<text
				x={rectX + RECT_WIDTH / 2}
				y={rectY + RECT_HEIGHT / 2}
				fill="#000"
				textAnchor="middle"
				fontSize="12"
				fontWeight="bold"
				dominantBaseline="middle"
			>
				Neutral Exposure
			</text>
		</g>
	);
};
const CustomTooltip = ({ active, payload }) => {
	if (!active || !payload || !payload.length) return null;

	const { key, netUnits, whiteValue, blueValue } = payload[0].payload;

	return (
		<div
			style={{
				backgroundColor: "#fff",
				border: "1px solid #000",
				padding: "8px",
				borderRadius: "4px",
			}}
		>
			<p style={{ margin: 0, fontWeight: "bold" }}>{key}</p>
			<p style={{ margin: "4px 0" }}>Net Units: {netUnits}</p>
			<p style={{ margin: "4px 0" }}>
				Net Exposure: ${formatAsMillions(whiteValue)}
			</p>
			<p style={{ margin: "4px 0", color: "blue" }}>
				Total Invested: ${formatAsMillions(blueValue)}
			</p>
		</div>
	);
};
const RiskScatterChart = ({ data, minValue, maxValue, yDomain }) => {
	const CustomTick = (props) => {
		const {
			x,
			y,
			payload: { value },
		} = props;
		const formattedLabel = formatLabel(value);
		const blueValue = data.find((item) => item.key === value)?.blueValue || 0;
		const whiteValue = data.find((item) => item.key === value)?.whiteValue || 0;
		const formattedBlueValue = formatAsMillions(blueValue);
		const formattedWhiteValue = `Net Exposure: ${formatAsMillions(whiteValue)}`;
		const FONT_SIZE = 12;
		const TEXT_PADDING = 4;
		const labelParts = [...formattedLabel];

		const textHeight = (FONT_SIZE + TEXT_PADDING) * (labelParts.length + 1);

		return (
			<g>
				<text
					x={x}
					y={y + 30 - textHeight / 2 + TEXT_PADDING}
					fontSize={FONT_SIZE}
					textAnchor="middle"
					fill="#000"
				>
					{labelParts.map((part, idx) => (
						<tspan
							key={idx}
							x={x}
							dy={idx === 0 ? 0 : FONT_SIZE + TEXT_PADDING}
						>
							{part}
						</tspan>
					))}
					<tspan x={x} dy={20 + FONT_SIZE + TEXT_PADDING}>
						{formattedWhiteValue}
					</tspan>
				</text>
				<text
					x={x}
					y={
						y +
						30 -
						textHeight / 2 +
						TEXT_PADDING +
						(FONT_SIZE + TEXT_PADDING) * labelParts.length
					}
					fontSize={FONT_SIZE}
					textAnchor="middle"
					fill="blue"
				>
					Total: {formattedBlueValue}
				</text>
			</g>
		);
	};

	const yTicks = generateYTicks(yDomain);

	return (
		<Grid container width={"100%"} height={350}>
			<ResponsiveContainer>
				<ScatterChart
					margin={{
						top: 40,
						right: 20,
						bottom: 40,
						left: 20,
					}}
				>
					<XAxis
						type="category"
						dataKey="key"
						interval={0}
						tickLine={{ transform: "translate(0, -6)" }}
						tick={<CustomTick />}
					/>
					<YAxis
						type="number"
						dataKey="netUnits"
						name="Net Units"
						label={{
							value: "Net Units of Risk Exposure (#)",
							angle: -90,
							position: "center",
						}}
						padding={{ top: 40, bottom: 40 }}
						domain={yDomain}
						ticks={yTicks}
					/>
					<ZAxis type="number" dataKey="whiteValue" />
					<ReferenceLine y={0} stroke="#000" strokeWidth={1} />
					<Scatter
						data={data}
						shape={(props) => (
							<CustomCell {...props} minValue={minValue} maxValue={maxValue} />
						)}
					/>
					<Tooltip content={<CustomTooltip />} />
				</ScatterChart>
			</ResponsiveContainer>
		</Grid>
	);
};

const CorrelatedRiskRatingsSlide = ({
	investments = [],
	title,
	id = null,
	pageNumber,
	fund,
}) => {
	const { unitsOfRiskPrimary, unitsOfRiskSecondary, entityId } = fund;

	const primaryLabel = PRIMARY_LABELING[entityId];
	const secondaryLabel = SECONDARY_LABELING[entityId];

	const [{ Primary: primaryData, Secondary: secondaryData }, tableData] =
		transformPortfolioRiskData(
			investments,
			unitsOfRiskPrimary,
			unitsOfRiskSecondary,
		);
	const { columns } = useFundManagementTable({ data: tableData });
	const calculateDomains = (data) => {
		// Calculate the absolute maximum value from netUnits
		const absMax = Math.abs(Math.max(...data.map((item) => item.netUnits)));

		// Function to get next multiple of a given number
		const getNextMultiple = (value: number, multiple: number): number => {
			return Math.ceil(value / multiple) * multiple;
		};

		let yDomain: number[];

		// Determine yDomain based on requirements
		const MIN_DOMAIN = 10;

		if (absMax <= 30) {
			const nextMultipleOf5 = getNextMultiple(absMax, 5);
			yDomain = [
				-Math.max(nextMultipleOf5, MIN_DOMAIN),
				Math.max(nextMultipleOf5, MIN_DOMAIN),
			];
		} else {
			const nearestMultipleOf10 = Math.round(absMax / 10) * 10;
			const useNextMultiple =
				nearestMultipleOf10 - absMax < 5 && nearestMultipleOf10 - absMax > 0;
			const finalMultiple = useNextMultiple
				? getNextMultiple(absMax, 10)
				: nearestMultipleOf10;

			yDomain = [
				-Math.max(finalMultiple, MIN_DOMAIN),
				Math.max(finalMultiple, MIN_DOMAIN),
			];
		}
		// Calculate min and max whiteValues (unchanged from original)
		const minValue = Math.min(...data.map((item) => Math.abs(item.whiteValue)));
		const maxValue = Math.max(...data.map((item) => Math.abs(item.whiteValue)));

		return { yDomain, minValue, maxValue };
	};

	const {
		yDomain: yPrimDomain,
		minValue: minValuePrim,
		maxValue: maxValuePrim,
	} = calculateDomains(primaryData);

	const {
		yDomain: ySecDomain,
		minValue: minValueSec,
		maxValue: maxValueSec,
	} = calculateDomains(secondaryData);

	return (
		<Slide>
			<SlideToggle>
				<SlideToggle.Slide>
					<ContentSlide disclaimer="All figures on this slide exclude realized investments.">
						<ContentSlide.Title>{title}</ContentSlide.Title>
						<ContentSlide.Content>
							<Typography
								variant="h3"
								sx={{ textAlign: "center", textDecoration: "underline" }}
							>
								{primaryLabel}: Portfolio Correlated Risk
							</Typography>
							<RiskScatterChart
								data={primaryData}
								minValue={minValuePrim}
								maxValue={maxValuePrim}
								yDomain={yPrimDomain}
							/>

							{secondaryData?.length && secondaryLabel !== "error" ? (
								<>
									<Typography
										variant="h3"
										sx={{
											textAlign: "center",
											textDecoration: "underline",
										}}
									>
										{secondaryLabel}: Portfolio Correlated Risk
									</Typography>
									<RiskScatterChart
										data={secondaryData}
										minValue={minValueSec}
										maxValue={maxValueSec}
										yDomain={ySecDomain}
									/>
								</>
							) : null}
						</ContentSlide.Content>
					</ContentSlide>
				</SlideToggle.Slide>
				<SlideToggle.Data>
					<Table
						columns={columns}
						data={tableData}
						title={title}
						fundName={fund.name}
					/>
				</SlideToggle.Data>
			</SlideToggle>
		</Slide>
	);
};

export default CorrelatedRiskRatingsSlide;

import { createSession, getSession } from "@/api/Process";
import useDebounce from "@/hooks/useDebounce";
import { useLayoutEffect, useState } from "react";
import { useQuery } from "react-query";
import { StringParam, useQueryParam } from "use-query-params";

export default function useTrackerTable(processId) {
	const [sessionId, setSessionId] = useQueryParam("sessionId", StringParam);
	const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
	const [filter, setFilter] = useState([]);
	const [sort, setSort] = useState([]);
	const [visibility, setVisibility] = useState({});
	const [order, setOrder] = useState([]);

	const debouncedFilter = useDebounce(filter, 2000);
	const debouncedSort = useDebounce(sort, 2000);
	const debouncedVisibility = useDebounce(visibility, 2000);
	const debouncedOrder = useDebounce(order, 2000);

	// Reset state and initial load flag when processId changes
	useLayoutEffect(() => {
		if (!processId) return;
		setFilter([]);
		setSort([]);
		setVisibility({});
		setOrder([]);
		setIsInitialLoadComplete(false);
	}, [processId]);

	// Set isInitialLoadComplete to true if sessionId is undefined
	useLayoutEffect(() => {
		if (!sessionId) {
			setIsInitialLoadComplete(true);
		}
	}, [sessionId]);

	useQuery(
		["session", processId, sessionId],
		() => getSession(processId, sessionId),
		{
			enabled: Boolean(processId && !isInitialLoadComplete && sessionId),
			onSuccess: (data) => {
				if (data.processId === processId) {
					setFilter(data.columnFilters);
					setSort(data.sorting);
					setVisibility(data.columnVisibility);
					setOrder(data.columnOrder);
				} else {
					setSessionId(undefined);
				}
				setIsInitialLoadComplete(true); // Mark initial load complete in all cases
			},
			retry: false,
			refetchInterval: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	useLayoutEffect(() => {
		if (!isInitialLoadComplete) return;

		const sessionBody = {
			columnFilters: debouncedFilter,
			sorting: debouncedSort,
			columnVisibility: debouncedVisibility,
			columnOrder: debouncedOrder,
		};

		const isDefaultState =
			debouncedFilter.length === 0 &&
			debouncedSort.length === 0 &&
			Object.keys(debouncedVisibility).length === 0 &&
			debouncedOrder.length === 0;
		if (isDefaultState) {
			if (sessionId) setSessionId(undefined);
			return;
		}

		createSession(processId, sessionBody).then((result) => {
			if (result?.id && result.processId === processId) {
				setSessionId(result.id, "replaceIn");
			}
		});
	}, [
		isInitialLoadComplete,
		sessionId,
		setSessionId,
		processId,
		debouncedFilter,
		debouncedSort,
		debouncedVisibility,
		debouncedOrder,
	]);

	return {
		filter,
		sort,
		visibility,
		order,
		setFilter,
		setSort,
		setVisibility,
		setOrder,
	};
}

import { getVotes, postVote, putResponseComment } from "@/api/FrameworkSurvey";
import KeyMaterials from "@/components/FirstMeetings/KeyMaterials";
import useFirstMeetingReports from "@/components/FirstMeetings/useFirstMeetings";
import { DEPARTMENT_LABELS } from "@/components/FirstMeetings/utils";
import LabeledDetail from "@/components/LabeledDetail";
import DataTable from "@/components/LocalTable";
import { SignalChip } from "@/components/SignalChip";
import { TextCell } from "@/components/Table/Primitives";
import { FrameworkChoiceColorIconMapping } from "@/constants/FrameworkChoiceColorIconMapping";
import { canVoteOnFirstMeeting } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import IHEChangeCellIcon from "@/pages/IHEChange/IHEIcon";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import UserAvatar from "@/ui/atoms/UserAvatar";
import Page from "@/ui/molecules/Page";
import { isValidUrl } from "@/utils/general";
import { CoPresent, VideoCameraFront } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
	Box,
	Button,
	Card,
	Chip,
	Divider,
	IconButton,
	Link,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link as RouterLink, useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import Vote from "../Vote";

const BlueChip = styled(Chip)(({ theme }) => ({
	color: theme.palette.text.primary,
	backgroundColor: theme.palette.background.blue,
}));

export default function FirstMeetingsReportSlide() {
	const { valorId } = useParams<{ valorId: string }>();

	const [pageSource] = useQueryParam("source", StringParam);
	const [responseId] = useQueryParam("responseId", StringParam);

	const { user } = useAuth();
	const canVote = canVoteOnFirstMeeting(user);
	const theme = useTheme();

	const { data = [], isLoading } = useFirstMeetingReports(
		dayjs("2024-03-20").format("YYYY-MM-DD"),
		null,
		[valorId],
	);
	const [isVoting, setIsVoting] = React.useState<boolean>(false);
	const [isSavingComment, setSavingComment] = React.useState<boolean>(false);
	const queryClient = useQueryClient();

	const activeReport = data?.find((report) => report.id === responseId);
	const MSLResponseId = activeReport?.id;
	const { data: voteData = { votes: {} }, isLoading: isLoadingVotes } =
		useQuery(["votes", MSLResponseId], () => getVotes(MSLResponseId), {
			enabled: !!MSLResponseId,
		});

	const activeVote = voteData.votes[user.id];

	const columns = useMemo(
		() =>
			[
				{
					header: "Attribute",
					id: "attribute",
					accessorFn: (row) => row.attribute,
					cell: (prop) => <TextCell value={prop.getValue()} />,
					size: 150,
				},
				{
					header: "Color",
					id: "color",
					accessorFn: (row) => row.color,
					size: 84,
					cell: (prop) => (
						<Box
							sx={{
								width: "64px",
								height: "32px",
								borderRadius: theme.spacing(1),
								backgroundColor: prop.getValue()
									? theme.palette[prop.getValue()].main
									: theme.palette.grey[400],
							}}
						/>
					),
				},
				{
					header: "Commentary",
					id: "value",
					accessorFn: (row) => row.value,
					cell: (prop) => <TextCell value={prop.getValue()} clamp={false} />,
					size: 731,
				},
			].map((column) => ({
				...column,
				enableColumnFilter: false,
				enableSorting: false,
				enableColumnMenu: false,
			})),
		[theme],
	);

	if (isLoading || !activeReport) return <CenteredProgress />;

	const {
		teamAssigned,
		partnerAssigned,
		decklink,
		source,
		recordingLink,
		description,
		roundOverview,
		investmentType,
		nextSteps,
		recommendation,
		zoomConsent,
		avengerSymbol,
		avengerSymbolComment,
	} = activeReport.metadata;
	const { tableData, keyMaterials } = activeReport;

	const AvengerIcon = FrameworkChoiceColorIconMapping.blue;

	const investmentBlurb = `${investmentType} [${
		DEPARTMENT_LABELS[activeReport.department]
	}]`;
	const returnLink =
		pageSource === "orgPage"
			? `/org/${valorId}`
			: decodeURIComponent(pageSource);

	return (
		<Page data-cy="page__oa_approvals">
			<Page.Title>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="flex-start"
					gap={1}
				>
					{pageSource && (
						<Button to={returnLink} LinkComponent={RouterLink}>
							<ArrowBackIosIcon fontSize="small" />
							{pageSource === "orgPage"
								? "Back to Organization"
								: decodeURIComponent(pageSource).includes(
											"/opportunity-assessment-reports",
										)
									? "Back to OA Approvals Reports"
									: "Return to Weekly IC"}
						</Button>
					)}
					<span>Opportunity Assessment</span>
				</Box>
			</Page.Title>
			<Page.Content>
				<Box display="flex" justifyContent="center" mb={canVote ? 40 : 2}>
					<Card
						sx={{
							width: "1000px",
							maxWidth: "1000px",
						}}
					>
						<Box padding={2} display="flex" flexDirection="column">
							<Box
								display="flex"
								justifyContent="space-between"
								paddingBottom={1}
								sx={{
									borderBottom: (t) => `1px solid ${t.palette.divider}`,
								}}
								flexDirection={{
									xs: "column",
									sm: "row",
								}}
							>
								<Box display="flex" flexGrow={1} gap={1}>
									<Box display="flex" gap={1} alignItems="center">
										<CompanyAvatar
											name={activeReport.organization.name}
											domain={activeReport.organization.domain}
											size="64"
											src={activeReport.organization.logoUrl}
										/>
										<Box
											display="flex"
											flexDirection="column"
											justifyContent="center"
										>
											<Typography variant="h3">
												{activeReport.organization.name}
											</Typography>
											<Link
												href={`//www.${activeReport.organization.domain}`}
												target="_blank"
												sx={{
													textDecoration: "none",
													color: theme.palette.text.secondary,
												}}
											>
												<Typography variant="body2">
													{activeReport.organization.domain}
												</Typography>
											</Link>
										</Box>
									</Box>
									<Divider orientation="vertical" flexItem />
									<Box
										display="flex"
										gap={1}
										alignItems="center"
										flexDirection="row"
									>
										<UserAvatar user={activeReport.user} />
										<Stack>
											<Typography variant="boldSubtitle2">Completed</Typography>
											<Typography variant="body1">
												{dayjs(activeReport.completedAt).format("MM/DD/YYYY")}
											</Typography>
										</Stack>
									</Box>
								</Box>
								<Box
									display="flex"
									alignItems="flex-start"
									justifyContent="flex-end"
									gap={3}
									width="300px"
								>
									{activeReport.fimoicaSignal ? (
										<SignalChip
											signal={activeReport.fimoicaSignal}
											showBorder
											showIcon
											showName
										/>
									) : null}

									<Box
										display="flex"
										flexDirection="row"
										alignItems="center"
										justifyContent="flex-end"
										height="100%"
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: {
													xs: "row",
													sm: "column",
												},
												gap: {
													xs: 2,
													sm: 0,
												},
												alignItems: "flex-end",
											}}
										>
											<Box
												sx={{
													display: "flex",
													gap: 1,
													margin: (t) => t.spacing(1, 0),
												}}
											>
												<Tooltip
													title={`Avenger Symbol ${
														avengerSymbol === "blue" ? "ACTIVE" : ""
													} ${avengerSymbolComment || ""}  `}
												>
													<Box
														sx={{
															height: "42px",
															width: "42px",
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															borderRadius: (t) => t.spacing(1),
															border: (t) => `1px solid ${t.palette.divider}`,
															color:
																avengerSymbol === "blue"
																	? theme.palette.primary.main
																	: theme.palette.action.disabled,
														}}
													>
														<AvengerIcon />
													</Box>
												</Tooltip>
												<Tooltip
													title={isValidUrl(decklink) ? "Open Deck" : decklink}
												>
													<IconButton
														color="primary"
														disabled={!isValidUrl(decklink)}
														sx={{
															border: (t) => `1px solid ${t.palette.divider}`,
															borderRadius: (t) => t.spacing(1),
														}}
														onClick={() => {
															window.open(decklink, "_blank");
														}}
														variant="contained"
													>
														<CoPresent />
													</IconButton>
												</Tooltip>
												<Tooltip
													title={recordingLink ? "Open Recording" : zoomConsent}
												>
													<Box>
														<IconButton
															color="primary"
															disabled={!recordingLink}
															sx={{
																border: (t) => `1px solid ${t.palette.divider}`,
																borderRadius: (t) => t.spacing(1),
															}}
															onClick={() => {
																window.open(recordingLink, "_blank");
															}}
															variant="contained"
														>
															<VideoCameraFront />
														</IconButton>
													</Box>
												</Tooltip>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>

							<Box padding={1}>
								<LabeledDetail title="DESCRIPTION">{description}</LabeledDetail>

								<Box
									display="grid"
									gridTemplateColumns={{
										xs: "1fr",
										md: "1fr 1fr",
										lg: "1fr 1fr",
										xl: "1fr 1fr",
									}}
									gap={2}
								>
									<LabeledDetail title="ROUND OVERVIEW">
										{roundOverview}
									</LabeledDetail>
									<LabeledDetail title="SOURCE">{source}</LabeledDetail>

									<LabeledDetail title="INVESTMENT TYPE">
										<Box>
											<BlueChip label={investmentBlurb} />
										</Box>
									</LabeledDetail>

									<LabeledDetail title="RECOMMENDATION">
										<Box
											display="flex"
											sx={{
												gap: "4px",
												alignItems: "center",
												padding: 1,
												borderRadius: 1,
												width: "fit-content",
												border: (t) =>
													`1px solid ${
														t.palette[recommendation.choice.color].main
													}`,
											}}
										>
											<IHEChangeCellIcon
												size="big"
												color={recommendation.choice.color}
											/>
											<Typography variant="boldBody2">
												{recommendation.choice.text}
											</Typography>
										</Box>
										{recommendation.commentary}
									</LabeledDetail>

									<LabeledDetail title="PARTNER ASSIGNED">
										<Box display="flex" sx={{ gap: "4px" }}>
											{partnerAssigned?.length ? (
												partnerAssigned.map((u) => (
													<UserAvatar key={u.id} user={u} />
												))
											) : (
												<>N/A</>
											)}
										</Box>
									</LabeledDetail>
									<LabeledDetail title="TEAM">
										<Box>
											<Box display="flex" sx={{ gap: "4px" }}>
												{}
												{teamAssigned?.length ? (
													teamAssigned.map((u) => (
														<UserAvatar key={u.id} user={u} />
													))
												) : (
													<>N/A</>
												)}
											</Box>
										</Box>
									</LabeledDetail>
									<LabeledDetail title="NEXT STEPS">{nextSteps}</LabeledDetail>
									<LabeledDetail title="KEY MATERIALS">
										<KeyMaterials keyMaterials={keyMaterials} />
									</LabeledDetail>
								</Box>
							</Box>

							<Box borderLeft={(t) => `1px solid ${t.palette.divider}`}>
								<DataTable data={tableData} columns={columns} />
							</Box>
						</Box>
					</Card>
				</Box>
				{canVote ? (
					<Vote
						activeVote={activeVote?.vote}
						activeComment={activeVote?.commentary}
						isLoading={isVoting || isLoadingVotes}
						isSavingComment={isSavingComment}
						backLocation={returnLink}
						handleCommentChange={async (comment) => {
							setSavingComment(true);
							try {
								await putResponseComment(MSLResponseId, comment);
							} finally {
								setSavingComment(false);
							}
						}}
						handleVoteChange={async (vote) => {
							if (vote === activeVote?.vote) {
								return;
							}
							try {
								setIsVoting(true);
								queryClient.setQueryData(["votes", MSLResponseId], {
									...voteData,
									votes: {
										...voteData.votes,
										[user.id]: {
											...voteData.votes[user.id],
											vote,
										},
									},
								});
								queryClient.invalidateQueries(["UnvotedReports"]);
								postVote(vote, MSLResponseId);
							} finally {
								setIsVoting(false);
							}
						}}
					/>
				) : null}
			</Page.Content>
		</Page>
	);
}

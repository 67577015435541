import DateRangeFilter from "@/ui/atoms/TableFilters/DateRangeFilter";
import DualSliderFilter from "@/ui/molecules/DualSliderFilter";
import SearchBar from "@/ui/molecules/SearchBar";
import { formatBigDollars } from "@/utils/numberFormat";
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	Switch,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
/* eslint-disable react/prop-types */
import React from "react";

import { saytForOrg, searchOrgCSV } from "@/api/Search";
import { SectorChip } from "@/components/SectorChip";
import MultiSelect from "@/ui/atoms/MultiSelect";
import { CancelOutlined, Close, Save } from "@mui/icons-material";

// @TODO: encapsulate search functionality into filters further
export default function Filters({
	setShowFilters,
	dispatch,
	chosenFirmList,
	chosenCompaniesList,
	busy,
	companiesList,
	noCompanies,
	firmList,
	firmListMembers,
	customFirms,
	minimumNumberOfFirms,
	FundingTypes,
	dealNames,
	filterRaised,
	dealDateRange,
	termSearch,
	usaOnly,
	predictedSectors,
	isBlockchain,
	relativeDealSearch,
	DealSearchOptions,
	sort,
	sortMapping,
	savedSearches,
	savedSearchId,
	setSavedSearch,
	saveModalStateCo,
	setBusy,
	filters,
}) {
	const theme = useTheme();

	const oldSectors = [
		"consumer",
		"real_estate",
		"enterprise_b_2_b",
		"vertical_ai",
		"health",
		"security",
		"food_retail",
		"fintech",
		"blockchain_crypto",
		"biotech_pharma",
		"energy_sustainability",
		"metaverse",
		"advanced_computing",
	];

	return (
		<Grid
			container
			item
			xs={12}
			sm={12}
			md={3}
			style={{
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[2],
				padding: "8px",
				overflowY: "auto",
				overflowX: "hidden",
				height: "100%",
				borderRadius: theme.shape.borderRadius,
			}}
		>
			<Grid item xs={12}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h2" />
					<IconButton
						onClick={() => setShowFilters(false)}
						color="secondary"
						variant="contained"
					>
						<CancelOutlined
							sx={{
								height: "1.25em",
								width: "1.25em",
							}}
						/>
					</IconButton>
				</Box>
			</Grid>
			<Grid item xs={12} style={{ display: "flex" }}>
				<FormControl style={{ flex: 1 }}>
					<InputLabel>Saved Searches</InputLabel>
					<Select
						sx={{
							flex: 1,
							color: theme.palette.primary.main,
						}}
						disabled={!savedSearches?.length}
						value={savedSearchId}
						onChange={(e) => {
							setSavedSearch(e.target.value);
						}}
						variant="standard"
						label="Saved Searches"
					>
						<MenuItem
							value="None"
							sx={{
								color: theme.palette.primary.main,
							}}
						>
							None
						</MenuItem>
						{savedSearches?.map((key) => (
							<MenuItem
								key={key.id}
								value={key.id}
								sx={{ color: theme.palette.primary.main }}
							>
								{key.searchName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<IconButton onClick={() => saveModalStateCo(savedSearchId)}>
					<Save />
				</IconButton>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="subtitle2">Search Term</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box>
					<TextField
						variant="outlined"
						fullWidth
						placeholder="Search..."
						value={termSearch}
						onChange={(e) =>
							dispatch({
								type: "changeValue",
								field: "termSearch",
								value: e.target.value,
							})
						}
					/>
				</Box>
			</Grid>

			<Grid item xs={12} style={{ padding: "8px 0px" }}>
				<Divider style={{ marginBottom: "4px" }} />
				<Typography variant="h3">Deal Participants</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box display="flex" alignItems="center">
					<FormControl style={{ flex: 1 }}>
						<InputLabel id="firm-list-label">Investor Firm Lists</InputLabel>
						<Select
							disabled={busy}
							value={chosenFirmList}
							onChange={(e) =>
								dispatch({
									type: "changeValue",
									field: "chosenFirmList",
									value: e.target.value,
								})
							}
							variant="standard"
							label="Investor Firm Lists"
						>
							<MenuItem value="None">
								<em>None</em>
							</MenuItem>
							{firmList?.map((list) => (
								<MenuItem key={list.id} value={list.id}>
									{list.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
				<Typography
					style={{ height: "24px", fontStyle: "italic" }}
					variant="subtitle2"
				>
					{firmListMembers?.length > 0 &&
						`${firmListMembers?.length} Investor(s) on this list`}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="subtitle2">Add Addtl. Investors</Typography>
			</Grid>
			<Grid item xs={12}>
				<SearchBar
					showSAYT
					selectTerm={({ name }) => {
						dispatch({
							type: "changeValue",
							field: "customFirms",
							value: [...customFirms, { name }],
						});
					}}
					entity="company"
					allowCustomResults
					onCustomResult={(term) =>
						dispatch({
							type: "changeValue",
							field: "customFirms",
							value: [...customFirms, { name: term }],
						})
					}
					search={saytForOrg}
					placeholder="Search for Investors"
				/>
			</Grid>
			<Grid item xs={12}>
				<Box display="inline-flex" style={{ gap: "4px", padding: "4px" }}>
					{customFirms?.map((firm) => (
						<Box
							key={firm.name}
							sx={{
								display: "flex",
								alignItems: "center",
								border: "1px solid black",
								padding: "2px",
								borderRadius: "4px",
								marginRight: "4px",
								color: theme.palette.getContrastText(
									theme.palette.blue.highlight,
								),
								backgroundColor: theme.palette.blue.highlight,
								"& > button": {
									height: "24px",
									width: "24px",
									color: theme.palette.getContrastText(
										theme.palette.blue.highlight,
									),
								},
							}}
						>
							<Typography variant="body2">{firm.name}</Typography>
							<IconButton
								onClick={() => {
									dispatch({
										type: "changeValue",
										field: "customFirms",
										value: customFirms.filter((x) => x.name !== firm.name),
									});
								}}
							>
								<Close />
							</IconButton>
						</Box>
					))}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Typography id="non-linear-slider" gutterBottom>
					Minimum number of selected investors on a deal
				</Typography>
				<Box style={{ margin: "0 16px 0 16px" }}>
					<Slider
						value={minimumNumberOfFirms}
						min={1}
						step={1}
						max={4}
						scale={(x) => x}
						onChange={(e, val) => {
							dispatch({
								type: "changeValue",
								field: "minimumNumberOfFirms",
								value: val,
							});
						}}
						marks={[
							{
								value: 1,
								label: 1,
							},
							{
								value: 2,
								label: 2,
							},
							{
								value: 3,
								label: 3,
							},
							{
								value: 4,
								label: 4,
							},
						]}
						track="normal"
					/>
				</Box>
			</Grid>

			<Grid item xs={12} style={{ padding: "8px 0px" }}>
				<Divider style={{ marginBottom: "4px" }} />
				<Typography variant="h3">Deal Attributes</Typography>
			</Grid>
			<Grid item xs={12}>
				<MultiSelect
					id="deal-select"
					allValues={FundingTypes || []}
					getKey={(x) => x}
					getLabel={(x) => x}
					value={dealNames || []}
					title="Series or Round Name"
					style={{ width: "100%", maxWidth: null }}
					onChange={(v) =>
						dispatch({ type: "changeValue", field: "dealNames", value: v })
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="subtitle2">Deal Size</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box style={{ display: "flex", justifyContent: "center" }}>
					<DualSliderFilter
						initialValue={filterRaised}
						valueLabelFormat={formatBigDollars}
						min={1}
						step={1}
						max={12}
						descale={(x) => {
							const invertMapping = {
								0: 1,
								100000: 2,
								500000: 3,
								1000000: 4,
								5000000: 5,
								10000000: 6,
								20000000: 7,
								50000000: 8,
								100000000: 9,
								200000000: 10,
								500000000: 11,
								Infinity: 12,
							};
							return invertMapping[x];
						}}
						scale={(x) => {
							const mapping = {
								1: 0,
								2: 100000,
								3: 500000,
								4: 1000000,
								5: 5000000,
								6: 10000000,
								7: 20000000,
								8: 50000000,
								9: 100000000,
								10: 200000000,
								11: 500000000,
								12: Number.POSITIVE_INFINITY,
							};
							return mapping[x];
						}}
						title="Deal Size Raised in Round"
						onSlide={(value) =>
							dispatch({ type: "changeValue", field: "filterRaised", value })
						}
					/>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2">Date Range</Typography>
			</Grid>
			<Grid item xs={12}>
				<Box display="flex" style={{ marginBottom: "8px" }}>
					<Box display="flex" style={{ flex: 1 }}>
						<DateRangeFilter
							filterValue={dealDateRange}
							setFilter={(filter) =>
								dispatch({
									type: "changeValue",
									field: "dealDateRange",
									value: filter,
								})
							}
						/>
					</Box>
					<Button
						disabled={!dealDateRange?.startDate || !dealDateRange?.endDate}
						variant="contained"
						color="primary"
						style={{ width: "32px", height: "32px", marginLeft: "4px" }}
						onClick={() =>
							dispatch({
								type: "changeValue",
								field: "dealDateRange",
								value: {},
							})
						}
					>
						<Close />
					</Button>
				</Box>
			</Grid>
			<Grid item xs={12} style={{ padding: "8px 0px" }}>
				<Divider style={{ marginBottom: "4px" }} />
				<Typography variant="h3">Miscellaneous</Typography>
			</Grid>

			<Grid item xs={12}>
				<FormControl style={{ flex: 1, width: "100%" }}>
					<InputLabel id="execs-select-label">
						Searched Co. has Executives from top companies
					</InputLabel>
					<Select
						disabled={busy}
						value={chosenCompaniesList}
						onChange={(e) =>
							dispatch({
								type: "changeValue",
								field: "chosenCompaniesList",
								value: e.target.value,
							})
						}
						variant="standard"
						label="Searched Co. has Executives from top companies"
					>
						<MenuItem value="None">
							<em>None</em>
						</MenuItem>
						{companiesList?.map((list) => (
							<MenuItem key={list.id} value={list.id}>
								{list.name}
							</MenuItem>
						))}
					</Select>
					<Typography
						style={{ height: "24px", fontStyle: "italic" }}
						variant="subtitle2"
					>
						{noCompanies && `${noCompanies} companies on this list`}
					</Typography>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl style={{ flex: 1, width: "100%" }}>
					<InputLabel id="sectors">Sectors</InputLabel>
					<Select
						multiple
						disabled={busy}
						value={predictedSectors}
						onChange={(e) => {
							dispatch({
								type: "changeValue",
								field: "predictedSectors",
								value: e.target.value,
							});
						}}
						variant="standard"
						renderValue={(selected) => (
							<Box display="flex" flexWrap="wrap">
								{selected.map((value) => (
									<div key={value} style={{ margin: "4px" }}>
										<SectorChip sectorType={value} />
									</div>
								))}
							</Box>
						)}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{oldSectors?.map((sector) => (
							<MenuItem key={sector} value={sector}>
								<SectorChip sectorType={sector} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={6}>
				<FormControlLabel
					control={
						<Switch
							checked={usaOnly}
							onChange={(e) =>
								dispatch({
									type: "changeValue",
									field: "usaOnly",
									value: e.target.checked,
								})
							}
							color="primary"
						/>
					}
					label="US Only"
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<FormControlLabel
					control={
						<Switch
							checked={isBlockchain}
							onChange={(e) =>
								dispatch({
									type: "changeValue",
									field: "isBlockchain",
									value: e.target.checked,
								})
							}
							color="primary"
						/>
					}
					label="Filter out Blockchain"
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="subtitle2">Deals to Search</Typography>

					<FormControl>
						<Select
							value={relativeDealSearch}
							onChange={(e) => {
								dispatch({
									type: "changeValue",
									field: "relativeDealSearch",
									value: e.target.value,
								});
							}}
							variant="standard"
						>
							{DealSearchOptions &&
								Object.keys(DealSearchOptions).map((key) => (
									<MenuItem key={key} value={key}>
										{key}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box>
					<Typography variant="subtitle2"> Sort</Typography>
					<FormControl style={{ marginBottom: "8px" }}>
						<Select
							value={sort}
							onChange={(e) =>
								dispatch({
									type: "changeValue",
									field: "sort",
									value: e.target.value,
								})
							}
							variant="standard"
						>
							{sortMapping &&
								Object.keys(sortMapping).map((s) => (
									<MenuItem key={s} value={s}>
										{sortMapping[s].label}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box display="flex" flexDirection="column" style={{ gap: "4px" }}>
					<Button
						color="primary"
						variant="outlined"
						disabled={busy}
						onClick={async () => {
							setBusy(true);
							try {
								await searchOrgCSV("SearchResults", {
									filters,
									sort: [sortMapping[sort].filter],
								});
							} finally {
								setBusy(false);
							}
						}}
					>
						Export Results to Excel
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
}

import { Box, Typography } from "@mui/material";
import React from "react";
import Footer from "./Footer";
import Slide from "./Slide";

const SlideComponent = ({ children, disclaimer }) => (
	<Slide fixedSize={true}>
		<Box display="flex" flexDirection="column" height="100%">
			<Box flex="1">{children}</Box>
			<Box height={40} width="100%" />
			<Box position="absolute" bottom={0} left={0} right={0}>
				<Footer disclaimer={disclaimer} />
			</Box>
		</Box>
	</Slide>
);

SlideComponent.Title = ({ children }) => (
	<Box
		sx={{
			borderBottom: "2px solid black",
			py: 2,
			width: "95%",
			textAlign: "left",
			mx: "auto",
		}}
	>
		<Typography
			variant="h2"
			sx={{
				fontWeight: 1000,
			}}
		>
			{children}
		</Typography>
	</Box>
);

SlideComponent.Content = ({ children }) => (
	<Box sx={{ overflow: "hidden", height: "761px" }}>{children}</Box>
);

export default SlideComponent;

import EuropaBold from "@/fonts/EuropaBold.otf";
import EuropaBoldItalic from "@/fonts/EuropaBoldItalic.otf";
import EuropaLight from "@/fonts/EuropaLight.otf";
import EuropaLightItalic from "@/fonts/EuropaLightItalic.otf";
import EuropaRegular from "@/fonts/EuropaRegular.otf";
import EuropaRegularItalic from "@/fonts/EuropaRegularItalic.otf";
import React from "react";
import {
	Link as RouterLink,
	type LinkProps as RouterLinkProps,
} from "react-router-dom";

import type { CustomThemeOptions } from "./customThemeOptions";

// eslint-disable-next-line react/display-name
export const LinkBehavior = React.forwardRef<
	HTMLAnchorElement,
	Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
	const { href, ...other } = props;
	// Map href (Material UI) -> to (react-router)
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <RouterLink ref={ref} to={href} {...other} />;
});

export const lightTheme = {
	black: {
		main: "#040115",
	},
	white: {
		dark: "#E0E0E0",
		highlight: "#ffffff",
		light: "#FAFAFA",
		main: "#F5F5F5",
	},
	blue: {
		dark: "#022787",
		main: "#0131af",
		light: "#b7ccff",
		highlight: "#E5EDFF",
	},
	teal: {
		main: "#b7ccff",
		highlight: "#D3EBF3",
	},

	red: {
		dark: "#D32E2E",
		main: "#F75050",
		highlight: "#FDCECE",
	},
	pink: {
		main: "#fb8da0",
	},
	yellow: {
		dark: "#DDB400",
		main: "#FFB52E",
		highlight: "#FFF5c8",
	},
	green: {
		dark: "#007500",
		main: "#00BB04",
		highlight: "#EBFFE5",
	},
	seaGreen: {
		main: "#009688",
		dark: "#00695c",
		highlight: "#b2dfdb",
	},
	orange: {
		main: "#ff9800",
		dark: "#ef6c00",
	},
	purple: {
		main: "#7B1F9F",
		highlight: "#F4E1FB",
		dark: "#4a1361",
	},
	gray: {
		main: "#8D8D8D",
		dark: "#4D4D4D",
		light: "#cdcdcd",
	},
	text: {
		primary: "rgba(0,0,0,0.87)",
		secondary: "rgba(0,0,0,0.54)",
		disabled: "rgba(0,0,0,0.38)",
		link: "#71AEF9",
	},
	dividerBlue: "rgba(1, 49, 175, 0.15)",
	background: {
		default: "#fafafa",
		paper: "#ffffff",
		blue: "#e9ebf5",
		oddTableRow: "#f0f3fc",
		activeTableColumn: "#e9ebf5",
		graphOverlay: "#71AEF9",
		blueGradient:
			"linear-gradient(217deg, #EBEEF8, #FFFFFF 70.71%), linear-gradient(127deg, #EBEEF8, #FFFFFF 70.71%), linear-gradient(336deg, #EBEEF8, #FFFFFF 70.71%)",
	},
	action: {
		active: "rgba(0,0,0,0.54)",
		hover: "rgba(0,0,0,0.04)",
		selected: "rgba(0,0,0,0.08)",
		disabled: "rgba(0,0,0,0.26)",
		disabledBackground: "rgba(0,0,0,0.12)",
	},
};

const darkTheme = {
	black: {
		main: "#040115",
	},
	white: {
		dark: "#E0E0E0",
		highlight: "#ffffff",
		light: "#FAFAFA",
		main: "#cdcdcd",
	},
	blue: {
		dark: "#3056B9",
		main: "#71AEF9",
		light: "#8FA9E7",
		highlight: "#E5EDFF",
	},
	teal: {
		main: "#45ABCC",
		highlight: "#D3EBF3",
	},
	purple: {
		main: "#B172CA",
		highlight: "#F4E1FB",
	},
	red: {
		dark: "#D75050",
		main: "#F75050",
		highlight: "#FDCECE",
	},
	pink: {
		main: "#FB8DA0",
	},
	yellow: {
		dark: "#D8C46F",
		main: "#FFB52E",
		highlight: "#FFF5C8",
	},
	green: {
		dark: "#277E27",
		main: "#00BB04",
		highlight: "#EBFFE5",
	},
	seaGreen: {
		main: "#009688",
	},
	orange: {
		main: "#ff9800",
	},
	gray: {
		main: "#8D8D8D",
		dark: "#6C6969",
		light: "#cdcdcd",
	},
	text: {
		primary: "#ffffff",
		secondary: "rgba(255,255,255,0.7)",
		disabled: "rgba(255,255,255,0.5)",
		link: "#4d7BF4",
	},
	dividerBlue: "#D9DFF2",
	background: {
		default: "#303030",
		paper: "#424242",
		blue: "#6871A2",
		oddTableRow: "#303030",
		graphOverlay: "#4d7BF4",
		activeTableColumn: "#4a5075",
		blueGradient:
			"linear-gradient(217deg, #424242, #2c2c2c 70.71%), linear-gradient(127deg, #424242, #2c2c2c 70.71%), linear-gradient(336deg, #424242, #2c2c2c 70.71%)",
	},
	action: {
		active: "#fff",
		hover: "rgba(255,255,255,0.08)",
		selected: "rgba(255,255,255,0.16)",
		disabled: "rgba(255,255,255,0.3)",
		disabledBackground: "rgba(255,255,255,0.12)",
	},
};

const palettePicker = {
	light: lightTheme,
	dark: darkTheme,
};

const overrides = {
	MuiCssBaseline: {
		"@global": {
			body: {
				fontFamily:
					'Europa, "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
			},
			"@font-face": [
				{
					fontFamily: "Europa",
					fontStyle: "normal",
					fontWeight: 300,
					src: `url(${EuropaRegular}) format("opentype")`,
				},
				{
					fontFamily: "Europa",
					fontStyle: "italic",
					fontWeight: 300,
					src: `url(${EuropaRegularItalic}) format("opentype")`,
				},
				{
					fontFamily: "Europa",
					fontStyle: "normal",
					fontWeight: 700,
					src: `url(${EuropaBold}) format("opentype")`,
				},
				{
					fontFamily: "Europa",
					fontStyle: "italic",
					fontWeight: 700,
					src: `url(${EuropaBoldItalic}) format("opentype")`,
				},
				{
					fontFamily: "Europa",
					fontStyle: "normal",
					fontWeight: 100,
					src: `url(${EuropaLight}) format("opentype")`,
				},
				{
					fontFamily: "Europa",
					fontStyle: "italic",
					fontWeight: 100,
					src: `url(${EuropaLightItalic}) format("opentype")`,
				},
			],
		},
	},
	MuiTooltip: {
		tooltip: {
			fontSize: "1em",
		},
	},
	MuiPaper: {
		elevation1: {
			boxShadow:
				"0px 1px 3px 0px #00000033, 0px 2px 1px -1px #0000001F, 0px 1px 1px 0px #00000024",
		},
		elevation2: {
			boxShadow: "0px 4px 2px 0px #3A3A4433, 0px 4px 8px 0px #5A5B6A33",
		},
		elevation3: {
			boxShadow: "0px 4px 8px 0px #3A3A4429, 0px 8px 16px 0px #5A5B6A29",
		},
		elevation4: {
			boxShadow: "0px 8px 16px 0px #3A3A441F, 0px 16px 32px 0px #5A5B6A1F",
		},
		elevation5: {
			boxShadow: "0px 12px 24px 0px #3A3A4414, 0px 24px 48px 0px #5A5B6A14",
		},
	},
	MuiSlider: {
		thumb: {
			width: "16px",
			height: "16px",
			marginTop: "-6px",
		},
		rail: {
			height: "4px",
			color: "#c4c4c4",
		},
		track: {
			height: "4px",
		},
	},
	MuiAppBar: {
		root: {
			boxShadow: "none",
		},
	},
	MuiDrawer: {
		root: {
			zIndex: 1200,
		},
	},
	MuiMenu: {
		paper: {
			boxShadow: "0px 8px 16px 0px #3A3A441F, 0px 16px 32px 0px #5A5B6A1F",
		},
	},
	MuiList: {
		padding: {
			paddingTop: 0,
			paddingBottom: 0,
		},
	},
	MuiButtonBase: {
		root: {
			fontFamily:
				'Europa, "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
		},
	},
	MuiInputBase: {
		"&::after": {
			color: "inherit",
		},
	},
	MuiLink: {
		root: {
			textDecoration: "underline",
		},
		underlineHover: {
			textDecoration: "underline",
		},
	},
};

export const getTheme = (mode: "light" | "dark") => {
	const customTheme: CustomThemeOptions = {
		layers: {
			stickyTableHeaderCell: 400,
			stickyTableCell: 300,
			stickyTableRow: 200,
			colResize: 250,
		},
		palette: {
			mode,
			type: mode,
			primary: undefined,
			secondary: undefined,
			stopLight: {
				bad: "#bb1e10",
				okay: "#FFB52E",
				good: "#75cd32",
				great: "#00BB04",
				best: "#007500",
			},
			competitors: [
				"#0042ed",
				"#27AE60",
				"#E74C3C",
				"#9B59B6",
				"#48C9B0",
				"#A93226",
				"#654321",
				"#808080",
				"#add8e6",
				"#F1C40F",
				"#E67E22",
				"#234116",
				"#C24E47",
				"#2F3A8E",
				"#C34B75",
				"#D9A18C",
				"#5E3CB4",
			],
			vpo: [
				"#343090",
				"#5f59f7",
				"#8c61ff",
				"#6592fd",
				"#44c2fd",
				"#48d6d2",
				"#81e9e6",
				"#8dd1e1",
				"#82ca9d",
				"#a4de6c",
				"#ffcb00",
			],
			brandOrder: [
				"purple",
				"pink",
				"red",
				"blue",
				"teal",
				"seaGreen",
				"green",
				"yellow",
				"orange",
				"gray",
			],
			charts: {
				purple: [
					"#310c40",
					"#4a135f",
					"#62197f",
					"#7b1f9f",
					"#8835a9",
					"#954cb2",
					"#a362bc",
					"#b079c5",
					"#bd8fcf",
					"#caa5d9",
				],
				blue: [
					"#001a5f",
					"#00288e",
					"#002ea6",
					"#0042ed",
					"#1a55ef",
					"#3368f1",
					"#4d7bf2",
					"#668ef4",
					"#80a1f6",
					"#99b3f8",
				],
				teal: [
					"#1c4452",
					"#29677a",
					"#30788f",
					"#3789a3",
					"#3e9ab8",
					"#45abcc",
					"#58b3d1",
					"#6abcd6",
					"#8fcde0",
					"#a2d5e6",
				],
			},
			...palettePicker[mode],
		},
		shape: {
			borderRadius: 8,
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
		typography: {
			fontFamily:
				'Europa, "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
			fontWeightLight: 100,
			fontWeightRegular: 300,
			fontWeightMedium: 400,
			fontWeightSemiBold: 600,
			fontWeightBold: 700,
			fontWeightBlack: 900,
			h1: {
				fontWeight: 300,
				fontSize: "1.75rem",
				letterSpacing: "-0.5px",
			},
			h2: {
				fontWeight: 300,
				fontSize: "1.5rem",
				letterSpacing: "-0.5px",
			},
			h3: {
				fontWeight: 700,
				fontSize: "1.25rem",
				letterSpacing: "0px",
			},
			h4: {
				fontWeight: 500,
				fontSize: "1.063rem",
				letterSpacing: "0px",
			},
			h5: {
				fontWeight: 500,
				fontSize: "1rem",
				letterSpacing: "0px",
			},
			h6: {
				fontWeight: 500,
				fontSize: "0.875rem",
				letterSpacing: "0px",
			},
			subtitle1: {
				fontWeight: 300,
				fontSize: "1rem",
			},
			boldSubtitle1: {
				fontWeight: 700,
				fontSize: "1rem",
				textTransform: "uppercase",
				letterSpacing: "0.15px",
			},
			subtitle2: {
				fontWeight: 400,
				fontSize: "0.875rem",
				letterSpacing: "0.1px",
			},
			boldSubtitle2: {
				fontWeight: 700,
				fontSize: "0.875rem",
				letterSpacing: "0.1px",
			},
			tableHeader: {
				fontWeight: 700,
				fontSize: "0.875rem",
				textTransform: "uppercase",
				letterSpacing: "0.1px",
			},
			body1: {
				fontSize: "1rem",
				fontWeight: 300,
				letterSpacing: "0.5px",
			},
			boldBody1: {
				fontSize: "1rem",
				fontWeight: 700,
				letterSpacing: "0.5px",
			},
			body2: {
				fontSize: "0.875rem",
				fontWeight: 300,
				letterSpacing: "0.5px",
			},
			boldBody2: {
				fontSize: "0.875rem",
				fontWeight: 700,
				letterSpacing: "0.25px",
			},
			caption: {
				fontSize: "0.75rem",
				fontWeight: 300,
				letterSpacing: "0.4px",
			},
			overline: {
				fontSize: "0.75rem",
				fontWeight: 300,
				textTransform: "uppercase",
				letterSpacing: "1px",
			},
			gigaSmall: {
				fontSize: "10px !important",
				letterSpacing: "1px",
			},
		},
		// New MUI OVerrides
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					"@global": {
						body: {
							fontFamily:
								'Europa, "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
						},
						"@font-face": [
							{
								fontFamily: "Europa",
								fontStyle: "normal",
								fontWeight: 300,
								src: `url(${EuropaRegular}) format("opentype")`,
							},
							{
								fontFamily: "Europa",
								fontStyle: "italic",
								fontWeight: 300,
								src: `url(${EuropaRegularItalic}) format("opentype")`,
							},
							{
								fontFamily: "Europa",
								fontStyle: "normal",
								fontWeight: 700,
								src: `url(${EuropaBold}) format("opentype")`,
							},
							{
								fontFamily: "Europa",
								fontStyle: "italic",
								fontWeight: 700,
								src: `url(${EuropaBoldItalic}) format("opentype")`,
							},
							{
								fontFamily: "Europa",
								fontStyle: "normal",
								fontWeight: 100,
								src: `url(${EuropaLight}) format("opentype")`,
							},
							{
								fontFamily: "Europa",
								fontStyle: "italic",
								fontWeight: 100,
								src: `url(${EuropaLightItalic}) format("opentype")`,
							},
						],
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						fontSize: "1em",
						whiteSpace: "pre-line",
					},
				},
			},
			MuiInputBase: {
				styleOverrides: {
					"&::after": {
						color: "inherit",
					},
				},
			},
			MuiLink: {
				styleOverrides: {
					root: {
						textDecoration: "underline",
					},
					underlineHover: {
						textDecoration: "underline",
					},
				},
			},
			MuiAvatar: {
				styleOverrides: {
					img: {
						"-webkit-touch-callout": "none",
					},
				},
			},
			MuiCard: {
				styleOverrides: {
					root: {
						border: `1px solid ${palettePicker[mode].dividerBlue} !important`,
						borderRadius: "0 !important",
						boxShadow: "none !important",
					},
				},
			},
		},
		// MUI Old OVerrides
		overrides,
	};

	customTheme.palette.primary = customTheme.palette.blue;
	customTheme.palette.primary.highlight = customTheme.palette.blue.highlight;
	customTheme.palette.secondary = customTheme.palette.purple;
	customTheme.palette.secondary.highlight =
		customTheme.palette.purple.highlight;

	return customTheme;
};

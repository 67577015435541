import { formatNumber } from "@/utils/numberFormat";
import { Grid, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import type Fund from "../Fund";
import type FundInvestments from "../FundInvestments";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../constants";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";
import WaterfallChart from "./shared/WaterfallChart";

function Slide12({
	fund,
	investments,
}: {
	fund: Fund;
	investments: FundInvestments;
}) {
	const waterFallColoring = {
		[fund.primaryInvestmentLabel]: PRIMARY_COLOR,
		[fund.secondaryInvestmentLabel]: SECONDARY_COLOR,
		Reserves: "#00BB04",
	};

	const maxPrimaryUnits = fund.maxPrimaryUnits;
	const maxSecondaryUnits = fund.maxSecondaryUnits;

	const extractSpacedUnits = (unitData, getter, isPrimary) => {
		const result = [];
		unitData.forEach((dataPoint, idx) => {
			const formattedRisk = Number(formatNumber(getter(dataPoint), 1));
			if (idx === 0) {
				result.push({
					xLabel: dataPoint.year,
					[fund.primaryInvestmentLabel]: isPrimary ? formattedRisk : undefined,
					[fund.secondaryInvestmentLabel]: isPrimary
						? undefined
						: formattedRisk,
					total: formattedRisk,
					spacer:
						(isPrimary ? maxPrimaryUnits : maxSecondaryUnits) - formattedRisk,
					Reserves: 0,
				});
			} else if (idx === 1) {
				result.push({
					xLabel: dataPoint.year,
					[fund.primaryInvestmentLabel]: isPrimary ? formattedRisk : 0,
					[fund.secondaryInvestmentLabel]: isPrimary ? 0 : formattedRisk,
					total: formattedRisk,
					spacer: result[0].spacer - formattedRisk,
					Reserves: 0,
				});
			} else {
				result.push({
					xLabel: dataPoint.year,
					[fund.primaryInvestmentLabel]: isPrimary ? formattedRisk : 0,
					[fund.secondaryInvestmentLabel]: isPrimary ? 0 : formattedRisk,
					total: formattedRisk,
					spacer: result[idx - 1].spacer - formattedRisk,
					Reserves: 0,
				});
			}
		});
		return result;
	};

	const plannedPrimaryUnitsData = extractSpacedUnits(
		fund.plannedInvestmentDeployments,
		(v) => v.primaryAmountsInUnitsOfRisk,
		true,
	);

	const actualPrimaryUnitsData = extractSpacedUnits(
		investments?.actualDeployments,
		(v) => v.primaryAmountInUnitsOfRisk,
		true,
	);

	const plannedPrimaryDeploymentsData = [
		{
			xLabel: "Total Fund",
			[fund.primaryInvestmentLabel]: maxPrimaryUnits,

			total: maxPrimaryUnits,
			spacer: 0,
		},
		...plannedPrimaryUnitsData,
	];

	const actualPrimaryDeploymentsData = [
		{
			xLabel: "Total Fund",
			[fund.primaryInvestmentLabel]: maxPrimaryUnits,

			total: maxPrimaryUnits,
			spacer: 0,
			Reserves: 0,
		},
		...actualPrimaryUnitsData,
		{
			xLabel: "Remaining (excl Reserves)",
			Reserves: 0,
			[fund.primaryInvestmentLabel]: formatNumber(
				investments.remainingPrimaryCapitalToDeploy / fund.unitsOfRiskPrimary,
				1,
			),

			spacer: 0,
			total:
				investments.remainingPrimaryCapitalToDeploy / fund.unitsOfRiskPrimary,
		},
		{
			xLabel: "Reserves (Committed)",
			Reserves: formatNumber(fund.primaryReservesInUnitsOfRisk, 1),
			total: formatNumber(fund.primaryReservesInUnitsOfRisk, 1),
			spacer:
				Number(
					formatNumber(
						investments.remainingPrimaryCapitalToDeploy /
							fund.unitsOfRiskPrimary,
						1,
					),
				) - Number(formatNumber(fund.primaryReservesInUnitsOfRisk, 1)),
		},
		{
			xLabel: "Remaining Invested Capital",
			Reserves: 0,
			total:
				Number(
					formatNumber(
						investments.remainingPrimaryCapitalToDeploy /
							fund.unitsOfRiskPrimary,
						1,
					),
				) - Number(formatNumber(fund.primaryReservesInUnitsOfRisk, 1)),
			[fund.primaryInvestmentLabel]:
				Number(
					formatNumber(
						investments.remainingPrimaryCapitalToDeploy /
							fund.unitsOfRiskPrimary,
						1,
					),
				) - Number(formatNumber(fund.primaryReservesInUnitsOfRisk, 1)),
		},
	];
	const plannedSecondaryUnitsData = extractSpacedUnits(
		fund.plannedInvestmentDeployments,
		(v) => v.secondaryAmountsInUnitsOfRisk,
		false,
	);

	const plannedlSecondaryDeployments = [
		{
			xLabel: "Total Fund",
			[fund.secondaryInvestmentLabel]: maxSecondaryUnits,
			total: maxSecondaryUnits,
			spacer: 0,
		},
		...plannedSecondaryUnitsData,
	];

	const actualSecondaryUnitsData = extractSpacedUnits(
		investments?.actualDeployments,
		(v) => v.secondaryAmountInUnitsOfRisk,
		false,
	);

	const actualSecondaryDeployments = [
		{
			xLabel: "Total Fund",
			[fund.secondaryInvestmentLabel]: maxSecondaryUnits,
			total: maxSecondaryUnits,
			spacer: 0,
		},
		...actualSecondaryUnitsData,
		{
			xLabel: "Remaining (excl Reserves)",
			Reserves: 0,
			[fund.secondaryInvestmentLabel]: formatNumber(
				investments.remainingSecondaryCapitalToDeploy /
					fund.unitsOfRiskSecondary,
				1,
			),
			spacer: 0,
			total:
				investments.remainingSecondaryCapitalToDeploy /
				fund.unitsOfRiskSecondary,
		},
		{
			xLabel: "Reserves (Committed)",
			[fund.secondaryInvestmentLabel]: 0,
			Reserves: 0,
			spacer: 0,
			total: 0,
		},
		{
			xLabel: "Remaining Invested Capital",
			Reserves: 0,
			spacer: 0,
			[fund.secondaryInvestmentLabel]: formatNumber(
				investments.remainingSecondaryCapitalToDeploy /
					fund.unitsOfRiskSecondary,
				1,
			),
			total:
				investments.remainingSecondaryCapitalToDeploy /
				fund.unitsOfRiskSecondary,
		},
	];

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide>
					<ContentSlide.Title>
						{fund.name}: Investment Period Deployment Pacing (Units of Risk)
					</ContentSlide.Title>
					<ContentSlide.Content>
						<Grid
							container
							spacing={3}
							direction="row"
							sx={{
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Grid item xs={6} mt={4}>
								<Typography variant="h3" textAlign="center">
									Planned {fund.primaryInvestmentLabel} Deployments{" "}
								</Typography>
								<WaterfallChart
									data={plannedPrimaryDeploymentsData}
									colors={waterFallColoring}
									width={525}
									showTotal
									showInsideLabels={false}
									formatter={(v) => Number(v).toFixed(1)}
								/>
							</Grid>
							<Grid item xs={6} mt={4}>
								<Typography variant="h3" textAlign="center">
									Actual {fund.primaryInvestmentLabel} Deployments{" "}
								</Typography>
								<WaterfallChart
									data={actualPrimaryDeploymentsData}
									colors={waterFallColoring}
									width={525}
									showTotal
									showInsideLabels={false}
									formatter={(v) => Number(v).toFixed(1)}
								/>
							</Grid>
							{fund.secondaryInvestmentLabel === "error" ? null : (
								<>
									<Grid item xs={6}>
										<Typography variant="h3" textAlign="center">
											Planned {fund.secondaryInvestmentLabel} Deployments{" "}
										</Typography>
										<WaterfallChart
											data={plannedlSecondaryDeployments}
											colors={waterFallColoring}
											width={525}
											showTotal
											showInsideLabels={false}
											formatter={(v) => Number(v).toFixed(1)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="h3" textAlign="center">
											Actual {fund.secondaryInvestmentLabel} Deployments{" "}
										</Typography>
										<WaterfallChart
											data={actualSecondaryDeployments}
											// data={plannedlSecondaryDeployments}
											colors={waterFallColoring}
											width={525}
											showTotal
											// showInsideLabels={false}
											formatter={(v) => Number(v).toFixed(1)}
										/>
									</Grid>
								</>
							)}
						</Grid>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>
			<SlideToggle.Data>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<div style={{ marginBottom: "30px" }}>
							<h4
								style={{
									marginBottom: "10px",
									borderBottom: "1px solid #ddd",
									paddingBottom: "5px",
								}}
							>
								Planned {fund.primaryInvestmentLabel} Deployments
							</h4>
							<table style={{ width: "100%" }}>
								<thead>
									<tr>
										<th style={{ textAlign: "left", padding: "8px 4px" }}>
											Year
										</th>
										<th style={{ textAlign: "right", padding: "8px 4px" }}>
											{fund.primaryInvestmentLabel} Amount
										</th>
									</tr>
								</thead>
								<tbody>
									<tr style={{ backgroundColor: "#f5f5f5" }}>
										<td
											style={{
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											Total Fund
										</td>
										<td
											style={{
												textAlign: "right",
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											{formatNumber(
												fund.primaryAllocation / fund.unitsOfRiskPrimary,
												1,
											)}
										</td>
									</tr>
									{fund.plannedInvestmentDeployments.map((deployment) => (
										<tr key={deployment.year}>
											<td style={{ padding: "8px 4px" }}>{deployment.year}</td>
											<td style={{ textAlign: "right", padding: "8px 4px" }}>
												{formatNumber(
													deployment.primaryAmountsInUnitsOfRisk,
													1,
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</Grid>

					<Grid item xs={6}>
						<div style={{ marginBottom: "30px" }}>
							<h4
								style={{
									marginBottom: "10px",
									borderBottom: "1px solid #ddd",
									paddingBottom: "5px",
								}}
							>
								Actual {fund.primaryInvestmentLabel} Deployments
							</h4>
							<table style={{ width: "100%" }}>
								<thead>
									<tr>
										<th style={{ textAlign: "left", padding: "8px 4px" }}>
											Year
										</th>
										<th style={{ textAlign: "right", padding: "8px 4px" }}>
											{fund.primaryInvestmentLabel} Amount
										</th>
									</tr>
								</thead>
								<tbody>
									<tr style={{ backgroundColor: "#f5f5f5" }}>
										<td
											style={{
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											Total Fund
										</td>
										<td
											style={{
												textAlign: "right",
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											{formatNumber(
												fund.primaryAllocation / fund.unitsOfRiskPrimary,
												1,
											)}
										</td>
									</tr>
									{investments?.actualDeployments.map((deployment) => (
										<tr key={deployment.year}>
											<td style={{ padding: "8px 4px" }}>{deployment.year}</td>
											<td style={{ textAlign: "right", padding: "8px 4px" }}>
												{formatNumber(deployment.primaryAmountInUnitsOfRisk, 1)}
											</td>
										</tr>
									))}
									<tr style={{ borderTop: "1px solid #ddd" }}>
										<td
											style={{
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											Remaining excl Reserves
										</td>
										<td
											style={{
												textAlign: "right",
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											{formatNumber(
												investments.remainingPrimaryCapitalToDeploy /
													fund.unitsOfRiskPrimary,
												1,
											)}
										</td>
									</tr>
									<tr>
										<td style={{ padding: "8px 4px" }}>Reserves (Committed)</td>
										<td style={{ textAlign: "right", padding: "8px 4px" }}>
											{formatNumber(fund.primaryReservesInUnitsOfRisk, 1)}
										</td>
									</tr>
									<tr style={{ backgroundColor: "#f5f5f5" }}>
										<td
											style={{
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											Remaining
										</td>
										<td
											style={{
												textAlign: "right",
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											{formatNumber(
												(investments.remainingPrimaryCapitalToDeploy -
													fund.primaryReserves) /
													fund.unitsOfRiskPrimary,
												1,
											)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Grid>

					<Grid item xs={6}>
						<div style={{ marginBottom: "30px" }}>
							<h4
								style={{
									marginBottom: "10px",
									borderBottom: "1px solid #ddd",
									paddingBottom: "5px",
								}}
							>
								Planned {fund.secondaryInvestmentLabel} Deployments
							</h4>
							<table style={{ width: "100%" }}>
								<thead>
									<tr>
										<th style={{ textAlign: "left", padding: "8px 4px" }}>
											Year
										</th>
										<th style={{ textAlign: "right", padding: "8px 4px" }}>
											{fund.secondaryInvestmentLabel} Amount
										</th>
									</tr>
								</thead>
								<tbody>
									<tr style={{ backgroundColor: "#f5f5f5" }}>
										<td
											style={{
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											Total Fund
										</td>
										<td
											style={{
												textAlign: "right",
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											{formatNumber(
												fund.secondaryAllocation / fund.unitsOfRiskSecondary,
												1,
											)}
										</td>
									</tr>
									{fund.plannedInvestmentDeployments.map((deployment) => (
										<tr key={deployment.year}>
											<td style={{ padding: "8px 4px" }}>{deployment.year}</td>
											<td style={{ textAlign: "right", padding: "8px 4px" }}>
												{formatNumber(
													deployment.secondaryAmountsInUnitsOfRisk,
													1,
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</Grid>

					<Grid item xs={6}>
						<div style={{ marginBottom: "30px" }}>
							<h4
								style={{
									marginBottom: "10px",
									borderBottom: "1px solid #ddd",
									paddingBottom: "5px",
								}}
							>
								Actual {fund.secondaryInvestmentLabel} Deployments
							</h4>
							<table style={{ width: "100%" }}>
								<thead>
									<tr>
										<th style={{ textAlign: "left", padding: "8px 4px" }}>
											Year
										</th>
										<th style={{ textAlign: "right", padding: "8px 4px" }}>
											{fund.secondaryInvestmentLabel} Amount
										</th>
									</tr>
								</thead>
								<tbody>
									<tr style={{ backgroundColor: "#f5f5f5" }}>
										<td
											style={{
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											Total Fund
										</td>
										<td
											style={{
												textAlign: "right",
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											{formatNumber(
												fund.secondaryAllocation / fund.unitsOfRiskSecondary,
												1,
											)}
										</td>
									</tr>
									{investments?.actualDeployments.map((deployment) => (
										<tr key={deployment.year}>
											<td style={{ padding: "8px 4px" }}>{deployment.year}</td>
											<td style={{ textAlign: "right", padding: "8px 4px" }}>
												{formatNumber(
													deployment.secondaryAmountInUnitsOfRisk,
													1,
												)}
											</td>
										</tr>
									))}
									<tr style={{ borderTop: "1px solid #ddd" }}>
										<td
											style={{
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											Remaining excl Reserves
										</td>
										<td
											style={{
												textAlign: "right",
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											{formatNumber(
												investments.remainingSecondaryCapitalToDeploy /
													fund.unitsOfRiskSecondary,
												1,
											)}
										</td>
									</tr>
									<tr>
										<td style={{ padding: "8px 4px" }}>Reserves (Committed)</td>
										<td style={{ textAlign: "right", padding: "8px 4px" }}>
											0
										</td>
									</tr>
									<tr style={{ backgroundColor: "#f5f5f5" }}>
										<td
											style={{
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											Remaining
										</td>
										<td
											style={{
												textAlign: "right",
												padding: "8px 4px",
												fontWeight: "bold",
											}}
										>
											{formatNumber(
												investments.remainingSecondaryCapitalToDeploy /
													fund.unitsOfRiskSecondary,
												1,
											)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Grid>
				</Grid>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide12;

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import type React from "react";

export default function SavedViews({
	views,
	value,
	onChange,
}: {
	views: { id: string; name: string }[];
	value: string;
	onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}) {
	const hasDefault = views?.some((view) => view.isDefault === true);

	return (
		<Box
			py={1}
			sx={{
				width: "100%",
			}}
		>
			<FormControl
				sx={{
					width: {
						xs: "100%",
						sm: "320px",
					},
				}}
				size="small"
			>
				<InputLabel id="views-select-label" shrink>
					Views
				</InputLabel>
				<Select
					value={value}
					onChange={onChange}
					disabled={!views?.length}
					displayEmpty
					size="small"
					id="views-select"
					labelId="views-select-label"
					label="Views"
					variant="outlined"
				>
					{hasDefault ? null : (
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
					)}
					{views
						?.filter((view) => view?.public)
						.map((view) => (
							<MenuItem key={view.id} value={view.id}>
								{view.name}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Box>
	);
}

import { Box, Paper, Skeleton } from "@mui/material";
import React from "react";

const SlideSkeletons = () => {
	return (
		<>
			{Array.from({ length: 10 }).map((_, i) => (
				<Paper
					key={`slide-skeleton-${i}`}
					sx={{
						width: "10in",
						height: "5.625in", // 16:9 ratio for 10 inch width
						mb: 2,
						p: 2,
						boxSizing: "border-box",
					}}
				>
					<Box sx={{ height: "10%", mb: 2 }}>
						<Skeleton variant="rectangular" width="60%" height="100%" />
					</Box>

					<Box sx={{ height: "75%", display: "flex", flexDirection: "column" }}>
						<Skeleton
							variant="rectangular"
							width="100%"
							height="33%"
							sx={{ mb: 2 }}
						/>
						<Skeleton
							variant="rectangular"
							width="100%"
							height="33%"
							sx={{ mb: 2 }}
						/>
						<Skeleton variant="rectangular" width="100%" height="33%" />
					</Box>

					<Box
						sx={{
							height: "10%",
							mt: 2,
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Skeleton variant="rectangular" width="30%" height="100%" />
						<Skeleton variant="rectangular" width="30%" height="100%" />
					</Box>
				</Paper>
			))}
		</>
	);
};

export default SlideSkeletons;

import { getAnswer, putAnswer } from "@/api/FrameworkSurvey";
import { getInvestorUsers } from "@/api/Users";
import { isInPartnerClass } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import { useDebounce } from "@/hooks/useDebounce";
import { useSocket } from "@/hooks/useSocket";
import Progress from "@/ui/atoms/Progress";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useMemo, useState } from "react";
import { useFormState } from "react-final-form";
import { useQuery, useQueryClient } from "react-query";
import DateInput from "./DateInput";
import DollarEstimate from "./DollarEstimate";
import DollarEstimateBizPersonalPrediction from "./DollarEstimateBizPersonalPrediction";
import DollarEstimateBizPersonalPredictionWithPeriod from "./DollarEstimateBizPersonalPredictionWithPeriod";
import DollarEstimateWithPeriod from "./DollarEstimateWithPeriod";
import FiscalYear from "./FiscalYear";
import FreeText from "./FreeText";
import FutureQuarter from "./FutureQuarter";
import MultiChoice from "./MultiChoice";
import MultipleUser from "./MultipleUser";
import ReturnDistribution from "./ReturnDistribution";
import RichText from "./RichText";
import SingleUser from "./SingleUser";

dayjs.extend(utc);

interface Props {
	question: {
		choices?: {
			choiceText: string;
			color: string;
			id: string;
		}[];
		questionType: string;
		id: string;
		commentRequired: boolean;
		showComment: boolean;
		skippable?: boolean;
		subtitle?: string;
	};
	responseId: string;
	valorId: string;
	name: string;
	// eslint-disable-next-line react/require-default-props
	subtitle?: string;
}

// eslint-disable-next-line react/display-name
export default React.memo((props: Props) => {
	const { question, name, subtitle, responseId, valorId } = props;
	const socket = useSocket();

	const [isSaving, setIsSaving] = useState(false);
	const [savedAt, setSavedAt] = useState(null);
	const [peerUpdateUser, setPeerUpdateUser] = useState(null);
	const [error, setError] = useState(false);
	const { user } = useAuth();

	const { questionType, id } = question;

	const formState = useFormState();
	const queryClient = useQueryClient();

	const { values = {}, modified = {} } = formState;
	const currentAnswer = values[id];

	const ids = [
		`${id}.choiceId`,
		`${id}.commentary`,
		`${id}.userIds`,
		`${id}.answerData`,
	];
	const isAnyDirty = ids.some((key) => modified[key]);

	const debouncedCurrentAnswer = useDebounce(currentAnswer, 500);

	const {
		refetch,
		data: priorAnswer = {},
		isLoading: isPriorAnswerLoading,
	} = useQuery(
		["PriorAnswer", responseId, id],
		async () => {
			const remoteAnswer = getAnswer(responseId, id);
			return remoteAnswer;
		},
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			staleTime: Number.POSITIVE_INFINITY,
			retry: 0,
			retryDelay: 0,
			enabled: false,
		},
	);

	useEffect(() => {
		if (socket) {
			socket.on("update_survey_question_answer", async (data) => {
				if (data.responseId === responseId && data.questionId === id) {
					// queryClient.invalidateQueries(["PriorAnswer", responseId, id]);
					// const response = await refetch();
					queryClient.setQueryData(
						["PriorAnswer", responseId, id],
						data.answerData,
					);
					setSavedAt(data.answerData.answeredAt);
					if (data.user.id !== user.id) {
						setPeerUpdateUser(data.user);
					}
				}
			});
		}
		return () => {
			if (socket) {
				socket.off("update_survey_question_answer");
			}
		};
	}, [socket, queryClient, responseId, refetch, id]);

	const isInitial = isPriorAnswerLoading
		? null
		: debouncedCurrentAnswer?.choiceId === priorAnswer?.choiceId &&
			debouncedCurrentAnswer?.commentary === priorAnswer?.commentary &&
			debouncedCurrentAnswer?.userIds === priorAnswer?.userIds &&
			debouncedCurrentAnswer?.answerData === priorAnswer?.answerData;

	const someValue =
		debouncedCurrentAnswer?.choiceId ||
		debouncedCurrentAnswer?.commentary ||
		debouncedCurrentAnswer?.userIds ||
		debouncedCurrentAnswer?.answerData;

	useEffect(() => {
		const asyncWrite = async () => {
			setIsSaving(true);
			setError(false);
			try {
				const response = await putAnswer(responseId, question.id, {
					...debouncedCurrentAnswer,
					answeredAt: dayjs().utc(),
					valorId,
				});
				if (response.error) {
					throw new Error(response.error);
				}
				if (response.answeredAt) {
					setSavedAt(response.answeredAt);
					queryClient.setQueryData(
						["PriorAnswer", responseId, question.id],
						response,
					);
					setPeerUpdateUser(null);
					// already in room
					socket.emit("update_survey_question_answer", {
						responseId,
						questionId: question.id,
						roomId: responseId,
						answerData: {
							...debouncedCurrentAnswer,
							answeredAt: dayjs().utc(),
							valorId,
						},
						user: {
							id: user.id,
							firstName: user.firstName,
							lastName: user.lastName,
							profilePicture: user.profilePicture,
						},
					});
				}
			} catch (e) {
				// show user error
				// set value to prior value ?
				console.log(e);
				setError(true);
			} finally {
				setIsSaving(false);
			}
		};
		if (isAnyDirty && !isInitial && someValue) {
			asyncWrite();
		}
	}, [
		debouncedCurrentAnswer,
		isAnyDirty,
		queryClient,
		question.id,
		someValue,
		valorId,
		responseId,
		isInitial,
		socket,
		setError,
		user.firstName,
		user.id,
		user.lastName,
		user.profilePicture,
	]);

	const { data: users, isLoading } = useQuery("Investors", getInvestorUsers);

	const questionGrid = useMemo(
		() => (
			<Grid
				container
				spacing={2}
				sx={{
					padding: 1,
					display: "flex",
				}}
			>
				{questionType === "MULTIPLE_CHOICE" && (
					<MultiChoice
						questionId={question.id}
						commentRequired={question.commentRequired}
						showComment={question.showComment}
						choices={question?.choices}
						initialValue={priorAnswer?.choiceId}
						initalComment={priorAnswer?.commentary}
						skippable={question.skippable}
					/>
				)}
				{questionType === "RETURN_DISTRIBUTION" && (
					<ReturnDistribution
						questionId={question.id}
						initialValue={priorAnswer?.answerData}
						initialComment={priorAnswer?.commentary}
					/>
				)}
				{questionType === "DOLLAR_ESTIMATE" && (
					<DollarEstimate
						questionId={question.id}
						initialValue={priorAnswer?.answerData}
						initialComment={priorAnswer?.commentary}
						skippable={question.skippable}
					/>
				)}
				{questionType === "QUARTER" && (
					<FutureQuarter
						questionId={question.id}
						initialComment={priorAnswer.commentary}
					/>
				)}
				{questionType === "TEXT" && (
					<FreeText
						questionId={question.id}
						skippable={question.skippable}
						initialValue={priorAnswer?.commentary}
					/>
				)}
				{questionType === "SINGLE_USER" &&
					(isLoading ? (
						<Progress />
					) : (
						<SingleUser
							questionId={question.id}
							users={users.filter((u) => !isInPartnerClass(u))}
							initialValue={priorAnswer?.userIds}
							skippable={question.skippable}
						/>
					))}
				{questionType === "RICH_TEXT" && (
					<RichText
						questionId={question.id}
						skippable={question.skippable}
						initialValue={priorAnswer?.commentary}
					/>
				)}
				{questionType === "SINGLE_USER_PARTNER" &&
					(isLoading ? (
						<Progress />
					) : (
						<SingleUser
							questionId={question.id}
							users={users.filter(isInPartnerClass)}
							initialValue={priorAnswer?.userIds}
						/>
					))}
				{questionType === "FISCAL_YEAR" && (
					<FiscalYear
						questionId={question.id}
						initialValue={priorAnswer.answerData}
					/>
				)}

				{questionType === "MULTIPLE_USER" &&
					(isLoading ? (
						<Progress />
					) : (
						<MultipleUser
							questionId={question.id}
							users={users}
							initialValue={priorAnswer?.userIds}
						/>
					))}
				{questionType === "DOLLAR_ESTIMATE_WITH_PERIOD_TYPE" && (
					<DollarEstimateWithPeriod
						questionId={question.id}
						initialValue={
							priorAnswer?.answerData
								? JSON.parse(priorAnswer?.answerData)
								: undefined
						}
						initialComment={priorAnswer?.commentary}
					/>
				)}
				{questionType === "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION" && (
					<DollarEstimateBizPersonalPrediction
						questionId={question.id}
						initialValue={
							priorAnswer?.answerData
								? JSON.parse(priorAnswer?.answerData)
								: undefined
						}
						initialComment={priorAnswer?.commentary}
					/>
				)}

				{questionType ===
					"DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE" && (
					<DollarEstimateBizPersonalPredictionWithPeriod
						questionId={question.id}
						initialValue={
							priorAnswer?.answerData
								? JSON.parse(priorAnswer?.answerData)
								: undefined
						}
						initialComment={priorAnswer?.commentary}
					/>
				)}

				{questionType === "DATE" && (
					<DateInput
						questionId={question.id}
						initialValue={
							priorAnswer?.answerData
								? JSON.parse(priorAnswer?.answerData)
								: undefined
						}
						initialComment={priorAnswer?.commentary}
						showComment={question.showComment}
						skippable={question.skippable}
					/>
				)}
			</Grid>
		),
		[
			question.id,
			questionType,
			question.choices,
			question.skippable,
			question.commentRequired,
			question.showComment,
			users,
			isLoading,
			priorAnswer?.choiceId,
			priorAnswer?.commentary,
			priorAnswer?.answerData,
			priorAnswer?.userIds,
		],
	);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					justifyContent: "flex-start",
					padding: 1,
					marginBottom: 1,
					background: (t) =>
						peerUpdateUser ? t.palette.background.blue : "white",
					borderRadius: (t) => t.spacing(1),
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography
						sx={{
							marginRight: 2,
							color: (theme) => theme.palette.text.secondary,
							fontWeight: (theme) => theme.typography.fontWeightBold,
						}}
						variant="h3"
					>
						{name}
					</Typography>
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						{isSaving ? <Progress /> : null}
						{savedAt ? (
							<Typography variant="caption">
								Saved at{" "}
								{dayjs.utc(savedAt).local().format("MM/DD/YYYY hh:mm A")}
							</Typography>
						) : null}
						{peerUpdateUser ? (
							<>
								<Typography variant="caption" sx={{ marginRight: "4px" }}>
									{" "}
									{` by ${peerUpdateUser.firstName}  ${peerUpdateUser.lastName}`}
								</Typography>
								<UserAvatar user={peerUpdateUser} displayTooltip />
							</>
						) : (
							savedAt && <Typography variant="caption">{" by you"}</Typography>
						)}
						{error && (
							<Typography
								sx={{ color: (t) => t.palette.error.main }}
								variant="caption"
							>
								{" Error saving, if this persists contact the labs team"}
							</Typography>
						)}
					</Box>
				</Box>
				<Typography
					sx={{
						marginRight: 2,
						color: (theme) => theme.palette.text.secondary,
						fontWeight: (theme) => theme.typography.fontWeightBold,
					}}
					variant="body2"
				>
					{subtitle}
				</Typography>
				<Typography
					sx={{
						marginRight: 2,
						color: (theme) => theme.palette.text.secondary,
						fontWeight: (theme) => theme.typography.fontWeightBold,
						whiteSpace: "pre-wrap",
					}}
					variant="body2"
					display="block"
				>
					{question.subtitle}
				</Typography>
			</Box>
			{isPriorAnswerLoading ? (
				<Box display="flex" flexDirection="column" gap={1}>
					<Box display="flex" justifyContent="space-between" gap={1}>
						<Skeleton variant="rounded" height={80} width="33%" />
						<Skeleton variant="rounded" height={80} width="33%" />
						<Skeleton variant="rounded" height={80} width="33%" />
					</Box>
					<Skeleton variant="rounded" height={80} width="100%" />
				</Box>
			) : (
				questionGrid
			)}
		</Box>
	);
});

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SortIcon from "@mui/icons-material/Sort";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
	Badge,
	Box,
	ButtonBase,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Popover,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import type React from "react";
import { useRef, useState } from "react";

import {
	type Column,
	type Header,
	type Table,
	flexRender,
} from "@tanstack/react-table";

import Filter from "../Filter";

type SortType = "alpha" | "numeric" | "date" | "boolean" | "default";

type HeaderCellProps = {
	header: Header<any, unknown>;
	column: Column<unknown>;
	table: Table<any>;
	shrink?: boolean;
};

type HeaderCellMenuProps = {
	name: string;
	sortType: SortType;
	canSort: boolean;
	canFilter: boolean;
	canHide: boolean;
	isSorted: false | "asc" | "desc";
	onFilterOptionClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
	onSortOptionClick: (order: false | "asc" | "desc") => void;
	onHideColumnClick: () => void;
};

const ColResize = styled("div")(({ theme }) => ({
	position: "absolute",
	right: 0,
	top: 0,
	height: "100%",
	width: "5px",
	cursor: "col-resize",
	userSelect: "none",
	touchAction: "none",
	zIndex: theme.layers.colResize,
	opacity: 0,
	"&:hover": {
		opacity: 1,
	},
}));

function FilterByEmpty() {
	return (
		<Box display="flex" flexDirection="row">
			<Typography
				variant="body2"
				sx={(theme) => ({
					px: 1,
					background: "rgba(0, 0, 0, 0.05)",
					display: "flex",
					alignItems: "center",
					borderRight: `1px solid ${theme.palette.divider}`,
				})}
			>
				Filter by
			</Typography>
			<FormGroup
				sx={{
					px: 1,
				}}
			>
				<FormControlLabel
					control={<Checkbox size="small" />}
					label={
						<Typography sx={{ fontSize: "14px" }}>Only empty cells</Typography>
					}
				/>
			</FormGroup>
		</Box>
	);
}

export function HeaderCellMenu({
	name,
	sortType,
	canSort,
	canFilter,
	canHide,
	isSorted,
	onFilterOptionClick,
	onSortOptionClick,
	onHideColumnClick,
}: HeaderCellMenuProps) {
	const ref = useRef(null);
	const [showContextMenu, setShowContextMenu] = useState(false);

	const sortLabelMap: { [key in SortType]: { asc: string; desc: string } } = {
		alpha: {
			asc: "Sort A to Z",
			desc: "Sort Z to A",
		},
		numeric: {
			asc: "Sort Low to High",
			desc: "Sort High to Low",
		},
		date: {
			asc: "Sort (Oldest First)",
			desc: "Sort (Newest First)",
		},
		boolean: {
			asc: "Sort (False First)",
			desc: "Sort (True First)",
		},
		default: {
			asc: "Sort Asc",
			desc: "Sort Desc",
		},
	};

	return (
		<Box>
			<ButtonBase
				sx={(theme) => ({
					borderRadius: 0.5,
					border: "2px solid",
					borderColor: theme.palette.divider,
					color: theme.palette.divider,
					width: "20px",
					height: "20px",
				})}
				onClick={() => setShowContextMenu(true)}
				ref={ref}
			>
				<KeyboardArrowDownIcon />
			</ButtonBase>
			{ref.current && showContextMenu && (
				<Popover
					id="header-cell-context-menu"
					open={showContextMenu}
					anchorEl={ref.current}
					onClose={() => setShowContextMenu(false)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<MenuList>
						{canFilter ? (
							<MenuItem
								disabled={!canFilter}
								onClick={(e) => {
									onFilterOptionClick(e);
									setShowContextMenu(false);
								}}
							>
								<ListItemIcon>
									<FilterListIcon />
								</ListItemIcon>
								<ListItemText>
									<Typography variant="body2">{`Filter by ${name}`}</Typography>
								</ListItemText>
							</MenuItem>
						) : null}
						{canSort && <Divider />}
						{canSort && (
							<MenuItem
								selected={isSorted === "asc"}
								onClick={() => {
									if (isSorted === "asc") {
										onSortOptionClick(false);
									} else {
										onSortOptionClick("asc");
									}
								}}
							>
								<ListItemIcon>
									<SortIcon
										sx={{
											transform: "scale(1, -1)",
										}}
									/>
								</ListItemIcon>
								<ListItemText>
									<Typography variant="body2">
										{sortLabelMap[sortType]?.asc || sortLabelMap.default.asc}
									</Typography>
								</ListItemText>
							</MenuItem>
						)}
						{canSort && (
							<MenuItem
								selected={isSorted === "desc"}
								onClick={() => {
									if (isSorted === "desc") {
										onSortOptionClick(false);
									} else {
										onSortOptionClick("desc");
									}
								}}
							>
								<ListItemIcon>
									<SortIcon />
								</ListItemIcon>
								<ListItemText>
									<Typography variant="body2">
										{sortLabelMap[sortType]?.desc || sortLabelMap.default.desc}
									</Typography>
								</ListItemText>
							</MenuItem>
						)}
						{canHide && (
							<>
								<Divider />
								<MenuItem onClick={onHideColumnClick}>
									<ListItemIcon>
										<VisibilityOffIcon />
									</ListItemIcon>
									<ListItemText>
										<Typography variant="body2">{`Hide ${name}`}</Typography>
									</ListItemText>
								</MenuItem>
							</>
						)}
					</MenuList>
				</Popover>
			)}
		</Box>
	);
}

export default function HeaderCell({
	header,
	column,
	table,
	shrink = false,
}: HeaderCellProps) {
	const ref = useRef(null);
	const [showFilter, setShowFilter] = useState(null);
	const theme = useTheme();

	if (header.isPlaceholder) {
		return null;
	}

	const { getContext } = header;

	const headerTitle = column.columnDef.header as string;
	const isReadOnly = column.columnDef.isReadOnly || false;
	return (
		<th
			colSpan={header.colSpan}
			style={{
				minWidth: header.getSize(),
				width: header.getSize(),
				maxWidth: header.getSize(), // shrink ? header.getSize() : null,
				padding: "8px",
				borderRight: `1px solid ${theme.palette.divider}`,
				background: column.getIsFiltered()
					? `${theme.palette.background.activeTableColumn} !important`
					: null,
			}}
			ref={ref}
		>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="flex-start"
				height="100%"
				sx={{
					overflow: "hidden",
					px: 1,
					py: 1,
				}}
			>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					gap={0.5}
					sx={{
						overflow: "hidden",
					}}
				>
					<Tooltip title={headerTitle || ""}>
						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							sx={{
								overflow: "hidden",
							}}
						>
							<Box
								sx={{
									display: "flex",
									minWidth: "20px",
									height: "32px",
									overflow: "hidden",
									alignItems: "center",
								}}
							>
								<Box>
									{{
										asc: (
											<Badge
												badgeContent={column.getSortIndex() + 1}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
											>
												<ArrowUpwardIcon fontSize="small" />
											</Badge>
										),
										desc: (
											<Badge
												badgeContent={column.getSortIndex() + 1}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
											>
												<ArrowDownwardIcon fontSize="small" />
											</Badge>
										),
									}[column.getIsSorted() as string] ?? null}
								</Box>
								<Box
									sx={(t) => ({
										"& > p": {
											color: column.columnDef?.isOrganizationField
												? `${t.palette.primary.main} !important`
												: null,
											fontWeight: 600,
											fontStyle: isReadOnly ? "italic" : null,
										},
									})}
								>
									{shrink ? (
										<Typography variant="body2">
											{flexRender(column.columnDef.header, getContext())}
											{/* {headerTitle} */}
										</Typography>
									) : (
										flexRender(column.columnDef.header, getContext())
									)}
								</Box>
							</Box>
						</Box>
					</Tooltip>
					{(column.getCanSort() || column.getCanFilter()) && (
						<HeaderCellMenu
							name={column.columnDef.header as string}
							sortType={
								column.columnDef.sortType ||
								column.columnDef.meta?.sortType ||
								"alpha"
							}
							canSort={column.getCanSort()}
							canFilter={column.getCanFilter()}
							canHide
							isSorted={column.getIsSorted()}
							onSortOptionClick={(order) => {
								if (order === false) {
									column.clearSorting();
								} else {
									column.toggleSorting(order === "desc", true);
								}
							}}
							onFilterOptionClick={() => {
								setShowFilter(!!column.columnDef.header);
							}}
							onHideColumnClick={() => {
								column.toggleVisibility();
							}}
						/>
					)}
				</Box>

				<ColResize
					onMouseDown={header.getResizeHandler()}
					onTouchStart={header.getResizeHandler()}
					sx={{
						transform: () => {
							if (column.getIsResizing()) {
								let offset = table.getState().columnSizingInfo.deltaOffset || 0;
								if (offset < 0) {
									offset = Math.max(
										offset,
										(column.columnDef.minSize || 0) - header.getSize(),
									);
								}
								return `translateX(${offset}px)`;
							}
							return null;
						},
						background: column.getIsResizing()
							? theme.palette.divider
							: theme.palette.divider,
						opacity: column.getIsResizing() ? 0.5 : null,
					}}
				/>

				{ref.current && showFilter && (
					<Popover
						id="header-cell-filter"
						open={showFilter}
						anchorEl={ref.current}
						onClose={() => setShowFilter(false)}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}
					>
						<Box display="flex" flexDirection="column">
							<Typography variant="body2" sx={{ padding: 1 }}>
								{column.columnDef.header}
							</Typography>
							<Divider />
							{column.columnDef.optional && (
								<>
									<FilterByEmpty />
									<Divider />
								</>
							)}
							{column.columnDef.filter ? (
								<Filter
									header={column.columnDef.header}
									component={column.columnDef.filter}
									getFilterValue={column.getFilterValue}
									setFilterValue={column.setFilterValue}
									getFacetedUniqueValues={column.getFacetedUniqueValues}
									field={column.columnDef.meta?.field}
									columnId={column.id}
								/>
							) : null}
							{/* <Filter
                header={column.columnDef.header}
                component={column.columnDef.filter}
                getFilterValue={column.getFilterValue}
                setFilterValue={column.setFilterValue}
                getFacetedUniqueValues={column.getFacetedUniqueValues}
                field={column.columnDef.meta?.field}
                columnId={column.id}
              /> */}
						</Box>
					</Popover>
				)}
			</Box>
		</th>
	);
}

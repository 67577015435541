import axios from "axios";
import type { paths } from "./index";

export type GetFundsResponse =
	paths["/api/fund-management/funds"]["get"]["responses"]["200"]["content"]["application/json"];
export type GetFundByIdResponse =
	paths["/api/fund-management/funds/{fund_entity_id}"]["get"]["responses"]["200"]["content"]["application/json"];
export type GetFundInvestmentsResponse =
	paths["/api/fund-management/funds/{fund_entity_id}/investments"]["get"]["responses"]["200"]["content"]["application/json"];

export type createFundCompanyOverrideRequestBody =
	paths["/api/fund-management/funds/{fund_entity_id}/overrides/{valor_id}"]["post"]["requestBody"]["content"]["application/json"];
export type createFundCompanyOverrideResponse =
	paths["/api/fund-management/funds/{fund_entity_id}/overrides/{valor_id}"]["post"]["responses"]["201"]["content"]["application/json"];

export const getFunds = async (): Promise<GetFundsResponse> => {
	const response = await axios.get("/api/fund-management/funds");
	return response.data;
};

export const getFundById = async (id: number): Promise<GetFundByIdResponse> => {
	const response = await axios.get(`/api/fund-management/funds/${id}`);
	return response.data;
};

export const getFundInvestments = async (
	id: number,
): Promise<GetFundInvestmentsResponse> => {
	const response = await axios.get(
		`/api/fund-management/funds/${id}/investments`,
	);
	return response.data;
};

export const createFundCompanyOverride = async (
	fundEntityId: string | number, // Accepting both string and number based on API (string) and existing pattern (number)
	valorId: string, // Assuming ValorUUID is a string type
	key: string,
	value: string,
	commentary: string,
): Promise<createFundCompanyOverrideResponse> => {
	const response = await axios.post(
		`/api/fund-management/funds/${fundEntityId}/overrides/${valorId}`,
		{
			key,
			value,
			commentary,
		},
	);
	return response.data;
};

export const deleteFundCompanyOverride = async (
	fundEntityId: string | number,
	valorId: string,
	key: string,
): Promise<void> => {
	await axios.delete(
		`/api/fund-management/funds/${fundEntityId}/overrides/${valorId}/${key}`,
	);
};

export const createFundOverride = async (
	fundEntityId: string | number,
	key: string,
	value: string,
	commentary: string,
) => {
	const response = await axios.post(
		`/api/fund-management/funds/${fundEntityId}/overrides`,
		{
			key,
			value,
			commentary,
		},
	);
	return response.data;
};

export default {
	getFunds,
	getFundById,
	getFundInvestments,
};

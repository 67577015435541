import { Box, Chip, ClickAwayListener, useTheme } from "@mui/material";
import type React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import OverflowBox from "./OverflowBox";

export default function ChipCell({
	value,
}: {
	value?: { id: string; value: string; meta: string }[];
}) {
	const [showOverflow, setShowOverflow] = useState(false);
	const anchorRef = useRef<null | HTMLElement>(null);
	const theme = useTheme();

	const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setShowOverflow(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowOverflow(false);
	}, []);

	const parseColor = useCallback((meta: string) => {
		if (!meta) {
			return {
				color: undefined,
				type: undefined,
			};
		}
		const [color, type] = meta.split(".");
		return {
			color,
			type,
		};
	}, []);

	if (!value || value?.length === 0) {
		return <>-</>;
	}
	const chips = value.map((x) => {
		const { color, type } = parseColor(x.meta);
		const themeColor = theme.palette?.[color]?.[type];

		return (
			<Chip
				key={x.id}
				sx={
					color
						? {
								backgroundColor: themeColor,
								color: theme.palette.getContrastText(themeColor),
							}
						: null
				}
				label={x.value || x}
				size="small"
			/>
		);
	});

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<Box maxHeight="100px" position="relative" display="flex" height="100%">
				<Box
					ref={anchorRef}
					display="flex"
					flexDirection="row"
					alignItems="center"
					gap={1}
					overflow="hidden"
					visibility={showOverflow ? "hidden" : "visible"}
					sx={{
						cursor: "pointer",
					}}
					onClick={handleClick}
				>
					{chips}
				</Box>
				<OverflowBox
					open={showOverflow}
					anchorEl={anchorRef.current}
					onClose={handleClose}
				>
					{chips}
				</OverflowBox>
			</Box>
		</ClickAwayListener>
	);
}

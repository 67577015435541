import WarningIcon from "@mui/icons-material/Warning";
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Tooltip,
} from "@mui/material";
import React from "react";

interface MissingOrganizationsProps {
	investments: any; // You might want to define a more specific type based on your data structure
}

const MissingOrganizations: React.FC<MissingOrganizationsProps> = ({
	investments,
}) => {
	const [open, setOpen] = React.useState(false);
	const missingOrgInvestments = React.useMemo(() => {
		return investments.filter((investment: any) => !investment.organization);
	}, [investments]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			{missingOrgInvestments.length > 0 && (
				<Tooltip title="Missing Organizations">
					<IconButton color="error" onClick={handleClickOpen}>
						<WarningIcon />
					</IconButton>
				</Tooltip>
			)}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Missing Organizations</DialogTitle>
				<DialogContent>
					{missingOrgInvestments.length > 0 ? (
						<List>
							{missingOrgInvestments.map((investment: any, index: number) => (
								<ListItem key={investment.entityId}>
									<ListItemText
										primary={investment.name || "Unnamed Investment"}
										secondary={`Entity Id: ${investment.entityId || "N/A"}`}
									/>
								</ListItem>
							))}
						</List>
					) : (
						<Alert severity="success">
							All investments have mapped organizations.
						</Alert>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default MissingOrganizations;

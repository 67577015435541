import type { getFundInvestments } from "@/api/FundManagement";
import type Fund from "./Fund";
import InvestmentOrganization from "./InvestmentOrganization";

import dayjs from "dayjs";
import type { DataKey } from "recharts/types/util/types";

type GetFundInvestmentsResponse = ReturnType<typeof getFundInvestments>;
type InvestmentsResponse = Awaited<GetFundInvestmentsResponse>;
type Investment = InvestmentsResponse["data"][number];

export default class FundInvestments extends Array<Investment> {
	private fund?: Fund;
	private orgOverrides: Record<string, any>;
	private asOfDate: Date;

	constructor(
		investments: Investment[],
		fund?: Fund,
		localOrgOverrides: Record<
			string,
			{
				exitValue: number;
				ownershipPercent: number;
				liquidityYear: number;
			}
		> = {},
		asOfDate: Date = null,
	) {
		// Call super first with length
		super();

		this.fund = fund;
		this.orgOverrides = localOrgOverrides;
		this.asOfDate = asOfDate;

		// Then copy the items
		if (Array.isArray(investments)) {
			// filter out any investments after asOfDate
			investments?.forEach((investment, index) => {
				// if (
				//   dayjs(investment.investmentDate) <= dayjs(asOfDate) ||
				//   !investment.investmentDate
				// ) {
				const siblingInvestments = investments.filter(
					({ organization }) =>
						organization &&
						organization?.valorId === investment.organization?.valorId,
				);

				this.push({
					...investment,
					// add logic here where if any sibling investments are primary,
					// else use investments designation
					fundInternalDesignation: investment.organization
						? siblingInvestments.reduce((acc, curr) => {
								if (acc === "Primary") return acc;
								return curr.fundInternalDesignation;
							}, "")
						: investment.fundInternalDesignation,
					organization: investment.organization
						? new InvestmentOrganization(
								investment.organization,
								siblingInvestments,
								localOrgOverrides[investment.organization?.valorId] || [],
							)
						: null,
				});
				// }
			});
		}
	}

	private _actualDeployments:
		| {
				year: number;
				primaryAmount: number;
				secondaryAmount: number;
				amount: number;
				primaryAmountInUnitsOfRisk: number;
				secondaryAmountInUnitsOfRisk: number;
		  }[]
		| null = null;

	get actualDeployments(): {
		year: number;
		primaryAmount: number;
		secondaryAmount: number;
		amount: number;
		primaryAmountInUnitsOfRisk: number;
		secondaryAmountInUnitsOfRisk: number;
	}[] {
		if (this._actualDeployments !== null) {
			return this._actualDeployments;
		}

		// Create a map of year -> { primary, secondary, amount }
		const deployments = new Map<
			number,
			{ primary: number; secondary: number; amount: number }
		>();

		// Loop through investments and add amounts to appropriate year buckets
		this.forEach((investment) => {
			const year = new Date(investment.investmentDate).getFullYear();
			if (year === 1969) {
				console.log("Bad Investment Date: ", investment);
			}
			const isPrimary = investment.fundInternalDesignation === "Primary";
			const amount = investment.investedCapital;

			if (amount === null || amount === 0) {
				console.log("Bad Investment Amount: ", investment);
			}

			if (!deployments.has(year)) {
				deployments.set(year, { primary: 0, secondary: 0, amount: 0 });
			}

			const yearData = deployments.get(year);
			if (isPrimary) {
				yearData.primary += amount;
			} else {
				yearData.secondary += amount;
			}
			yearData.amount += amount;
		});

		// Convert map to array of objects sorted by year
		this._actualDeployments = Array.from(deployments.entries())
			.map(([year, data]) => ({
				year,
				primaryAmount: data.primary,
				secondaryAmount: data.secondary,
				amount: data.amount,
				primaryAmountInUnitsOfRisk: data.primary / this.fund.unitsOfRiskPrimary,
				secondaryAmountInUnitsOfRisk:
					data.secondary / this.fund.unitsOfRiskSecondary,
			}))
			.filter(
				(deployment) => deployment.year > 1969 && deployment.amount !== null,
			)
			.sort((a, b) => a.year - b.year);

		return this._actualDeployments;
	}
	get actualPrimaryCapitalDeployed(): number {
		const currentYear = new Date().getFullYear();
		return this._actualDeployments.reduce((acc, deployment) => {
			if (deployment.year <= currentYear) {
				return acc + deployment.primaryAmount;
			}
			return acc;
		}, 0);
	}

	get actualSecondaryCapitalDeployed(): number {
		const currentYear = new Date().getFullYear();
		return this._actualDeployments.reduce((acc, deployment) => {
			if (deployment.year <= currentYear) {
				return acc + deployment.secondaryAmount;
			}
			return acc;
		}, 0);
	}

	get remainingPrimaryCapitalToDeploy(): number {
		const totalDeployed = this._actualDeployments.reduce(
			(acc, deployment) => acc + deployment.primaryAmount,
			0,
		);

		return this.fund.plannedPrimaryInvestedCapital - totalDeployed;
	}

	get remainingSecondaryCapitalToDeploy(): number {
		const totalDeployed = this._actualDeployments.reduce(
			(acc, deployment) => acc + deployment.secondaryAmount,
			0,
		);

		// ░░░░░░░░▄▄▄▀▀▀▄▄███▄░░░░░░░░░░░░░░
		// ░░░░░▄▀▀░░░░░░░▐░▀██▌░░░░░░░░░░░░░
		// ░░░▄▀░░░░▄▄███░▌▀▀░▀█░░░░░░░░░░░░░
		// ░░▄█░░▄▀▀▒▒▒▒▒▄▐░░░░█▌░░░░░░░░░░░░
		// ░▐█▀▄▀▄▄▄▄▀▀▀▀▌░░░░░▐█▄░░░░░░░░░░░
		// ░▌▄▄▀▀░░░░░░░░▌░░░░▄███████▄░░░░░░
		// ░░░░░░░░░░░░░▐░░░░▐███████████▄░░░
		// ░░░░░le░░░░░░░▐░░░░▐█████████████▄
		// ░░░░toucan░░░░░░▀▄░░░▐█████████████▄
		// ░░░░░░has░░░░░░░░▀▄▄███████████████
		// ░░░░░arrived░░░░░░░░░░░░█▀██████░░
		return this.fund.secondaryAllocation - totalDeployed;
	}

	get remainingCapitalToDeploy(): number {
		return (
			this.remainingPrimaryCapitalToDeploy +
			this.remainingSecondaryCapitalToDeploy
		);
	}
	get remainingCapitalToDeployByYear(): Array<{
		year: number;
		amount: number;
		primaryAmount: number;
		secondaryAmount: number;
	}> {
		const currentYear = new Date().getFullYear();
		// ░░░░░░░░▄▄▄▀▀▀▄▄███▄░░░░░░░░░░░░░░
		// ░░░░░▄▀▀░░░░░░░▐░▀██▌░░░░░░░░░░░░░
		// ░░░▄▀░░░░▄▄███░▌▀▀░▀█░░░░░░░░░░░░░
		// ░░▄█░░▄▀▀▒▒▒▒▒▄▐░░░░█▌░░░░░░░░░░░░
		// ░▐█▀▄▀▄▄▄▄▀▀▀▀▌░░░░░▐█▄░░░░░░░░░░░
		// ░▌▄▄▀▀░░░░░░░░▌░░░░▄███████▄░░░░░░
		// ░░░░░░░░░░░░░▐░░░░▐███████████▄░░░
		// ░░░░░le░░░░░░░▐░░░░▐█████████████▄
		// ░░░░toucan░░░░░░▀▄░░░▐█████████████▄
		// ░░░░░░has░░░░░░░░▀▄▄███████████████
		// ░░░░░arrived░░░░░░░░░░░░█▀██████░░
		//
		// this.fund.deploymentPeriodDesign = should be assumed investment period
		const endYear = this.fund.vintageYear + this.fund.deploymentPeriodDesign;
		const remainingYears = endYear - currentYear;

		const remainingCapital = this.remainingCapitalToDeploy;
		const remainingPrimaryCapital = this.remainingPrimaryCapitalToDeploy;
		const remainingSecondaryCapital = this.remainingSecondaryCapitalToDeploy;
		const yearlyDeployments = [];

		const fullYearPercentage = 1 / remainingYears;
		const fractionPercentage =
			1 - fullYearPercentage * Math.floor(remainingYears);

		for (let i = 0; i < Math.floor(remainingYears); i++) {
			const year = currentYear + i;
			const amount = remainingCapital * fullYearPercentage;
			const primaryAmount = remainingPrimaryCapital * fullYearPercentage;
			const secondaryAmount = remainingSecondaryCapital * fullYearPercentage;
			yearlyDeployments.push({
				year,
				amount,
				primaryAmount,
				secondaryAmount,
			});
		}

		// add fraction percentage
		if (remainingYears !== Math.floor(remainingYears)) {
			const amount = remainingCapital * fractionPercentage;
			const primaryAmount = remainingPrimaryCapital * fractionPercentage;
			const secondaryAmount = remainingSecondaryCapital * fractionPercentage;
			yearlyDeployments.push({
				year: currentYear + Math.floor(remainingYears),
				amount,
				primaryAmount,
				secondaryAmount,
			});
		}

		return yearlyDeployments;
	}

	get companies(): Array<InvestmentOrganization> {
		const companiesMap = new Map<string, InvestmentOrganization>();

		this.forEach((investment) => {
			const company = investment.organization;
			if (company) {
				companiesMap.set(company?.valorId, company);
			}
		});

		return Array.from(companiesMap.values());
	}

	get currentRealizedValue(): number {
		return this.reduce((acc, inv) => acc + inv.realizedValue, 0);
	}

	get projectedRealizedValue(): number {
		return this.companies.reduce(
			(acc, org) => acc + (org?.projectedRealizedValue || 0),
			0,
		);
	}

	get totalInvestedCapital(): number {
		return this.reduce(
			(acc, investment) => acc + investment.investedCapital,
			0,
		);
	}

	get totalInvestedCapitalAsPercentage() {
		if (this.fund.totalFundSize <= 0) return "N/A";
		return (this.totalInvestedCapital / this.fund.totalFundSize) * 100;
	}

	get totalFeePayingInvestedCapitalAsPercentage() {
		if (this.fund.feePayingCapital <= 0) return "N/A";
		return (
			(this.totalInvestedCapital / this.fund.feePayingCapitalCommitted) * 100
		);
	}

	get projectedGrossMoic(): number {
		return this.projectedRealizedValue / this.totalInvestedCapital;
	}

	get yearlyLiquidityProjections(): {
		year: number;
		estimatedLiquidity: number;
	}[] {
		// First, get unique years from effectiveLiquidityYear
		const possibleYears = this.companies
			? Array.from(
					new Set(
						this.companies
							.map((company) => Number(company.effectiveLiquidityYear))
							.filter((year) => year > 0),
					),
				).sort((a, b) => a - b) // Sort years in ascending order
			: [];

		// get min max
		const minYear = Math.min(...possibleYears);
		const maxYear = Math.max(...possibleYears);

		// Recreate years array using minYear and maxYear
		const years = Array.from(
			{ length: maxYear - minYear + 1 },
			(_, i) => minYear + i,
		);

		// Calculate sum of projectedRealizedValue for each year
		const yearProjections =
			years && this.companies
				? years.map((year) => ({
						year,
						estimatedLiquidity: this.companies
							.filter(
								(company) => Number(company.effectiveLiquidityYear) === year,
							)
							.reduce(
								(sum, company) => sum + (company.unrealizedValue || 0),
								0,
							),
					}))
				: [];

		return yearProjections;
	}
}

import { Person } from "@mui/icons-material";
import { Box, Chip, ClickAwayListener, Tooltip } from "@mui/material";
import type React from "react";
import { useCallback, useRef, useState } from "react";
import OverflowBox from "./OverflowBox";

type PersonValue = {
	id: string;
	firstName: string;
	lastName: string;
	primaryEmail: string;
};

const renderPersons = (persons: PersonValue[], showLabel = true) =>
	persons.map((person) => {
		const fullName = `${person.firstName} ${person.lastName}`;
		const initials = `${person.firstName} ${person.lastName[0].toUpperCase()}`;

		if (!showLabel) {
			return (
				<Tooltip title={`${fullName} (${person.primaryEmail})`} key={person.id}>
					<Chip label={initials} sx={{ height: "25px" }} />
				</Tooltip>
			);
		}

		return (
			<Tooltip title={person.primaryEmail} key={person.id}>
				<Chip label={fullName} avatar={<Person />} sx={{ height: "25px" }} />
			</Tooltip>
		);
	});

export default function PersonCell({
	value,
	showLabel = true,
}: {
	value: PersonValue[];
	showLabel?: boolean;
}) {
	const [showOverflow, setShowOverflow] = useState(false);
	const anchorRef = useRef<null | HTMLElement>(null);

	const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setShowOverflow(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowOverflow(false);
	}, []);

	if (!value || !Array.isArray(value)) return <>-</>;

	return (
		<ClickAwayListener onClickAway={() => setShowOverflow(false)}>
			<Box position="relative">
				<Box
					ref={anchorRef}
					display="inline-flex"
					flexWrap="nowrap"
					alignItems="center"
					gap={0.5}
					overflow="hidden"
					whiteSpace="nowrap"
					width="100%"
					sx={{
						overflow: "hidden",
						cursor: "pointer",
					}}
					onClick={handleClick}
				>
					{renderPersons(value, showLabel)}
				</Box>
				<OverflowBox
					open={showOverflow}
					anchorEl={anchorRef.current}
					onClose={handleClose}
				>
					{renderPersons(value, true)}
				</OverflowBox>
			</Box>
		</ClickAwayListener>
	);
}

import {
	Box,
	Checkbox,
	MenuItem,
	MenuList,
	Radio,
	Skeleton,
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import { color } from "@mui/system";
import { uniq } from "lodash";
import React, { useEffect, useCallback, useMemo } from "react";
import { useQuery } from "react-query";

export const uniqFlatSort = (arr: string[]): string[] =>
	uniq(arr.flat()).sort();

type Option =
	| {
			label: string;
			value: string | number;
	  }
	| string;

type FilterProps = {
	getFilterValue: () => any;
	setFilterValue: (value: any) => void;
	getFacetedUniqueValues: () => Map<any, any>;
};

type SelectFilterProps = {
	columnId: string;
	options?: Option[];
	onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
	multiple?: boolean;
	getOptionsLabel?: (value: any) => string | React.ReactNode;
	getOptionsValue?: (value: any) => string;
	getUniqueVal?: (value: any) => string;
	filterOptions?: (options: any[]) => any[];
	getFilterCounts?: () => Promise<Array<{ label: string; count: number }>>;
};

// Memoized styled components
const DenseMenuList = styled(MenuList)(() => ({
	padding: 0,
	maxHeight: "300px",
	overflow: "auto",
}));

const DenseMenuItem = styled(MenuItem)(() => ({
	padding: "4px",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: 1,
	fontSize: "14px",
	maxHeight: "30px",
	cursor: "pointer",
	minWidth: 144,
}));

const FilterCount = React.memo(
	({
		isLoading,
		count,
		label,
	}: {
		isLoading: boolean;
		count: number | null;
		label: string | React.ReactNode;
	}) => {
		console.log({ label, count });
		let render = null;

		if (isLoading) {
			render = <Skeleton variant="text" width="24px" />;
		} else if (count === undefined) {
			render = 0;
		} else {
			render = count;
		}

		return (
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				fontSize="12px"
				color="text.secondary"
				ml={1}
				width={24}
			>
				{render}
			</Box>
		);
	},
);

const FilterMenuItem = React.memo(
	({
		option,
		isChecked,
		Control,
		getOptionsLabel,
		getOptionsValue,
		getUniqueVal,
		filterCounts,
		isLoadingCounts,
		showCounts,
		onClick,
	}: {
		option: Option;
		isChecked: boolean;
		Control: typeof Checkbox | typeof Radio;
		getOptionsLabel: (value: any) => string | React.ReactNode;
		getOptionsValue: (value: any) => string;
		getUniqueVal: (value: any) => string;
		isLoadingCounts: boolean;
		filterCounts: Record<string, number> | null;
		showCounts: boolean;
		onClick: (event: React.MouseEvent) => void;
	}) => (
		<DenseMenuItem
			key={getUniqueVal(getOptionsValue(option)) ?? getOptionsValue(option)}
			value={getOptionsValue(option)}
			onClick={onClick}
		>
			{showCounts && (
				<FilterCount
					isLoading={isLoadingCounts}
					count={
						filterCounts
							? filterCounts[getOptionsLabel(option) as string]
							: null
					}
					label={getOptionsLabel(option)}
				/>
			)}
			<Control size="small" checked={isChecked} />
			{getOptionsLabel(option)}
		</DenseMenuItem>
	),
);

export function SelectFilter({
	columnId,
	getFilterValue,
	setFilterValue,
	getFacetedUniqueValues,
	multiple = false,
	options = null,
	getOptionsLabel = (value: any) => value,
	getOptionsValue = (value: any) => value,
	getUniqueVal = (value: any) => value,
	filterOptions = (opt: any[]) => opt,
	getFilterCounts = null,
	onChange = null,
}: FilterProps & SelectFilterProps) {
	const columnFilterValue = getFilterValue();

	const { data: filterCounts, isLoading } = useQuery(
		["filterCounts", columnId],
		getFilterCounts,
		{
			enabled: !!getFilterCounts,
			initialData: null,
			select: (data) =>
				data?.reduce(
					(acc, { label, count }) => {
						acc[label] = count;
						return acc;
					},
					{} as Record<string, number>,
				),
		},
	);

	const sortedUniqueValues = useMemo(() => {
		if (options) {
			return filterOptions(options);
		}
		const possibleValues = getFacetedUniqueValues().keys();
		return filterOptions(
			Array.from(possibleValues).flat().filter(Boolean).sort(),
		);
	}, [getFacetedUniqueValues, options, filterOptions]);

	const isChecked = useCallback(
		(optionValue: any) => {
			if (multiple) {
				if (!columnFilterValue) return false;
				const b = getUniqueVal(optionValue);
				return !!columnFilterValue
					?.map(getOptionsValue)
					.map(getUniqueVal)
					?.includes(getOptionsValue(b));
			}
			const a = getUniqueVal(columnFilterValue);
			const b = getOptionsValue(getUniqueVal(optionValue));
			return a === b;
		},
		[columnFilterValue, multiple, getUniqueVal, getOptionsValue],
	);

	const handleClick = useCallback(
		(option: Option) => (event: React.MouseEvent) => {
			if (multiple) {
				let value = (columnFilterValue as []) || [];
				const optionValue = getUniqueVal(option);
				const index = value
					.map(getUniqueVal)
					.map(getOptionsValue)
					.indexOf(getOptionsValue(optionValue));

				if (index === -1) {
					value = [...value, optionValue];
				} else {
					value = [...value.slice(0, index), ...value.slice(index + 1)];
				}

				if (value.length === 0) {
					value = undefined;
				}

				if (onChange) {
					onChange({
						...event,
						target: { ...event.target, value },
					} as React.ChangeEvent<{ value: unknown }>);
				} else {
					setFilterValue(value);
				}
			} else {
				if (onChange) {
					onChange({
						...event,
						target: { ...event.target, value: getOptionsValue(option) },
					} as React.ChangeEvent<{ value: unknown }>);
				} else if (
					getUniqueVal(columnFilterValue) ===
					getUniqueVal(getOptionsValue(option))
				) {
					setFilterValue(undefined);
				} else {
					setFilterValue(getOptionsValue(option));
				}
			}
		},
		[
			multiple,
			columnFilterValue,
			onChange,
			setFilterValue,
			getUniqueVal,
			getOptionsValue,
		],
	);

	const Control = multiple ? Checkbox : Radio;

	return (
		<Box>
			<DenseMenuList>
				{sortedUniqueValues.map((option) => (
					<FilterMenuItem
						key={getUniqueVal(getOptionsValue(option))}
						isLoadingCounts={isLoading}
						option={option}
						isChecked={isChecked(option)}
						Control={Control}
						getOptionsLabel={getOptionsLabel}
						getOptionsValue={getOptionsValue}
						getUniqueVal={getUniqueVal}
						filterCounts={filterCounts}
						showCounts={getFilterCounts !== null}
						onClick={handleClick(option)}
					/>
				))}
			</DenseMenuList>
		</Box>
	);
}

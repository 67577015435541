import type { ResponsesSearchResult } from "@/api/FrameworkSurvey";
import { useMemo } from "react";
import { STANDALONE_COMMENTARY } from "../constants";

export default function useRichCommentary(
	activeSurvey: ResponsesSearchResult[0][0],
) {
	return useMemo(() => {
		const p2pSurvey = activeSurvey;
		if (!p2pSurvey) return null;

		const getRichCommentary = (
			survey: ResponsesSearchResult[0][0],
			attributeId: string,
		) => survey.answers[attributeId].commentary;

		return {
			management: getRichCommentary(
				p2pSurvey,
				STANDALONE_COMMENTARY.Management,
			),
			growth: getRichCommentary(p2pSurvey, STANDALONE_COMMENTARY.Growth),
			market: getRichCommentary(p2pSurvey, STANDALONE_COMMENTARY.Market),
			keyUnderwriting: getRichCommentary(
				p2pSurvey,
				STANDALONE_COMMENTARY["Key Underwriting Variances"],
			),
		};
	}, [activeSurvey]);
}

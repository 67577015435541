import { formatAsMillions, formatNumber } from "@/utils/numberFormat";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import renderCustomizedLabel from "./shared/Label";
import CustomLegend from "./shared/Legend";
import CustomTooltip from "./shared/Tooltip";

import { PRIMARY_COLOR, SECONDARY_COLOR, TERTIARY_COLOR } from "../constants";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";
import { Table, useFundManagementTable } from "./shared/Table";

const SectionTitle = styled(Typography)(({ theme }) => ({
	fontWeight: "bold",
	marginBottom: theme.spacing(1),
	marginTop: theme.spacing(1.5),
	textDecoration: "underline",
	textAlign: "center",
}));

const ChartContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	borderRadius: theme.shape.borderRadius,
	px: theme.spacing(1),
	height: 320,
}));

function Slide3({ fund }) {
	// Prepare data for the quarterly values table
	const getQuarterlyTableData = () => {
		if (!fund || !fund.quarters) return [];

		return fund.quarters
			.toSorted((a, b) => a.year - b.year || a.quarter - b.quarter)
			.map((quarter) => ({
				Quarter: quarter.quarter,
				Year: quarter.year,
				"Total Capital Called": formatAsMillions(quarter.totalCapitalCalled),
				"Capital Call Fund Expenses": formatAsMillions(
					quarter.capitalCallFundExpenses,
				),
				"Capital Call Syndication Costs": formatAsMillions(
					quarter.capitalCallSyndicationCosts,
				),
				"Capital Call Org Costs": formatAsMillions(quarter.capitalCallOrgCosts),
				"Capital Call Management Fees": formatAsMillions(
					quarter.capitalCallManagementFees,
				),
				"Capital Call Investments": formatAsMillions(
					quarter.capitalCallInvestments,
				),
			}));
	};

	// Set up columns for the quarterly table
	const quarterlyTableData = getQuarterlyTableData();
	const { columns: quarterlyColumns } = useFundManagementTable({
		data: quarterlyTableData,
	});

	// Column order to match the original table
	const columnOrder = [
		"Quarter",
		"Year",
		"Total Capital Called",
		"Capital Call Fund Expenses",
		"Capital Call Syndication Costs",
		"Capital Call Org Costs",
		"Capital Call Management Fees",
		"Capital Call Investments",
	];

	// Prepare data for charts
	const getYearlyChartData = () => {
		if (!fund || !fund.quarters) return [];

		const yearlyTotals = fund.quarters.reduce((acc, quarter) => {
			if (!acc[quarter.year]) {
				acc[quarter.year] = {
					year: quarter.year,
					"Fund Expenses": 0,
					"Management Fees": 0,
					Investments: 0,
					"Total Capital Called": 0,
				};
			}
			// Fund Expenses now include org expenses, syndication costs and fund expenses
			acc[quarter.year]["Fund Expenses"] +=
				quarter.capitalCallFundExpenses +
				quarter.capitalCallSyndicationCosts +
				quarter.capitalCallOrgCosts;

			acc[quarter.year]["Management Fees"] += quarter.capitalCallManagementFees;
			acc[quarter.year].Investments += quarter.capitalCallInvestments;
			acc[quarter.year]["Total Capital Called"] += quarter.totalCapitalCalled;
			return acc;
		}, {});

		const yearData = Object.values(yearlyTotals).sort(
			(a, b) => a.year - b.year,
		);

		// Add a total for the life of the fund
		const lifeOfFundTotal = {
			year: "Fund Life to Date",
			"Fund Expenses": 0,
			"Management Fees": 0,
			Investments: 0,
			"Total Capital Called": 0,
		};

		yearData.forEach((yearItem) => {
			lifeOfFundTotal["Fund Expenses"] += yearItem["Fund Expenses"];
			lifeOfFundTotal["Management Fees"] += yearItem["Management Fees"];
			lifeOfFundTotal.Investments += yearItem.Investments;
			lifeOfFundTotal["Total Capital Called"] +=
				yearItem["Total Capital Called"];
		});

		return [...yearData, lifeOfFundTotal];
	};

	const getYearlyPercentageChartData = () => {
		if (!fund || !fund.quarters || !fund.totalFundSize) return [];

		const yearlyData = getYearlyChartData();
		const totalFundSize = fund.totalFundSize;

		return yearlyData.map((year) => ({
			year: year.year,
			"Fund Expenses": (year["Fund Expenses"] / totalFundSize) * 100,
			"Management Fees": (year["Management Fees"] / totalFundSize) * 100,
			Investments: (year.Investments / totalFundSize) * 100,
			"Total Capital Called":
				(year["Total Capital Called"] / totalFundSize) * 100,
		}));
	};

	const yearlyChartData = getYearlyChartData();
	const yearlyPercentageChartData = getYearlyPercentageChartData();

	// Colors for stacked bar charts
	const colors = {
		"Fund Expenses": TERTIARY_COLOR,
		"Management Fees": SECONDARY_COLOR,
		Investments: PRIMARY_COLOR,
	};

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide>
					<ContentSlide.Title>
						{fund.name}: Capital Called Details
					</ContentSlide.Title>

					<ContentSlide.Content>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SectionTitle variant="h6">Capital Called by Year</SectionTitle>
								<ChartContainer>
									<ResponsiveContainer width="100%" height="100%">
										<BarChart
											data={yearlyChartData}
											margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="year" />
											<YAxis
												tickFormatter={(value) =>
													`$${formatAsMillions(value, 0)}`
												}
											/>
											<Tooltip
												content={
													<CustomTooltip
														formatter={(value) =>
															`$${formatAsMillions(value, 0)}`
														}
													/>
												}
											/>
											<Legend content={<CustomLegend />} />
											<Bar
												dataKey="Investments"
												stackId="a"
												fill={colors.Investments}
											>
												<LabelList
													dataKey="Investments"
													position="middle"
													style={{ fill: "#000" }}
													content={(props) =>
														renderCustomizedLabel(
															{ ...props, fill: "#fff" },
															(value) =>
																value ? `$${formatAsMillions(value, 0)}` : null,
														)
													}
												/>
											</Bar>
											<Bar
												dataKey="Management Fees"
												stackId="a"
												fill={colors["Management Fees"]}
											>
												<LabelList
													dataKey="Management Fees"
													position="middle"
													content={(props) =>
														renderCustomizedLabel(props, (value) =>
															value ? `$${formatAsMillions(value, 0)}` : null,
														)
													}
												/>
											</Bar>
											<Bar
												dataKey="Fund Expenses"
												stackId="a"
												fill={colors["Fund Expenses"]}
											>
												<LabelList
													dataKey="Fund Expenses"
													position="middle"
													content={(props) =>
														renderCustomizedLabel(props, (value) =>
															value ? `$${formatAsMillions(value, 0)}` : null,
														)
													}
												/>
												<LabelList
													dataKey="Total Capital Called"
													position="top"
													offset={5}
													fill="#000"
													fontWeight="bold"
													fontSize={14}
													formatter={(value) =>
														value ? `$${formatAsMillions(value, 0)}` : null
													}
												/>
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</ChartContainer>
							</Grid>

							{/* Capital Called by Year Percentage Chart */}
							<Grid item xs={12}>
								<SectionTitle variant="h6">
									Capital Called by Year (% of Total Fund)
								</SectionTitle>
								<ChartContainer>
									<ResponsiveContainer width="100%" height="100%">
										<BarChart
											data={yearlyPercentageChartData}
											margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="year" />
											<YAxis
												tickFormatter={(value) => `${formatNumber(value, 0)}%`}
											/>
											<Tooltip
												content={
													<CustomTooltip
														formatter={(value) => `${formatNumber(value, 0)}%`}
													/>
												}
											/>

											<Legend content={<CustomLegend />} />
											<Bar
												dataKey="Investments"
												stackId="a"
												fill={colors.Investments}
											>
												<LabelList
													dataKey="Investments"
													position="middle"
													fill="#000"
													content={(props) =>
														renderCustomizedLabel(
															{ ...props, fill: "#fff" },
															(value) =>
																value ? `${formatNumber(value, 0)}%` : null,
														)
													}
												/>
											</Bar>
											<Bar
												dataKey="Management Fees"
												stackId="a"
												fill={colors["Management Fees"]}
											>
												<LabelList
													dataKey="Management Fees (incl. waiver)"
													position="middle"
													content={(props) =>
														renderCustomizedLabel(props, (value) =>
															value ? `${formatNumber(value, 0)}%` : null,
														)
													}
												/>
											</Bar>
											<Bar
												dataKey="Fund Expenses"
												stackId="a"
												fill={colors["Fund Expenses"]}
											>
												<LabelList
													dataKey="Fund Expenses"
													position="middle"
													content={(props) =>
														renderCustomizedLabel(props, (value) =>
															value ? `${formatNumber(value, 0)}%` : null,
														)
													}
												/>
												<LabelList
													dataKey="Total Capital Called"
													position="top"
													fill="#000"
													fontWeight="bold"
													offset={5}
													formatter={(value) =>
														value ? `${formatNumber(value, 0)}%` : null
													}
												/>
											</Bar>
										</BarChart>
									</ResponsiveContainer>
								</ChartContainer>
							</Grid>
						</Grid>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>
			<SlideToggle.Data>
				<h2>Capital Called by Year</h2>

				<h2>Quarterly Values</h2>
				<div className="quarterly-values">
					{fund?.quarters && (
						<Table
							columns={quarterlyColumns}
							data={quarterlyTableData}
							title={`${fund.name || "Fund"} - Quarterly Capital Called`}
							fundName={fund.name || "Fund"}
							columnOrder={columnOrder}
						/>
					)}
				</div>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide3;

import Flake from "@/ui/atoms/Icons/Flake";
import { Box, Typography } from "@mui/material";
import React from "react";

const Footer = ({ disclaimer }) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: 1,
				backgroundColor: (theme) => theme.palette.primary.main,
				color: (theme) =>
					theme.palette.getContrastText(theme.palette.primary.main),
				position: "relative",
			}}
		>
			<Box display="flex" gap={2} alignItems="center">
				<Flake color="white" />
				<Typography variant="gigaSmall" fontStyle="italic" align="start">
					{disclaimer}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Typography variant="body1" sx={{ fontWeight: "bold" }}>
					VALOR
				</Typography>
				<Typography variant="body2" sx={{ marginLeft: 1 }}>
					EQUITY PARTNERS
				</Typography>
			</Box>
		</Box>
	);
};

export default Footer;

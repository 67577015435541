import { AuthenticationError } from "@/api/Error";
import {
	type CompanyNoteResult,
	PAGE_SIZE,
	queryCompanyNotes,
} from "@/api/Notes";
import useDebounce from "@/hooks/useDebounce";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Box, Button, Card } from "@mui/material";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import NotesFilters from "../NotesFilters";
import NotesList from "../NotesList";
import NotesSearch from "../NotesSearch";

type SearchDrawerProps = {
	open: boolean;
	userId: string;
	valorId: string;
	onOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function SearchDrawer({
	open,
	userId,
	valorId,
	onOpen,
	onClose,
}: SearchDrawerProps) {
	const [loading, setLoading] = useState<boolean>();
	const [shouldSearch, setShouldSearch] = useState<boolean>(true);
	const [search, setSearch] = useState<string>("");
	const [filter, setFilter] = useState<[string, string, string]>(null);
	const [searchOffset, setSearchOffset] = useState<number>(0);
	const [notes, setNotes] = useState<CompanyNoteResult>(null);
	const [error, setError] = useState(null);

	const debouncedSearch = useDebounce(search, 500);

	useQuery(`notes-${valorId}-${debouncedSearch}-${searchOffset}`, async () => {
		if (shouldSearch) {
			setLoading(true);
			try {
				const results = await queryCompanyNotes(
					valorId,
					debouncedSearch,
					searchOffset,
				);
				const totalHits =
					searchOffset > 0
						? [...(notes?.hits || []), ...results.hits]
						: results.hits;

				setNotes({
					total: results.total,
					hits: totalHits,
				});
				setError(null);
			} catch (e) {
				if (e instanceof AuthenticationError) {
					setError("You are not authorized to view this organizations notes.");
					setNotes(null);
				}

				if (e instanceof Error) {
					setNotes(null);
					setError("An error occurred while searching for notes.");
				}
			}
			setShouldSearch(false);
			setLoading(false);
		}
	});

	function localFilterResults(results?: CompanyNoteResult): CompanyNoteResult {
		if (!results) {
			return {
				total: {
					value: 0,
					offset: 0,
				},
				hits: [],
			};
		}
		return {
			total: results.total,
			hits: results.hits.filter((note) => {
				if (filter) {
					const [, field, value] = filter;
					return note[field] === value;
				}
				return true;
			}),
		};
	}

	function filtersToShow() {
		if (!notes) {
			return [];
		}
		return Object.keys(
			notes.hits.reduce(
				(acc, note) => {
					const next = {
						...acc,
						[note.meetingType]: true,
					};
					return next;
				},
				{
					"My Notes": true,
				},
			),
		);
	}

	const searchNotes = useCallback((value: string, offset: number) => {
		setShouldSearch(true);
		setSearch(value);
		setSearchOffset(offset);
	}, []);

	useEffect(() => {
		searchNotes("", 0);
	}, [searchNotes]);

	return (
		<Box display="flex" flexDirection="row" flexShrink={100} maxWidth="600px">
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				width="30px"
				style={{
					cursor: "pointer",
				}}
				onClick={(e) => {
					if (open) onClose(e);
					else onOpen(e);
				}}
			>
				{open ? <ChevronRight /> : <ChevronLeft />}
			</Box>
			<Card
				style={{
					width: open ? "100%" : "0px",
					minWidth: open ? "100px" : "0px",
					overflow: "auto",
				}}
			>
				<Box display="flex" flexDirection="column" gap={1} padding={2}>
					<Box width="100%">
						<NotesSearch
							value={search}
							onUpdate={(newValue) => searchNotes(newValue, 0)}
						/>
					</Box>
					<NotesFilters
						userId={userId}
						onChange={(label, field, value) => {
							if (filter && filter[0] === label) setFilter(null);
							else setFilter([label, field, value]);
						}}
						filtersToShow={filtersToShow()}
						value={filter?.[0]}
					/>
					{error && <Box>An error occurred.</Box>}
					{loading && <Box>Loading...</Box>}
					{notes?.hits?.length === 0 && <Box>No notes found.</Box>}
					<NotesList
						notes={localFilterResults(notes).hits}
						userId=""
						editNote={() => {}}
						deleteNote={() => {}}
						hideControls
					/>
					{notes?.hits?.length > 0 && (
						<Box paddingY={2}>
							{notes?.total.value > searchOffset + PAGE_SIZE && (
								<Button
									disabled={loading}
									onClick={() => searchNotes(search, searchOffset + PAGE_SIZE)}
									variant="outlined"
									color="primary"
									fullWidth
								>
									{loading ? "Loading..." : "View More"}
								</Button>
							)}
						</Box>
					)}
				</Box>
			</Card>
		</Box>
	);
}

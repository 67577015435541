import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(() => ({
	redOnHover: {
		"&:hover": {
			fill: "red",
		},
	},
}));

export default function RemoveButton({ title, onClick, style }) {
	const classes = useStyles();

	return (
		<Tooltip title={title} placement="bottom" style={style}>
			<IconButton onClick={onClick}>
				<HighlightOffIcon className={classes.redOnHover} />
			</IconButton>
		</Tooltip>
	);
}

RemoveButton.propTypes = {
	title: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.object,
};

RemoveButton.defaultProps = {
	onClick: () => {},
	title: "Remove",
	style: {},
};

import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { canSeeProcess } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import PageNotFound from "@/ui/organisms/PageNotFound";

import useDebounce from "@/hooks/useDebounce";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { ProcessProvider } from "./ProcessContext";
import useTrackerTable from "./useTrackerTable";

import Page from "@/ui/molecules/Page";
import ProcessView from "./ProcessView";

export default function Process() {
	const { processId } = useParams<{ processId: string }>();
	const navigate = useNavigate();
	const { user } = useAuth();
	const hasAccessToProcess = canSeeProcess(user, processId);

	const [query, setQuery] = useQueryParam("q", StringParam);

	const handleSetQuery = useCallback(
		(q) => {
			setQuery(q);
		},
		[setQuery],
	);
	const [, setSessionId] = useQueryParam("sessionId", StringParam);

	const {
		filter,
		sort,
		visibility,
		order,
		setFilter,
		setSort,
		setVisibility,
		setOrder,
	} = useTrackerTable(processId);

	const [view] = useQueryParam("view", withDefault(StringParam, "board"));
	const [groupBy, setGroupBy] = useQueryParam(
		"groupBy",
		withDefault(StringParam, ""),
	);

	const debouncedQuery = useDebounce(query, 350);
	// redirect to home if accessing other process
	if (!processId || (!hasAccessToProcess && processId)) {
		navigate("/home");
	}

	if (!hasAccessToProcess && processId) {
		return <PageNotFound />;
	}

	return (
		<Page data-cy="page__process_management">
			<ProcessProvider
				processId={processId}
				mode={view === "table" || view === "archived" ? "ungrouped" : "grouped"}
				activeGroup={groupBy}
				query={debouncedQuery}
				archived={view === "archived"}
				sorting={sort}
				columnFilters={filter}
				columnVisibility={visibility}
				columnOrder={order}
				onSortingChange={setSort}
				onColumnFiltersChange={setFilter}
				onColumnVisibilityChange={setVisibility}
				onColumnOrderChange={setOrder}
			>
				<ProcessView
					query={query}
					onQueryChange={handleSetQuery}
					view={view}
					groupBy={groupBy}
					onGroupByChange={setGroupBy}
				/>
			</ProcessProvider>
		</Page>
	);
}

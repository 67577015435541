import useDebounce from "@/hooks/useDebounce";
import useKeyPress from "@/hooks/useKeyPress";
import SAYTCompanyResult from "@/ui/atoms/SAYTCompanyResult";
import SAYTKeywordResult from "@/ui/atoms/SAYTKeywordResult";
import {
	Box,
	InputBase,
	Paper,
	type Theme,
	Typography,
	createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@mui/icons-material/Search";
import React, { useRef, useState, useEffect } from "react";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		search: {
			position: "relative",
			width: "100%",
			marginLeft: 0,
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.background.paper,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: theme.shape.borderRadius,
			zIndex: theme.zIndex.appBar,
		},
		searchIcon: {
			position: "absolute",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			height: "100%",
			padding: theme.spacing(0, 2),
			color: theme.palette.text.primary,
			pointerEvents: "none",
		},
		inputRoot: {
			width: "100%",
			color: "inherit",
		},
		inputInput: {
			width: "100%",
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create("width"),
		},
		resultHolder: {
			position: "absolute",
			zIndex: theme.zIndex.appBar,
			maxHeight: "420px",
			overflowY: "auto",
			boxShadow: theme.shadows[3],
			"& #sayt-box": {
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			"& :last-child": {
				border: 0,
			},
		},
		resultBackground: {
			position: "fixed",
			width: "100%",
			height: "100%",
			top: 0,
			left: 0,
			background: "rgba(0,0,0,0.1)",
			zIndex: theme.zIndex.appBar,
		},
		focusedResults: {
			backgroundColor: theme.palette.action.hover,
		},
	}),
);

interface SearchResult {
	id?: string;
	valorId?: string;
	name?: string;
	domain?: string;
	logoUrl?: string;
	keyword?: string;
}

interface Props {
	search: (query: string) => SearchResult[];
	entity: "company" | "keyword";
	selectTerm?: (item: string) => void;
	onCustomResult?: (searchTerm: string) => void;
	onClose?(): () => void;
	placeholder?: string;
	showSAYT?: boolean;
	allowCustomResults?: boolean;
	autoFocus?: boolean;
}

export default function SearchBar(props: Props) {
	// rename to select result
	const {
		search,
		selectTerm,
		entity,
		placeholder = "Search...",
		onClose,
		showSAYT = false,
		allowCustomResults = false,
		onCustomResult,
		autoFocus = false,
	} = props;

	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState("");

	const [focusedIndex, setFocusedIndex] = useState(-1);

	const [shouldSearch, setShouldSearch] = useState(false);
	const [results, setResults] = useState([]);
	const debouncedSearchTerm = useDebounce(searchTerm, 150);
	const searchInputRef = useRef();
	const noResultsText = "No Results Found for ";

	useEffect(() => {
		if (autoFocus && searchInputRef?.current) {
			searchInputRef.current?.focus();
		}
	}, [searchInputRef, autoFocus]);

	const scrollResult = (direction) => {
		const { length } = results;
		if (length) {
			if (direction === "up") {
				if (focusedIndex === -1) {
					return setFocusedIndex(results.length - 1);
				}
				return setFocusedIndex(focusedIndex - 1);
			}
			if (focusedIndex === length - 1) {
				return setFocusedIndex(-1);
			}
			return setFocusedIndex(focusedIndex + 1);
		}
		return -1;
	};

	const { status } = useQuery(`searchTerm-${debouncedSearchTerm}`, async () => {
		if (debouncedSearchTerm && shouldSearch) {
			const apiResults = await search(debouncedSearchTerm);
			return setResults(apiResults);
		}
		if (shouldSearch && debouncedSearchTerm === "") {
			return setResults([]);
		}
		return null;
	});

	const onChange = (event) => {
		const term = event.target.value;
		setShouldSearch(Boolean(term.length));
		setSearchTerm(term);
	};

	const onClick = (item) => {
		setShouldSearch(false);
		setSearchTerm("");
		setResults([]);
		setFocusedIndex(-1);
		selectTerm(item); // rename to select result
	};

	const clearResults = () => {
		setResults([]);
		setSearchTerm("");
		setFocusedIndex(-1);
		onClose();
	};

	useKeyPress("Escape", () => clearResults());

	useKeyPress("ArrowUp", () => scrollResult("up"));
	useKeyPress("ArrowDown", () => scrollResult("down"));
	useKeyPress("Enter", () => {
		if (
			allowCustomResults &&
			searchTerm.length &&
			(focusedIndex === -1 || !results.length)
		) {
			onCustomResult(searchTerm);
			clearResults();
			return;
		}
		if (focusedIndex === -1 && results.length) {
			onClick(results[0]);
		} else if (results[focusedIndex]) {
			onClick(results[focusedIndex]);
		}
	});

	const buildSAYTCompanyResults = (searchResults = []) => {
		if (searchResults.length) {
			return searchResults.map((searchResult, index) => {
				const focused = index === focusedIndex;
				return (
					<Box
						id="sayt-box"
						data-cy={`sayt-${searchResult.valorId || searchResult.id}`}
						padding={2}
						onMouseEnter={() => setFocusedIndex(index)}
						onMouseLeave={() => setFocusedIndex(null)}
						key={searchResult.valorId || searchResult.id}
						className={focused ? `${classes.focusedResults}` : ""}
						onClick={() => onClick(searchResult)}
					>
						{entity === "company" && (
							<SAYTCompanyResult
								query={searchTerm}
								title={searchResult.name}
								domain={searchResult.domain}
								logoUrl={searchResult.logoUrl}
							/>
						)}
						{entity === "keyword" && (
							<SAYTKeywordResult
								query={searchTerm}
								term={searchResult.keyword}
							/>
						)}
					</Box>
				);
			});
		}
		return (
			<Box padding={2}>
				<Typography>
					{noResultsText}
					{searchTerm}
					<br />
					{!!allowCustomResults && "Press Enter to Add Anyway"}
				</Typography>
			</Box>
		);
	};

	const loadingResults = () => (
		<Box padding={2}>
			<Typography> Loading... </Typography>
		</Box>
	);

	return (
		<div className={classes.root}>
			{showSAYT && shouldSearch && Boolean(searchTerm.length) ? (
				<div className={classes.resultBackground} onClick={clearResults} />
			) : null}
			<div className={classes.search}>
				<div className={classes.searchIcon}>
					<SearchIcon />
				</div>
				<InputBase
					inputRef={searchInputRef}
					data-cy="search-input"
					placeholder={placeholder}
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					value={searchTerm}
					onChange={onChange}
					inputProps={{ "aria-label": "search" }}
				/>
			</div>
			<Paper className={classes.resultHolder}>
				{status === "loading" && loadingResults()}
				{Boolean(showSAYT && shouldSearch) &&
					status !== "loading" &&
					Boolean(searchTerm.length) &&
					buildSAYTCompanyResults(results)}
			</Paper>
		</div>
	);
}

import FimoicaChip from "@/components/SignalChip/FimoicaChip";
import TartarusChip from "@/components/SignalChip/TartarusChip";
import { Box } from "@mui/material";
import React from "react";

type Sources = "tartarus" | "fimoica";
type PredictionValue = {
	score: number;
	source: Sources;
	signalId: string;
};

interface PredictionCellProps {
	value: PredictionValue;
}
export default function PredictionCell({ value }: PredictionCellProps) {
	if (!value) {
		return <>-</>;
	}
	const { score, source, signalId } = value;

	if (source === "tartarus") {
		return (
			<Box
				sx={{ display: "flex", justifyContent: "center", alignitems: "center" }}
			>
				<TartarusChip showName={false} score={score} signalId={signalId} />
			</Box>
		);
	}
	if (source === "fimoica") {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignitems: "center",
					width: "100%",
				}}
			>
				<FimoicaChip showBorder showIcon signal={{ score, signalId }} />
			</Box>
		);
	}
	return <>-</>;
}

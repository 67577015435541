import { formatAsMillions } from "@/utils/numberFormat";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { CONSTRUCTION_COLORS } from "../../constants";
import CustomTooltip from "./Tooltip";

const pieChartHelper = (data) =>
	data.reduce((acc, obj) => {
		if (typeof obj === "object" && obj !== null) {
			Object.entries(obj).forEach(([key, value]) => {
				if (typeof value === "number" && key !== "designation") {
					acc.push({ name: key, value });
				}
			});
		}
		return acc;
	}, []);

const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
	color,
	value,
	isMonetary,
}) => {
	if (value === 0) return null;
	const RADIAN = Math.PI / 180;
	const OUTSIDE_THRESHOLD = 0.05;

	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN) + 10;
	const y = cy + radius * Math.sin(-midAngle * RADIAN);
	const textAnchor = midAngle > 90 && midAngle < 270 ? "end" : "start";

	if (percent < OUTSIDE_THRESHOLD) {
		const outerX = cx + (outerRadius + 10) * Math.cos(-midAngle * RADIAN);
		const outerY = cy + (outerRadius + 10) * Math.sin(-midAngle * RADIAN);
		return (
			<>
				<text
					x={outerX}
					y={outerY}
					fill="black"
					textAnchor={textAnchor}
					dominantBaseline="central"
					style={{ fontSize: "12px", fontWeight: "bold" }}
				>
					{`${(percent * 100).toFixed(0)}%`}
				</text>
				<text
					x={outerX}
					y={outerY + 12}
					fill="black"
					textAnchor={textAnchor}
					dominantBaseline="central"
					style={{ fontSize: "10px", fontWeight: "bold" }}
				>
					{isMonetary ? formatAsMillions(value) : value}
				</text>
			</>
		);
	}

	return (
		<>
			<text
				x={x - 5}
				y={y}
				fill={color}
				textAnchor={textAnchor}
				dominantBaseline="central"
				style={{ fontSize: "12px", fontWeight: "bold" }}
			>
				{`${(percent * 100).toFixed(0)}%`}
			</text>
			<text
				x={x - 5}
				y={y + 15}
				fill={color}
				textAnchor={textAnchor}
				style={{ fontSize: "10px" }}
			>
				{isMonetary ? formatAsMillions(value) : value}
			</text>
		</>
	);
};

export const FundManagementPieChart = ({
	data,
	isMonetary = false,
	unitOfRisk = null,
}) => {
	const cleanData = pieChartHelper(data);
	const colors = CONSTRUCTION_COLORS["entropy"];
	return (
		<PieChart width={240} height={260}>
			<Tooltip
				formatter={(value, name, props) =>
					isMonetary
						? `${formatAsMillions(value)} - ${(value / unitOfRisk).toFixed(1)} units of risk`
						: value
				}
				content={
					<CustomTooltip
						formatter={(value, name, props) =>
							isMonetary
								? `${formatAsMillions(value)} - ${(value / unitOfRisk).toFixed(1)} units of risk`
								: value
						}
					/>
				}
			/>
			<Pie
				data={cleanData}
				dataKey="value"
				nameKey="name"
				cx="50%"
				cy="50%"
				outerRadius={80}
				isAnimationActive={false}
				label={(props) =>
					renderCustomizedLabel({
						...props,
						isMonetary,
						unitOfRisk,
						color: colors[props.name].text,
					})
				}
				labelLine={false}
			>
				{cleanData.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={colors[entry.name].fill} />
				))}
			</Pie>
		</PieChart>
	);
};

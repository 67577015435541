import { getResponses } from "@/api/FrameworkSurvey";
import {
	Calculate,
	CloudDone,
	CoPresent,
	Map,
	ShowChart,
	Topic,
	TravelExplore,
} from "@mui/icons-material";
import { uniqBy } from "lodash";
import { useMemo } from "react";
import { useQuery } from "react-query";

const surveyIds = [
	"43860881-ce24-4105-89a7-07f5170e84f1",
	"0d4772ae-d656-4763-bda6-ff5b4e941354",
	"4ffe8931-76b1-4cd4-899b-e9d609b8cfa1",
	"bedfb774-3086-5cb8-9a0e-c4050cef5fd3",
	"b7050dec-791a-46a5-b206-fa18b7a05498",
	"89c5be86-b6f3-4756-a4af-6fb997ef052f",
	"8efa5760-679a-4af9-a027-1b98c9b8183e",
	"35f42e38-7cf0-47f0-8dc6-0018e8ddf62c",
	"c519857f-c2e8-4a6e-9e8d-3b0ca645c90e",
];

const AVENGER_ICON = "18290679-4078-4aeb-8efb-e526a7cb9750";
const DECK_LINK_ATTRIBUTE = "b7987320-4207-409e-99c3-eec211cfb050";
const SOURCE_ATTRIBUTE = "a4c7859a-6419-47b7-b46c-f0dae3cfdf4d";
const DESCRIPTION_ATTRIBUTE = "30ac352f-4c59-4e55-9c0d-3c91aa1d0bc6";
const ROUND_OVERVIEW_DESCRIPTION_ATTRIBUTE =
	"e3b769cc-928b-405c-833e-4c411afafca7";

const INVESTMENT_TYPE_ATTRIBUTE = "901ae578-a7e2-4bdc-93d5-a7d65ded3bc0";
const RECOMMENDATION_ATTRIBUTE = "1596f339-7078-4e9d-ac44-eb6c2c293ee4";

const TEAM_ASSIGNED_ATTRIBUTE = "c519857f-c2e8-4a6e-9e8d-3b0ca645c90e";
const PARTNER_ASSIGNED_ATTRIBUTE = "d8d5c358-c677-4d55-a058-57f8b846979f";

const NEXT_STEPS_ATTRBIUTE = "188422b6-c698-406d-b385-849f85dd8c5c";
const ZOOM_CONSENT_ATTRIBUTE = "36258310-f56b-4472-ac23-8ee5c1c92814";

const tableAttributes = {
	"64e3dacd-6a0d-4f65-baee-817be3b115df": "Relationship",
	"a7794e74-a93f-4dca-86af-1e09c8446bfc": "Market Positioning",
	"5a0d0042-541b-40e3-8708-ba4f82020046": "Macro Perspective",
	"5600da9c-cf55-4124-b637-4b3f1a327b3c": "Micro Perspective",
	"3e7fe2bb-4ef1-4a73-9778-af39569b1414": "Financials",
	"c2ab60e0-b601-4624-9737-8dcbee2bf3e9": "Risk",
	"9064634a-55e7-46cb-b513-dfa634a982d4": "Product Market Fit",
	"e1761ca3-e6c8-4e03-96bf-ce2f67b6023b": "Uniqueness of Asset",
	"902eb787-0df0-44f6-92f2-523785d4897e": "Information Advantage",
	"db4363be-b9ca-406d-b038-57846c336048": "Structure",
};

const keyMaterialsCheckList: {
	[attributeId: string]: { name: string; icon: any };
} = {
	"b7987320-4207-409e-99c3-eec211cfb050": {
		name: "Link to Deck on Sharepoint",
		icon: CoPresent,
	},
	"da8107f0-f032-45ae-bafe-a158654b058b": {
		name: "Company Data Room",
		icon: CloudDone,
	},
	"16e6e432-4e17-4b5e-bc89-735716a0a77d": {
		name: "Company Financial Model",
		icon: ShowChart,
	},
	"fd0ce313-fa4b-45ad-bd9a-c317c6ba5b28": {
		name: "Valor Model / ROIC Analysis",
		icon: Calculate,
	},
	"2588c77a-18b1-4d52-95e3-13e53aa03269": { name: "Market Map", icon: Map },
	"d6a17731-94f6-4d00-8613-e3f55d6e1e71": {
		name: "Have you reviewed market maps for this space?",
		icon: TravelExplore,
	},
	"87f9bede-d187-4fcb-94ec-a8a9f57dc305": {
		name: "Other Materials",
		icon: Topic,
	},
};

export default function useFirstMeetingReports(
	completedSince,
	completedBefore,
	valorIds,
) {
	const { data, isLoading, refetch } = useQuery(
		[
			"OppAssessmentReports",
			completedSince,
			surveyIds,
			valorIds,
			completedBefore,
		],
		() =>
			getResponses({
				surveyIds,
				completedSince,
				completedBefore,
				valorIds,
			}),
	);

	const uniqData = useMemo(() => {
		if (!data) return [];
		return uniqBy(data, "id");
	}, [data]);

	const withTableData = useMemo(() => {
		if (!uniqData) return [];
		return uniqData.map((response) => {
			const avengerSymbol = response.answers[AVENGER_ICON]?.choice?.color;
			const avengerSymbolComment = response.answers[AVENGER_ICON]?.commentary;
			const teamAssigned =
				response.answers[TEAM_ASSIGNED_ATTRIBUTE]?.users ?? [];
			const partnerAssigned =
				response.answers[PARTNER_ASSIGNED_ATTRIBUTE]?.users ?? [];
			const decklink = response.answers[DECK_LINK_ATTRIBUTE]?.commentary;
			const source = response.answers[SOURCE_ATTRIBUTE]?.commentary;
			const description = response.answers[DESCRIPTION_ATTRIBUTE]?.commentary;
			const roundOverview =
				response.answers[ROUND_OVERVIEW_DESCRIPTION_ATTRIBUTE]?.commentary;
			const investmentType =
				response.answers[INVESTMENT_TYPE_ATTRIBUTE]?.choice?.text;
			const recommendation = response.answers[RECOMMENDATION_ATTRIBUTE];
			let zoomConsent = response.answers[ZOOM_CONSENT_ATTRIBUTE]
				? `${response.answers[ZOOM_CONSENT_ATTRIBUTE]?.choice?.text}`
				: null;
			if (response.answers[ZOOM_CONSENT_ATTRIBUTE]?.commentary) {
				zoomConsent = `${zoomConsent} - ${response.answers[ZOOM_CONSENT_ATTRIBUTE]?.commentary}`;
			}

			const nextSteps = response.answers[NEXT_STEPS_ATTRBIUTE]?.commentary;

			const recordingLink = response.recordings
				? `https://valorep.sharepoint.com/${
						response.recordings[0].product === "Growth" ? "Investments/" : ""
					}${response.recordings[0].path}`
				: null;
			const keyMaterials = Object.keys(keyMaterialsCheckList)
				.map((attributeId) => ({
					id: attributeId,
					attribute: keyMaterialsCheckList[attributeId].name,
					Icon: keyMaterialsCheckList[attributeId].icon,
					color: response.answers[attributeId]?.choice?.color,
					comment: response.answers[attributeId]?.commentary,
				}))
				.filter((material) => material.color);
			return {
				...response,
				tableData: Object.keys(tableAttributes).map((attributeId) => ({
					id: attributeId,
					attribute: tableAttributes[attributeId],
					value:
						response.answers[attributeId]?.commentary ??
						response.answers[attributeId]?.choice?.value,
					color: response.answers[attributeId]?.choice?.color ?? "",
				})),
				keyMaterials,
				metadata: {
					avengerSymbol,
					avengerSymbolComment,
					decklink,
					source,
					description,
					roundOverview,
					investmentType,
					teamAssigned,
					partnerAssigned,
					recordingLink,
					nextSteps,
					recommendation,
					zoomConsent,
				},
			};
		});
	}, [uniqData]);

	return {
		data: withTableData,
		isLoading,
		refetch,
	};
}

import type { ResponsesSearchResult } from "@/api/FrameworkSurvey";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import Department from "./Department";

function SurveyBrief({
	valorId,
	surveyName,
	surveyId,
	id,
	completedAt,
	department,
	activeSurvey = false,
}: {
	id: string;
	valorId: string;
	surveyId: string;
	surveyName: string;
	completedAt: string;
	department: string;
	activeSurvey?: boolean;
}) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "space-between",
				border: (theme) => {
					if (activeSurvey) return `2px solid ${theme.palette.primary.main}`;
					return `1px solid ${theme.palette.divider}`;
				},
				p: 1,
				borderRadius: 1,
			}}
		>
			<Typography variant="body1">{surveyName}</Typography>
			<Typography variant="body2" color="textSecondary">
				<Department department={department} />
			</Typography>
			<Link
				to={`/org/${valorId}/response/${id}?surveyId=${surveyId}`}
				target="_blank"
			>
				<Box component={"span"} sx={{ display: "flex", alignItems: "center" }}>
					View Response
					<OpenInNewIcon sx={{ width: 20, height: 20 }} />
				</Box>
			</Link>
			<Typography variant="caption">
				{dayjs(completedAt).format("MMM D, YYYY")}
			</Typography>
		</Box>
	);
}

export default function SurveyBriefList({
	osSurvey,
	oneMonthSurvey,
	sixMonthSurvey,
}: {
	osSurvey: ResponsesSearchResult[0][0] | null;
	oneMonthSurvey: ResponsesSearchResult[0][0] | null;
	sixMonthSurvey: ResponsesSearchResult[0][0] | null;
}) {
	return (
		<Stack
			sx={{
				textAlign: "center",
				gap: 2,
			}}
		>
			<Box
				display={"flex"}
				flexDirection={"row"}
				gap={2}
				justifyContent={"center"}
			>
				{osSurvey && (
					<SurveyBrief
						id={osSurvey.id}
						surveyId={osSurvey.surveyId}
						surveyName={osSurvey.surveyName}
						valorId={osSurvey.valorId}
						completedAt={osSurvey.completedAt}
						department={osSurvey.department}
					/>
				)}

				{oneMonthSurvey && (
					<SurveyBrief
						id={oneMonthSurvey.id}
						surveyId={oneMonthSurvey.surveyId}
						surveyName={`${oneMonthSurvey.surveyName} (1 Month)`}
						valorId={oneMonthSurvey.valorId}
						completedAt={oneMonthSurvey.completedAt}
						department={oneMonthSurvey.department}
						activeSurvey={!sixMonthSurvey}
					/>
				)}

				{sixMonthSurvey && (
					<SurveyBrief
						id={sixMonthSurvey.id}
						surveyId={sixMonthSurvey.surveyId}
						surveyName={`${sixMonthSurvey.surveyName} (6 Month)`}
						valorId={sixMonthSurvey.valorId}
						completedAt={sixMonthSurvey.completedAt}
						department={sixMonthSurvey.department}
						activeSurvey
					/>
				)}
			</Box>
		</Stack>
	);
}

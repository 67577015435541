import * as React from "react";

import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";

import { formatBigDollars } from "@/utils/numberFormat";
import { ContentCopy, FileDownload } from "@mui/icons-material";
import {
	Box,
	IconButton,
	Table as MuiTable,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from "@mui/material";
import xlsx from "json-as-xlsx";

interface IHeaderGroup {
	name: string;
	start: number;
	end: number;
}

export const useFundManagementTable = ({ data }) => {
	const columnHelper = createColumnHelper();
	if (!data || data.length === 0) return { columns: [] };
	const keys = Object.keys(data[0]);
	const columns = React.useMemo(
		() =>
			keys.map((key) =>
				columnHelper.accessor(key, {
					header: key,
					cell: (info) =>
						key == "investedCapital"
							? formatBigDollars(info.getValue(), false)
							: info.getValue(),
					id: key,
				}),
			),
		[data],
	);
	return {
		columns,
	};
};

export const Table = ({
	columns,
	data,
	title,
	fundName,
	columnOrder = [],
	headerGroups = [],
}) => {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		initialState: {
			columnOrder,
		},
	});

	const headerGroupings = (headerGroups: IHeaderGroup[]) => {
		const sortedGroups = [...headerGroups].sort((a, b) => a.start - b.start);
		const cells: JSX.Element[] = [];
		let currentPosition = 0;

		sortedGroups.forEach((item) => {
			if (item.start > currentPosition) {
				const gapSize = item.start - currentPosition - 1;
				cells.push(
					<TableCell
						key={`gap-${currentPosition}`}
						colSpan={gapSize}
						sx={{ backgroundColor: "transparent" }}
					/>,
				);
			}

			cells.push(
				<TableCell
					key={`group-${item.name}-${item.start}`}
					colSpan={item.end - item.start + 1}
					align="center"
				>
					{item.name}
				</TableCell>,
			);

			currentPosition = item.end;
		});

		return <TableRow>{cells}</TableRow>;
	};

	const exportExcel = () =>
		xlsx(
			[
				{
					sheet: fundName,
					columns: columns.map((column) => ({
						label: `${column.header} `,
						value: column.header,
					})),
					content: data,
				},
			],
			{
				fileName: title,
				extraLength: 3,
				writeOptions: {},
			},
		);

	const copyToClipboard = () => {
		const headers = columns.map((col) => col.header).join(",");
		const rows = data.map((row) =>
			columns
				.map((col) => {
					const value = row[col.header];
					return typeof value === "string" && value.includes(",")
						? `"${value}"`
						: value;
				})
				.join(","),
		);

		const csv = [headers, ...rows].join("\n");
		navigator.clipboard.writeText(csv);
	};

	return (
		<>
			<Box
				sx={{ padding: 1 }}
				display="flex"
				flexDirection="row"
				justifyContent="flex-start"
			>
				<Tooltip title="Copy as CSV">
					<IconButton onClick={copyToClipboard}>
						<ContentCopy />
					</IconButton>
				</Tooltip>
				<IconButton onClick={exportExcel}>
					<FileDownload />
				</IconButton>
			</Box>
			<TableContainer sx={{ maxHeight: 600, maxWidth: 1500 }}>
				<MuiTable stickyHeader>
					<TableHead>
						{headerGroupings(headerGroups)}
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<TableCell key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHead>
					<TableBody>
						{table.getRowModel().rows.map((row) => (
							<TableRow key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<td key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</TableRow>
						))}
					</TableBody>
					<tfoot>
						{table.getFooterGroups().map((footerGroup) => (
							<TableRow key={footerGroup.id}>
								{footerGroup.headers.map((header) => (
									<TableCell key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.footer,
													header.getContext(),
												)}
									</TableCell>
								))}
							</TableRow>
						))}
					</tfoot>
				</MuiTable>
			</TableContainer>
		</>
	);
};

import DataCardTable from "@/components/LocalCardTable";
import DataTable from "@/components/LocalTable";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import PageComponent from "@/ui/molecules/Page";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
	Alert,
	Box,
	Card,
	Divider,
	MenuItem,
	Select,
	Skeleton,
	Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import Department from "./Department";
import ReviewCommentary from "./ReviewCommentary";
import P2PAnalysisSkeleton from "./Skeleton";
import SurveyBriefList from "./SurveyBriefList";
import useP2PAnalysis from "./useP2PAnalysis";

export default function P2PAnalysis(): JSX.Element {
	const [selectedDeal, setSelectedDeal] = React.useState<string | null>(null);
	const [completedSince, setCompletedSince] = React.useState<string | null>(
		dayjs().subtract(7, "days").format("YYYY-MM-DD"),
	);

	const {
		lastXDays,
		p2pResponsesLoading,
		opportunities,
		activeSurvey,
		matchedSurveys,
		monthlyAssessmentData,
		monthlyAssessmentColumns,
		p2pMetricsData,
		p2pMetricsColumns,
		richCommentary,
		activeDealSurveysLoading,
	} = useP2PAnalysis(
		selectedDeal,
		setSelectedDeal,
		completedSince ? dayjs(completedSince) : dayjs().subtract(7, "days"),
	);

	const dealSelector = () => (
		<Box display="flex" flexDirection="column" gap={2}>
			<Typography variant="h2">
				{lastXDays === 0
					? "Submitted today"
					: `Submitted in the last ${lastXDays} days`}
			</Typography>
			<Box sx={{ width: "200px" }}>
				<DatePicker
					label="Completed Since"
					value={completedSince ? dayjs(completedSince).local() : null}
					onChange={(date) =>
						setCompletedSince(date ? dayjs(date).format("YYYY-MM-DD") : null)
					}
					slotProps={{
						field: {
							clearable: true,
							onKeyDown: (e) => e.preventDefault(),
						},
					}}
					maxDate={dayjs()}
					closeOnSelect
					orientation="portrait"
				/>
			</Box>
			{opportunities?.length === 0 && (
				<Alert severity="info">
					No Performance to Plan surveys have been submitted in the last{" "}
					{lastXDays} days.
				</Alert>
			)}
			{opportunities?.length > 0 && (
				<Select
					value={selectedDeal}
					onChange={(e) => {
						setSelectedDeal(e.target.value as string);
					}}
				>
					{opportunities.map(({ opportunity, organization }) => (
						<MenuItem
							key={opportunity.internalId}
							value={opportunity.internalId}
						>
							<Box display="flex" flexDirection="row" gap={1}>
								<CompanyAvatar
									src={organization.logoUrl}
									name={organization.name}
									size={24}
								/>
								{`${organization.name} - ${opportunity.name}`}
							</Box>
						</MenuItem>
					))}
				</Select>
			)}
		</Box>
	);

	if (p2pResponsesLoading || activeDealSurveysLoading) {
		return (
			<PageComponent>
				<PageComponent.Title>P2P Analysis</PageComponent.Title>
				<PageComponent.Content>
					<Box padding={1} display="flex" flexDirection="column" gap={2}>
						{p2pResponsesLoading ? (
							<Box display="flex" flexDirection="column" gap={2}>
								<Typography variant="h2">Since {lastXDays} days ago</Typography>
								<Skeleton variant="rounded" height={48} />
							</Box>
						) : (
							dealSelector()
						)}
					</Box>
					<P2PAnalysisSkeleton />
				</PageComponent.Content>
			</PageComponent>
		);
	}

	return (
		<PageComponent>
			<PageComponent.Title>P2P Analysis</PageComponent.Title>
			<PageComponent.Content>
				<Box
					sx={{
						p: 1,
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					{dealSelector()}
					<Box>
						<SurveyBriefList
							osSurvey={matchedSurveys?.osSurvey}
							oneMonthSurvey={matchedSurveys?.oneMonthSurvey}
							sixMonthSurvey={matchedSurveys?.sixMonthSurvey}
						/>
					</Box>

					{/* render active company report */}
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							gap: 3,
							flexWrap: "wrap",
							justifyContent: "center",
						}}
					>
						{monthlyAssessmentData?.map(({ name: key, rows: value }) => {
							return (
								<Box
									key={key}
									sx={{
										border: (theme) => `1px solid ${theme.palette.divider}`,
										display: "flex",
										flexDirection: { xs: "column", md: "row" },
										maxWidth: "100%",
									}}
								>
									<Box
										sx={{
											position: "relative",
											width: { xs: "100%", md: "30px" },
										}}
									>
										<Typography
											variant="h3"
											sx={{
												transformOrigin: "top left",
												transform: {
													xs: "none",
													md: "rotate(-90deg)",
												},
												position: {
													xs: "static",
													md: "absolute",
												},
												bottom: { xs: "auto", md: "50%" },
												mb: { xs: 0, md: -key.length },
												width: "max-content",
												padding: { xs: 1, md: 0 },
											}}
										>
											{key}
										</Typography>
									</Box>

									<Box
										sx={{
											borderLeft: (theme) =>
												`1px solid ${theme.palette.divider}`,
											overflow: "auto",
											maxWidth: {
												xs: "100%",
												md: "calc(100% - 30px)",
											},
										}}
									>
										<DataTable
											key={key}
											data={value}
											columns={monthlyAssessmentColumns}
										/>
									</Box>
								</Box>
							);
						})}
					</Box>

					<Divider />

					{activeSurvey && (
						<Box>
							{/* render active deal commentary */}
							{dayjs(activeSurvey.completedAt) > dayjs("2025-02-01") ? (
								<div>
									<Box sx={{ p: 1 }}>
										<ReviewCommentary
											management={richCommentary?.management ?? "N/A"}
											growth={richCommentary?.growth ?? "N/A"}
											market={richCommentary?.market ?? "N/A"}
											keyUnderwriting={richCommentary?.keyUnderwriting ?? "N/A"}
										/>
									</Box>
									{p2pMetricsData.length > 0 ? (
										<Box
											sx={{
												borderLeft: (theme) => ({
													md: `1px solid ${theme.palette.divider}`,
												}),
											}}
										>
											<DataCardTable
												data={p2pMetricsData}
												columns={p2pMetricsColumns}
											/>
										</Box>
									) : null}
								</div>
							) : (
								<Alert severity="info">
									Performance To Plan survey may have been submitted before this
									section existed. Submit a new survey at 6 months to see the
									data for this section.
								</Alert>
							)}
						</Box>
					)}
				</Box>
			</PageComponent.Content>
		</PageComponent>
	);
}

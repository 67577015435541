import {
  type GetFundInvestmentsResponse,
  createFundCompanyOverride,
  createFundOverride,
  deleteFundCompanyOverride,
  getFundById,
  getFundInvestments,
} from "@/api/FundManagement";
import {
  canReadFundManagement,
  canWriteFundManagement,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import Page from "@/ui/molecules/Page";
import EditIcon from "@mui/icons-material/Edit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {
  Alert,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { debounce } from "lodash";
import { uniqBy } from "lodash";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import FundEditor from "./FundEditor";

import {
  ACTIVE_FUNDS,
  CV_CONSORTIO_ENTITY_ID,
  FUND_III_ENTITY_ID,
  FUND_IV_ENTITY_ID,
  FUND_VI_ENTITY_ID,
  FUND_V_ENTITY_ID,
  SEED_ENTITY_ID,
  VAAI_ENTITY_ID,
  VOF_ENTITY_ID,
  VSVII_ENTITY_ID,
  VSVI_ENTITY_ID,
} from "./constants";

import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

import MissingOrganizations from "./MissingOrganizations";
import SlideLoadingState from "./SlideLoadingState";
import TitleSlide from "./Slides/TitleSlide";

import PortfolioConstructionSlides from "./Slides/PortfolioConstruction";
import Slide1 from "./Slides/Slide01";
import Slide2 from "./Slides/Slide02";
import Slide3 from "./Slides/Slide03";
import Slide4 from "./Slides/Slide04";
import Slide5 from "./Slides/Slide05";
import Slide6 from "./Slides/Slide06";
import Slide7 from "./Slides/Slide07";
import Slide8 from "./Slides/Slide08";
import Slide9 from "./Slides/Slide09";
import Slide10 from "./Slides/Slide10";
import Slide11 from "./Slides/Slide11";
import Slide12 from "./Slides/Slide12";
import Slide13 from "./Slides/Slide13";

import Fund from "./Fund";
import FundInvestments from "./FundInvestments";

dayjs.extend(quarterOfYear);

type Investment = GetFundInvestmentsResponse["data"][number];

const TableOfContents = ({ onSlideClick, slideItems, currentSlideIndex }) => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 65,
        padding: 2,
        width: "100%",
        height: "calc(100vh - 65px)",
        backgroundColor: "background.paper",
        backdropFilter: "blur(5px)",
        borderLeft: "1px solid #e0e0e0",
        overflowY: "auto",
      }}
    >
      <h3>Table of Contents</h3>
      <Box component="nav">
        {slideItems.map((item, index) => (
          <Box
            key={item.id}
            component="div"
            sx={{
              cursor: "pointer",
              padding: "8px",
              "&:hover": { backgroundColor: "#f5f5f5" },
              backgroundColor:
                currentSlideIndex === index ? "#e3f2fd" : "transparent",
            }}
            onClick={() => onSlideClick(item.id, index)}
          >
            <Typography
              variant={"h6"}
              fontWeight={item.isHeader ? "bold" : "normal"}
              sx={{ paddingLeft: item.isHeader ? 0 : 2 }}
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

// Generate quarters from fund inception to current quarter
const generateQuarterOptions = (vintageYear) => {
  if (!vintageYear) return [];

  const quarters = [];
  const currentDate = dayjs();

  let year = vintageYear;
  let quarter = 1;
  const endYear = currentDate.year();
  const endQuarter = Math.ceil((currentDate.month() + 1) / 3);

  while (year < endYear || (year === endYear && quarter <= endQuarter)) {
    const quarterEndMonth = (quarter - 1) * 3 + 2;
    const quarterEndDate = dayjs(new Date(year, quarterEndMonth, 1))
      .endOf("month")
      .toDate();

    quarters.push({
      label: `Q${quarter} ${year}`,
      value: quarterEndDate,
    });

    quarter++;
    if (quarter > 4) {
      quarter = 1;
      year++;
    }
  }

  return quarters;
};

// Helper function to get ordered slide refs
const getOrderedSlideRefs = (slideItems, slideIds) => {
  return slideItems.map((item) => slideIds[item.id]);
};

function FundManagement() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [asOfDate, setAsOfDate] = useQueryParam<Date | null>(
    "asOfValue",
    withDefault(StringParam, "")
  );
  const [selectedQuarter, setSelectedQuarter] = useQueryParam<string>(
    "asOfLabel",
    withDefault(StringParam, "")
  );
  const [selectedFund, setSelectedFund] = useQueryParam(
    "fund",
    withDefault(NumberParam, VSVII_ENTITY_ID)
  );

  useEffect(() => {
    setAsOfDate("");
    setSelectedQuarter("");
  }, [setAsOfDate, setSelectedQuarter]);

  const isSelectedFundActive = useMemo(() => {
    return ACTIVE_FUNDS.indexOf(selectedFund) !== -1;
  }, [selectedFund]);

  const isSelectedFundVSV = useMemo(() => {
    return [VSVII_ENTITY_ID, VSVI_ENTITY_ID].indexOf(selectedFund) !== -1;
  }, [selectedFund]);

  const slideIds = useMemo(
    () => ({
      fundOverview: React.createRef(),
      fundMetricsTitle: React.createRef(),
      fundMetricsLifeToDate: React.createRef(),
      capitalCalled: React.createRef(),
      portfolioPerformanceTitle: React.createRef(),
      companyPerformancePrimary: React.createRef(),
      liquidityProjection: React.createRef(),
      investedProjectedValue: React.createRef(),
      capitalInvestedExitValues: React.createRef(),
      portfolioRiskRating: React.createRef(),
      investmentPacingTitle: React.createRef(),
      capitalAvailable: React.createRef(),
      investmentPeriodAllocation: React.createRef(),
      deploymentPlanStatus: React.createRef(),
      deploymentsUnitsRisk: React.createRef(),
      plannedVsActualDeployments: React.createRef(),
      riskRatings: React.createRef(),
      platformTechConstruction: React.createRef(),
      segmentConstruction: React.createRef(),
      verticalConstruction: React.createRef(),
      valueChainConstruction: React.createRef(),
      entropyBinaryRisk: React.createRef(),
      portfolioConstruction: React.createRef(),
      riskCategory: React.createRef(),
    }),
    []
  );

  useEffect(() => {
    if (user) {
      if (!canReadFundManagement(user)) {
        navigate("/home");
      }
    }
  }, [user, navigate]);

  const [orgOverrides, setOrgOverrides] = useState<Record<string, any>>({});
  const [fundOverrides, setFundOverrides] = useState<Record<string, any>>({});
  const {
    data,
    isLoading: isFundLoading,
    error,
  } = useQuery(["fund", selectedFund], async () => getFundById(selectedFund), {
    enabled: !!user,
  });
  const fund = useMemo(() => {
    if (!data) return null;
    return new Fund(data?.data, fundOverrides[selectedFund]);
  }, [data, fundOverrides, selectedFund]);
  const asOfFund = useMemo(() => {
    if (!data) return null;
    return new Fund(data?.data, fundOverrides[selectedFund], asOfDate);
  }, [data, fundOverrides, selectedFund, asOfDate]);

  const quarterOptions = useMemo(() => {
    if (!fund) return [];
    return generateQuarterOptions(fund?.vintageYear);
  }, [fund]);

  const fundOptions = useMemo(() => {
    return [
      { label: "VSV I", value: VSVI_ENTITY_ID },
      { label: "VSV II", value: VSVII_ENTITY_ID },
      { label: "VOF", value: VOF_ENTITY_ID },
      { label: "VAAI", value: VAAI_ENTITY_ID },
      { label: "Seed", value: SEED_ENTITY_ID },
      { label: "Fund III", value: FUND_III_ENTITY_ID },
      { label: "Fund IV", value: FUND_IV_ENTITY_ID },
      { label: "Fund V", value: FUND_V_ENTITY_ID },
      { label: "Fund VI", value: FUND_VI_ENTITY_ID },
    ];
  }, []);

  const [editorOpen, setEditorOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setFullscreen(false);
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    if (quarterOptions.length > 0 && !selectedQuarter) {
      const mostRecentQuarter = quarterOptions[quarterOptions.length - 3];
      setSelectedQuarter(mostRecentQuarter.label);
      setAsOfDate(mostRecentQuarter.value);
    }
  }, [quarterOptions, selectedQuarter, setSelectedQuarter, setAsOfDate]);

  const {
    data: { data: investments } = { data: [] },
    isLoading: investmentsLoading,
    error: investmentsError,
  } = useQuery(
    ["investments", selectedFund],
    async () => getFundInvestments(selectedFund),
    {
      enabled: !!user,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const fundInvestments = useMemo(() => {
    if (!investments || !Array.isArray(investments) || !fund) return null;
    return new FundInvestments(investments, fund, orgOverrides || []);
  }, [investments, fund, orgOverrides]);

  const isLoading = investmentsLoading || isFundLoading;

  const slideItems = useMemo(
    () => [
      { id: "fundOverview", name: "Fund Overview" },
      {
        id: "fundMetricsTitle",
        name: "Fund Metrics To Date",
        isHeader: true,
      },
      {
        id: "fundMetricsLifeToDate",
        name: "Fund Metrics - Fund Life to Date",
      },
      { id: "capitalCalled", name: "Capital Called Details" },
      {
        id: "portfolioPerformanceTitle",
        name: "Portfolio Company Performance",
        isHeader: true,
      },
      {
        id: "companyPerformancePrimary",
        name: `Company Performance (${fund?.primaryInvestmentLabel} Portfolio)`,
      },
      { id: "liquidityProjection", name: "Liquidity by Year Projection" },
      {
        id: "investedProjectedValue",
        name: "Fund Life to Date Invested & Projected Gross Value",
      },
      {
        id: "capitalInvestedExitValues",
        name: `${fund?.primaryInvestmentLabel} Investments – Capital Invested and Estimated Exit Values`,
      },
      {
        id: "portfolioRiskRating",
        name: `${fund?.primaryInvestmentLabel} Portfolio Risk Rating`,
      },
      ...(isSelectedFundActive
        ? [
            {
              id: "investmentPacingTitle",
              name: "Investment Pacing",
              isHeader: true,
            },
            {
              id: "capitalAvailable",
              name: "Capital Available for Investments",
            },
            {
              id: "investmentPeriodAllocation",
              name: "Fund Design - Investable Capital Summary",
            },
            {
              id: "deploymentPlanStatus",
              name: "Investment Period Deployment Pacing ($)",
            },
            {
              id: "deploymentsUnitsRisk",
              name: "Investment Period Deployment Pacing (Units of Risk)",
            },
            {
              id: "plannedVsActualDeployments",
              name: "Investment Period Planned vs. Actual Deployments",
            },
          ]
        : []),
      {
        id: "portfolioConstruction",
        name: "Portfolio Construction",
        isHeader: true,
      },
      { id: "entropyBinaryRisk", name: "Entropy & Binary Risk Ratings" },
      { id: "riskRatings", name: "Correlated Risk Ratings" },
      {
        id: "platformTechConstruction",
        name: "Platform Technology Portfolio Construction",
      },
      { id: "verticalConstruction", name: "Vertical Portfolio Construction" },
      { id: "segmentConstruction", name: "Segment Portfolio Construction" },
      ...(isSelectedFundVSV
        ? [
            {
              id: "valueChainConstruction",
              name: "Value Chain Portfolio Construction",
            },
          ]
        : []),
      {
        id: "riskCategory",
        name: "Risk Category Portfolio Construction",
      },
    ],
    [fund?.primaryInvestmentLabel, isSelectedFundVSV, isSelectedFundActive]
  );

  // Scroll handler to update current slide
  const isProgrammaticScroll = useRef(false);
  useEffect(() => {
    const orderedSlideRefs = getOrderedSlideRefs(slideItems, slideIds);

    if (isLoading) return;

    // Configure IntersectionObserver
    const observerOptions = {
      root: document, // Use the scroll container as the viewport
      rootMargin: "0px",
      threshold: [0.7], // Trigger when 70% of the slide is visible
    };

    const observer = new IntersectionObserver((entries) => {
      // Skip updates during programmatic scrolling
      if (isProgrammaticScroll.current) return;
      // Find the slide with the highest intersection ratio (most visible)
      let mostVisibleIndex = currentSlideIndex;
      let highestRatio = 0;

      entries.forEach((entry) => {
        const index = orderedSlideRefs.findIndex(
          (ref) => ref.current === entry.target
        );

        if (index !== -1 && entry.intersectionRatio > highestRatio) {
          highestRatio = entry.intersectionRatio;
          mostVisibleIndex = index;
        }
      });

      // Only update if we found a new most visible slide
      if (mostVisibleIndex !== currentSlideIndex) {
        setCurrentSlideIndex(mostVisibleIndex);
      }
    }, observerOptions);

    // Observe all slide elements
    orderedSlideRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [slideItems, slideIds, isLoading, currentSlideIndex]);
  // Keyboard navigation
  useEffect(() => {
    const orderedSlideRefs = getOrderedSlideRefs(slideItems, slideIds);

    const handleKeyDown = (event: KeyboardEvent) => {
      if (editorOpen || slide5ModalOpen || isLoading) return;

      const totalSlides = orderedSlideRefs.length;

      switch (event.key) {
        case "ArrowRight":
        case "ArrowDown":
          event.preventDefault();
          if (currentSlideIndex < totalSlides - 1) {
            const nextIndex = currentSlideIndex + 1;
            isProgrammaticScroll.current = true;
            setCurrentSlideIndex(nextIndex);
            const nextSlide = orderedSlideRefs[nextIndex]?.current;
            if (nextSlide) {
              nextSlide.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
              setTimeout(() => {
                isProgrammaticScroll.current = false;
              }, 500);
            }
          }
          break;

        case "ArrowLeft":
        case "ArrowUp":
          event.preventDefault();
          if (currentSlideIndex > 0) {
            const prevIndex = currentSlideIndex - 1;
            isProgrammaticScroll.current = true;
            setCurrentSlideIndex(prevIndex);
            const prevSlide = orderedSlideRefs[prevIndex]?.current;
            if (prevSlide) {
              prevSlide.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
              setTimeout(() => {
                isProgrammaticScroll.current = false;
              }, 500);
            }
          }
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [slideItems, slideIds, editorOpen, currentSlideIndex, isLoading]);

  const handleSlideClick = (slideId, index) => {
    isProgrammaticScroll.current = true;
    setCurrentSlideIndex(index);
    const element = slideIds[slideId]?.current;
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      setTimeout(() => {
        isProgrammaticScroll.current = false;
      }, 500);
    }
  };

  const handleQuarterChange = (event) => {
    const selectedLabel = event.target.value;
    setSelectedQuarter(selectedLabel);
    const selectedOption = quarterOptions.find(
      (option) => option.label === selectedLabel
    );
    if (selectedOption) {
      setAsOfDate(selectedOption.value);
    }
  };

  const handleFundChange = (event) => {
    const selectedLabel = event.target.value;
    setSelectedFund(selectedLabel);
  };

  const handleCompanyOverrideSubmit = async (
    companyId: string,
    key,
    value,
    commentary
  ) => {
    try {
      createFundCompanyOverride(
        selectedFund,
        companyId,
        key,
        value,
        commentary
      );
      setOrgOverrides((prevData) => ({
        ...prevData,
        [companyId]: (prevData[companyId] || [])
          .filter((d) => d.key !== key)
          .concat([{ key, value }]),
      }));
    } catch (error) {
      console.error("Error submitting override:", error);
    }
  };

  const handleCompanyOverrideDelete = async (
    companyId: string,
    key: string
  ) => {
    try {
      deleteFundCompanyOverride(selectedFund, companyId, key);
      setOrgOverrides((prevData) => ({
        ...prevData,
        [companyId]: (prevData[companyId] || [])
          .filter((item) => item.key !== key)
          .concat([{ key, value: null }]),
      }));
    } catch (error) {
      console.error("Error deleting override:", error);
    }
  };

  const handleFundOverrideSubmit = async (key, value, commentary) => {
    try {
      createFundOverride(selectedFund, key, value, commentary);
      setFundOverrides((prevData) => ({
        ...prevData,
        [selectedFund]: (prevData[selectedFund] || [])
          .filter((d) => d.key === key)
          .concat([{ key, value }]),
      }));
    } catch (error) {
      console.error("Error submitting override:", error);
    }
  };

  const [slide5ModalOpen, setSlide5ModalOpen] = useState(false);
  const handleModalOpen = () => {
    setSlide5ModalOpen(true);
  };
  const handleModalClose = () => {
    setSlide5ModalOpen(false);
  };

  const isMob = useMediaQuery((theme) => theme.breakpoints.down("md"));
  if (isMob) {
    return (
      <Box padding={1}>
        <Alert severity="warning">
          Fund Management is currently only available on larger view ports.
          Please try again on a larger device to see this feature.
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={
        fullscreen
          ? {
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 10 ** 10,
              overflow: "auto",
              background: (theme) => theme.palette.background.blueGradient,
            }
          : {}
      }
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 1,
                borderBottom: "1px solid #e0e0e0",
                backgroundColor: "background.paper",
                backdropFilter: "blur(5px)",
                padding: 1,
              }}
            >
              <Typography variant="h2">Fund Management</Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="quarter-select-label">
                    As of Quarter
                  </InputLabel>
                  <Select
                    labelId="quarter-select-label"
                    id="quarter-select"
                    value={selectedQuarter}
                    label="As of Quarter"
                    onChange={handleQuarterChange}
                  >
                    {quarterOptions.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="quarter-select-label">Funds</InputLabel>
                  <Select
                    labelId="quarter-select-label"
                    id="quarter-select"
                    value={selectedFund}
                    label="Funds"
                    onChange={handleFundChange}
                  >
                    {fundOptions.map((option) => (
                      <MenuItem key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {canWriteFundManagement(user) && (
                  <Tooltip title="Edit Fund">
                    <IconButton
                      onClick={() => setEditorOpen(true)}
                      disabled={isFundLoading}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {fullscreen ? (
                  <Tooltip title="Exit Fullscreen">
                    <IconButton onClick={() => setFullscreen(false)}>
                      <FullscreenExitIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Fullscreen">
                    <IconButton onClick={() => setFullscreen(true)}>
                      <FullscreenIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <MissingOrganizations investments={investments} />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "center",
                  my: 2,
                  overflow: "auto",
                }}
              >
                {isLoading ? (
                  <SlideLoadingState />
                ) : (
                  <>
                    <Box ref={slideIds.fundOverview}>
                      <Slide1
                        fund={fund}
                        asOfFund={asOfFund}
                        investments={fundInvestments}
                      />
                    </Box>
                    <TitleSlide ref={slideIds.fundMetricsTitle}>
                      <TitleSlide.Title>{fund?.name}</TitleSlide.Title>
                      <TitleSlide.Subtitle>
                        Fund Metrics To Date
                      </TitleSlide.Subtitle>
                    </TitleSlide>
                    <Box ref={slideIds.fundMetricsLifeToDate}>
                      <Slide2 fund={asOfFund} />
                    </Box>
                    <Box ref={slideIds.capitalCalled}>
                      <Slide3 fund={fund} />
                    </Box>
                    <TitleSlide ref={slideIds.portfolioPerformanceTitle}>
                      <TitleSlide.Title>{fund?.name}</TitleSlide.Title>
                      <TitleSlide.Subtitle>
                        Portfolio Company Performance, Expected Values,
                        Liquidity, and RISK Ratings
                      </TitleSlide.Subtitle>
                    </TitleSlide>
                    <Box ref={slideIds.companyPerformancePrimary}>
                      <Slide4
                        fund={fund}
                        investmentCompanies={fundInvestments?.companies}
                      />
                    </Box>
                    <Box ref={slideIds.liquidityProjection}>
                      <Slide5
                        fund={fund}
                        asOfFund={asOfFund}
                        investments={fundInvestments}
                        handleOverrideSubmit={handleCompanyOverrideSubmit}
                        handleOverrideDelete={handleCompanyOverrideDelete}
                        onModalOpen={handleModalOpen}
                        onModalClose={handleModalClose}
                      />
                    </Box>
                    <Box ref={slideIds.investedProjectedValue}>
                      <Slide6
                        fund={fund}
                        investments={fundInvestments}
                        investmentCompanies={fundInvestments?.companies}
                      />
                    </Box>
                    <Box ref={slideIds.capitalInvestedExitValues}>
                      <Slide7
                        fund={fund}
                        investments={fundInvestments}
                        investmentCompanies={fundInvestments?.companies}
                      />
                    </Box>
                    <Box ref={slideIds.portfolioRiskRating}>
                      <Slide8
                        fund={fund}
                        investmentCompanies={fundInvestments?.companies}
                      />
                    </Box>

                    {isSelectedFundActive ? (
                      <>
                        <TitleSlide ref={slideIds.investmentPacingTitle}>
                          <TitleSlide.Title>{fund?.name}</TitleSlide.Title>
                          <TitleSlide.Subtitle>
                            Investment Pacing
                          </TitleSlide.Subtitle>
                        </TitleSlide>

                        <Box ref={slideIds.capitalAvailable}>
                          <Slide9 fund={fund} />
                        </Box>

                        <Box ref={slideIds.investmentPeriodAllocation}>
                          <Slide10 fund={fund} />
                        </Box>

                        <Box ref={slideIds.deploymentPlanStatus}>
                          <Slide11 fund={fund} investments={fundInvestments} />
                        </Box>

                        <Box ref={slideIds.deploymentsUnitsRisk}>
                          <Slide12 fund={fund} investments={fundInvestments} />
                        </Box>

                        <Box ref={slideIds.plannedVsActualDeployments}>
                          <Slide13 fund={fund} investments={fundInvestments} />
                        </Box>
                      </>
                    ) : null}
                    <TitleSlide ref={slideIds.portfolioConstruction}>
                      <TitleSlide.Title>{fund?.name}</TitleSlide.Title>
                      <TitleSlide.Subtitle>
                        Portfolio Construction
                      </TitleSlide.Subtitle>
                    </TitleSlide>

                    <PortfolioConstructionSlides
                      fund={fund}
                      investments={fundInvestments}
                      refs={{
                        riskRatings: slideIds.riskRatings,
                        platformTechConstruction:
                          slideIds.platformTechConstruction,
                        segmentConstruction: slideIds.segmentConstruction,
                        verticalConstruction: slideIds.verticalConstruction,
                        valueChainConstruction: slideIds.valueChainConstruction,
                        entropyBinaryRisk: slideIds.entropyBinaryRisk,
                        riskCategory: slideIds.riskCategory,
                      }}
                    />
                  </>
                )}
              </Box>

              <Box sx={{ width: "220px", flexShrink: 0 }}>
                {isLoading ? (
                  <Skeleton variant="rectangular" height={"100%"} />
                ) : (
                  <TableOfContents
                    onSlideClick={handleSlideClick}
                    slideItems={slideItems}
                    currentSlideIndex={currentSlideIndex}
                  />
                )}
              </Box>

              <FundEditor
                fund={fund}
                open={editorOpen}
                onClose={() => setEditorOpen(false)}
                onSubmit={(key, value, commentary) => {
                  handleFundOverrideSubmit(key, value, commentary);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FundManagement;

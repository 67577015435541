import { formatBigFundingRoundDollars } from "@/utils/numberFormat";
import {
	Box,
	ClickAwayListener,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
} from "@mui/material";
import type React from "react";
import { useCallback, useRef, useState } from "react";
import OverflowBox from "./OverflowBox";

const DisplayBox = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
}));

type FieldType = "string" | "number" | "dollar";

type TableJsonValue = {
	value: string;
	type?: FieldType;
	title?: string;
	data: string[][];
	columns: {
		title: string;
		type: FieldType;
	}[];
};

const formatCellValue = (value: string, type: FieldType): string => {
	if (type === "dollar") return formatBigFundingRoundDollars(Number(value));
	return value;
};

export default function TableJsonCell({ value }: { value: TableJsonValue }) {
	const [showOverflow, setShowOverflow] = useState(false);
	const anchorRef = useRef<null | HTMLElement>(null);

	const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setShowOverflow(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowOverflow(false);
	}, []);

	const displayValue = value?.value;
	const displayTitle = value?.title;

	if (displayValue === undefined || displayValue === null) return <>-</>;
	const formatDollar = (val: number): string =>
		new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(val);

	let specificValue = displayValue;
	if (value?.type === "dollar")
		specificValue = formatDollar(Number(displayValue));

	let formattedDisplayValue = displayValue;
	if (value?.type === "dollar")
		formattedDisplayValue = formatBigFundingRoundDollars(displayValue);

	return (
		<ClickAwayListener onClickAway={() => setShowOverflow(false)}>
			<Box position="relative" display="flex" height="100%" alignItems="center">
				<Box
					ref={anchorRef}
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						cursor: "pointer",
					}}
					onClick={handleClick}
				>
					{formattedDisplayValue}
				</Box>
				<OverflowBox
					open={showOverflow}
					anchorEl={anchorRef.current}
					onClose={handleClose}
				>
					<DisplayBox elevation={0}>
						<TableContainer>
							<Table size="small" aria-label="probability values">
								<TableHead>
									<TableRow>
										{value.columns.map((column) => (
											<TableCell key={column.title}>
												<Typography variant="tableHeader">
													{column.title}
												</Typography>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{value.data.map((row) => (
										<TableRow key={row[0]}>
											{row.map((cell, index) => (
												<TableCell key={cell}>
													{formatCellValue(cell, value.columns[index].type)}
												</TableCell>
											))}
										</TableRow>
									))}
									<TableRow>
										<TableCell
											colSpan={value.columns.length - 1}
											align="right"
											sx={{ fontWeight: "bold" }}
										>
											{displayTitle}
										</TableCell>
										<TableCell sx={{ fontWeight: "bold" }}>
											{specificValue}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</DisplayBox>
				</OverflowBox>
			</Box>
		</ClickAwayListener>
	);
}

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState, useMemo } from "react";

const IC_APPROVED_UNDER_TS_PRIMARY_KEY = "ic_approved_under_ts_primary";
const IC_APPROVED_UNDER_TS_SECONDARY_KEY = "ic_approved_under_ts_secondary";
const PRIMARY_RESERVES_KEY = "primary_reserves";

const FundEditor = ({ open, onClose, onSubmit, fund }) => {
	const [overrideData, setOverrideData] = useState<OverrideData[]>([]);
	const onChange = (key: string, value: any) => {
		setOverrideData((prev) => [
			...prev.filter((item) => item.key !== key),
			{ key, value },
		]);
	};

	const history = fund?.history || [];

	// Handle form submission
	const handleSubmit = (event) => {
		event.preventDefault();

		if (fund) {
			overrideData.forEach(({ key, value }) => {
				if (
					(key === IC_APPROVED_UNDER_TS_PRIMARY_KEY &&
						value !== fund.icApprovedUnderTsPrimary) ||
					(key === IC_APPROVED_UNDER_TS_SECONDARY_KEY &&
						value !== fund.icApprovedUnderTsSecondary) ||
					(key === PRIMARY_RESERVES_KEY && value !== fund.primaryReserves)
				) {
					onSubmit(key, value, commentary);
				}
			});
		}
		onClose();
	};

	const icApprovedUnderTsPrimary = useMemo(() => {
		return overrideData.find((x) => x.key === IC_APPROVED_UNDER_TS_PRIMARY_KEY)
			?.value;
	}, [overrideData]);

	const icApprovedUnderTsSecondary = useMemo(() => {
		return overrideData.find(
			(x) => x.key === IC_APPROVED_UNDER_TS_SECONDARY_KEY,
		)?.value;
	}, [overrideData]);

	const primaryReserves = useMemo(() => {
		return overrideData.find((x) => x.key === PRIMARY_RESERVES_KEY)?.value;
	}, [overrideData]);

	const [commentary, setCommentary] = useState("");
	React.useEffect(() => {
		setCommentary("");
	}, [open]);

	React.useEffect(() => {
		if (fund) {
			// set initial override data
			const initialData = {};
			initialData[IC_APPROVED_UNDER_TS_PRIMARY_KEY] =
				fund.icApprovedUnderTsPrimary;
			initialData[IC_APPROVED_UNDER_TS_SECONDARY_KEY] =
				fund.icApprovedUnderTsSecondary;
			initialData[PRIMARY_RESERVES_KEY] = fund.primaryReserves;
			setOverrideData(
				Object.entries(initialData).map(([key, value]) => ({ key, value })),
			);
		}
	}, [fund]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>
				{fund ? `Edit Fund: ${fund.name}` : "Add Fund Details"}
			</DialogTitle>

			<DialogContent>
				<Box
					component="form"
					onSubmit={handleSubmit}
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						mt: 2,
					}}
				>
					{/* First Text Field */}
					<TextField
						label="Less: IC Approved / Under TS Primary"
						name="icTsApproved"
						value={icApprovedUnderTsPrimary}
						onChange={(e) =>
							onChange(IC_APPROVED_UNDER_TS_PRIMARY_KEY, e.target.value)
						}
						fullWidth
						required
					/>

					<TextField
						label="Less: IC Approved / Under TS Secondary"
						name="icTsApproved"
						value={icApprovedUnderTsSecondary}
						onChange={(e) =>
							onChange(IC_APPROVED_UNDER_TS_SECONDARY_KEY, e.target.value)
						}
						fullWidth
						required
					/>

					{/* Second Text Field */}
					<TextField
						label="Less: Investment Period Reserves/Allocated"
						name="investmentReserves"
						value={primaryReserves}
						onChange={(e) => onChange(PRIMARY_RESERVES_KEY, e.target.value)}
						fullWidth
						required
					/>

					{/* Added Commentary Text Field */}
					<TextField
						label="Commentary"
						name="commentary"
						value={commentary}
						onChange={(e) => setCommentary(e.target.value)}
						fullWidth
						multiline // Makes it a textarea for longer text
						rows={4} // Sets initial number of rows
						helperText="Add any additional comments or notes here"
					/>
				</Box>
				<Box
					sx={{
						maxHeight: "200px",
						overflowY: "auto",
					}}
				>
					{history.map(
						({
							id,
							key,
							value,
							author,
							commentary,
							deleted,
							deletedAt,
							createdAt,
						}) => (
							<Box
								key={id}
								sx={{
									opacity: deleted ? 0.5 : 1,
									mb: 1,
								}}
							>
								<Tooltip
									title={
										deleted &&
										"Value has been overwritten by a more recent edit."
									}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											gap: 1,
										}}
									>
										<Box>{dayjs(createdAt).format("MMM DD")}</Box>
										<Box>
											<Typography variant="body2">
												{`${author.firstName} ${author.lastName}`} updated{" "}
												{key.replace(/_/g, " ")} to{" "}
												{Number(value).toLocaleString()}.
											</Typography>
											<Typography variant="caption">{commentary}</Typography>
										</Box>
									</Box>
								</Tooltip>
							</Box>
						),
					)}
				</Box>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button type="submit" variant="contained" onClick={handleSubmit}>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FundEditor;

import { Box, Paper } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const SlideContainer = styled(Paper)(({ theme, fixedSize }) => ({
	width: fixedSize ? "11.9in" : "100%",
	height: fixedSize ? "9in" : "auto",
	display: "flex",
	flexDirection: "column",
	backgroundColor: theme.palette.background.default,
	borderRadius: theme.spacing(1),
	boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
	position: "relative",
	overflow: "hidden",
}));

const SlideContent = styled(Box)({
	flex: 1,
	position: "relative",
	zIndex: 1,
});

const Slide = ({ children, fixedSize = true }) => {
	const containerRef = React.useRef(null);
	const [scale, setScale] = React.useState(1);

	React.useEffect(() => {
		// Only apply scaling logic when in fixedSize mode
		if (fixedSize) {
			const resizeObserver = new ResizeObserver((entries) => {
				if (containerRef.current) {
					const parentWidth =
						containerRef.current.parentElement?.clientWidth ||
						containerRef.current.clientWidth;
					const containerWidth = containerRef.current.scrollWidth;
					const newScale = parentWidth / containerWidth;
					setScale(Math.min(1, newScale));
				}
			});

			if (containerRef.current) {
				resizeObserver.observe(containerRef.current.parentElement);
			}

			return () => {
				if (containerRef.current) {
					resizeObserver.unobserve(containerRef.current.parentElement);
				}
				resizeObserver.disconnect();
			};
		} else {
			// Reset scale when in responsive mode
			setScale(1);
		}
	}, [fixedSize]); // Add fixedSize as dependency

	return (
		<SlideContainer
			elevation={3}
			ref={containerRef}
			fixedSize={fixedSize}
			sx={{
				transformOrigin: "top left",
				transform: fixedSize ? `scale(${scale})` : "none",
			}}
		>
			<SlideContent>{children}</SlideContent>
		</SlideContainer>
	);
};

export default Slide;

import {
	PRIMARY_LABELING,
	SECONDARY_LABELING,
	VSVII_ENTITY_ID,
	VSVI_ENTITY_ID,
} from "@/pages/FundManagement/constants.ts";
import { formatAsMillions } from "@/utils/numberFormat";
import { Box, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { CONSTRUCTION_COLORS, colors } from "../../constants";
import ContentSlide from "../ContentSlide";
import type Fund from "../Fund";
import type FundInvestments from "../FundInvestments";
import Slide from "../Slide";
import SlideToggle from "../SlideToggle";
import { FundManagementPieChart } from "../shared/PieChart";
import { Table, useFundManagementTable } from "../shared/Table";
import CorrelatedRiskRatingsSlide from "./CorrelatedRiskRatings";
import EntropyBinaryRiskRatingSlide from "./EntropyBinaryRisk";
import { StackedBarChart } from "./PortfolioConstructionStackedBarChart";
import {
	groupInvestmentsByTwoProperties,
	transformByDesignation,
	transformForTable,
} from "./usePortfolioConstruction";

const applyThresholdToGroupedData = (groupedData, threshold) => {
	return Object.fromEntries(
		Object.entries(groupedData).map(([designation, groups]) => {
			const result = { Other: [] };

			Object.entries(groups).forEach(([groupKey, items]) => {
				const totalCapital = items.reduce(
					(sum, item) => sum + item.investedCapital,
					0,
				);

				if (totalCapital < threshold) {
					result.Other.push(...items);
				} else {
					result[groupKey] = items;
				}
			});

			return [
				designation,
				result.Other.length === 0
					? Object.fromEntries(
							Object.entries(result).filter(([key]) => key !== "Other"),
						)
					: result,
			];
		}),
	);
};

const InvestmentSummarySlide = ({
	fund,
	groupedData,
	title,
	id = null,
	pageNumber,
}) => {
	const fundPrimaryLabel = PRIMARY_LABELING[fund.entityId];
	const fundSecondaryLabel = SECONDARY_LABELING[fund.entityId];

	const designations = Object.keys(groupedData || {});
	const { unitsOfRiskPrimary, unitsOfRiskSecondary } = fund;

	const dataByDesignation = designations.map(
		(designation) => groupedData[designation],
	);

	const mapRenameDesignation = (designatedData) => {
		let copy = [...designatedData];
		copy = copy.sort(
			({ designation: designationA }, { designation: designationB }) => {
				if (designationA === "Primary") return -1;
				else if (designationB === "Primary") return 1;
				else return designationA.localeCompare(designationB);
			},
		);
		copy = copy.map((group) => {
			group["designation"] =
				group["designation"] === "Primary"
					? fundPrimaryLabel
					: fundSecondaryLabel;

			return group;
		});
		return copy;
	};

	const investedCapital = mapRenameDesignation(
		transformByDesignation({ data: dataByDesignation }),
	);
	const unitOfRiskGroup = mapRenameDesignation(
		transformByDesignation({
			data: dataByDesignation,
			group: "units_of_risk",
			primaryUnitOfRisk: unitsOfRiskPrimary,
			secondaryUnitOfRisk: unitsOfRiskSecondary,
		}),
	);

	const countGroup = mapRenameDesignation(
		transformByDesignation({
			data: dataByDesignation,
			group: "count",
		}),
	);

	const tableData = transformForTable({
		data: dataByDesignation,
		primaryUnitsOfRisk: unitsOfRiskPrimary,
		secondaryUnitsOfRisk: unitsOfRiskSecondary,
	});

	const { columns } = useFundManagementTable({ data: tableData });

	const allCategories = useMemo(() => {
		const categoriesSet = new Set();
		[investedCapital, unitOfRiskGroup, countGroup].forEach((data) => {
			data.forEach((item) => {
				Object.keys(item)
					.filter((key) => key !== "designation")
					.forEach((category) => categoriesSet.add(category));
			});
		});
		return Array.from(categoriesSet);
	}, [investedCapital, unitOfRiskGroup, countGroup]);

	const getCategoryColorMap = (categories) =>
		Object.fromEntries(
			categories.map((category, index) => [
				category,
				{
					fill: "#fff",
					text:
						category === "OTHER"
							? "#000000"
							: colors[index % colors.length].text,
				},
			]),
		);

	const categoryColorMap =
		CONSTRUCTION_COLORS[id] ?? getCategoryColorMap(allCategories);

	const categoriesToRender = Object.keys(categoryColorMap).filter(
		(c) => allCategories.indexOf(c) !== -1,
	);

	return (
		<Slide>
			<SlideToggle>
				<SlideToggle.Slide>
					<ContentSlide disclaimer="All figures on this slide exclude realized investments.">
						<ContentSlide.Title>{title}</ContentSlide.Title>
						<ContentSlide.Content>
							<Grid container>
								<Grid
									item
									xs={4}
									display="flex"
									flexDirection="column"
									alignItems="center"
								>
									<Typography variant="h3">Invested Capital</Typography>
									<StackedBarChart
										data={investedCapital}
										formattingFunction={(val) => `$${formatAsMillions(val)}`}
										categories={allCategories}
										categoryColorMap={categoryColorMap}
									/>
								</Grid>
								<Grid
									item
									xs={4}
									display="flex"
									flexDirection="column"
									alignItems="center"
								>
									<Typography variant="h3"> Unit of Risk</Typography>
									<StackedBarChart
										data={unitOfRiskGroup}
										formattingFunction={(x) => x.toFixed(1)}
										categories={allCategories}
										categoryColorMap={categoryColorMap}
									/>
								</Grid>
								<Grid
									item
									xs={4}
									display="flex"
									flexDirection="column"
									alignItems="center"
								>
									<Typography variant="h3"> # of Companies</Typography>
									<StackedBarChart
										data={countGroup}
										formattingFunction={(x) => x.toLocaleString()}
										categories={allCategories}
										categoryColorMap={categoryColorMap}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sx={{
										display: "flex",
										justifyContent: "center",
										padding: "16px 0",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexWrap: "wrap",
											alignItems: "center",
											gap: "12px",
											backgroundColor: "#f5f5f5",
											padding: "8px 16px",
											borderRadius: "8px",
											boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
										}}
									>
										{categoriesToRender.map((category) => (
											<Box
												key={category}
												sx={{
													display: "flex",
													alignItems: "center",
													gap: "8px",
													fontSize: "14px",
													fontWeight: 500,
													color: "#333",
												}}
											>
												<Box
													sx={{
														height: "12px",
														width: "12px",
														backgroundColor: categoryColorMap[category].fill,
														borderRadius: "2px",
													}}
												/>
												{category}
											</Box>
										))}
									</Box>
								</Grid>
							</Grid>
						</ContentSlide.Content>
					</ContentSlide>
				</SlideToggle.Slide>
				<SlideToggle.Data>
					<Table
						columns={columns}
						data={tableData}
						title={title}
						fundName={fund.name}
					/>
				</SlideToggle.Data>
			</SlideToggle>
		</Slide>
	);
};

const createInvestmentSlides = ({
	groupedInvestments,
	investments,
	fund,
	refs,
}) => {
	const {
		byDesignationByRisk,
		byDesignationByPlatformTechnology,
		byDesignationByTargetSegment,
		byDesignationByTargetVertical,
		byDesignationByValueChainSector,
		byDesignationByEntropy,
		byDesignationByBinaryRisk,
	} = groupedInvestments;

	return {
		entropySlide: (
			<Box ref={refs.entropyBinaryRisk}>
				<EntropyBinaryRiskRatingSlide
					groupedEntropyData={byDesignationByEntropy}
					groupedBinaryData={byDesignationByBinaryRisk}
					title={`${fund.name}: Entropy & Binary Risk Ratings`}
					fund={fund}
				/>
			</Box>
		),
		inflationSlide:
			// ░░░░░░░░▄▄▄▀▀▀▄▄███▄░░░░░░░░░░░░░░
			// ░░░░░▄▀▀░░░░░░░▐░▀██▌░░░░░░░░░░░░░
			// ░░░▄▀░░░░▄▄███░▌▀▀░▀█░░░░░░░░░░░░░
			// ░░▄█░░▄▀▀▒▒▒▒▒▄▐░░░░█▌░░░░░░░░░░░░
			// ░▐█▀▄▀▄▄▄▄▀▀▀▀▌░░░░░▐█▄░░░░░░░░░░░
			// ░▌▄▄▀▀░░░░░░░░▌░░░░▄███████▄░░░░░░
			// ░░░░░░░░░░░░░▐░░░░▐███████████▄░░░
			// ░░░░░le░░░░░░░▐░░░░▐█████████████▄
			// ░░░░toucan░░░░░░▀▄░░░▐█████████████▄
			// ░░░░░░has░░░░░░░░▀▄▄███████████████
			// ░░░░░arrived░░░░░░░░░░░░█▀██████░░
			fund.name === "CV Consortio" ? null : (
				<Box ref={refs.riskRatings}>
					<CorrelatedRiskRatingsSlide
						title={`${fund.name}: Correlated Risk Ratings`}
						fund={fund}
						investments={investments}
					/>
				</Box>
			),
		platformTechnologySlide: (
			<Box ref={refs.platformTechConstruction}>
				<InvestmentSummarySlide
					fund={fund}
					id="platformTechnology"
					groupedData={byDesignationByPlatformTechnology}
					title={`${fund.name}: Platform Technology Portfolio Construction`}
				/>
			</Box>
		),
		targetVerticalSlide: (
			<Box ref={refs.verticalConstruction}>
				<InvestmentSummarySlide
					fund={fund}
					id="targetVertical"
					groupedData={byDesignationByTargetVertical}
					title={`${fund.name}: Vertical Portfolio Construction`}
				/>
			</Box>
		),
		targetSegmentSlide: (
			<Box ref={refs.segmentConstruction}>
				<InvestmentSummarySlide
					fund={fund}
					id="targetSegment"
					groupedData={byDesignationByTargetSegment}
					title={`${fund.name}: Segment Portfolio Construction`}
				/>
			</Box>
		),
		riskSlide: (
			<Box ref={refs.riskCategory}>
				<InvestmentSummarySlide
					fund={fund}
					id="riskRating"
					groupedData={byDesignationByRisk}
					title={`${fund.name}: RISK Category Portfolio Construction`}
				/>
			</Box>
		),
		valueChainSectorSlide: [VSVII_ENTITY_ID, VSVI_ENTITY_ID].includes(
			fund.entityId,
		) ? (
			<Box ref={refs.valueChainConstruction}>
				<InvestmentSummarySlide
					fund={fund}
					id="valueChainSector"
					groupedData={byDesignationByValueChainSector}
					title={`${fund.name}: Value Chain Portfolio Construction`}
				/>
			</Box>
		) : null,
	};
};

const useInvestmentGroups = (investments: FundInvestments[], fund: Fund) => {
	const investmentsByDesignationByRisk = useMemo(() => {
		return groupInvestmentsByTwoProperties(
			investments,
			"organization.fundInternalDesignation",
			"organization.riskRating",
		);
	}, [investments]);

	const investmentsByDesignationByPlatformTechnology = useMemo(() => {
		return groupInvestmentsByTwoProperties(
			investments,
			"organization.fundInternalDesignation",
			"organization.platformTechnology",
		);
	}, [investments]);

	const investmentsByDesignationByTargetSegment = useMemo(() => {
		const grouped = groupInvestmentsByTwoProperties(
			investments,
			"organization.fundInternalDesignation",
			"organization.targetSegment",
		);

		const THRESHOLD = fund.totalInvestedCapital * 0.025;

		return applyThresholdToGroupedData(grouped, THRESHOLD);
	}, [investments, fund.totalInvestedCapital]);

	const investmentsByDesignationByTargetVertical = useMemo(() => {
		const grouped = groupInvestmentsByTwoProperties(
			investments,
			"organization.fundInternalDesignation",
			"organization.targetVertical",
		);
		const THRESHOLD = fund.totalInvestedCapital * 0.025;

		return applyThresholdToGroupedData(grouped, THRESHOLD);
	}, [investments, fund.totalInvestedCapital]);

	const investmentsByDesignationByValueChainSector = useMemo(() => {
		if ([VSVII_ENTITY_ID, VSVI_ENTITY_ID].indexOf(fund.entityId) === -1) {
			return {};
		}
		return groupInvestmentsByTwoProperties(
			investments,
			"organization.fundInternalDesignation",
			"organization.valueChainSector",
		);
	}, [investments, fund.entityId]);

	const investmentsByDesignationByEntropy = useMemo(() => {
		return groupInvestmentsByTwoProperties(
			investments,
			"organization.fundInternalDesignation",
			"organization.entropyScore",
		);
	}, [investments]);

	const investmentsByDesignationByBinaryRisk = useMemo(() => {
		return groupInvestmentsByTwoProperties(
			investments,
			"organization.fundInternalDesignation",
			"organization.binaryRisk",
		);
	}, [investments]);

	const investmentsByDesignationByInflation = useMemo(() => {
		return groupInvestmentsByTwoProperties(
			investments,
			"organization.fundInternalDesignation",
			"organization.inflation",
		);
	}, [investments]);

	return {
		byDesignationByRisk: investmentsByDesignationByRisk,
		byDesignationByPlatformTechnology:
			investmentsByDesignationByPlatformTechnology,
		byDesignationByTargetSegment: investmentsByDesignationByTargetSegment,
		byDesignationByTargetVertical: investmentsByDesignationByTargetVertical,
		byDesignationByValueChainSector: investmentsByDesignationByValueChainSector,
		byDesignationByEntropy: investmentsByDesignationByEntropy,
		byDesignationByBinaryRisk: investmentsByDesignationByBinaryRisk,
		byDesignationByInflation: investmentsByDesignationByInflation,
	};
};

const InvestmentDashboard = ({ investments, fund, refs }) => {
	const groupedInvestments = useInvestmentGroups(
		investments.filter((i) => i.organization),
		fund,
	);
	const slides = createInvestmentSlides({
		groupedInvestments,
		investments,
		fund,
		refs,
	});

	return (
		<>
			{Object.values(slides).map((slide, idx) => (
				<Box ref={refs[idx]}>{slide}</Box>
			))}
		</>
	);
};

const PortfolioConstructionSlides = ({ fund, investments, refs }) => {
	const filteredInvestments = useMemo(() => {
		return investments.filter(
			(investment) =>
				investment.organization &&
				investment.organization.furtherInvestmentStatus !== "Exited",
		);
	}, [investments]);
	return (
		<>
			<InvestmentDashboard
				investments={filteredInvestments}
				fund={fund}
				refs={refs}
			/>
		</>
	);
};

export default PortfolioConstructionSlides;

import { Box, Paper } from "@mui/material";

type LegendEntry = {
	color: string;
	value: string;
};

type CustomLegendProps = {
	payload: LegendEntry[];
};

export const CustomLegend = ({ payload }: CustomLegendProps) => {
	if (!payload || !payload.length) return null;

	return (
		<Paper
			sx={{
				bgcolor: "background.paper",
				p: "8px 16px",
				display: "flex",
				gap: "8px",
				flexWrap: "wrap",
				justifyContent: "center",
				maxWidth: "100%",
				margin: "0 auto",
				borderRadius: "8px",
				boxShadow: 1,
			}}
		>
			{payload.map((entry, index) => (
				<Box
					key={`legend-item-${index}`}
					sx={{
						display: "flex",
						flexWrap: "wrap",
						alignItems: "center",
						fontSize: "12px",
					}}
				>
					<Box
						sx={{
							width: "10px",
							height: "10px",
							bgcolor: entry.color,
							border: "1px solid #ccc",
							mr: "4px",
						}}
					/>
					<Box sx={{ color: "text.primary" }}>{entry.value}</Box>
				</Box>
			))}
		</Paper>
	);
};

export default CustomLegend;

import { formatAsMillions } from "@/utils/numberFormat";
import { Grid } from "@mui/material";
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useMemo } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";
import CustomTooltip from "./shared/Tooltip";

const LegendContainer = styled(Box)(({ theme }) => ({
	paddingLeft: theme.spacing(6),
	paddingRight: theme.spacing(6),
	maxWidth: "800px",
}));

const LegendItem = styled(Box)({
	display: "flex",
	alignItems: "center",
	marginBottom: "24px",
});

const ColorCircle = styled(Box)(({ bgcolor }) => ({
	display: "block",
	width: "32px",
	height: "32px",
	borderRadius: "50%",
	backgroundColor: bgcolor,
	marginRight: "16px",
	flexShrink: 0,
}));

const LegendText = styled(Typography)({
	fontSize: "12px",
	lineHeight: "1",
});

const RatingDot = styled("div")(({ rating }) => ({
	width: "20px",
	height: "20px",
	borderRadius: "50%",
	backgroundColor:
		rating === "R"
			? "red"
			: rating === "Y"
				? "yellow"
				: rating === "G"
					? "green"
					: "gray",
	display: "inline-block",
}));

const statusColors = {
	green: "#4CAF50",
	yellow: "#FFC107",
	red: "#F44336",
};

// Status indicator component
const StatusCell = ({ status }) => (
	<TableCell
		align="center"
		padding="none"
		sx={{
			border: "1px solid #e0e0e0",
			p: 0,
		}}
	>
		<Box
			sx={{
				backgroundColor: statusColors[status],
				width: "100%",
				height: "28px",
			}}
		/>
	</TableCell>
);

// Component for rendering the three percentage charts
const InvestmentCharts = ({ investmentCompanies }) => {
	// Filter only companies with the relevant data
	const filteredCompanies = investmentCompanies.filter(
		(c) =>
			c.performanceRating &&
			c.managementRating &&
			c.expectedReturn &&
			c.cashRunway &&
			!c.hasExited,
	);

	// Categories to analyze
	const categories = ["Perf", "Mgmt", "Exp Ret", "Runway"];
	const properties = [
		"performanceRating",
		"managementRating",
		"expectedReturn",
		"cashRunway",
	];

	// Calculate percentages for each metric and dimension
	const calculatePercentages = (valueAccessor) => {
		return categories.map((name, idx) => {
			const property = properties[idx];
			const totals = {
				G: filteredCompanies
					.filter((c) => c[property] === "G")
					.reduce(valueAccessor, 0),
				Y: filteredCompanies
					.filter((c) => c[property] === "Y")
					.reduce(valueAccessor, 0),
				R: filteredCompanies
					.filter((c) => c[property] === "R")
					.reduce(valueAccessor, 0),
			};

			const total = Object.values(totals).reduce((a, b) => a + b, 0);

			return {
				name,
				red: Math.round((totals.R / total) * 100),
				yellow: Math.round((totals.Y / total) * 100),
				green:
					100 -
					(Math.round((totals.R / total) * 100) +
						Math.round((totals.Y / total) * 100)),
			};
		});
	};

	// Generate data for each chart
	const companyData = calculatePercentages((sum) => sum + 1);

	const capitalData = calculatePercentages(
		(sum, c) => sum + c.totalInvestedCapitalForFund,
	);

	const valueData = calculatePercentages(
		(sum, c) => sum + c.totalAggregateValue,
	);

	const renderCustomizedLabel = (props) => {
		const { x, y, width, height, value, fill } = props;

		if (value === 0 || height < 20) return null;

		return (
			<text
				x={x + width / 2}
				y={y + height / 2}
				fill={fill || "#000"}
				textAnchor="middle"
				dominantBaseline="middle"
				fontWeight="bold"
			>
				{value}%
			</text>
		);
	};

	// Individual chart component
	const ChartComponent = ({ data, title }) => (
		<Box>
			<Typography
				variant="h6"
				align="center"
				sx={{ fontWeight: "bold", height: "45px" }}
			>
				{title}
			</Typography>
			<ResponsiveContainer width="100%" height={400}>
				<BarChart data={data} margin={{ top: 40, bottom: 5 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="name"
						interval={0}
						angle={-45}
						textAnchor="end"
						height={70}
					/>
					<Tooltip
						content={<CustomTooltip formatter={(value) => `${value}%`} />}
					/>
					<Bar
						barSize={40}
						dataKey="green"
						stackId="a"
						fill="#4caf50"
						isAnimationActive={false}
					>
						<LabelList
							dataKey="green"
							fill="white"
							content={renderCustomizedLabel}
						/>
					</Bar>
					<Bar
						barSize={40}
						dataKey="yellow"
						stackId="a"
						fill="#ffeb3b"
						isAnimationActive={false}
					>
						<LabelList dataKey="yellow" content={renderCustomizedLabel} />
					</Bar>
					<Bar
						barSize={40}
						dataKey="red"
						stackId="a"
						fill="#f44336"
						isAnimationActive={false}
					>
						<LabelList
							dataKey="red"
							fill="white"
							content={renderCustomizedLabel}
						/>
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</Box>
	);

	return (
		<Grid container spacing={1}>
			<Grid item xs={4}>
				<ChartComponent data={companyData} title="% by Company" />
			</Grid>
			<Grid item xs={4}>
				<ChartComponent data={capitalData} title="% by Invested Capital" />
			</Grid>
			<Grid item xs={4}>
				<ChartComponent data={valueData} title="% by Aggregate Value" />
			</Grid>
			<Grid item xs={12}>
				<LegendContainer>
					<LegendItem>
						<ColorCircle bgcolor="#4caf50" />
						<LegendText>
							= Exceeding plans / engaging with Valor team / high probability of
							meeting base case returns / runway of 24 months or greater
						</LegendText>
					</LegendItem>

					<LegendItem>
						<ColorCircle bgcolor="#ffeb3b" />
						<LegendText>
							= Close to or meeting plans / alignment unclear / reasonable
							probability of meeting base case returns / runway of 12-24 months
						</LegendText>
					</LegendItem>

					<LegendItem>
						<ColorCircle bgcolor="#f44336" />
						<LegendText>
							= Behind plans / management issues /marked below cost or unclear /
							runway of 12 months or less
						</LegendText>
					</LegendItem>
				</LegendContainer>
			</Grid>
		</Grid>
	);
};

function Slide4({ fund, investmentCompanies }) {
	// Filter companies with the relevant primary investment label
	const filteredCompanies = investmentCompanies.filter(
		(company) =>
			company.internalDesignationForFund === "Primary" && !company.hasExited,
	);

	// Process data for the table view
	const companies = useMemo(
		() =>
			filteredCompanies
				.map((company) => ({
					totalAggregateValue: company.totalAggregateValue,
					totalInvestedCapital: company.totalInvestedCapitalForFund,
					name: company.name,
					invested: formatAsMillions(company.totalInvestedCapitalForFund),
					aggregate: formatAsMillions(company.totalAggregateValue),
					perf:
						company.performanceRating === "G"
							? "green"
							: company.performanceRating === "Y"
								? "yellow"
								: "red",
					mgmt:
						company.managementRating === "G"
							? "green"
							: company.managementRating === "Y"
								? "yellow"
								: "red",
					expReturn:
						company.expectedReturn === "G"
							? "green"
							: company.expectedReturn === "Y"
								? "yellow"
								: "red",
					estRunway:
						company.cashRunway === "G"
							? "green"
							: company.cashRunway === "Y"
								? "yellow"
								: "red",
				}))
				.toSorted((a, b) => b.totalAggregateValue - a.totalAggregateValue),
		[filteredCompanies],
	);

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide disclaimer="All figures on this slide exclude realized investments.">
					<ContentSlide.Title>
						{fund.name}: Portfolio Company Performance (
						{fund.primaryInvestmentLabel} Portfolio)
					</ContentSlide.Title>
					<ContentSlide.Content>
						<Grid container spacing={2}>
							<Grid item xs={7}>
								<TableContainer
									component={Paper}
									sx={{
										mt: 2,
										mb: 2,
										overflowX: "auto",
										overflowY: "auto",
										maxHeight: "750px",
									}}
								>
									<Table size="small">
										<TableHead>
											<TableRow sx={{ backgroundColor: "#f5f5f5" }}>
												<TableCell
													sx={{
														fontWeight: "bold",
														borderRight: "1px solid #e0e0e0",
													}}
												>
													Company
												</TableCell>
												<TableCell
													align="center"
													sx={{
														fontWeight: "bold",
														borderRight: "1px solid #e0e0e0",
														fontSize: "0.75rem",
													}}
												>
													Invested
													<br />
													Capital
													<br />
												</TableCell>
												<TableCell
													align="center"
													sx={{
														fontWeight: "bold",
														borderRight: "1px solid #e0e0e0",
														fontSize: "0.75rem",
													}}
												>
													Aggregate
													<br />
													Value
													<br />
												</TableCell>
												<TableCell
													align="center"
													sx={{
														fontWeight: "bold",
														borderRight: "1px solid #e0e0e0",
														fontSize: "0.75rem",
													}}
												>
													Perf
												</TableCell>
												<TableCell
													align="center"
													sx={{
														fontWeight: "bold",
														borderRight: "1px solid #e0e0e0",
														fontSize: "0.75rem",
													}}
												>
													Mgmt
												</TableCell>
												<TableCell
													align="center"
													sx={{
														fontWeight: "bold",
														borderRight: "1px solid #e0e0e0",
														fontSize: "0.75rem",
													}}
												>
													Exp
													<br />
													Return
												</TableCell>
												<TableCell
													align="center"
													sx={{
														fontWeight: "bold",
														fontSize: "0.75rem",
													}}
												>
													Est
													<br />
													Runway
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{companies.map((company, index) => (
												<TableRow
													key={index}
													sx={{
														"&:nth-of-type(odd)": {
															backgroundColor: "#fafafa",
														},
													}}
												>
													<TableCell
														sx={{
															borderRight: "1px solid #e0e0e0",
															fontSize: "0.8rem",
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
															maxWidth: "120px",
														}}
													>
														{company.name}
													</TableCell>
													<TableCell
														align="center"
														sx={{
															borderRight: "1px solid #e0e0e0",
															fontSize: "0.8rem",
														}}
													>
														{company.invested}
													</TableCell>
													<TableCell
														align="center"
														sx={{
															borderRight: "1px solid #e0e0e0",
															fontSize: "0.8rem",
														}}
													>
														{company.aggregate}
													</TableCell>
													<StatusCell status={company.perf} />
													<StatusCell status={company.mgmt} />
													<StatusCell status={company.expReturn} />
													<StatusCell status={company.estRunway} />
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>

							<Grid item xs={5}>
								<Box
									display="flex"
									flexDirection="column"
									justifyContent="space-between"
									alignItems="center"
								>
									<InvestmentCharts investmentCompanies={filteredCompanies} />
								</Box>
							</Grid>
						</Grid>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>

			<SlideToggle.Data>
				<div className="company-info">
					<h3>Company Performance ({fund.primaryInvestmentLabel} Portfolio)</h3>

					<table>
						<thead>
							<tr>
								<th>Company</th>
								<th>Invested Capital</th>
								<th>Aggregate Value</th>
								<th>Performance</th>
								<th>Management</th>
								<th>Expected Return</th>
								<th>Est. Runway</th>
							</tr>
						</thead>
						<tbody>
							{investmentCompanies.map((company, index) => (
								<tr key={index}>
									<td>{company.name}</td>
									<td>
										$
										{(company.totalInvestedCapitalForFund / 1000000)?.toFixed(
											1,
										)}
										M
									</td>
									<td>
										${(company.totalAggregateValue / 1000000)?.toFixed(1)}M
									</td>
									<td>
										<RatingDot rating={company.performanceRating} />{" "}
										{company.performanceRating}
									</td>
									<td>
										<RatingDot rating={company.managementRating} />{" "}
										{company.managementRating}
									</td>
									<td>
										<RatingDot rating={company.expectedReturn} />{" "}
										{company.expectedReturn}
									</td>
									<td>
										<RatingDot rating={company.cashRunway} />{" "}
										{company.cashRunway}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide4;

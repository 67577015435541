export type RYG = "red" | "yellow" | "green";

// Survey IDs as constants to avoid magic strings
export const P2P_SURVEYS = {
	P2P_SEED: "3abfe1f3-d2a7-4075-be63-18636145dd2b",
	P2P_VSV: "92648490-d9d2-460b-838d-a998e9436a6b",
	P2P_ANCILLARY: "e7964681-0094-4e96-8e40-f5e63386704e",
	P2P_GROWTH: "270ff687-7307-474b-8221-ea10262a758d",
} as const;

export const OS_SURVEYS = {
	PLATFORM: "15ff5389-b145-4f75-85ad-dc2f35819847",
	ANCILLARY: "c7e3878c-5b20-4a28-8cc5-ad5aa947fc8e",
	VSV: "c7f4fea5-2645-4898-b9a2-34d9aab11b8e",
	SEED: "0bab04e7-98db-4706-be5f-1176a88a53d4",
} as const;

export const ONE_MONTH_CHOICE_IDS = [
	"2243a823-7e77-4678-8792-885921ae608c", //	Month 1
	"fa9e2196-de28-47a1-8bcf-ea2088780f8e", //	Month 1
	"799a32b7-3982-4022-8b75-33208120f7d0", //	1-Month
	"3c09b22f-ef50-059b-f6b5-759691d6f269", //	1-Month
	"65ff18dd-e8b9-18f1-fcd6-d9ccfda64df2", //	1-Month
];

export const SIX_MONTH_CHOICE_IDS = [
	"59f780ea-5a79-4b9c-a70f-2c2f39ec8afd", // Month 6
	"2a78d48c-2e9f-48e9-b4a5-c5a0604286d5", // Month 6
	"ff8bae09-d8d5-400a-94fb-a2e06836f317", // 6-Month
	"df4d69df-92c9-2d68-bf99-6c70a61c73b2", // 6-Month
	"9510ada4-fb6a-3872-6f30-8a5d395532b5", // 6-Month
];

export const SECTION_IDS = [
	"fc2058d2-f0be-4cd3-8e6c-44f2830b2441", //	Management
	"c7532da2-c9cd-4109-abb9-27e9c6d65f97", //	Management
	"4b5e68bb-1594-4d0c-a5ec-f7787b79f34b", //	Fundamentals
	"0b9237d1-6d45-4204-861a-ccfd519f1000", //	Fundamentals
	"1ad2b85e-7a87-48f5-bef1-64643837fef9", //	Revenue
	"8b286d22-fe03-4411-871c-89fe4a827a3f", //	Revenue
	"73ff754f-36e6-4c03-9ea5-7e1b329aa709", //	Asymmetric Risk
	"3a2a5849-fe3f-4905-ba5a-0d187c6cd83f", //	Asymmetric Risk
];

export const COMPARISON_ATTRIBUTES = [
	{
		sectionName: "Management",
		attributes: [
			{ name: "Relationship", id: "64e3dacd-6a0d-4f65-baee-817be3b115df" },
			{
				name: "Skills / Traits / Attributes",
				id: "bd092e9e-807f-45af-8952-4728888b7615",
			},
			{
				name: "Domain Experience / Expertise",
				id: "6d345e02-5c41-4a46-b5d1-7b9be09412e7",
			},
			{
				name: "Resources / Processes / Values",
				id: "c3a6c9ff-02d5-48f9-82ec-b75fbaf19eed",
			},
			{
				name: "Alignment / Engagement",
				id: "97894ea6-fd45-41cc-ad68-a7548b03ee60",
			},
			{
				name: "Performance and/or Milestones",
				id: "788d58d7-a946-4109-a7d8-e9b984001cbf",
			},
		],
	},
	{
		sectionName: "Fundamentals",
		attributes: [
			{
				name: "Market Positioning",
				id: "a7794e74-a93f-4dca-86af-1e09c8446bfc",
			},
			{ name: "Macro Perspective", id: "5a0d0042-541b-40e3-8708-ba4f82020046" },
			{ name: "Micro Perspective", id: "5600da9c-cf55-4124-b637-4b3f1a327b3c" },
			{ name: "Financials", id: "3e7fe2bb-4ef1-4a73-9778-af39569b1414" },
			{ name: "Liquidity", id: "10b997e4-9064-4d15-8256-84e028dc5031" },
			{ name: "Risk", id: "c2ab60e0-b601-4624-9737-8dcbee2bf3e9" },
		],
	},
	{
		sectionName: "Revenue",
		attributes: [
			{ name: "Scalability", id: "fb97a2c7-b928-4201-ab47-a72a1e877110" },
			{
				name: "Product Market Fit",
				id: "9064634a-55e7-46cb-b513-dfa634a982d4",
			},
			{ name: "Growth Rate", id: "86e596e7-497f-4d58-b6bb-cf6ebe060e57" },
			{
				name: "Operating Leverage",
				id: "12b72171-61bb-43d4-baf1-d2b72e8a8eb7",
			},
			{ name: "Cash Flow", id: "a8f30084-cc19-4261-9df7-ae6a462de0dd" },
		],
	},
	{
		sectionName: "Asymmetric Risk",
		attributes: [
			{
				name: "Uniqueness of Asset",
				id: "e1761ca3-e6c8-4e03-96bf-ce2f67b6023b",
			},
			{
				name: "Information Advantage",
				id: "902eb787-0df0-44f6-92f2-523785d4897e",
			},
			{ name: "Structure", id: "db4363be-b9ca-406d-b038-57846c336048" },
			{
				name: "Take Out Opportunity",
				id: "2a88d825-ed5a-42f4-98cb-4f1ab4e8697f",
			},
			{
				name: "Return Distribution",
				id: "3c76d505-da61-4193-b948-9ab1db194305",
			},
		],
	},
];

export const STANDALONE_COMMENTARY = {
	Management: "9628af5f-ab4d-4890-9676-f6f89c7148ed",
	Growth: "f692104e-33c4-4865-b256-5f405be51f4a",
	Market: "5794cdb1-a741-470b-9dbe-3a2502c9a541",
	"Key Underwriting Variances": "7dd009ca-76e6-4798-b902-cdf47b561f30",
};

// TODO: update this to use attribtue ids instead
export const P2P_METRICS_QUESTIONS = [
	[
		{
			name: "Revenue",
			questionIds: [
				"33c4d8e8-8ef1-4d1d-bb40-d78acbce5aa7",
				"133bac54-5054-466b-ae8c-191636fadd21",
				"5e9ac158-b4b5-4aa6-9093-4a86beca321e",
				"55052b69-00d4-4b17-8c52-755b40569931",
			],
			questionType: "MULTIPLE_CHOICE",
		},
		{
			name: "Revenue from OS Forecast",
			questionIds: [
				"87816cba-198b-469b-a2fd-434d5659daae",
				"0a8cce29-ca1b-4527-9910-e732fbc3a76b",
				"d8b63c46-0797-4e4f-bf8f-050f7dd451b8",
				"cfb93b52-0220-4405-bee9-2d5e0c4b11ee",
			],
			questionType: "DOLLAR_ESTIMATE",
		},
		{
			name: "Updated P2P Revenue",
			questionIds: [
				"d55fc4da-7286-4ee0-8df0-5eb17316da15",
				"b50a5b02-5124-4467-95c1-0ebd99d604e6",
				"bdd6aeec-37c3-469e-8388-1f1dd86b35cf",
				"3daee7da-1a3a-4b25-878d-36f3b4305eba",
			],
			questionType: "DOLLAR_ESTIMATE",
			compareId: "updatedValue",
		},
		{
			name: "Revenue Commentary",
			questionIds: [
				"7458f328-f7fd-4cff-a925-09d9ca0c8b5d",
				"e3ba3148-e699-484b-b61e-670ab70ddb0f",
				"078b36a7-86e3-4aae-90fe-e5bc5fa83f9d",
				"2a5c7be3-73ba-4c73-a5a2-bdbbeb9ef492",
			],
			questionType: "RICH_TEXT",
		},
	],
	[
		{
			name: "Gross Profit",
			questionIds: [
				"eafed2fe-25e4-4f75-86d1-8f8f79e5d4e4",
				"79d5001a-0f3d-45f4-aa4c-f33d8e36f8b9",
				"22b68757-f572-4e97-ac59-9508a317cbc1",
				"5510ab29-a39a-41ba-a04b-7b90674c0473",
			],
			questionType: "MULTIPLE_CHOICE",
		},
		{
			name: "Gross Profit from OS Forecast",
			questionIds: [
				"715d2080-9903-4a0f-b50f-e5d8974645c8",
				"9167ccfc-aed9-4e22-ac3d-f9d59e6ba7f8",
				"510329fc-63b6-423b-9818-4e4fd05c89a0",
				"0c52dd34-fc7f-4e30-aa3f-0cf8eeeb0929",
			],
			questionType: "DOLLAR_ESTIMATE",
		},
		{
			name: "Updated P2P Gross Profit",
			questionIds: [
				"44fac30d-5780-434a-b5a2-ac6778e7a32d",
				"26cda57c-3826-4dfb-bd50-43493cdb8fea",
				"6c92126a-2400-4154-b606-57591f278fda",
				"293577a3-fe50-4bc3-ad35-d5312fb7d87f",
			],
			questionType: "DOLLAR_ESTIMATE",
			compareId: "updatedValue",
		},
		{
			name: "Gross Profit Commentary",
			questionIds: [
				"ed8ef81c-f4ef-4c5c-8f4c-9f16d57e1c69",
				"13ce84e3-84e0-4873-aa2b-c56324cbea95",
				"14a34802-408e-4479-a633-ced61f78a0d1",
				"79d05895-fbc9-444b-b128-10cc1740b986",
			],
			questionType: "RICH_TEXT",
		},
	],
	[
		{
			name: "OpEx",
			questionIds: [
				"e4097af2-6927-4c3d-aa4c-6777559ba79a",
				"ed3ea0e6-91b5-40ea-9142-92a9f5d74f97",
				"cb866c28-beef-4200-981a-ebf48812f34b",
				"d6767557-6300-4bb2-ba73-b48e1ac66e34",
			],
			questionType: "MULTIPLE_CHOICE",
		},
		{
			name: "OpEx from OS Forecast",
			questionIds: [
				"d43059f0-d170-4099-a425-5e85bbba1b13",
				"8a1dab8d-db53-4048-b22b-cf978e0eef28",
				"2fdc27ef-4ed9-4f99-8c00-17edad93aaaf",
				"ab3d260b-bf03-4c2d-b22c-5aa23f93865a",
			],
			questionType: "DOLLAR_ESTIMATE",
		},
		{
			name: "Updated P2P OpEx",
			questionIds: [
				"25e89948-ea92-412f-8d26-23ee0c8fcadc",
				"d544b3bc-e612-4496-90b1-41c109f67acf",
				"f77e0523-811e-441a-a2f8-1f8642955199",
				"302d0fad-a6ab-4668-9ab2-8313cb1c44d7",
			],
			questionType: "DOLLAR_ESTIMATE",
			compareId: "updatedValue",
		},
		{
			name: "OpEx Commentary",
			questionIds: [
				"611228ab-58c3-48b1-ad01-da562a1bc283",
				"ec9e9f60-ac7f-481b-b0b7-e24652ff2331",
				"33bf286e-817b-4485-95fa-5f255cb9e575",
				"99422aee-6a06-4609-ab44-ef7e2163164a",
			],
			questionType: "RICH_TEXT",
		},
	],
	[
		{
			name: "EBITDA",
			questionIds: [
				"3b9110d6-e063-439c-96aa-16c5920146fd",
				"dd3d3db1-3098-4a66-b9d8-b6e877955282",
				"604a6917-05ca-453c-951e-d42ce41be04c",
				"e3ac2639-3c63-481c-8d88-363ea12422df",
			],
			questionType: "MULTIPLE_CHOICE",
		},
		{
			name: "EBITDA from OS Forecast",
			questionIds: [
				"85009ab3-7f9c-4141-95c1-5b515290a412",
				"30ff840d-7c45-4566-84f3-86d0b4ddf6e4",
				"3f39e583-df4d-4787-b415-5fc7574ba6ae",
				"f7aa6438-889b-4f9c-b79e-a87a235fb193",
			],
			questionType: "DOLLAR_ESTIMATE",
		},
		{
			name: "Updated P2P EBITDA",
			questionIds: [
				"ca16c88c-c846-479d-8d91-ad0aa9c05af8",
				"06f2a398-334b-4ff9-bcb1-3e22afe5048d",
				"83b8c1b0-7946-4387-ab42-857d59e2f10f",
				"2aee8cd4-eca6-43cf-b525-f0e3fdf917b8",
			],
			questionType: "DOLLAR_ESTIMATE",
			compareId: "updatedValue",
		},
		{
			name: "EBITDA Commentary",
			questionIds: [
				"c06181f8-5eb9-45f3-add3-4163a7a437b1",
				"c68033aa-c96a-48a5-a1fa-24b503d301ca",
				"64a57f69-0d27-43f0-ac32-7173049b6db7",
				"f75905fd-4dd8-4542-ae0b-d36c996a4f15",
			],
			questionType: "RICH_TEXT",
		},
	],
	[
		{
			name: "Cash Burn",
			questionIds: [
				"f6f9b0e1-e1c6-49a2-a815-ce8842633aa4",
				"16fbd580-e405-4752-93a2-91e4f558ef38",
				"b7376991-44a3-46d4-8eaa-e007bee40b0e",
				"419533c1-93e6-4c40-84a0-2347ab668e0f",
			],
			questionType: "MULTIPLE_CHOICE",
		},
		{
			name: "Cash Burn from OS Forecast",
			questionIds: [
				"41c1132a-aacb-4770-97de-54550844e934",
				"d3f96eda-4b6c-4918-bf34-d97c031f35be",
				"98606421-5e5d-41b0-a386-5cf02d5a125f",
				"31a5a680-5ab5-49a0-a2db-8f7b0a113877",
			],
			questionType: "DOLLAR_ESTIMATE",
		},
		{
			name: "Updated P2P Cash Burn",
			questionIds: [
				"56a8bf2d-ebf3-4d7f-948b-6acd19751252",
				"e4636318-9ca9-4f04-a709-db401046f1a8",
				"8b4fb421-92fe-4494-9214-064ff87a2134",
				"05b5d3ef-f1fe-4ad6-9cb0-c858b6d09375",
			],
			questionType: "DOLLAR_ESTIMATE",
			compareId: "updatedValue",
		},
		{
			name: "Cash Burn Commentary",
			questionIds: [
				"8670a293-11cf-4854-a316-e821c97f4d08",
				"0bab2ec5-e80d-4424-8a33-9c3362fe4864",
				"7d01cb1f-b433-4949-9538-1ef7402ee845",
				"c10db87d-be68-4d24-8638-5e14022ea2c4",
			],
			questionType: "RICH_TEXT",
		},
	],
];

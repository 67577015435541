import { formatAsMillions } from "@/utils/numberFormat";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	LabelList,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import type Fund from "../Fund";
import {
	PRIMARY_COLOR,
	PRIMARY_LABELING,
	SECONDARY_COLOR,
	SECONDARY_LABELING,
	VAAI_ENTITY_ID,
	VSVII_ENTITY_ID,
	VSVI_ENTITY_ID,
} from "../constants";

import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";
import { WaterfallChart, renderCustomAxisTick } from "./shared/WaterfallChart";

interface ChartData {
	name: string;
	value: number | string;
}

interface RiskBarChartProps {
	data: ChartData[];
	primaryLabel: string;
	formatValue: (value: number | string) => string;
	showCurrency: boolean;
}

function RiskBarChart({
	data,
	primaryLabel,
	formatValue,
	showCurrency,
}: RiskBarChartProps) {
	return (
		<BarChart width={550} height={300} data={data}>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="name" stroke="#000" />
			<YAxis
				label={{
					angle: -90,
					position: "insideLeft",
				}}
				tickFormatter={
					showCurrency ? (value) => `$${formatAsMillions(value, 0)}` : undefined
				}
			/>
			<Bar
				dataKey="value"
				label={{
					position: "inside",
					formatter: (value) => formatValue(value),
					fill: "#fff",
				}}
			>
				{data.map((entry, index) => (
					<Cell
						key={`cell-${index}`}
						fill={entry.name === primaryLabel ? PRIMARY_COLOR : SECONDARY_COLOR}
					/>
				))}
			</Bar>
		</BarChart>
	);
}

function Slide10({ fund }: { fund: Fund }) {
	const { entityId } = fund;

	const specialFunds = [VAAI_ENTITY_ID, VSVII_ENTITY_ID, VSVI_ENTITY_ID];

	const isSpecialTitle = specialFunds.indexOf(Number(entityId)) !== -1;
	const primaryLabel = PRIMARY_LABELING[entityId];
	const secondaryLabel = SECONDARY_LABELING[entityId];

	const unitsOfRiskDefinitionData = [
		{ name: primaryLabel, value: fund.unitsOfRiskPrimary },
		{ name: secondaryLabel, value: fund.unitsOfRiskSecondary },
	].filter((x) => x.name !== "error");

	const unitsOfRiskAvailableData = [
		{ name: primaryLabel, value: fund.maxPrimaryUnits.toFixed(0) },
		{ name: secondaryLabel, value: fund.maxSecondaryUnits.toFixed(0) },
	].filter((x) => x.name !== "error");

	const investableCapitalData = [
		{
			xLabel: "Total Capital Available to Invest",
			Capital:
				fund.capitalAvailableForInvestmentIncludingPostInvestmentReserves,
			spacer: 0,
			"(-)": 0,
			total: fund.capitalAvailableForInvestmentIncludingPostInvestmentReserves,
		},
		...(fund.ancillaryBucketAmount === 0
			? []
			: [
					{
						xLabel: "Ancillary Allocation",
						"(-)": fund.ancillaryBucketAmount,
						Capital: 0,
						spacer: fund.totalInvestableCapital - fund.ancillaryBucketAmount,
						total: fund.ancillaryBucketAmount,
					},
				]),
		{
			xLabel: "Post Investment Period Reserves",
			"(-)": fund.postInvestmentPeriodReserves,
			Capital: 0,
			spacer:
				fund.capitalAvailableForInvestmentIncludingPostInvestmentReserves -
				fund.ancillaryBucketAmount -
				fund.postInvestmentPeriodReserves,
			total: fund.postInvestmentPeriodReserves,
		},
		{
			xLabel: "Investment Period, Capital Available",
			Capital: fund.investableCapitalInInvestmentPeriod,
			spacer: 0,
			total: fund.investableCapitalInInvestmentPeriod,
		},
	];

	const secondaryAllocations = [
		{
			xLabel: `Total Capital Available to Invest,  ${fund.secondaryInvestmentLabel}`,
			Capital: fund.ancillaryBucketAmount + fund.placeholderAllocation,
			spacer: 0,
			"(-)": 0,
			total: fund.ancillaryBucketAmount + fund.placeholderAllocation,
		},
		{
			xLabel: `Post Investment Period Reserves, ${fund.secondaryInvestmentLabel}`,
			"(-)": fund.ancillaryReserveAmount,
			spacer:
				fund.ancillaryBucketAmount +
				fund.placeholderAllocation -
				fund.ancillaryReserveAmount,
			total: fund.ancillaryReserveAmount,
		},
		{
			xLabel: `Investment Period Capital Available, ${fund.secondaryInvestmentLabel}`,
			Capital: fund.secondaryAllocation,
			spacer: 0,
			total: fund.secondaryAllocation,
		},
	];

	const specialAllocations = [
		{
			xLabel: primaryLabel,
			Capital: fund.plannedPrimaryInvestedCapital,
			final: 0,
			total: fund.plannedPrimaryInvestedCapital,
			"(-)": 0,
			"(+)": 0,
			spacer: 0,
		},
		{
			xLabel: secondaryLabel,
			Capital: 0,
			total: fund.secondaryAllocation,
			"(-)": 0,
			"(+)": fund.secondaryAllocation,
			spacer: fund.plannedPrimaryInvestedCapital,
		},
		{
			xLabel: "Investment Period Capital Available",
			final: fund.plannedPrimaryInvestedCapital + fund.secondaryAllocation,
			total: fund.plannedPrimaryInvestedCapital + fund.secondaryAllocation,
			"(-)": 0,
			"(+)": 0,
			spacer: 0,
		},
	];

	const waterfallColors = {
		"(-)": "#D3D3D3",
		Capital: PRIMARY_COLOR,
		"(+)": SECONDARY_COLOR,
		total: "#000000",
		final: "#fff",
	};

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide>
					<ContentSlide.Title>
						{`${fund.name}: Fund Design - Investable Capital Summary`}
					</ContentSlide.Title>
					<ContentSlide.Content>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Typography variant="h3" my={1} textAlign="center">
									{isSpecialTitle
										? "Investable Capital (Investment Period)"
										: `${primaryLabel} Investable Capital (Investment Period)`}
								</Typography>
								<WaterfallChart
									width={600}
									height={350}
									data={investableCapitalData}
									colors={waterfallColors}
									rotateLabels={false}
									showTotal
									formatter={(value, key) =>
										key === "-"
											? `($${formatAsMillions(Math.abs(value), 0)})`
											: `$${formatAsMillions(value, 0)}`
									}
								/>

								<Typography variant="h3" my={1} textAlign="center">
									Units of Risk Definition
								</Typography>
								<RiskBarChart
									data={unitsOfRiskDefinitionData}
									primaryLabel={primaryLabel}
									formatValue={(value) => `$${formatAsMillions(value)}`}
									showCurrency={true}
								/>
							</Grid>

							<Grid item xs={6}>
								{secondaryLabel === "error" ? null : (
									<>
										{isSpecialTitle ? (
											<>
												<Typography variant="h3" my={1} textAlign="center">
													{primaryLabel} &amp; {secondaryLabel} Allocations
												</Typography>
												<WaterfallChart
													width={600}
													height={350}
													data={specialAllocations}
													colors={waterfallColors}
													rotateLabels={false}
													showTotal
													formatter={(value, key) =>
														key === "-"
															? `($${formatAsMillions(Math.abs(value), 0)})`
															: `$${formatAsMillions(value, 0)}`
													}
												/>
											</>
										) : (
											<>
												<Typography variant="h3" my={1} textAlign="center">
													{secondaryLabel} Investable Capital (Investment
													Period)
												</Typography>
												<WaterfallChart
													width={600}
													height={350}
													data={secondaryAllocations}
													colors={waterfallColors}
													rotateLabels={false}
													showTotal
													formatter={(value, key) =>
														key === "-"
															? `($${formatAsMillions(Math.abs(value), 0)})`
															: `$${formatAsMillions(value, 0)}`
													}
												/>
											</>
										)}

										<Typography variant="h3" my={1} textAlign="center">
											Units of Risk Available
										</Typography>
										<RiskBarChart
											data={unitsOfRiskAvailableData}
											primaryLabel={primaryLabel}
											formatValue={(value) => value}
											showCurrency={false}
										/>
									</>
								)}
							</Grid>
						</Grid>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>

			<SlideToggle.Data>
				<h2>{fund.name} - Fund Design - Investable Capital Summary</h2>
				<h3>Investable Capital; During; Investment; Period;</h3>(
				<table>
					<tbody>
						<tr>
							<td>Investable Capital</td>
							<td>{formatAsMillions(fund.totalInvestableCapital)}</td>
						</tr>
						<tr>
							<td>Post Investment Period Reserves</td>
							<td>{formatAsMillions(fund.postInvestmentPeriodReserves)}</td>
						</tr>
						<tr>
							<td>Investment Period, Capital Available</td>
							<td>
								${formatAsMillions(fund.investableCapitalInInvestmentPeriod)}
							</td>
						</tr>
					</tbody>
				</table>
				)
				<h3>
					; {primaryLabel}; and; {secondaryLabel}; Allocations;
				</h3>
				<table>
					<tbody>
						<tr>
							<td>{primaryLabel}; Allocation;</td>
							<td>{formatAsMillions(fund.primaryAllocation)}</td>;
						</tr>
						<tr>
							<td>{secondaryLabel}; Allocation;</td>
							<td>{formatAsMillions(fund.secondaryAllocation)}</td>;
						</tr>
						<tr>
							<td>Investment Period, Capital Available</td>
							<td>
								$ formatAsMillions(fund.investableCapitalInInvestmentPeriod);
							</td>
						</tr>
					</tbody>
				</table>
				<h3>Units of Risk</h3>
				<table>
					<thead>
						<tr>
							<th>Units of Risk</th>
							<th>Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{primaryLabel};</td>
							<td>{formatAsMillions(fund.unitsOfRiskPrimary)}</td>;
						</tr>
						<tr>
							<td>{secondaryLabel};</td>
							<td>{formatAsMillions(fund.unitsOfRiskSecondary)}</td>;
						</tr>
					</tbody>
				</table>
				<h3>Units of Risk Available</h3>
				<table>
					<thead>
						<tr>
							<th>Units of Risk</th>
							<th>Available</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{primaryLabel};</td>
							<td>{fund.maxPrimaryUnits}</td>
						</tr>
						<tr>
							<td>{secondaryLabel};</td>
							<td>{fund.maxSecondaryUnits}</td>
						</tr>
					</tbody>
				</table>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide10;

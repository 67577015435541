import {
	convertFile,
	countTokens,
	getContext,
	getContextNotes,
	getContextSurveys,
	getCrmContexts,
	getGeneralContexts,
} from "@/api/Contexts";
import useDebounce from "@/hooks/useDebounce";
import { Alert, Box, Snackbar, Stack } from "@mui/material";
import type React from "react";
import { useEffect, useMemo, useReducer, useRef } from "react";
import { ActionButtons } from "./ActionButtons";
import { MentionDialog } from "./CompanyMentionDialog";
import { MessageInput } from "./MessageInput";
import { PasteAndFileContexts } from "./PasteAndFileContexts";
import { SelectedContexts } from "./SelectedCompanyContexts";
import { TokenUsageChart } from "./TokenUsageChart";
import type { SelectedContextProps } from "./utils/ChatState";
import { chatReducer, initialState } from "./utils/useChatReducer";

const ALLOWED_FILE_TYPES = [
	"pdf",
	"doc",
	"docx",
	"txt",
	"csv",
	"xls",
	"xlsx",
	"ppt",
	"pptx",
	"md",
];

const MAX_TOKENS = 1_048_576;

export default function ChatBox({
	onSend,
	placeholder,
	onStop,
	disabled,
}: {
	onSend: (message: string) => void;
	placeholder: string;
	onStop: () => void;
	disabled: boolean;
}) {
	const [state, dispatch] = useReducer(chatReducer, initialState);
	const debouncedMessage = useDebounce(state.message, 500);
	const textFieldRef = useRef<HTMLDivElement>(null);

	const totalTokens = useMemo(() => {
		const sumContextTokens = state.selectedContexts.reduce(
			(sum, context) => sum + (context.data?.tokenCount || 0),
			0,
		);
		const sumFileTokens = state.fileContexts.reduce(
			(sum, file) => sum + (file.tokenCount || 0),
			0,
		);
		return sumContextTokens + sumFileTokens + state.inputTokens;
	}, [state.selectedContexts, state.inputTokens, state.fileContexts]);

	const processFile = async (file: File) => {
		const fileId = crypto.randomUUID();
		dispatch({ type: "INCREMENT_LOADING" });

		dispatch({
			type: "ADD_FILE_CONTEXT",
			payload: { id: fileId, name: file.name, isLoading: true },
		});

		try {
			const result = await convertFile(file);
			dispatch({
				type: "UPDATE_FILE_CONTEXT",
				payload: {
					id: fileId,
					name: file.name,
					content: result.content,
					tokenCount: result.tokenCount,
					isLoading: false,
				},
			});
		} catch (error) {
			dispatch({
				type: "UPDATE_FILE_CONTEXT",
				payload: {
					id: fileId,
					name: file.name,
					error: "Failed to process file",
					isLoading: false,
				},
			});
			dispatch({
				type: "SET_ERROR",
				payload: `Failed to process file ${file.name}: ${error.message}`,
			});
		} finally {
			dispatch({ type: "DECREMENT_LOADING" });
		}
	};

	const validateFile = (file: File): boolean => {
		const extension = file.name.split(".").pop()?.toLowerCase() || "";
		if (!ALLOWED_FILE_TYPES.includes(extension)) {
			dispatch({
				type: "SET_ERROR",
				payload: `File type .${extension} is not supported`,
			});
			return false;
		}

		if (file.size > 50 * 1024 * 1024) {
			dispatch({
				type: "SET_ERROR",
				payload: "File size exceeds 50MB limit",
			});
			return false;
		}
		return true;
	};

	const handleFileDrop = (files: File[]) => {
		const validFiles = files.filter(validateFile);
		if (validFiles.length !== files.length) {
			dispatch({
				type: "SET_ERROR",
				payload: "Some files were rejected due to invalid type or size",
			});
		}
		validFiles.forEach(processFile);
	};

	const handleRemoveFileContext = (fileId: string) => {
		dispatch({
			type: "REMOVE_FILE_CONTEXT",
			payload: fileId,
		});
	};

	const handleSendMessage = () => {
		if (!disabled && !state.isLoading) {
			const contextData = state.selectedContexts
				.map((context) => context.data?.context || "")
				.filter(Boolean);

			const fileData = state.fileContexts
				.map((file) => file.content)
				.filter(Boolean);

			const pastedData = state.pastedContents
				.map(
					(content) =>
						`<private_context><title>Pasted Content</title><content>${content.content}</content></private_context>`,
				)
				.filter(Boolean);

			const fullMessage = [
				...contextData,
				...fileData,
				...pastedData,
				state.message,
			]
				.filter(Boolean)
				.join("\n\n");

			onSend(fullMessage);
			dispatch({ type: "RESET_CHAT" });
		}
	};

	useEffect(() => {
		const fetchContextData = async (context: SelectedContextProps) => {
			dispatch({ type: "INCREMENT_LOADING" });
			try {
				let data;
				switch (context.contextType) {
					case "note":
						data = await getContextNotes(context.valorId);
						break;
					case "survey":
						data = await getContextSurveys(context.valorId);
						break;
					case "general":
						data = await getGeneralContexts(context.valorId);
						break;
					case "crm":
						data = await getCrmContexts(context.valorId);
						break;
					default:
						throw new Error(`Unsupported context type: ${context.contextType}`);
				}

				dispatch({
					type: "UPDATE_CONTEXT_DATA",
					payload: {
						id: context.id,
						contextType: context.contextType,
						data: {
							context: data.context,
							tokenCount: data.tokenCount || 0,
						},
					},
				});
			} catch (error) {
				dispatch({
					type: "SET_ERROR",
					payload: `Error fetching ${context.contextType} data: ${error.message}`,
				});
			} finally {
				dispatch({ type: "DECREMENT_LOADING" });
			}
		};

		state.selectedContexts.forEach((context) => {
			if (!context.data) {
				fetchContextData(context);
			}
		});
	}, [state.selectedContexts]);

	useEffect(() => {
		const countMessageTokens = async () => {
			if (debouncedMessage.trim()) {
				try {
					const result = await countTokens(debouncedMessage, "grok-2-1212");
					dispatch({
						type: "SET_INPUT_TOKENS",
						payload: result.tokenCount || 0,
					});
				} catch (error) {
					dispatch({
						type: "SET_ERROR",
						payload: `Error counting tokens: ${error.message}`,
					});
				}
			} else {
				dispatch({ type: "SET_INPUT_TOKENS", payload: 0 });
			}
		};

		countMessageTokens();
	}, [debouncedMessage]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch({
			type: "SET_MESSAGE",
			payload: e.target.value,
		});
	};

	const handleSelectMention = async (company: any) => {
		dispatch({ type: "INCREMENT_LOADING" });
		try {
			const contextData = await getContext(company.valorId);
			dispatch({
				type: "SET_SELECTED_COMPANY",
				payload: {
					...company,
					contextTypes: contextData.contextTypes,
				},
			});
		} catch (error) {
			dispatch({
				type: "SET_ERROR",
				payload: `Failed to get context for ${company.name}: ${error.message}`,
			});
		} finally {
			dispatch({ type: "DECREMENT_LOADING" });
		}
	};

	const handleContextTypeSelection = async () => {
		if (!state.selectedCompany) return;

		const newContexts = state.selectedContextTypes.map((type) => ({
			...state.selectedCompany,
			contextType: type,
		}));

		dispatch({
			type: "ADD_SELECTED_CONTEXTS",
			payload: newContexts,
		});

		dispatch({ type: "SET_SELECTED_COMPANY", payload: null });
		dispatch({ type: "SET_SELECTED_CONTEXT_TYPES", payload: [] });
		dispatch({ type: "TOGGLE_CONTEXT_DIALOG", payload: false });
	};

	const handleRemoveContext = (contextId: string, contextType: string) => {
		dispatch({
			type: "REMOVE_SELECTED_CONTEXT",
			payload: { id: contextId, contextType },
		});
	};

	const handleKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (disabled) return;
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			if (state.message.trim()) {
				handleSendMessage();
			}
		}
	};

	const handleAddMoreContext = async (company: any) => {
		try {
			const contextData = await getContext(company.valorId);
			dispatch({
				type: "SET_SELECTED_COMPANY",
				payload: {
					...company,
					contextTypes: contextData.contextTypes,
				},
			});
			dispatch({ type: "TOGGLE_CONTEXT_DIALOG", payload: true });
		} catch (error) {
			dispatch({
				type: "SET_ERROR",
				payload: `Failed to get context for ${company.name}: ${error.message}`,
			});
		}
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		const pastedText = event.clipboardData.getData("Text");
		const PASTE_THRESHOLD = 500; // Define your character length threshold

		if (pastedText.length > PASTE_THRESHOLD) {
			event.preventDefault();
			const pastedContentId = crypto.randomUUID();
			dispatch({
				type: "ADD_PASTED_CONTENT",
				payload: { id: pastedContentId, content: pastedText },
			});
		}
	};
	const handleRemovePastedContent = (contentId: string) => {
		dispatch({
			type: "REMOVE_PASTED_CONTENT",
			payload: contentId,
		});
	};

	return (
		<Box>
			<Snackbar
				open={!!state.error}
				autoHideDuration={6000}
				onClose={() => dispatch({ type: "SET_ERROR", payload: null })}
			>
				<Alert
					onClose={() => dispatch({ type: "SET_ERROR", payload: null })}
					severity="error"
					sx={{ width: "100%" }}
				>
					{state.error}
				</Alert>
			</Snackbar>

			<Stack
				spacing={{ sm: 1 }}
				sx={{
					width: "100%",
					my: { sm: 1 },
				}}
			>
				{(state.selectedContexts.length > 0 ||
					state.fileContexts.length > 0 ||
					totalTokens > 500) && (
					<TokenUsageChart usedTokens={totalTokens} maxTokens={MAX_TOKENS} />
				)}

				<SelectedContexts
					contexts={state.selectedContexts}
					onRemoveContext={handleRemoveContext}
					onAddContext={handleAddMoreContext}
				/>

				<PasteAndFileContexts
					files={state.fileContexts}
					onRemoveFile={handleRemoveFileContext}
					pastedContents={state.pastedContents}
					onRemovePastedContent={handleRemovePastedContent}
				/>

				<ActionButtons
					onAddContext={() => {
						dispatch({ type: "TOGGLE_CONTEXT_DIALOG", payload: true });
						dispatch({ type: "SET_SELECTED_COMPANY", payload: null });
						dispatch({ type: "SET_SELECTED_CONTEXT_TYPES", payload: [] });
					}}
					onAddFile={handleFileDrop}
					allowedFileTypes={ALLOWED_FILE_TYPES}
				/>
			</Stack>

			<MessageInput
				message={state.message}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				onSend={handleSendMessage}
				onStop={onStop}
				disabled={disabled || state.isLoading}
				placeholder={placeholder}
				inputRef={textFieldRef}
				onFileDrop={handleFileDrop}
				handlePaste={handlePaste}
			/>

			<MentionDialog
				open={state.isContextDialogOpen}
				onClose={() => {
					dispatch({ type: "TOGGLE_CONTEXT_DIALOG", payload: false });
					dispatch({ type: "SET_SELECTED_COMPANY", payload: null });
					dispatch({ type: "SET_SELECTED_CONTEXT_TYPES", payload: [] });
				}}
				selectedCompany={state.selectedCompany}
				selectedContextTypes={state.selectedContextTypes}
				selectedContexts={state.selectedContexts}
				onSelectMention={handleSelectMention}
				onContextTypeSelect={(type) =>
					dispatch({
						type: "SET_SELECTED_CONTEXT_TYPES",
						payload: state.selectedContextTypes.includes(type)
							? state.selectedContextTypes.filter((t) => t !== type)
							: [...state.selectedContextTypes, type],
					})
				}
				onConfirm={handleContextTypeSelection}
			/>
		</Box>
	);
}

export default class InvestmentOrganization {
	public valorId: string;
	public name: string;
	public id: string;
	public performanceRating?: string;
	public furtherInvestmentStatus?: string;
	public cashRunway?: string;
	public managementRating?: string;
	public expectedReturn?: string;
	private estimatedLiquidityYear?: number;
	private probabilisticAdjustedEquityExitValuation?: number;
	public riskRating?: string;
	public targetVertical?: string;
	public platformTechnology?: string;
	public targetSegment?: string;
	public valueChainSector?: string;
	public entropyScore?: string;
	public binaryRisk?: string;
	public leanGrowthPotentialProjects?: string;
	public revGenPotentialProjects?: string;
	public humanCapitalProjectedProjects?: string;
	public inflation?: string;
	public risingInterestRates?: string;
	public labor?: string;
	public chinaRisk?: string;
	public regulatoryGovernmentPolicy?: string;
	public geopolitical?: string;
	public pandemic?: string;

	private overrideExitValue?: number;
	private overrideOwnershipPercent?: number;
	private overrideLiquidityDate?: string;
	private _overrideHistory: Array<{
		id: string;
		key: string;
		value: string;
		author: {
			id: string;
			firstName: string;
			lastName: string;
			profilePicture: string;
		};
	}>;

	constructor(
		data: InvestmentOrg,
		investments: FundInvestments,
		localOverrides: [],
	) {
		this.valorId = data.valorId;
		this.name = data.name;
		this.id = data.id;
		this.performanceRating = data.performanceRating;
		this.furtherInvestmentStatus = data.furtherInvestmentStatus;
		this.cashRunway = data.cashRunway;
		this.managementRating = data.managementRating;
		this.expectedReturn = data.expectedReturn;
		this.estimatedLiquidityYear = data.estimatedLiquidityYear;
		this.probabilisticAdjustedEquityExitValuation =
			data.probabilisticAdjustedEquityExitValuation;
		this.riskRating = data.riskRating;
		this.targetVertical = data.targetVertical;
		this.platformTechnology = data.platformTechnology;
		this.targetSegment = data.targetSegment;
		this.valueChainSector = data.valueChainSector;
		this.entropyScore = data.entropyScore;
		this.binaryRisk = data.binaryRisk;
		this.leanGrowthPotentialProjects = data.leangrowthPotentialProjects;
		this.revGenPotentialProjects = data.revGenPotentialProjects;
		this.humanCapitalProjectedProjects = data.humanCapitalProjectedProjects;
		this.inflation = data.inflation;
		this.risingInterestRates = data.risingInterestRates;
		this.labor = data.labor;
		this.chinaRisk = data.chinaRisk;
		this.regulatoryGovernmentPolicy = data.regulatoryGovernmentPolicy;
		this.geopolitical = data.geopolitical;
		this.pandemic = data.pandemic;
		this.manualReview = data.manualReview;

		this.hasExited = data.furtherInvestmentStatus === "Exited";

		this._investments = investments;

		const validateOverrides = (overridesArray: any, id: string): any => {
			if (overridesArray === null || overridesArray === undefined) {
				return overridesArray;
			}

			if (!Array.isArray(overridesArray)) {
				throw new Error(
					`Overrides must be an array ${id}, received ${JSON.stringify(overridesArray)}`,
				);
			}

			if (overridesArray.length === 0) {
				return overridesArray;
			}

			// Validate that all items have key and value properties
			for (const item of overridesArray) {
				if (
					!item ||
					typeof item !== "object" ||
					!("key" in item) ||
					!("value" in item)
				) {
					throw new Error(
						"Each override must be an object with key and value properties",
					);
				}
			}

			return overridesArray;
		};

		this._overrideHistory = data.overrides || [];
		validateOverrides(
			(data.overrides || []).filter(({ deleted }) => !deleted),
			"data.overrides",
		);
		validateOverrides(localOverrides || [], "localOverrides");

		// Initialize overrides property
		this.overrides = [
			...(data.overrides || []).filter(({ deleted }) => !deleted),
			...(localOverrides || []),
		].reduce((acc, curr) => {
			// Check if curr has a key property
			if (!curr.key) {
				console.error(
					'Error: Override object is missing required "key" property',
					curr,
				);
				return acc; // Skip this item and continue with the next
			}

			return {
				...acc,
				[curr.key.replace(/[-_]([a-z])/g, (_, letter) => letter.toUpperCase())]:
					curr.value,
			};
		}, {});
	}

	public get effectiveExitValuation(): number | undefined {
		if (this.overrides?.exitValue) {
			return Number(this.overrides.exitValue);
		}
		return this.probabilisticAdjustedEquityExitValuation;
	}

	public get totalSharesOwned() {
		return this._investments.reduce(
			(acc, curr) => curr.sharesOwnedForInvestment + acc,
			0,
		);
	}

	public get totalSharesOutstanding() {
		return (
			this._investments.reduce(
				(acc, curr) => Math.max(curr.totalSharesOutstanding, acc),
				0,
			) || null
		);
	}

	public get ownershipPercent(): number {
		if (!this.totalSharesOutstanding) return 0;
		return this.totalSharesOwned / this.totalSharesOutstanding;
	}

	public get effectiveOwnershipPercent(): number | undefined {
		if (this.overrides?.ownershipPercent) {
			return Number(this.overrides.ownershipPercent);
		}
		return this.ownershipPercent;
	}

	public get effectiveLiquidityYear(): number {
		if (this.overrides?.liquidityYear) {
			return Number(this.overrides.liquidityYear);
		}
		return Number(this.estimatedLiquidityYear);
	}

	public get realizedValue(): number {
		return this._investments.reduce((acc, inv) => acc + inv.realizedValue, 0);
	}

	public get projectedRealizedValue(): number {
		return this.unrealizedValue + this.realizedValue;
	}

	public get unrealizedValue(): number {
		return this.effectiveExitValuation * this.effectiveOwnershipPercent;
	}

	public get projectedGrossMoic(): number {
		return this.projectedRealizedValue / this.totalInvestedCapitalForFund;
	}

	public get currentGrossMoic(): number {
		return this.totalAggregateValue / this.totalInvestedCapitalForFund;
	}

	public get totalInvestedCapitalForFund(): number {
		return this._investments.reduce(
			(acc, curr) => curr.investedCapital + acc,
			0,
		);
	}

	public get totalAggregateValue(): number {
		return this._investments.reduce(
			(acc, curr) => curr.aggregateValue + acc,
			0,
		);
	}

	public get internalDesignationForFund(): string {
		return this._investments.reduce((acc, curr) => {
			if (acc === "Primary") return acc;
			return curr.fundInternalDesignation;
		}, null);
	}

	public get internalDesignations(): Array {
		return this._investments.map((curr) => curr.fundInternalDesignation);
	}

	public get fundInternalDesignation(): string {
		return this._investments.reduce((acc, curr) => {
			if (acc === "Primary") return acc;
			return curr.fundInternalDesignation;
		}, null);
	}

	public get hasFutureLiquidity(): boolean {
		return (
			this.effectiveLiquidityYear &&
			this.effectiveOwnershipPercent &&
			this.effectiveExitValuation
		);
	}

	public get hasOverrides(): boolean {
		const hasOverrides =
			this.overrides?.liquidityYear?.length ||
			this.overrides?.exitValue?.length ||
			this.overrides?.ownershipPercent?.length;

		return hasOverrides;
	}

	public get overrideHistory() {
		return this._overrideHistory.sort(
			(a, b) =>
				new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
		);
	}
}

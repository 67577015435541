import { formatAsMillions, formatNumber } from "@/utils/numberFormat";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";

import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

// Custom styled components
const HeaderPaper = styled(Paper)(({ theme }) => ({
	backgroundColor: "#ccd5ff",
	padding: theme.spacing(2),
	textAlign: "center",
	border: "1px solid #3f51b5",
	marginBottom: theme.spacing(4),
}));

const BlueTitle = styled(Typography)(({ theme }) => ({
	color: "#3f51b5",
	fontWeight: "bold",
	marginBottom: theme.spacing(2),
}));

const HeaderDivider = styled(Divider)(({ theme }) => ({
	backgroundColor: "#3f51b5",
	margin: "0 auto",
	width: "50%",
	marginBottom: theme.spacing(3),
}));

const InfoItem = styled(Typography)(({ theme }) => ({
	marginBottom: theme.spacing(1),
	fontSize: "0.9rem",
}));

const BulletList = styled("ul")(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	marginTop: 0,
	marginBottom: theme.spacing(2),
}));

const NestedBulletList = styled("ul")(({ theme }) => ({
	paddingLeft: theme.spacing(4),
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1),
}));

const BulletItem = styled("li")(({ theme }) => ({
	marginBottom: theme.spacing(1),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
	fontWeight: "bold",
	marginBottom: theme.spacing(1),
	marginTop: theme.spacing(2),
}));

const Footer = styled(Typography)(({ theme }) => ({
	fontSize: "0.75rem",
	marginTop: theme.spacing(4),
}));

function Slide1({ fund, asOfFund, investments }) {
	const hasFeePayingCapital = fund.feePayingCapitalCommitted > 0;

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide>
					<ContentSlide.Content>
						<Box
							sx={{
								paddingTop: 10,
							}}
						>
							{/* Header Box */}
							<HeaderPaper
								elevation={3}
								sx={{
									width: "500px",
									marginLeft: "auto",
									marginRight: "auto",
								}}
							>
								<BlueTitle variant="h3">{fund.name}</BlueTitle>
								<HeaderDivider />

								<InfoItem>
									<Box component="span" sx={{ fontWeight: "bold" }}>
										Investment Focus:
									</Box>{" "}
									{fund.investmentFocus}
								</InfoItem>
								<InfoItem>
									<Box component="span" sx={{ fontWeight: "bold" }}>
										Vintage Year:
									</Box>{" "}
									{fund.vintageYear}
								</InfoItem>
								<InfoItem>
									<Box component="span" sx={{ fontWeight: "bold" }}>
										Deployment Period Design:
									</Box>{" "}
									{`${fund.deploymentPeriodDesign} years`}
								</InfoItem>
								<InfoItem>
									<Box component="span" sx={{ fontWeight: "bold" }}>
										Units of Risk Definition:
									</Box>
								</InfoItem>
								<InfoItem>
									${formatAsMillions(fund.unitsOfRiskPrimary)}{" "}
									{fund.primaryInvestmentLabel} Investments
								</InfoItem>
								{fund.secondaryInvestmentLabel === "error" ? null : (
									<InfoItem>
										${formatAsMillions(fund.unitsOfRiskSecondary)}{" "}
										{fund.secondaryInvestmentLabel} Investments
									</InfoItem>
								)}
							</HeaderPaper>

							{/* Content Grid */}
							<Grid container sx={{ margin: "auto", width: "80%" }}>
								{/* Left Column */}
								<Grid item xs={6}>
									<BulletList>
										<BulletItem>
											<Typography variant="boldBody2">
												Total Fund Size: ${formatAsMillions(fund.totalFundSize)}
											</Typography>
											<NestedBulletList sx={{ listStyleType: "'-  '" }}>
												<BulletItem>
													${formatAsMillions(fund.feePayingCapitalCommitted)}{" "}
													Fee Paying LP Capital
												</BulletItem>
												<BulletItem>
													${formatAsMillions(fund.nonFeePayingCapitalCommitted)}{" "}
													Non Fee Paying LP Capital
												</BulletItem>
											</NestedBulletList>
										</BulletItem>
									</BulletList>
								</Grid>
								<Grid item xs={6}>
									<BulletList>
										<BulletItem>
											<Typography variant="boldBody2">
												Capital Invested: $
												{formatAsMillions(investments.totalInvestedCapital)} as
												of Q{fund.latestQuarter?.quarter}{" "}
												{fund.latestQuarter?.year}
											</Typography>
											<NestedBulletList sx={{ listStyleType: "'-  '" }}>
												<BulletItem>
													{formatNumber(
														investments.totalInvestedCapitalAsPercentage,
														1,
													)}
													% of Total Fund Size
												</BulletItem>
												{hasFeePayingCapital && (
													<BulletItem>
														{formatNumber(
															investments.totalFeePayingInvestedCapitalAsPercentage,
															1,
														)}
														% of Fee Paying LP Capital
													</BulletItem>
												)}
											</NestedBulletList>
										</BulletItem>
									</BulletList>
								</Grid>
								<Grid item xs={6}>
									<BulletList>
										<BulletItem>
											<Typography variant="boldBody2">
												Key Fund Metrics as of Q
												{asOfFund.latestQuarter?.quarter}{" "}
												{asOfFund.latestQuarter?.year}
											</Typography>
											<NestedBulletList sx={{ listStyleType: "'-  '" }}>
												<BulletItem>
													{formatNumber(asOfFund.grossMoic, 2)}x Gross MOIC and{" "}
													{formatNumber(asOfFund.netMoic, 2)}x Net MOIC
												</BulletItem>
												<BulletItem>
													{formatNumber(asOfFund.grossIrr * 100, 1)}% Gross IRR
													and {formatNumber(asOfFund.netIrr * 100, 1)}% Net IRR
												</BulletItem>
												<BulletItem>
													{formatNumber(asOfFund.impairmentRatio * 100, 1)}%
													Impairment Ratio
												</BulletItem>
											</NestedBulletList>
										</BulletItem>
									</BulletList>
								</Grid>

								<Grid item xs={6}>
									<BulletList>
										<BulletItem>
											<Typography variant="boldBody2">
												Capital Called: $
												{formatAsMillions(fund.totalCapitalCalled)} as of Q
												{fund.latestQuarter?.quarter} {fund.latestQuarter?.year}
											</Typography>
											<NestedBulletList sx={{ listStyleType: "'-  '" }}>
												<BulletItem>
													{formatNumber(fund.totalCapitalCalledAsPercentage, 1)}
													% of Total Fund Size
												</BulletItem>

												{hasFeePayingCapital && (
													<BulletItem>
														{formatNumber(
															fund.totalCapitalCalledFeePayingAsPercentage,
															1,
														)}
														% of Fee Paying LP Capital
													</BulletItem>
												)}
											</NestedBulletList>
										</BulletItem>
									</BulletList>
								</Grid>
							</Grid>
						</Box>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>
			<SlideToggle.Data>
				<h2>Fund</h2>
				<div>
					<table className="funds-table">
						<thead>
							<tr>
								<th>Property</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Name</td>
								<td>{fund.name}</td>
							</tr>
							<tr>
								<td>Investment Focus</td>
								<td>{fund.investmentFocus}</td>
							</tr>
							<tr>
								<td>Vintage Year</td>
								<td>{fund.vintageYear}</td>
							</tr>
							<tr>
								<td>Deployment Period Design</td>
								<td>{`${fund.deploymentPeriodDesign} years`}</td>
							</tr>
							<tr>
								<td>Units of Risk Primary</td>
								<td>{fund.unitsOfRiskPrimary}</td>
							</tr>
							<tr>
								<td>Units of Risk Secondary</td>
								<td>{fund.unitsOfRiskSecondary}</td>
							</tr>
							<tr>
								<td>Total Fund Size</td>
								<td>{fund.totalFundSize}</td>
							</tr>
							<tr>
								<td>General Partner Commitment</td>
								<td>{fund.generalPartnerCommitment}</td>
							</tr>
							<tr>
								<td>Fee and Carry LP Commitments</td>
								<td>{fund.feeAndCarryLpCommitments}</td>
							</tr>
							<tr>
								<td>Fee Only LP Commitments</td>
								<td>{fund.feeOnlyLpCommitments}</td>
							</tr>
							<tr>
								<td>Carry Only LP Commitments</td>
								<td>{fund.carryOnlyLpCommitments}</td>
							</tr>
							<tr>
								<td>No Fee No Carry LP Commitments</td>
								<td>{fund.noFeeNoCarryLpCommitments}</td>
							</tr>
							<tr>
								<td>Total Invested Capital</td>
								<td>{fund.totalInvestedCapital}</td>
							</tr>
							<tr>
								<td>Projected Gross MOIC</td>
								<td>{formatNumber(investments.projectedGrossMoic, 2)}x</td>
							</tr>
						</tbody>
					</table>

					<h3>Client Quarterly Metrics</h3>
					<table className="client-quarters-table">
						<thead>
							<tr>
								<th>Client</th>
								<th>Quarter</th>
								<th>Year</th>
								<th>Total Capital Called</th>
								<th>Capital Call Investments</th>
								<th>Capital Call Fees</th>
								<th>Capital Call Expenses</th>
							</tr>
						</thead>
						<tbody>
							{(() => {
								// Group quarters by client
								const clientGroups = {};
								const feePayingTotal = {
									totalCapitalCalled: 0,
									totalInvestments: 0,
									totalFees: 0,
									totalExpenses: 0,
								};
								const nonFeePayingTotal = {
									totalCapitalCalled: 0,
									totalInvestments: 0,
									totalFees: 0,
									totalExpenses: 0,
								};

								fund.clientQuarters?.forEach((quarter) => {
									const clientName = quarter.client?.name || "Unknown";
									const isFeePaying = quarter.client?.feePaying ?? false;

									if (!clientGroups[clientName]) {
										clientGroups[clientName] = {
											quarters: [],
											totalCapitalCalled: 0,
											totalInvestments: 0,
											totalFees: 0,
											totalExpenses: 0,
											isFeePaying: isFeePaying,
										};
									}

									clientGroups[clientName].quarters.push(quarter);
									clientGroups[clientName].totalCapitalCalled +=
										quarter.totalCapitalCalled || 0;
									clientGroups[clientName].totalInvestments +=
										quarter.capitalCallInvestments || 0;
									clientGroups[clientName].totalFees +=
										quarter.capitalCallFees || 0;
									clientGroups[clientName].totalExpenses +=
										quarter.capitalCallExpenses || 0;

									// Add to fee paying or non-fee paying totals
									const totalGroup = isFeePaying
										? feePayingTotal
										: nonFeePayingTotal;
									totalGroup.totalCapitalCalled +=
										quarter.totalCapitalCalled || 0;
									totalGroup.totalInvestments +=
										quarter.capitalCallInvestments || 0;
									totalGroup.totalFees += quarter.capitalCallFees || 0;
									totalGroup.totalExpenses += quarter.capitalCallExpenses || 0;
								});

								// Render rows with client groups and totals
								const rows = [];

								// Add client details and totals
								Object.entries(clientGroups).forEach(([clientName, data]) => {
									// Add individual quarter rows
									data.quarters.forEach((quarter) => {
										rows.push(
											<tr
												key={`${clientName}-${quarter.year}-${quarter.quarter}`}
											>
												<td>{clientName}</td>
												<td>{quarter.quarter}</td>
												<td>{quarter.year}</td>
												<td>{formatAsMillions(quarter.totalCapitalCalled)}</td>
												<td>
													{formatAsMillions(quarter.capitalCallInvestments)}
												</td>
												<td>{formatAsMillions(quarter.capitalCallFees)}</td>
												<td>{formatAsMillions(quarter.capitalCallExpenses)}</td>
											</tr>,
										);
									});

									// Add total row for each client
									rows.push(
										<tr
											key={`${clientName}-total`}
											style={{
												fontWeight: "bold",
												backgroundColor: "#f5f5f5",
											}}
										>
											<td>{clientName} Total</td>
											<td colSpan={2} />
											<td>{formatAsMillions(data.totalCapitalCalled)}</td>
											<td>{formatAsMillions(data.totalInvestments)}</td>
											<td>{formatAsMillions(data.totalFees)}</td>
											<td>{formatAsMillions(data.totalExpenses)}</td>
										</tr>,
									);
								});

								// Add Fee Paying Totals
								rows.push(
									<tr
										key="fee-paying-total"
										style={{
											fontWeight: "bold",
											backgroundColor: "#e3f2fd", // Light blue background
										}}
									>
										<td>Fee Paying Total</td>
										<td colSpan={2} />
										<td>
											{formatAsMillions(feePayingTotal.totalCapitalCalled)}
										</td>
										<td>{formatAsMillions(feePayingTotal.totalInvestments)}</td>
										<td>{formatAsMillions(feePayingTotal.totalFees)}</td>
										<td>{formatAsMillions(feePayingTotal.totalExpenses)}</td>
									</tr>,
								);

								// Add Non-Fee Paying Totals
								rows.push(
									<tr
										key="non-fee-paying-total"
										style={{
											fontWeight: "bold",
											backgroundColor: "#e8f5e9", // Light green background
										}}
									>
										<td>Non-Fee Paying Total</td>
										<td colSpan={2} />
										<td>
											{formatAsMillions(nonFeePayingTotal.totalCapitalCalled)}
										</td>
										<td>
											{formatAsMillions(nonFeePayingTotal.totalInvestments)}
										</td>
										<td>{formatAsMillions(nonFeePayingTotal.totalFees)}</td>
										<td>{formatAsMillions(nonFeePayingTotal.totalExpenses)}</td>
									</tr>,
								);

								return rows;
							})()}
						</tbody>
					</table>
				</div>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide1;

import { formatAsMillions } from "@/utils/numberFormat";
import { Grid, Typography } from "@mui/material";

import { useMemo } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from "recharts";
import type Fund from "../Fund";
import type FundInvestments from "../FundInvestments";
import type InvestmentOrganization from "../InvestmentOrganization";
import { PRIMARY_COLOR } from "../constants";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";

const CHART_HEIGHT = 300;
const LABEL_ROTATION = 45;
const LABEL_OFFSET = 10;

export const renderCustomAxisTick = (props) => {
	const { x, y, payload } = props;
	if (!payload.value) return null;
	const words = String(payload.value.slice(0, 20)).split(" ");
	const lineHeight = 14;
	const maxWidth = 100;

	return (
		<g transform={`translate(${x},${y + 10})`}>
			{words
				.reduce((lines, word, i) => {
					const lastLine = lines[lines.length - 1] || [];
					const testLine = [...lastLine, word].join(" ");
					return testLine.length * 6 > maxWidth
						? [...lines, [word]]
						: [...lines.slice(0, -1), [...lastLine, word]];
				}, [])
				.map((line, index) => (
					<text
						key={index}
						textAnchor="end"
						fontSize={12}
						transform="rotate(-45)"
						dy={index * lineHeight + 10}
						fill="black"
					>
						{line.join(" ")}
					</text>
				))}
		</g>
	);
};

<XAxis dataKey="name" tick={renderCustomAxisTick} />;

function Slide7({
	fund,
	investmentCompanies,
}: {
	fund: Fund;
	investments: FundInvestments;
	investmentCompanies: InvestmentOrganization[];
}) {
	const primaryCompanies = useMemo(
		() =>
			investmentCompanies.filter(
				(company) =>
					company.fundInternalDesignation === "Primary" && !company.hasExited,
			),
		[investmentCompanies],
	);

	const topChartData = useMemo(() => {
		const sorted = [...primaryCompanies].sort(
			(a, b) => b.totalInvestedCapitalForFund - a.totalInvestedCapitalForFund,
		);
		return sorted.map((company) => ({
			name: company.name,
			value: company.totalInvestedCapitalForFund,
			valorId: company.valorId,
		}));
	}, [primaryCompanies]);

	const bottomChartData = useMemo(() => {
		const sorted = [...primaryCompanies].sort(
			(a, b) => b.projectedRealizedValue - a.projectedRealizedValue,
		);
		return sorted.map((company) => ({
			name: company.name,
			value: company.projectedRealizedValue,
			moic: company.projectedGrossMoic,
			valorId: company.valorId,
		}));
	}, [primaryCompanies]);

	const totalInvested = useMemo(
		() => topChartData.reduce((sum, item) => sum + item.value, 0),
		[topChartData],
	);

	const totalProjected = useMemo(
		() => bottomChartData.reduce((sum, item) => sum + item.value, 0),
		[bottomChartData],
	);

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide disclaimer="All figures on this slide exclude realized investments.">
					<ContentSlide.Title>
						{fund.name}: {fund.primaryInvestmentLabel} Investments – Capital
						Invested and Estimated Exit Values
					</ContentSlide.Title>
					<ContentSlide.Content>
						<Grid container spacing={1} sx={{ height: CHART_HEIGHT }}>
							<Grid item xs={12} sx={{ height: "375px" }}>
								<Typography
									variant="h3"
									style={{ width: "100%", textAlign: "center" }}
								>
									Capital Invested: {formatAsMillions(totalInvested, 0)}
								</Typography>

								<ResponsiveContainer width="100%" height="100%">
									<BarChart
										data={topChartData}
										margin={{ top: 20, right: 20, bottom: 100, left: 20 }}
									>
										<CartesianGrid strokeDasharray="3 3" />

										<XAxis
											dataKey="name"
											angle={LABEL_ROTATION}
											dy={LABEL_OFFSET + 5}
											interval={0}
											tick={renderCustomAxisTick}
										/>
										<YAxis
											tickFormatter={(val) => `$${formatAsMillions(val, 0)}`}
											tick={{ fontSize: 12 }}
										/>

										<Bar
											dataKey="value"
											fill="white"
											stroke="black"
											barSize={20}
										>
											<LabelList
												dataKey="value"
												position="top"
												formatter={(val) => `$${formatAsMillions(val, 0)}`}
												style={{ fontSize: 12, fill: "#000" }}
											/>
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</Grid>

							<Grid item xs={12} sx={{ height: "375px" }}>
								<Typography
									variant="h3"
									style={{ width: "100%", textAlign: "center" }}
								>
									Projected Returns: {formatAsMillions(totalProjected, 0)}
								</Typography>

								<ResponsiveContainer width="100%" height="100%">
									<BarChart
										data={bottomChartData}
										margin={{ top: 0, right: 20, bottom: 100, left: 20 }}
									>
										<CartesianGrid strokeDasharray="3 3" />
										<XAxis
											dataKey="name"
											angle={LABEL_ROTATION}
											textAnchor="start"
											dy={LABEL_OFFSET}
											interval={0}
											tick={renderCustomAxisTick}
										/>
										<YAxis
											padding={{ top: 40 }}
											tickFormatter={(val) => `$${formatAsMillions(val, 0)}`}
											tick={{ fontSize: 12 }}
										/>

										<Bar dataKey="value" fill="#0042ed" barSize={20}>
											<LabelList
												dataKey="value"
												position="top"
												formatter={(val) => `$${formatAsMillions(val, 0)}`}
												style={{ fontSize: 12, fill: "#000" }}
											/>
											<LabelList
												dataKey="moic"
												position="top"
												dy={-20}
												formatter={(val) => `${val.toFixed(1)}x`}
												fontWeight="bold"
												style={{ fontSize: 12, fill: PRIMARY_COLOR }}
											/>
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</Grid>
						</Grid>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>

			<SlideToggle.Data>
				<h2>
					{fund.primaryInvestmentLabel} Investments – Capital Invested and
					Estimated Exit Values
				</h2>
				<div>
					<h3>Capital Invested</h3>
					<table>
						<thead>
							<tr>
								<th>Company</th>
								<th>Invested Capital</th>
							</tr>
						</thead>
						<tbody>
							{topChartData.map((company) => (
								<tr key={company.valorId}>
									<td>{company.name}</td>
									<td>{formatAsMillions(company.value)}</td>
								</tr>
							))}
						</tbody>
					</table>

					<h3>Projected Returns</h3>
					<table>
						<thead>
							<tr>
								<th>Company</th>
								<th>Projected MOIC</th>
								<th>Projected Value</th>
							</tr>
						</thead>
						<tbody>
							{bottomChartData.map((company) => (
								<tr key={company.valorId}>
									<td>{company.name}</td>
									<td>{company.moic?.toFixed(2)}x</td>
									<td>{formatAsMillions(company.value)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide7;

import { lightTheme } from "@/utils/valorMUITheme";
export const PRIMARY_COLOR = lightTheme.blue.main;
export const SECONDARY_COLOR = "#b7ccff";
export const TERTIARY_COLOR = lightTheme.gray.main;

export const colors = [
	{ fill: lightTheme.blue.main, text: "#fff" },
	{ fill: "#b7ccff", text: "#000" },
	{ fill: lightTheme.purple.main, text: "#fff" },
	{ fill: lightTheme.red.main, text: "#fff" },
	{ fill: lightTheme.pink.main, text: "#000" },
	{ fill: lightTheme.yellow.main, text: "#000" },
	{ fill: lightTheme.green.main, text: "#fff" },
	{ fill: lightTheme.seaGreen.main, text: "#fff" },
	{ fill: lightTheme.orange.main, text: "#000" },
	{ fill: lightTheme.gray.light, text: "#fff" },
];

export const CONSTRUCTION_COLORS = {
	riskRating: {
		Ramp: { fill: lightTheme.blue.main, text: "#fff" },
		Improvement: { fill: lightTheme.green.main, text: "#000" },
		Sale: { fill: lightTheme.yellow.main, text: "#000" },
		Kuma: { fill: lightTheme.red.main, text: "#fff" },
	},
	entropy: {
		"Pro-Entropic": { fill: lightTheme.blue.main, text: "#fff" },
		Resilient: { fill: lightTheme.gray.light, text: "#000" },
		Durable: { fill: lightTheme.yellow.main, text: "#000" },
		Cyclical: { fill: lightTheme.red.main, text: "#fff" },
		Yes: { fill: lightTheme.gray.light, text: "#000" },
		No: { fill: lightTheme.blue.main, text: "#fff" },
	},
	platformTechnology: {
		"Advanced Engineering": { fill: lightTheme.blue.main, text: "#fff" },
		"Artificial Intelligence / Data Science": {
			fill: lightTheme.purple.main,
			text: "#fff",
		},
		"Molecular Engineering": { fill: lightTheme.yellow.main, text: "#000" },
		Blockchain: { fill: lightTheme.red.main, text: "#fff" },
		"Computing / Hardware / IoT / Semiconductors": {
			fill: lightTheme.seaGreen.main,
			text: "#000",
		},
		Sustainability: { fill: lightTheme.green.main, text: "#000" },
		Other: { fill: "#fff", text: "#000", stroke: "#000" },
	},
	targetSegment: {
		"Manufacturing Tech": { fill: lightTheme.blue.main, text: "#fff" },
		"Carbon Reduction": { fill: lightTheme.green.main, text: "#000" },
		Robotics: { fill: lightTheme.purple.main, text: "#fff" },
		"Space Tech": { fill: lightTheme.yellow.main, text: "#000" },
		"Compute Technologies": { fill: lightTheme.seaGreen.main, text: "#000" },
		"Infrastructure & Enabling Technologies": {
			fill: lightTheme.orange.main,
			text: "#000",
		},
		"Energy Production": { fill: lightTheme.red.main, text: "#fff" },
		"Productivity & Development Tools": {
			fill: lightTheme.blue.light,
			text: "#000",
		},
		"Business Services": { fill: lightTheme.teal.main, text: "#000" },
		Crypto: { fill: lightTheme.yellow.dark, text: "#fff" },
		"Insurtech, Payments, Lending": {
			fill: lightTheme.seaGreen.dark,
			text: "#fff",
		}, // Added seaGreen.dark
		"Consumer & Commerce Technology": {
			fill: lightTheme.green.dark,
			text: "#fff",
		},
		"Food, Restaurant & Retail Technology": {
			fill: lightTheme.blue.dark,
			text: "#fff",
		},
		"Digital Media & Metaverse": { fill: lightTheme.orange.dark, text: "#fff" }, // Added orange.dark
		"Health Tech": { fill: lightTheme.pink.main, text: "#000" },
		Wellness: { fill: lightTheme.purple.dark, text: "#fff" }, // Added purple.dark
		Cybersecurity: { fill: lightTheme.gray.light, text: "#000" },
		Defense: { fill: lightTheme.blue.highlight, text: "#000" },
		"Safety & Preventative Maintenance": {
			fill: lightTheme.green.highlight,
			text: "#000",
		},
		"Asset Utilization": { fill: lightTheme.purple.highlight, text: "#000" },
		"Supply Chain & Last Mile": {
			fill: lightTheme.yellow.highlight,
			text: "#000",
		},
		"Biotech & Pharma": { fill: lightTheme.seaGreen.highlight, text: "#000" }, // Added seaGreen.highlight
		Other: { fill: "#fff", text: "#000", stroke: "#000" },
	},

	targetVertical: {
		"Advanced Manufacturing": { fill: lightTheme.blue.main, text: "#fff" },
		"Advanced Engineering": { fill: lightTheme.purple.main, text: "#fff" },
		"Biotech & Pharma": { fill: lightTheme.yellow.main, text: "#000" },
		Energy: { fill: lightTheme.red.main, text: "#fff" },
		"Enterprise B2B": { fill: lightTheme.seaGreen.main, text: "#000" },
		"Financial Technology": { fill: lightTheme.green.main, text: "#000" },
		"Food, Retail, & Consumer": { fill: lightTheme.blue.light, text: "#000" },
		"Health & Wellness": { fill: "#b7ccff", text: "#000" },
		"Strategic Tech": { fill: lightTheme.orange.main, text: "#000" },
		"Transportation & Logistics": { fill: lightTheme.red.main, text: "#fff" },
		Other: { fill: "#fff", text: "#000", stroke: "#000" },
	},
	valueChainSector: {
		Food: { fill: lightTheme.blue.light, text: "#000" },
		"Food Tech": { fill: lightTheme.purple.main, text: "#fff" },
		Retail: { fill: lightTheme.yellow.main, text: "#000" },
		"Retail Tech": { fill: lightTheme.red.main, text: "#fff" },
		Sustainability: { fill: lightTheme.green.main, text: "#000" },
		Other: { fill: "#fff", text: "#000", stroke: "#000" },
	},
};
export const VSVI_ENTITY_ID = 31569322;
export const VSVII_ENTITY_ID = 29255553;
export const VOF_ENTITY_ID = 29358363;
export const VAAI_ENTITY_ID = 32048679;
export const SEED_ENTITY_ID = 30663793;
export const FUND_III_ENTITY_ID = 30685537;
export const FUND_IV_ENTITY_ID = 31572090;
export const FUND_V_ENTITY_ID = 30717969;
export const FUND_VI_ENTITY_ID = 28373065;
export const CV_CONSORTIO_ENTITY_ID = 30668504;

export const FUND_ENTITY_IDS = [
	{ entityId: VSVI_ENTITY_ID, name: "VSV I" },
	{ entityId: VSVII_ENTITY_ID, name: "VSV II" },
	{ entityId: VOF_ENTITY_ID, name: "VOF" },
	{ entityId: VAAI_ENTITY_ID, name: "VAAI" },
	{ entityId: SEED_ENTITY_ID, name: "SEED" },
	{ entityId: FUND_III_ENTITY_ID, name: "FUND III" },
	{ entityId: FUND_IV_ENTITY_ID, name: "FUND IV" },
	{ entityId: FUND_V_ENTITY_ID, name: "FUND V" },
	{ entityId: FUND_VI_ENTITY_ID, name: "FUND VI" },
	{ entityId: CV_CONSORTIO_ENTITY_ID, name: "CV CONSORTIO" },
];

export const RED_COLOR = "#ff0000";
export const YELLOW_COLOR = "#ffff00";
export const GREEN_COLOR = "#01b050";

export const PRIMARY_LABELING = {
	[VSVI_ENTITY_ID]: "Core",
	[VSVII_ENTITY_ID]: "Core",
	[VOF_ENTITY_ID]: "Platform",
	[VAAI_ENTITY_ID]: "Core",
	[SEED_ENTITY_ID]: "Seed",
	[FUND_III_ENTITY_ID]: "Platform",
	[FUND_IV_ENTITY_ID]: "Platform",
	[FUND_V_ENTITY_ID]: "Platform",
	[FUND_VI_ENTITY_ID]: "Platform",
	[CV_CONSORTIO_ENTITY_ID]: "Platform",
	default: "Platform",
};

export const SECONDARY_LABELING = {
	[VSVI_ENTITY_ID]: "Placeholder",
	[VSVII_ENTITY_ID]: "Placeholder",
	[VOF_ENTITY_ID]: "error",
	[VAAI_ENTITY_ID]: "Placeholder",
	[SEED_ENTITY_ID]: "error",
	[FUND_III_ENTITY_ID]: "error",
	[FUND_IV_ENTITY_ID]: "Ancillary",
	[FUND_V_ENTITY_ID]: "Ancillary",
	[FUND_VI_ENTITY_ID]: "Ancillary",
	[CV_CONSORTIO_ENTITY_ID]: "error",
	default: "Ancillary",
};

export const ACTIVE_FUNDS = [
	VSVII_ENTITY_ID,
	VAAI_ENTITY_ID,
	FUND_VI_ENTITY_ID,
	SEED_ENTITY_ID,
];

import { formatAsMillions } from "@/utils/numberFormat";
import {
	Alert,
	Box,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import type Fund from "../Fund";
import type FundInvestments from "../FundInvestments";
import { PRIMARY_LABELING, SECONDARY_LABELING } from "../constants";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";

const SubtitleTypography = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	textAlign: "center",
	marginBottom: theme.spacing(1),
	backgroundColor: theme.palette.blue.main,
	color: theme.palette.common.white,
}));

const StyledTableCell = styled(TableCell)(() => ({}));

function Slide13({
	fund,
	investments,
}: {
	fund: Fund;
	investments: FundInvestments;
}) {
	const { actualDeployments } = investments ?? { actualDeployments: [] };
	const { entityId } = fund;
	const primaryLabel = PRIMARY_LABELING[entityId];
	const secondaryLabel = SECONDARY_LABELING[entityId];
	const showSecondary = secondaryLabel !== "error";

	const theme = useTheme();

	const actualDeploymentsPrimary = investments.actualPrimaryCapitalDeployed;
	const actualDeploymentsSecondary = investments.actualSecondaryCapitalDeployed;

	const currentYearInvestments =
		investments.remainingCapitalToDeployByYear?.[0];

	const currentYearPrimaryUnits =
		(currentYearInvestments?.primaryAmount -
			fund.primaryReserves -
			fund.icApprovedUnderTsPrimary) /
		fund.unitsOfRiskPrimary;

	const currentYearSecondaryUnits =
		(currentYearInvestments?.secondaryAmount -
			fund.icApprovedUnderTsSecondary) /
		fund.unitsOfRiskSecondary;

	const remainingYears = investments.remainingCapitalToDeployByYear
		.reduce((acc, { year }) => {
			acc.push(year);
			return acc;
		}, [])
		.join(", ");

	const remaingYearsNotThisYear = investments.remainingCapitalToDeployByYear
		.slice(1)
		.map(({ year, primaryAmount, secondaryAmount }) => ({
			year,
			primaryUnits: primaryAmount / fund.unitsOfRiskPrimary,
			secondaryUnits: secondaryAmount / fund.unitsOfRiskSecondary,
		}));

	const remainingPrimaryUnits = remaingYearsNotThisYear.reduce(
		(acc, { primaryUnits }) => acc + Number(primaryUnits.toFixed(1)),
		0,
	);
	const remainingSecondaryUnits = remaingYearsNotThisYear.reduce(
		(acc, { secondaryUnits }) => acc + Number(secondaryUnits.toFixed(1)),
		0,
	);

	const totalRemainingPrimary = remainingPrimaryUnits + currentYearPrimaryUnits;
	const totalRemainingSecondary =
		remainingSecondaryUnits + currentYearSecondaryUnits;

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide disclaimer={fund?.latestCommentary}>
					<ContentSlide.Title>
						{fund.name}: Investment Period Planned vs. Actual Deployments
					</ContentSlide.Title>
					<ContentSlide.Content>
						<Box display="flex" flexDirection="column" height="100%">
							<Container maxWidth="lg" sx={{ padding: 2 }}>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<SubtitleTypography>
											Actual Capital Remaining to Invest Detail
										</SubtitleTypography>
										<TableContainer component={Paper}>
											<Table size="small">
												<TableHead>
													<TableRow>
														<StyledTableCell padding="none" />
														<StyledTableCell
															align="center"
															padding="none"
															sx={{ textDecoration: "underline" }}
														>
															<Typography variant="boldBody2">
																{primaryLabel}
															</Typography>
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell
																align="center"
																padding="none"
																sx={{ textDecoration: "underline" }}
															>
																<Typography variant="boldBody2">
																	{secondaryLabel}
																</Typography>
															</StyledTableCell>
														)}
														<StyledTableCell
															align="center"
															padding="none"
															sx={{ textDecoration: "underline" }}
														>
															<Typography variant="boldBody2">Total</Typography>
														</StyledTableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<StyledTableCell padding="none">
															<Typography variant="boldBody2">
																Capital Deployed to Date
															</Typography>
														</StyledTableCell>
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(actualDeploymentsPrimary)}
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell align="center" padding="none">
																{formatAsMillions(actualDeploymentsSecondary)}
															</StyledTableCell>
														)}
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(
																actualDeploymentsPrimary +
																	actualDeploymentsSecondary,
															)}
														</StyledTableCell>
													</TableRow>
													<TableRow>
														<StyledTableCell padding="none">
															<Typography variant="boldBody2">
																Investment Period Remaining Capital to Deploy
															</Typography>
														</StyledTableCell>
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(
																investments.remainingPrimaryCapitalToDeploy,
															)}
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell align="center" padding="none">
																{formatAsMillions(
																	investments.remainingSecondaryCapitalToDeploy,
																)}
															</StyledTableCell>
														)}
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(
																investments.remainingCapitalToDeploy,
															)}
														</StyledTableCell>
													</TableRow>
													<TableRow>
														<StyledTableCell
															colSpan={showSecondary ? 4 : 3}
															sx={{
																backgroundColor: "#f0f0f0",
																fontWeight: "bold",
																borderTop: "2px solid black",
															}}
															padding="none"
														>
															Capital Available to Deploy per Year
														</StyledTableCell>
													</TableRow>
													{investments.remainingCapitalToDeployByYear.map(
														({
															year,
															amount,
															primaryAmount,
															secondaryAmount,
														}) => (
															<TableRow key={year}>
																<StyledTableCell
																	padding="none"
																	sx={{ paddingLeft: theme.spacing(2) }}
																>
																	{year}
																</StyledTableCell>
																<StyledTableCell align="center" padding="none">
																	{formatAsMillions(primaryAmount)}
																</StyledTableCell>
																{showSecondary && (
																	<StyledTableCell
																		align="center"
																		padding="none"
																	>
																		{formatAsMillions(secondaryAmount)}
																	</StyledTableCell>
																)}
																<StyledTableCell align="center" padding="none">
																	{formatAsMillions(amount)}
																</StyledTableCell>
															</TableRow>
														),
													)}
													<TableRow
														style={{
															borderBlock: "2px solid black",
															backgroundColor: theme.palette.blue.light,
														}}
													>
														<StyledTableCell
															sx={{ fontWeight: "bold" }}
															padding="none"
														>
															Total Remaining Capital to Deploy
														</StyledTableCell>
														<StyledTableCell
															align="center"
															sx={{ fontWeight: "bold" }}
															padding="none"
														>
															{formatAsMillions(
																investments.remainingPrimaryCapitalToDeploy,
															)}
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell
																align="center"
																sx={{ fontWeight: "bold" }}
																padding="none"
															>
																{formatAsMillions(
																	investments.remainingSecondaryCapitalToDeploy,
																)}
															</StyledTableCell>
														)}
														<StyledTableCell
															align="center"
															sx={{ fontWeight: "bold" }}
															padding="none"
														>
															{formatAsMillions(
																investments.remainingCapitalToDeploy,
															)}
														</StyledTableCell>
													</TableRow>
													<TableRow>
														<StyledTableCell
															colSpan={showSecondary ? 4 : 3}
															sx={{
																backgroundColor: "#f0f0f0",
																fontWeight: "bold",
																borderTop: "2px solid black",
															}}
															padding="none"
														>
															{currentYearInvestments?.year} Deployment Detail
														</StyledTableCell>
													</TableRow>
													<TableRow>
														<StyledTableCell padding="none">
															{currentYearInvestments?.year} Capital Available
															to Deploy
														</StyledTableCell>
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(
																currentYearInvestments?.primaryAmount,
															)}
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell align="center" padding="none">
																{formatAsMillions(
																	currentYearInvestments?.secondaryAmount,
																)}
															</StyledTableCell>
														)}
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(currentYearInvestments?.amount)}
														</StyledTableCell>
													</TableRow>
													<TableRow>
														<StyledTableCell
															padding="none"
															sx={{ paddingLeft: theme.spacing(2) }}
														>
															Less: Investments Under Term Sheet/IC Approved
														</StyledTableCell>
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(fund.icApprovedUnderTsPrimary)}
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell align="center" padding="none">
																{formatAsMillions(
																	fund.icApprovedUnderTsSecondary,
																)}
															</StyledTableCell>
														)}
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(
																fund.icApprovedUnderTsPrimary +
																	fund.icApprovedUnderTsSecondary,
															)}
														</StyledTableCell>
													</TableRow>
													<TableRow>
														<StyledTableCell
															padding="none"
															sx={{ paddingLeft: theme.spacing(2) }}
														>
															Less: Investment Period Reserves/Allocation
														</StyledTableCell>
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(fund.primaryReserves)}
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell align="center" padding="none">
																{formatAsMillions(0)}
															</StyledTableCell>
														)}
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(fund.primaryReserves)}
														</StyledTableCell>
													</TableRow>
													<TableRow>
														<StyledTableCell padding="none">
															<Typography variant="boldBody2">
																{currentYearInvestments?.year} Remaining Capital
																Available to Deploy
															</Typography>
														</StyledTableCell>
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(
																currentYearInvestments?.primaryAmount -
																	fund.primaryReserves -
																	fund.icApprovedUnderTsPrimary,
															)}
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell align="center" padding="none">
																{formatAsMillions(
																	currentYearInvestments?.secondaryAmount -
																		fund.icApprovedUnderTsSecondary,
																)}
															</StyledTableCell>
														)}
														<StyledTableCell align="center" padding="none">
															{formatAsMillions(
																currentYearInvestments?.amount -
																	fund.primaryReserves -
																	(fund.icApprovedUnderTsPrimary +
																		fund.icApprovedUnderTsSecondary),
															)}
														</StyledTableCell>
													</TableRow>
													<TableRow>
														<StyledTableCell
															colSpan={showSecondary ? 4 : 3}
															sx={{
																backgroundColor: "#f0f0f0",
																fontWeight: "bold",
																borderTop: "2px solid black",
															}}
															padding="none"
														>
															Units of Risk Remaining to Deploy
														</StyledTableCell>
													</TableRow>
													<StyledTableCell
														padding="none"
														sx={{ paddingLeft: theme.spacing(2) }}
													>
														{currentYearInvestments?.year}
													</StyledTableCell>
													<StyledTableCell align="center" padding="none">
														{currentYearPrimaryUnits?.toFixed(1)}
													</StyledTableCell>
													{showSecondary && (
														<StyledTableCell align="center" padding="none">
															{currentYearSecondaryUnits?.toFixed(1)}
														</StyledTableCell>
													)}

													<StyledTableCell align="center" padding="none">
														-
													</StyledTableCell>
													{remaingYearsNotThisYear.map(
														({ year, primaryUnits, secondaryUnits }) => (
															<TableRow key={year}>
																<StyledTableCell
																	padding="none"
																	sx={{ paddingLeft: theme.spacing(2) }}
																>
																	{year}
																</StyledTableCell>
																<StyledTableCell align="center" padding="none">
																	{primaryUnits?.toFixed(1)}
																</StyledTableCell>
																{showSecondary && (
																	<StyledTableCell
																		align="center"
																		padding="none"
																	>
																		{secondaryUnits?.toFixed(1)}
																	</StyledTableCell>
																)}
																<StyledTableCell align="center" padding="none">
																	-
																</StyledTableCell>
															</TableRow>
														),
													)}

													<TableRow
														style={{
															borderBlock: "2px solid black",
															backgroundColor: theme.palette.blue.light,
														}}
													>
														<StyledTableCell
															sx={{ fontWeight: "bold" }}
															padding="none"
														>
															Total Remaining Units of Risk to Deploy (
															{remainingYears})
														</StyledTableCell>
														<StyledTableCell
															align="center"
															sx={{ fontWeight: "bold" }}
															padding="none"
														>
															{totalRemainingPrimary.toFixed(1)}
														</StyledTableCell>
														{showSecondary && (
															<StyledTableCell
																align="center"
																sx={{ fontWeight: "bold" }}
																padding="none"
															>
																{totalRemainingSecondary.toFixed(1)}
															</StyledTableCell>
														)}
														<StyledTableCell
															align="center"
															sx={{ fontWeight: "bold" }}
															padding="none"
														>
															-
														</StyledTableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</Grid>
							</Container>
						</Box>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>
			<SlideToggle.Data>
				Actual Deployments
				<TableContainer component={Paper}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<StyledTableCell padding="none">Year</StyledTableCell>
								<StyledTableCell align="center" padding="none">
									{primaryLabel}
								</StyledTableCell>
								{showSecondary && (
									<StyledTableCell align="center" padding="none">
										{secondaryLabel}
									</StyledTableCell>
								)}
								<StyledTableCell align="center" padding="none">
									Total
								</StyledTableCell>
								<StyledTableCell align="center" padding="none">
									Primary Amount in Units of Risk
								</StyledTableCell>
								{showSecondary && (
									<StyledTableCell align="center" padding="none">
										Secondary Amount in Units of Risk
									</StyledTableCell>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{actualDeployments.map(
								({
									year,
									primaryAmount,
									secondaryAmount,
									amount,
									primaryAmountInUnitsOfRisk,
									secondaryAmountInUnitsOfRisk,
								}) => (
									<TableRow key={year}>
										<StyledTableCell padding="none">{year}</StyledTableCell>
										<StyledTableCell align="center" padding="none">
											{formatAsMillions(primaryAmount)}
										</StyledTableCell>
										{showSecondary && (
											<StyledTableCell align="center" padding="none">
												{formatAsMillions(secondaryAmount)}
											</StyledTableCell>
										)}
										<StyledTableCell align="center" padding="none">
											{formatAsMillions(amount)}
										</StyledTableCell>
										<StyledTableCell align="center" padding="none">
											{primaryAmountInUnitsOfRisk?.toFixed(1)}
										</StyledTableCell>
										{showSecondary && (
											<StyledTableCell align="center" padding="none">
												{secondaryAmountInUnitsOfRisk?.toFixed(1)}
											</StyledTableCell>
										)}
									</TableRow>
								),
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<h2>{fund.name} - Investment Period Planned vs. Actual Deployments</h2>
				<h3>Actual Capital Remaining to Invest Detail</h3>
				<table>
					<tbody>
						<tr>
							<td>Capital Deployed to Date</td>
							<td>{formatAsMillions(actualDeploymentsPrimary)}</td>
							{showSecondary && (
								<td>{formatAsMillions(actualDeploymentsSecondary)}</td>
							)}
							<td>
								{formatAsMillions(
									actualDeploymentsPrimary + actualDeploymentsSecondary,
								)}
							</td>
						</tr>
						<tr>
							<td>Investment Period Remaining Capital to Deploy</td>
							<td>
								{formatAsMillions(investments.remainingPrimaryCapitalToDeploy)}
							</td>
							{showSecondary && (
								<td>
									{formatAsMillions(
										investments.remainingSecondaryCapitalToDeploy,
									)}
								</td>
							)}
							<td>{formatAsMillions(investments.remainingCapitalToDeploy)}</td>
						</tr>
					</tbody>
				</table>
				<h3>Capital Available to Deploy per Year</h3>
				<table>
					<thead>
						<tr>
							<th>Year</th>
							<th>{primaryLabel}</th>
							{showSecondary && <th>{secondaryLabel}</th>}
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						{investments.remainingCapitalToDeployByYear.map(
							({ year, amount, primaryAmount, secondaryAmount }) => (
								<tr key={year}>
									<td>{year}</td>
									<td>{formatAsMillions(primaryAmount)}</td>
									{showSecondary && (
										<td>{formatAsMillions(secondaryAmount)}</td>
									)}
									<td>{formatAsMillions(amount)}</td>
								</tr>
							),
						)}
					</tbody>
				</table>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide13;

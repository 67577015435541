import SlideIcon from "@mui/icons-material/Slideshow";
import DataIcon from "@mui/icons-material/Storage";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useEffect, useRef, useState } from "react";

// import Slide from "./Slide";
// import SlideData from "./SlideData";
function Slide({ children }: { children: React.ReactNode }) {
	return <div>{children}</div>;
}

function SlideData({ children }: { children: React.ReactNode }) {
	return <div>{children}</div>;
}

function SlideToggle({ children }: { children: React.ReactNode }) {
	// Validate children
	const childrenArray = React.Children.toArray(children);
	if (childrenArray.length > 2) {
		throw new Error("SlideToggle can only have up to 2 children");
	}

	// Find slide and data components
	const slideComponent = childrenArray.find(
		(child) => React.isValidElement(child) && child.type === Slide,
	);
	const dataComponent = childrenArray.find(
		(child) => React.isValidElement(child) && child.type === SlideData,
	);

	const [showSlide, setShowSlide] = useState(Boolean(slideComponent));
	const [slideWidth, setSlideWidth] = useState(0);
	const slideRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (slideRef.current) {
			setSlideWidth(slideRef.current.offsetWidth);
		}
	}, []);

	const toggleView = () => {
		setShowSlide(!showSlide);
	};

	return (
		<div style={{ position: "relative" }}>
			<Tooltip
				title={
					!slideComponent || !dataComponent
						? "No slide or data view available to toggle to"
						: showSlide
							? "Switch to data view"
							: "Switch to slide view"
				}
			>
				<IconButton
					onClick={toggleView}
					style={{
						position: "absolute",
						top: 10,
						right: 10,
						zIndex: 10,
					}}
					disabled={!slideComponent || !dataComponent}
				>
					{showSlide ? <DataIcon /> : <SlideIcon />}
				</IconButton>
			</Tooltip>
			<div ref={slideRef} style={{ minWidth: showSlide ? "auto" : slideWidth }}>
				{showSlide ? slideComponent : dataComponent}
			</div>
		</div>
	);
}

SlideToggle.Slide = Slide;
SlideToggle.Data = SlideData;

export default SlideToggle;

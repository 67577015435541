import { uploadExternalImage, uploadImage } from "@/api/Images";
import {
	addNoteOppLink,
	createNote,
	readNote,
	removeNoteOppLink,
	updateNote,
} from "@/api/Notes";
import { useAuth } from "@/hooks/useAuth";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import RTFEditor from "@/ui/molecules/RichTextEditor";
import RTFSerializer from "@/ui/molecules/RichTextEditor/Serializer";
import CheckCircle from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/Lock";
import Sync from "@mui/icons-material/Sync";
import {
	Alert,
	Box,
	Breadcrumbs,
	Card,
	Divider,
	Link,
	Snackbar,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import React, { useState, useMemo, useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
	CapitalMarketsActive,
	LiquidityEventActive,
	MergersAndAcquisitionActive,
} from "../meeting-types";

import MeetingTypeSelect from "./MeetingTypeSelect";
import PushToProcess from "./PushToProcess";
import SearchDrawer from "./SearchDrawer";

function EditNotes() {
	const params = useParams();
	const { user } = useAuth();
	const navigate = useNavigate();
	const valorId = params.id || null;
	const documentId = params.noteId || null;

	const [, setError] = useState<boolean>(false);
	const [imageUploadError, setImageUploadError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [authorized, setAuthorized] = useState<boolean>(true);

	const [workingTitle, setWorkingTitle] = useState<string>("Untitled Draft");
	const [workingRichText, setWorkingRichText] = useState(null);
	const [workingMeetingDate, setWorkingMeetingDate] = useState<number>(
		Date.now(),
	);
	const [workingVisibility, setWorkingVisibility] = useState<string>("private");
	const [workingMeetingType, setWorkingMeetingType] = useState<string>(
		"Company Meeting / Call",
	);

	const [noteReference, setNoteReference] = useState<any>(null);

	const [linkBusy, setLinkBusy] = useState<boolean>(false);
	const [linkSuccess, setLinkSuccess] = useState<boolean>(false);
	const [linkError, setLinkError] = useState<boolean>(false);

	const [unlinkBusy, setUnlinkBusy] = useState<boolean>(false);
	const [unlinkSuccess, setUnlinkSuccess] = useState<boolean>(false);
	const [unlinkError, setUnlinkError] = useState<boolean>(false);

	const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState<boolean>(true);
	const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);

	const [hideHint, setHideHint] = useLocalStorageState({}, "hideHint");

	useEffect(() => {
		if (documentId) {
			setLoading(true);
			readNote(valorId, documentId)
				.then((note) => {
					const {
						title,
						richText,
						meetingDate,
						meetingType,
						visibility,
						authorId,
					} = note;
					setNoteReference(note);

					setWorkingTitle(title);
					setWorkingRichText(richText);
					setWorkingMeetingDate(meetingDate * 1000);
					if (meetingType) setWorkingMeetingType(meetingType);
					setWorkingVisibility(visibility);
					setLoading(false);

					// check user is owner of note
					if (
						user?.id !== authorId &&
						!(
							meetingType === CapitalMarketsActive ||
							meetingType === MergersAndAcquisitionActive ||
							meetingType === LiquidityEventActive
						)
					) {
						// redirect to view note
						navigate(`/org/${valorId}/notes/view/${documentId}`);
					}
				})
				.catch((error) => {
					if (error.response.status && error.response.status === 404) {
						// redirect to view page
						navigate(`/org/${valorId}/notes/view`);
					}
					if (error.status && error.status === 403) {
						setAuthorized(false);
						// TODO shannon
					}
					setError(true);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, [setLoading, documentId, valorId, setWorkingTitle, setWorkingRichText]);

	const [saving, setSaving] = useState<boolean>(false);
	const [docId, setDocId] = useState<string>(documentId);

	const goToEditPage = (noteId) => {
		// use replace state to avoid rerender and losing user progress
		window.history.replaceState(
			null,
			"Edit Note",
			`/org/${valorId}/notes/edit/${noteId}`,
		);
	};

	const upsertNote = useMemo(() => {
		/* eslint-disable  @typescript-eslint/no-explicit-any */
		const changes: [number, any, string, string, number, string, string][] = [];
		let latestUpdate = Promise.resolve(null);
		return debounce(
			async (
				newRichText,
				newTitle,
				vId,
				newMeetingDate,
				newVisibility,
				newMeetingType,
			) => {
				// add to changes
				const now = Date.now();
				changes.push([
					now,
					newRichText,
					newTitle,
					vId,
					Math.floor(newMeetingDate / 1000),
					newVisibility,
					newMeetingType,
				]);

				// get latest promise
				latestUpdate = latestUpdate.then(async (latestDocId?: string) => {
					const recentChange = changes[changes.length - 1];
					if (recentChange[0] === now) {
						setSaving(true);

						const [
							,
							richText,
							title,
							vvId,
							meetingDate,
							visibility,
							meetingType,
						] = recentChange;

						const note = {
							title,
							plainText: RTFSerializer.toString(richText),
							richText,
							meetingDate,
							meetingType,
							visibility,
						};

						// make api call
						if (!latestDocId && !docId) {
							// create new document
							const newNote = {
								...note,
								valorId: vvId,
							};
							try {
								const response = await createNote(newNote);
								// update url with new documentId
								if (response.documentId) {
									setDocId(response.documentId);
									setSaving(false);
									goToEditPage(response.documentId);
									return response.documentId;
								}
							} catch (e) {
								setSaving(false);
								return latestDocId;
							}
						} else {
							// update existing document
							const updatedNote = {
								...note,
								documentId: latestDocId || docId,
							};

							try {
								const response = await updateNote(updatedNote);
								setSaving(false);
								return response.documentId;
							} catch (e) {
								setSaving(false);
								return latestDocId;
							}
						}
					}
					setSaving(false);
					return latestDocId;
				});
			},
			250,
		);
	}, []);

	const handleChange = ({
		richText,
		title,
		meetingDate,
		visibility,
		meetingType,
	}: {
		/* eslint-disable  @typescript-eslint/no-explicit-any */
		richText?: any;
		title?: string;
		meetingDate?: number;
		visibility?: string;
		meetingType?: string;
	}) => {
		upsertNote(
			richText !== undefined ? richText : workingRichText,
			title !== undefined ? title : workingTitle,
			valorId,
			meetingDate !== undefined ? meetingDate : workingMeetingDate,
			visibility !== undefined ? visibility : workingVisibility,
			meetingType !== undefined ? meetingType : workingMeetingType,
		);
	};

	const handleTextChange = (newRichText: any) => {
		setWorkingRichText(newRichText);
		handleChange({ richText: newRichText });
	};

	const handleTitleChange = (newTitle) => {
		setWorkingTitle(newTitle);
		handleChange({ title: newTitle });
	};

	const handleMeetingDateChange = (newMeetingDate) => {
		setWorkingMeetingDate(newMeetingDate);
		handleChange({ meetingDate: newMeetingDate });
	};

	const handleVisibilityChange = (newVisibility) => {
		setWorkingVisibility(newVisibility);
		handleChange({ visibility: newVisibility });
	};

	const handleMeetingTypeChange = (newMeetingType) => {
		setWorkingMeetingType(newMeetingType);
		handleChange({ meetingType: newMeetingType });
	};

	const addProcessToNote = (oppId) => {
		setLinkBusy(true);
		addNoteOppLink(docId, oppId)
			.then(() => {
				setLinkBusy(false);
				setLinkSuccess(true);
				// add to note
				if (noteReference) {
					setNoteReference((prev) => ({
						...prev,
						processRelationsInternalIds: [
							...prev.processRelationsInternalIds,
							oppId,
						],
					}));
				}
			})
			.catch(() => {
				setLinkBusy(false);
				setLinkError(true);
			});
	};

	const removeProcessFromNote = (oppId) => {
		setUnlinkBusy(true);
		removeNoteOppLink(docId, oppId)
			.then(() => {
				setUnlinkBusy(false);
				setUnlinkSuccess(true);

				// remove from note
				setNoteReference((prev) => ({
					...prev,
					processRelationsInternalIds: prev.processRelationsInternalIds.filter(
						(id) => id !== oppId,
					),
				}));
			})
			.catch(() => {
				setUnlinkBusy(false);
				setUnlinkError(true);
			});
	};

	if (!authorized) {
		return (
			<ErrorMessage
				Icon={<LockIcon />}
				title="Unauthorized"
				message={
					<>
						You don’t have access to Notes for this company. If you think this
						is an error, please contact{" "}
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link
							onClick={(e) => {
								window.location.href =
									"mailto:labs@valorep.com?subject=Notes Access";
								e.preventDefault();
							}}
						>
							labs@valorep.com
						</Link>
					</>
				}
			/>
		);
	}
	return (
		<Box
			display="flex"
			flexDirection="row"
			maxHeight="calc(100vh - 64px - 16px)"
		>
			<Card
				elevation={0}
				style={{
					flexGrow: 100,
					overflowY: "auto",
				}}
			>
				<Box padding={2}>
					<Box
						display="flex"
						flexDirection="row"
						gap={1}
						justifyContent="space-between"
						alignItems="center"
						paddingY={2}
					>
						<Breadcrumbs>
							<Link
								component={RouterLink}
								to={`/org/${valorId}/notes/view`}
								data-cy="edit-note__notes-breadcrumb"
							>
								Notes
							</Link>
							<Typography>
								{docId ? `Edit "${workingTitle}"` : workingTitle}
							</Typography>
						</Breadcrumbs>

						<Box
							display="flex"
							flexDirection="row"
							gap={1}
							justifyContent="flex-start"
							alignItems="center"
							data-cy="edit-note__save-status"
						>
							{saving ? (
								<>
									<Sync />
									<Typography variant="body2">Saving...</Typography>
								</>
							) : (
								<>
									<CheckCircle />
									<Typography variant="body2">Saved</Typography>
								</>
							)}
						</Box>
					</Box>
					<Box width="100%" marginY={1}>
						<TextField
							label="Title"
							value={workingTitle}
							onChange={(event) => handleTitleChange(event.target.value)}
							disabled={loading}
							fullWidth
							data-cy="edit-note__title"
						/>
					</Box>
					<Box
						marginY={1}
						display="flex"
						flexDirection={{
							xs: "column",
							sm: "row",
						}}
						justifyContent="space-between"
						width="100%"
					>
						<Box
							display="flex"
							flexDirection={{
								xs: "column",
								sm: "row",
							}}
							gap={1}
							justifyContent="flex-start"
							alignItems={{
								xs: "flex-start",
								sm: "center",
							}}
							flexWrap="wrap"
						>
							<Typography variant="body2">Date:</Typography>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									open={isOpenDatePicker}
									onOpen={() => setIsOpenDatePicker(true)}
									onClose={() => setIsOpenDatePicker(false)}
									value={dayjs(workingMeetingDate)}
									onChange={(date) => handleMeetingDateChange(date.valueOf())}
									disabled={loading}
								/>
							</LocalizationProvider>
						</Box>
						<Box
							display="flex"
							flexWrap="wrap"
							flexDirection="row"
							justifyContent="flex-start"
							alignItems="center"
							gap={1}
						>
							<Typography variant="body2">Note Type:</Typography>
							<Box minWidth={200}>
								<MeetingTypeSelect
									value={workingMeetingType}
									onChange={(event) =>
										handleMeetingTypeChange(event.target.value)
									}
									disabled={loading}
								/>
							</Box>
						</Box>
						<Box>
							<Typography variant="body2">Note Visibility:</Typography>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Typography variant="boldBody2">Private</Typography>
								<Switch
									checked={workingVisibility !== "private"}
									onChange={() =>
										workingVisibility === "private"
											? handleVisibilityChange("public")
											: handleVisibilityChange("private")
									}
									color="primary"
									disabled={loading}
									data-cy={
										workingVisibility === "private"
											? "edit-note__publish"
											: "edit-note__unpublish"
									}
								/>
								<Typography variant="boldBody2">Public</Typography>
							</Box>
						</Box>
					</Box>
					<Box marginY={1} minHeight="50px">
						{!loading && (
							<>
								<RTFEditor
									autoFocus
									initialValue={workingRichText || null}
									uploadImage={async ({
										file,
										url,
									}: {
										file?: File;
										url?: string;
									}) => {
										if (file) {
											const ext = file.name.split(".").pop();
											try {
												const data = await uploadImage(
													`${crypto.randomUUID()}.${ext}`,
													file,
												);
												return {
													name: data.fileName,
													url: data.signedUrl,
												};
											} catch (err) {
												// show error toast
												setImageUploadError(true);
												return null;
											}
										}

										try {
											const data = await uploadExternalImage(url);
											return {
												name: data.fileName,
												url: data.signedUrl,
											};
										} catch (err) {
											setImageUploadError(true);
											return null;
										}
									}}
									onChange={(newRichText) => {
										handleTextChange(newRichText);
									}}
								/>
								{!(hideHint.hotkey && hideHint.listHotkey) && (
									<Box display="flex" flexDirection="column" gap={1} my={1}>
										{!hideHint.hotkey && (
											<Alert
												severity="info"
												onClose={() =>
													setHideHint({ ...hideHint, hotkey: true })
												}
											>
												Hint: Press Control (^) or Command (⌘) while editing a
												note to show hotkey hints
											</Alert>
										)}
										{!hideHint.listHotkey && (
											<Alert
												severity="info"
												onClose={() =>
													setHideHint({ ...hideHint, listHotkey: true })
												}
											>
												Hint: Type - or 1. followed by a space to start a list
											</Alert>
										)}
									</Box>
								)}
							</>
						)}
					</Box>
					<Divider />
					<Box paddingTop={2}>
						{!loading && (
							<Box mt={2}>
								<PushToProcess
									valorId={valorId}
									note={noteReference}
									disabled={linkBusy || unlinkBusy || !docId}
									onPush={addProcessToNote}
									onDelete={removeProcessFromNote}
								/>
							</Box>
						)}
						<Snackbar
							open={linkSuccess}
							autoHideDuration={6000}
							onClose={() => setLinkSuccess(false)}
							message="Note linked to opportunity"
							anchorOrigin={{
								horizontal: "left",
								vertical: "bottom",
							}}
						>
							<Alert onClose={() => setLinkSuccess(false)} severity="success">
								Note linked to opportunity
							</Alert>
						</Snackbar>
						<Snackbar
							open={linkError}
							autoHideDuration={6000}
							onClose={() => setLinkError(false)}
							message="Error linking not to opportunity"
							anchorOrigin={{
								horizontal: "left",
								vertical: "bottom",
							}}
						>
							<Alert onClose={() => setLinkError(false)} severity="error">
								Error linking note to opportunity
							</Alert>
						</Snackbar>

						<Snackbar
							open={unlinkSuccess}
							autoHideDuration={6000}
							onClose={() => setUnlinkSuccess(false)}
							message="Note removed from opportunity"
							anchorOrigin={{
								horizontal: "left",
								vertical: "bottom",
							}}
						>
							<Alert onClose={() => setUnlinkSuccess(false)} severity="success">
								Note removed from opportunity
							</Alert>
						</Snackbar>
						<Snackbar
							open={unlinkError}
							autoHideDuration={6000}
							onClose={() => setLinkError(false)}
							message="Error removing note from opportunity"
							anchorOrigin={{
								horizontal: "left",
								vertical: "bottom",
							}}
						>
							<Alert onClose={() => setUnlinkError(false)} severity="error">
								Error removing note from opportunity
							</Alert>
						</Snackbar>

						<Snackbar
							open={imageUploadError}
							autoHideDuration={6000}
							onClose={() => setImageUploadError(false)}
							message="Error uploading image"
							anchorOrigin={{
								horizontal: "left",
								vertical: "bottom",
							}}
						>
							<Alert
								onClose={() => setImageUploadError(false)}
								severity="error"
							>
								Error uploading image, please try again.
							</Alert>
						</Snackbar>
					</Box>
				</Box>
			</Card>
			<SearchDrawer
				open={isSearchDrawerOpen}
				userId={user?.id}
				valorId={valorId}
				onOpen={() => setIsSearchDrawerOpen(true)}
				onClose={() => setIsSearchDrawerOpen(false)}
			/>
		</Box>
	);
}

export default EditNotes;

import UserAvatar from "@/ui/atoms/UserAvatar";
import { Box, Chip, ClickAwayListener } from "@mui/material";
import type React from "react";
import { useCallback, useRef, useState } from "react";
import OverflowBox from "./OverflowBox";

type UserValue = {
	id: string;
	firstName: string;
	lastName: string;
	profilePicture: string;
};

const renderUsers = (users: UserValue[], showLabel = true) => {
	// Sort users by last name
	const sortedUsers = [...users].sort((a, b) =>
		a.lastName.localeCompare(b.lastName),
	);

	return sortedUsers.map((user) => {
		const fullName = `${user.firstName} ${user.lastName}`;

		const avatarUser = {
			name: fullName,
			profilePicture: user.profilePicture,
		};

		if (!showLabel) {
			return (
				<UserAvatar
					key={user.id}
					user={avatarUser}
					style={{
						width: "32px",
						height: "32px",
						fontSize: "1em",
					}}
				/>
			);
		}

		return (
			<Chip
				key={user.id}
				avatar={
					<UserAvatar
						user={avatarUser}
						style={{
							width: "25px",
							height: "25px",
							fontSize: "1em",
						}}
					/>
				}
				label={`${user.firstName} ${user.lastName}`}
				sx={{ height: "25px" }}
			/>
		);
	});
};

export default function UserCell({
	value,
	showLabel = true,
}: {
	value: UserValue[];
	showLabel?: boolean;
}) {
	const [showOverflow, setShowOverflow] = useState(false);
	const anchorRef = useRef<null | HTMLElement>(null);

	const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setShowOverflow(true);
	}, []);

	const handleClose = useCallback(() => {
		setShowOverflow(false);
	}, []);

	if (!value || !Array.isArray(value)) return <>-</>;

	return (
		<ClickAwayListener onClickAway={() => setShowOverflow(false)}>
			<Box position="relative" height="100%" display="flex">
				<Box
					ref={anchorRef}
					display="inline-flex"
					flexWrap="nowrap"
					alignItems="center"
					gap={0.5}
					overflow="hidden"
					whiteSpace="nowrap"
					width="100%"
					sx={{
						overflow: "hidden",
						cursor: "pointer",
					}}
					onClick={handleClick}
				>
					{renderUsers(value, showLabel)}
				</Box>
				<OverflowBox
					open={showOverflow}
					anchorEl={anchorRef.current}
					onClose={handleClose}
				>
					{renderUsers(value, true)}
				</OverflowBox>
			</Box>
		</ClickAwayListener>
	);
}

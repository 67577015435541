import { getRedirectProcessId } from "@/api/Process";
import Page from "@/ui/molecules/Page";
import { Alert } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

export const TaskRedirect = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { isError } = useQuery(
		["TaskRedirect", id],
		async () => {
			const data = await getRedirectProcessId(id);

			if (!data) {
				throw new Error("Bad redirect");
			}
			navigate(
				`/process-management/${data.processId}/tasks/${data.processId}-${data.id}?view=table`,
			);
		},
		{
			retry: false,
		},
	);

	if (isError) {
		return (
			<Page>
				<Page.Content>
					<Alert severity="error">Bad redirect, alert the labs team</Alert>
					Send id:
					{id}
				</Page.Content>
			</Page>
		);
	}

	return <div>Redirecting...</div>;
};

export default TaskRedirect;

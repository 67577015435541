import { Label } from "@mui/icons-material";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import renderCustomizedLabel from "../shared/Label";
import CustomTooltip from "../shared/Tooltip";

const sortCategoriesByTotal = (
	data: Array<Record<string, number | string>>,
	categories: string[],
) =>
	categories.sort((a, b) => {
		const totalA = data.reduce((sum, item) => sum + (item[a] as number), 0);
		const totalB = data.reduce((sum, item) => sum + (item[b] as number), 0);
		return totalB - totalA;
	});

export const StackedBarChart = ({
	data,
	formattingFunction,
	categories,
	categoryColorMap,
}) => {
	const sortedCategories = sortCategoriesByTotal(data, [...categories]);

	const otherIndex = sortedCategories.indexOf("Other");
	if (otherIndex !== -1) {
		sortedCategories.splice(otherIndex, 1);
		sortedCategories.push("Other");
	}

	const tickFormatter = formattingFunction
		? (val) => formattingFunction(val)
		: (val) => val;

	const dataWithTotals = data.map((item) => ({
		...item,
		total: sortedCategories.reduce(
			(sum, cat) => sum + (item[cat] as number),
			0,
		),
	}));

	return (
		<BarChart
			width={300}
			height={650}
			data={dataWithTotals}
			margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="designation" />
			<YAxis
				padding={{ top: 100 }}
				scale="linear"
				tickFormatter={tickFormatter}
				domain={[0, "dataMax"]}
			/>
			<Tooltip
				content={<CustomTooltip formatter={(val) => formattingFunction(val)} />}
			/>
			{sortedCategories.map((category, index) => (
				<Bar
					key={category}
					isAnimationActive={false}
					dataKey={category}
					stackId="stack"
					stroke={categoryColorMap[category]?.stroke ?? ""}
					fill={categoryColorMap[category]?.fill ?? "pink"}
					name={category}
				>
					<LabelList
						dataKey={category}
						position="inside"
						formatter={tickFormatter}
						content={(props) =>
							renderCustomizedLabel(props, formattingFunction)
						}
						fontSize={12}
						fill={categoryColorMap[category]?.text ?? "#000"}
					/>
					{category === sortedCategories[sortedCategories.length - 1] && (
						<LabelList
							dataKey={"total"}
							position="top"
							offset={8}
							fill="#000"
							fontSize={14}
							fontWeight="bold"
							formatter={(value, name, props) =>
								value ? formattingFunction(value, name, props) : null
							}
						/>
					)}
				</Bar>
			))}
		</BarChart>
	);
};

import {
	addCompanies,
	addMemberToFirmList,
	createFirmList,
	createTopCompaniesList,
	deleteCompany,
	deleteFirmMember,
	getFirmLists,
	getFirmListsMembers,
	getTopCompanies,
	getTopCompaniesList,
} from "@/api/InvestmentFirms";
import { saytForOrg } from "@/api/Search";
import { canSeeFirmList } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import RemoveButton from "@/ui/atoms/RemoveButton";
import SingleSelect from "@/ui/atoms/SingleSelect";
import AddCompanyDialog from "@/ui/molecules/AddCompanyDialog";
import Page from "@/ui/molecules/Page";
import {
	Avatar,
	Box,
	Button,
	Grid,
	TextField,
	Typography,
	makeStyles,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import Add from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "@mui/material";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useQuery, useQueryClient } from "react-query";
import NewListDialog from "./NewListDialog";

const useStyles = makeStyles((theme) => ({
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginTop: theme.spacing(2),
	},
	notAuthorized: {
		margin: "auto",
		fontSize: 20,
	},
}));

const InvestmentFirmChip = React.memo(
	({ firm, handleOnClick, readOnly }) => {
		const theme = useTheme();

		return (
			<Box
				key={`chip-${firm.valorId}`}
				boxShadow={1}
				bgcolor="background.paper"
				borderRadius="10px"
				p={1}
				style={{ display: "flex", alignItems: "center" }}
			>
				<Avatar
					alt="Firm Logo and Name"
					src={firm.logoUrl}
					style={{
						height: theme.spacing(5),
						width: theme.spacing(5),
						marginRight: theme.spacing(1),
					}}
				/>
				<Link href={`org/${firm.valorId}`}>{firm.name}</Link>
				{!readOnly && (
					<Box style={{ marginLeft: "auto" }}>
						<RemoveButton onClick={(e) => handleOnClick(e, firm.valorId)} />
					</Box>
				)}
			</Box>
		);
	},
	(prevProps, nextProps) =>
		prevProps.firm.valorId === nextProps.firm.valorId &&
		prevProps.readOnly === nextProps.readOnly,
);
InvestmentFirmChip.displayName = "InvestmentFirmChip";

function SourcingLists() {
	const classes = useStyles();
	const { user } = useAuth();
	const queryClient = useQueryClient();
	const [view, setView] = useState("VC Firms");

	const [dialogOpen, setDialogOpen] = useState(false);
	const [newListDialogOpen, setNewListDialogOpen] = useState(false);
	const [filteredMembers, setFilteredMembers] = useState([]);
	const [filteredCompanies, setFilteredCompanies] = useState([]);

	const [selectedFirm, setSelectedFirm] = useState({
		id: null,
		readOnly: false,
	});
	const [selectedCompaniesListId, setSelectedCompaniesListId] = useState(null);
	const { data: firmLists = [], refetch: refetchFirmList } = useQuery(
		["FirmLists"],
		getFirmLists,
		{
			onSuccess: (firmList) => setSelectedFirm(firmList[0]),
		},
	);
	const { data: companiesList = [], refetch: refetchCompaniesList } = useQuery(
		["CompaniesList"],
		getTopCompaniesList,
		{
			onSuccess: (companies) =>
				setSelectedCompaniesListId(
					companies?.length > 0 ? companies[companies.length - 1].id : null,
				),
		},
	);
	const {
		data: firmMembers = [],
		refetch: refetchFirmMembers,
		isLoading: isLoadingMembers,
	} = useQuery(
		["FirmMembers", selectedFirm?.id],
		async () => getFirmListsMembers(selectedFirm?.id),
		{
			onSuccess: setFilteredMembers,
		},
	);
	const {
		data: topCompanies = [],
		refetch: refetchTopCompanies,
		isLoading: isLoadingTopCompanies,
	} = useQuery(
		["TopCompanies", selectedCompaniesListId],
		async () => getTopCompanies(selectedCompaniesListId),
		{
			onSuccess: setFilteredCompanies,
		},
	);

	const isTopCompaniesView = view === "Top Companies";

	const handleOnClick = async (e, valorId) => {
		e.stopPropagation();
		if (isTopCompaniesView) {
			deleteCompany(selectedCompaniesListId, valorId);
			queryClient.setQueriesData(
				["TopCompanies", selectedCompaniesListId],
				(oldData) => oldData.filter((x) => x.valorId !== valorId),
			);
		} else {
			deleteFirmMember(selectedFirm?.id, valorId);
			queryClient.setQueriesData(["FirmMembers", selectedFirm?.id], (oldData) =>
				oldData.filter((x) => x.valorId !== valorId),
			);
		}
	};

	const handleAddMembers = async (valorIds) => {
		if (isTopCompaniesView) {
			await addCompanies(selectedCompaniesListId, valorIds);
			refetchTopCompanies();
		} else {
			await addMemberToFirmList(selectedFirm?.id, valorIds);
			refetchFirmMembers();
		}
	};

	const handleCreate = async (name, description) => {
		if (isTopCompaniesView) {
			await createTopCompaniesList(name, description);
			refetchCompaniesList();
		} else {
			await createFirmList(name, description);
			refetchFirmList();
		}
	};

	const isXsDown = useMediaQuery((theme) => theme.breakpoints.down("xs"));

	if (!canSeeFirmList(user)) {
		return (
			<ErrorMessage
				Icon={<LockIcon />}
				title="Unauthorized"
				message={
					<>
						You don’t have access to view Sourcing Lists page. If you think this
						is an error contact{" "}
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link
							onClick={(e) => {
								window.location.href =
									"mailto:labs@valorep.com?subject=Sourcing List Permissions";
								e.preventDefault();
							}}
						>
							labs@valorep.com
						</Link>
					</>
				}
			/>
		);
	}

	return (
		<>
			<Helmet>
				<title>Sourcing Lists - vOS</title>
			</Helmet>
			{canSeeFirmList(user) ? (
				<Page>
					<Page.Title data-cy="sourcing-list__title">Sourcing Lists</Page.Title>
					<Page.Content>
						<Box mt={2}>
							<Box mb={2} width="300px">
								<ButtonTabs
									options={["VC Firms", "Top Companies"]}
									onClick={setView}
									activeKey={view}
								/>
							</Box>
							<Box
								display="flex"
								flexDirection={isXsDown ? "column" : "row"}
								justifyContent={isXsDown ? "center" : "space-between"}
								alignItems={isXsDown ? "flex-start" : "center"}
								gridGap={8}
							>
								{isTopCompaniesView ? (
									<>
										<Box display="flex">
											<SingleSelect
												id="companies-lists"
												title="Companies Lists"
												width={250}
												value={selectedCompaniesListId || ""}
												getKey={(list) => list.id}
												getLabel={(list) => list.name}
												onChange={(key) => setSelectedCompaniesListId(key)}
												options={companiesList}
											/>
											<Box style={{ marginTop: "6px" }}>
												{selectedCompaniesListId && !isLoadingTopCompanies && (
													<Typography variant="subtitle2">{`${topCompanies.length} firms`}</Typography>
												)}
											</Box>
										</Box>
										<Button
											color="primary"
											variant="contained"
											fullWidth={isXsDown}
											startIcon={<Add color="inherit" />}
											onClick={() => setNewListDialogOpen(true)}
										>
											Create Companies List
										</Button>
									</>
								) : (
									<>
										<Box display="flex" data-cy="sourcing-list__firm-lists">
											<SingleSelect
												id="firm-lists"
												title="Firm Lists"
												width={250}
												value={selectedFirm?.id || ""}
												getKey={(list) => list.id}
												getLabel={(list) => list.name}
												onChange={(key, option) => setSelectedFirm(option)}
												options={firmLists}
											/>
											<Box style={{ marginTop: "6px" }}>
												{selectedFirm?.readOnly && (
													<Typography variant="subtitle2">
														synced from Affinity
													</Typography>
												)}
												{selectedFirm?.id && !isLoadingMembers && (
													<Typography variant="subtitle2">{`${firmMembers.length} firms`}</Typography>
												)}
											</Box>
										</Box>
										<Button
											color="primary"
											variant="contained"
											fullWidth={isXsDown}
											startIcon={<Add color="inherit" />}
											onClick={() => setNewListDialogOpen(true)}
										>
											Create Firm List
										</Button>
									</>
								)}
							</Box>
						</Box>
						<Box mt={1}>
							<Box
								display="flex"
								flexDirection={isXsDown ? "column" : "row"}
								justifyContent={isXsDown ? "center" : "space-between"}
								alignItems={isXsDown ? "flex-start" : "center"}
								gridGap={8}
								marginBottom={2}
							>
								<TextField
									onChange={(e) => {
										if (isTopCompaniesView) {
											setFilteredCompanies(
												topCompanies.filter((firm) =>
													firm.name
														.toLowerCase()
														.includes(e.target.value.toLocaleLowerCase()),
												),
											);
										} else {
											setFilteredMembers(
												firmMembers.filter((firm) =>
													firm.name
														.toLowerCase()
														.includes(e.target.value.toLocaleLowerCase()),
												),
											);
										}
									}}
									style={{
										minWidth: "250px",
									}}
									id="standard-basic"
									size="small"
									label="Search"
									variant="outlined"
									fullWidth={isXsDown}
								/>
								{((selectedFirm?.id && !selectedFirm?.readOnly) ||
									isTopCompaniesView) && (
									<Button
										color="primary"
										variant="contained"
										fullWidth={isXsDown}
										startIcon={<Add color="inherit" />}
										onClick={() => setDialogOpen(true)}
									>
										Add {isTopCompaniesView ? "Companies" : "Investors"}
									</Button>
								)}
							</Box>
							<Grid container spacing={1}>
								{(isTopCompaniesView ? filteredCompanies : filteredMembers).map(
									(firm) => (
										<Grid
											item
											xs={6}
											md={2}
											key={`firm-${firm.valorId}`}
											data-cy={`sourcing-list__firm-${firm.valorId}`}
										>
											<InvestmentFirmChip
												readOnly={!isTopCompaniesView && selectedFirm?.readOnly}
												firm={firm}
												handleOnClick={handleOnClick}
											/>
										</Grid>
									),
								)}
							</Grid>
						</Box>
						<AddCompanyDialog
							search={saytForOrg}
							open={dialogOpen}
							handleClose={() => setDialogOpen(false)}
							handleAddClick={handleAddMembers}
						/>
						<NewListDialog
							open={newListDialogOpen}
							handleClose={() => setNewListDialogOpen(false)}
							onCreate={handleCreate}
						/>
					</Page.Content>
				</Page>
			) : (
				<Box className={classes.notAuthorized}>
					You are not authorized to view this page.
				</Box>
			)}
		</>
	);
}

export default SourcingLists;

import {
	PRIMARY_LABELING,
	SECONDARY_LABELING,
} from "@/pages/FundManagement/constants.ts";
import { formatAsMillions } from "@/utils/numberFormat";
import { Box, Grid, Typography } from "@mui/material";
import { CONSTRUCTION_COLORS } from "../../constants";
import ContentSlide from "../ContentSlide";
import Slide from "../Slide";
import SlideToggle from "../SlideToggle";
import { FundManagementPieChart } from "../shared/PieChart";
import { Table, useFundManagementTable } from "../shared/Table";
import {
	transformByDesignation,
	transformForTable,
} from "./usePortfolioConstruction";

const EntropyBinaryRiskRatingSlide = ({
	groupedEntropyData,
	groupedBinaryData,
	title,
	pageNumber,
	fund,
}) => {
	const { unitsOfRiskPrimary, unitsOfRiskSecondary } = fund;
	const fundPrimaryLabel = PRIMARY_LABELING[fund.entityId];
	const fundSecondaryLabel = SECONDARY_LABELING[fund.entityId];
	const colors = CONSTRUCTION_COLORS["entropy"];

	const entropyDataByDesignation = Object.values(groupedEntropyData || {});
	const binaryDataByDesignation = Object.values(groupedBinaryData || {});

	const transformedEntropicData = transformByDesignation({
		data: entropyDataByDesignation,
	});

	const transformedEntropyCountData = transformByDesignation({
		data: entropyDataByDesignation,
		group: "count",
	});

	const transformedBinaryData = transformByDesignation({
		data: binaryDataByDesignation,
	});

	const transformedBinaryCountData = transformByDesignation({
		data: binaryDataByDesignation,
		group: "count",
	});

	const getTotal = (data, designation) =>
		data.find((item) => item.designation === designation) || {};

	const sumNumericValues = (obj) =>
		Object.values(obj).reduce(
			(sum, value) => (typeof value === "number" ? sum + value : sum),
			0,
		);

	const totalEntropyInvestedPrimary = sumNumericValues(
		getTotal(transformedEntropicData, "Primary"),
	);
	const totalEntropyInvestedSecondary = sumNumericValues(
		getTotal(transformedEntropicData, "Secondary"),
	);
	const totalEntropyCountPrimary = sumNumericValues(
		getTotal(transformedEntropyCountData, "Primary"),
	);
	const totalEntropyCountSecondary = sumNumericValues(
		getTotal(transformedEntropyCountData, "Secondary"),
	);
	const totalBinaryInvestedPrimary = sumNumericValues(
		getTotal(transformedBinaryData, "Primary"),
	);
	const totalBinaryInvestedSecondary = sumNumericValues(
		getTotal(transformedBinaryData, "Secondary"),
	);
	const totalBinaryCountPrimary = sumNumericValues(
		getTotal(transformedBinaryCountData, "Primary"),
	);
	const totalBinaryCountSecondary = sumNumericValues(
		getTotal(transformedBinaryCountData, "Secondary"),
	);

	const tableData = transformForTable({
		data: entropyDataByDesignation,
		primaryUnitsOfRisk: unitsOfRiskPrimary,
		secondaryUnitsOfRisk: unitsOfRiskSecondary,
	});

	const { columns } = useFundManagementTable({ data: tableData });

	const entropicCategories = [
		{ label: "Pro-Entropic", color: colors["Pro-Entropic"] },
		{ label: "Resilient", color: colors["Resilient"] },
		{ label: "Durable", color: colors["Durable"] },
		{ label: "Cyclical", color: colors["Cyclical"] },
	];

	const binaryCategories = [
		{ label: "Yes", color: colors["Yes"] },
		{ label: "No", color: colors["No"] },
	];

	return (
		<Slide>
			<SlideToggle>
				<SlideToggle.Slide>
					<ContentSlide disclaimer="All figures on this slide exclude realized investments.">
						<ContentSlide.Title>{title}</ContentSlide.Title>
						<ContentSlide.Content>
							<Grid container>
								<Grid item xs={6}>
									<Grid item xs={12}>
										<Box
											display="flex"
											flexDirection="column"
											alignItems="center"
										>
											<Typography variant="h3" mb={2}>
												{fundPrimaryLabel}: Entropy
											</Typography>
											<Grid container xs={12}>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													<Typography variant="boldBody1">
														$ Invested
													</Typography>
												</Grid>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													<Typography variant="boldBody1">
														# of Companies
													</Typography>
												</Grid>
											</Grid>
											<Grid container xs={12}>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													${formatAsMillions(totalEntropyInvestedPrimary)}
												</Grid>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													{totalEntropyCountPrimary}
												</Grid>
											</Grid>
											<Grid container>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													<FundManagementPieChart
														data={[
															getTotal(transformedEntropicData, "Primary"),
														]}
														unitOfRisk={unitsOfRiskPrimary}
														isMonetary
													/>
												</Grid>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													<FundManagementPieChart
														data={[
															getTotal(transformedEntropyCountData, "Primary"),
														]}
													/>
												</Grid>
											</Grid>
										</Box>
									</Grid>
									{fundSecondaryLabel !== "error" && (
										<Grid item xs={12}>
											<Box
												display="flex"
												flexDirection="column"
												alignItems="center"
											>
												<Typography variant="h3" mb={2}>
													{fundSecondaryLabel}: Entropy
												</Typography>
												<Grid container xs={12}>
													<Grid
														item
														xs={6}
														display="flex"
														justifyContent={"center"}
													>
														<Typography variant="boldBody1">
															$ Invested
														</Typography>
													</Grid>
													<Grid
														item
														xs={6}
														display="flex"
														justifyContent={"center"}
													>
														<Typography variant="boldBody1">
															# of Companies
														</Typography>
													</Grid>
												</Grid>
												<Grid container xs={12}>
													<Grid
														item
														xs={6}
														display="flex"
														justifyContent={"center"}
													>
														${formatAsMillions(totalEntropyInvestedSecondary)}
													</Grid>
													<Grid
														item
														xs={6}
														display="flex"
														justifyContent={"center"}
													>
														{totalEntropyCountSecondary}
													</Grid>
												</Grid>
												<Grid container>
													<Grid
														item
														xs={6}
														display="flex"
														justifyContent={"center"}
													>
														<FundManagementPieChart
															data={[
																getTotal(transformedEntropicData, "Secondary"),
															]}
															unitOfRisk={unitsOfRiskSecondary}
															isMonetary
														/>
													</Grid>
													<Grid
														item
														xs={6}
														display="flex"
														justifyContent={"center"}
													>
														<FundManagementPieChart
															data={[
																getTotal(
																	transformedEntropyCountData,
																	"Secondary",
																),
															]}
														/>
													</Grid>
												</Grid>
											</Box>
										</Grid>
									)}

									<Grid item xs={12}>
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												alignItems: "center",
												gap: "12px",
												backgroundColor: "#f5f5f5",
												padding: "8px 16px",
												borderRadius: "8px",
												boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
												width: "440px",
												justifySelf: "center",
												justifyContent: "space-around",
											}}
										>
											{entropicCategories.map((category) => (
												<Box
													key={category.label}
													sx={{
														display: "flex",
														alignItems: "center",
														gap: "8px",
														fontSize: "14px",
														fontWeight: 500,
														color: "#333",
													}}
												>
													<Box
														sx={{
															height: "12px",
															width: "12px",
															backgroundColor: category.color.fill,
															borderRadius: "2px",
														}}
													/>
													{category.label}
												</Box>
											))}
										</Box>
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<Grid item xs={12}>
										<Box
											display="flex"
											flexDirection="column"
											alignItems="center"
										>
											<Typography variant="h3" mb={2}>
												{fundPrimaryLabel}: Binary Risk / Concentration
											</Typography>
											<Grid container xs={12}>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													<Typography variant="boldBody1">
														$ Invested
													</Typography>
												</Grid>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													<Typography variant="boldBody1">
														# of Companies
													</Typography>
												</Grid>
											</Grid>
											<Grid container xs={12}>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													${formatAsMillions(totalBinaryInvestedPrimary)}
												</Grid>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													{totalBinaryCountPrimary}
												</Grid>
											</Grid>
											<Grid container>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													<FundManagementPieChart
														data={[getTotal(transformedBinaryData, "Primary")]}
														unitOfRisk={unitsOfRiskPrimary}
														isMonetary
													/>
												</Grid>
												<Grid
													item
													xs={6}
													display="flex"
													justifyContent={"center"}
												>
													<FundManagementPieChart
														data={[
															getTotal(transformedBinaryCountData, "Primary"),
														]}
													/>
												</Grid>
											</Grid>
										</Box>
									</Grid>
									{fundSecondaryLabel !== "error" && (
										<>
											<Grid item xs={12}>
												<Box
													display="flex"
													flexDirection="column"
													alignItems="center"
												>
													<Typography variant="h3" mb={2}>
														{fundSecondaryLabel}: Binary Risk / Concentration
													</Typography>
													<Grid container xs={12}>
														<Grid
															item
															xs={6}
															display="flex"
															justifyContent={"center"}
														>
															<Typography variant="boldBody1">
																$ Invested
															</Typography>
														</Grid>
														<Grid
															item
															xs={6}
															display="flex"
															justifyContent={"center"}
														>
															<Typography variant="boldBody1">
																# of Companies
															</Typography>
														</Grid>
													</Grid>
													<Grid container xs={12}>
														<Grid
															item
															xs={6}
															display="flex"
															justifyContent={"center"}
														>
															${formatAsMillions(totalBinaryInvestedSecondary)}
														</Grid>
														<Grid
															item
															xs={6}
															display="flex"
															justifyContent={"center"}
														>
															{totalBinaryCountSecondary}
														</Grid>
													</Grid>
													<Grid container>
														<Grid
															item
															xs={6}
															display="flex"
															justifyContent={"center"}
														>
															<FundManagementPieChart
																data={[
																	getTotal(transformedBinaryData, "Secondary"),
																]}
																unitOfRisk={unitsOfRiskSecondary}
																isMonetary
															/>
														</Grid>
														<Grid
															item
															xs={6}
															display="flex"
															justifyContent={"center"}
														>
															<FundManagementPieChart
																data={[
																	getTotal(
																		transformedBinaryCountData,
																		"Secondary",
																	),
																]}
															/>
														</Grid>
													</Grid>
												</Box>
											</Grid>
										</>
									)}
									<Grid item xs={12}>
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												alignItems: "center",
												gap: "12px",
												backgroundColor: "#f5f5f5",
												padding: "8px 16px",
												borderRadius: "8px",
												boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
												width: "240px",
												justifySelf: "center",
												justifyContent: "space-around",
											}}
										>
											{binaryCategories.map((category) => (
												<Box
													key={category.label}
													sx={{
														display: "flex",
														alignItems: "center",
														gap: "8px",
														fontSize: "14px",
														fontWeight: 500,
														color: "#333",
													}}
												>
													<Box
														sx={{
															height: "12px",
															width: "12px",
															backgroundColor: category.color.fill,
															borderRadius: "2px",
														}}
													/>
													{category.label}
												</Box>
											))}
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</ContentSlide.Content>
					</ContentSlide>
				</SlideToggle.Slide>
				<SlideToggle.Data>
					<Table
						columns={columns}
						data={tableData}
						title={title}
						fundName={fund.name}
					/>
				</SlideToggle.Data>
			</SlideToggle>
		</Slide>
	);
};

export default EntropyBinaryRiskRatingSlide;

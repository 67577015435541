import Progress from "@/ui/atoms/Progress";
import {
	Autocomplete,
	Box,
	Checkbox,
	Chip,
	MenuItem,
	MenuList,
	Radio,
	TextField,
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import React from "react";
import { useQuery } from "react-query";

type Option = {
	label: string;
	value: string | number;
	count: number;
};

type FilterValue = { label: string; value: string };

interface SelectFilterProps {
	multiple?: boolean;
	getFilterCounts: () => Promise<Option[]>;
	setFilterValue: (value: FilterValue | FilterValue[] | undefined) => void;
	getFilterValue: () => FilterValue | FilterValue[] | undefined;
	columnId: string;
	fieldName: string;
}

const DenseMenuList = styled(MenuList)(() => ({
	padding: 0,
	maxHeight: "300px",
	overflow: "auto",
}));

const DenseMenuItem = styled(MenuItem)(() => ({
	padding: "4px",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: 1,
	fontSize: "14px",
	maxHeight: "30px",
	cursor: "pointer",
	minWidth: 144,
}));

const CountDisplay = ({ count }: { count: number }) => (
	<Box
		component="span"
		sx={{
			mr: 1,
			color: "text.secondary",
			fontSize: 12,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			ml: 1,
			width: 24,
		}}
	>
		{count}
	</Box>
);

export function DynamicSelectFilter({
	multiple,
	getFilterCounts,
	getFilterValue,
	columnId,
	setFilterValue,
	fieldName,
}: SelectFilterProps) {
	const { data: options = [], isLoading } = useQuery<Option[]>(
		["filterCounts", columnId],
		getFilterCounts,
		{ keepPreviousData: true },
	);

	const sortedOptions = React.useMemo(
		() => [...options].sort((a, b) => a.label.localeCompare(b.label)),
		[options],
	);

	const columnFilterValue = getFilterValue();

	console.log({
		options: options,
		sortedOptions: sortedOptions,
		columnFilterValue: columnFilterValue,
	});

	const isChecked = React.useCallback(
		(optionValue: string) => {
			if (multiple) {
				return (columnFilterValue as FilterValue[])?.some(
					(item) => item.value === optionValue,
				);
			}
			return (columnFilterValue as FilterValue)?.value === optionValue;
		},
		[columnFilterValue, multiple],
	);

	const handleSelectChange = React.useCallback(
		(option: Option) => {
			if (multiple) {
				const currentValues = (columnFilterValue as FilterValue[]) || [];
				const newValues = isChecked(option.value.toString())
					? currentValues.filter((v) => v.value !== option.value.toString())
					: [
							...currentValues,
							{ label: option.label, value: option.value.toString() },
						];
				setFilterValue(newValues.length ? newValues : undefined);
			} else {
				setFilterValue(
					isChecked(option.value.toString())
						? undefined
						: { label: option.label, value: option.value.toString() },
				);
			}
		},
		[columnFilterValue, isChecked, multiple, setFilterValue],
	);

	const handleAutocompleteChange = React.useCallback(
		(_: any, newValue: Option | Option[] | null) => {
			if (multiple) {
				const values = (newValue as Option[])?.map((option) => ({
					label: option.label,
					value: option.value.toString(),
				}));
				setFilterValue(values?.length ? values : undefined);
			} else {
				setFilterValue(
					newValue
						? {
								label: (newValue as Option).label,
								value: (newValue as Option).value.toString(),
							}
						: undefined,
				);
			}
		},
		[multiple, setFilterValue],
	);

	if (isLoading) {
		return (
			<Box
				sx={{
					display: "flex",
					minWidth: 220,
					minHeight: 220,
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Progress />
			</Box>
		);
	}

	if (sortedOptions.length > 10) {
		return (
			<Box sx={{ minWidth: 220, padding: "8px" }}>
				<Autocomplete
					autoFocus
					multiple={multiple}
					options={sortedOptions}
					getOptionLabel={(option) => option.label}
					renderOption={(props, option) => (
						<MenuItem {...props}>
							<CountDisplay count={option.count} />
							{option.label}
						</MenuItem>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							size="small"
							placeholder={`Filter ${fieldName}`}
						/>
					)}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip
								label={option.label}
								size="small"
								{...getTagProps({ index })}
								key={option.value}
							/>
						))
					}
					value={
						multiple
							? sortedOptions.filter((option) =>
									(columnFilterValue as FilterValue[])?.some(
										(item) => item.value === option.value.toString(),
									),
								)
							: sortedOptions.find(
									(option) =>
										option.value.toString() ===
										(columnFilterValue as FilterValue)?.value,
								) || null
					}
					onChange={handleAutocompleteChange}
					sx={{ minWidth: 220 }}
				/>
			</Box>
		);
	}

	const Control = multiple ? Checkbox : Radio;

	return (
		<Box sx={{ minWidth: 220 }}>
			<DenseMenuList>
				{sortedOptions.map((option) => (
					<DenseMenuItem
						key={option.value}
						onClick={() => handleSelectChange(option)}
					>
						<CountDisplay count={option.count} />
						<Control
							size="small"
							checked={isChecked(option.value.toString())}
						/>
						{option.label}
					</DenseMenuItem>
				))}
			</DenseMenuList>
		</Box>
	);
}

export default DynamicSelectFilter;

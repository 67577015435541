import {
	AccountBalance,
	AdminPanelSettings,
	Biotech,
	CloudUpload,
	Construction,
	DynamicFeed,
	Key,
	KeyboardArrowDown,
	MeetingRoom,
	MonetizationOn,
	ShowChart,
	SupervisorAccount,
} from "@mui/icons-material";
import {
	Badge,
	Box,
	Collapse,
	Divider,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Drawer as MuiDrawer,
	Typography,
	useMediaQuery,
} from "@mui/material";
import {
	type CSSObject,
	type Theme,
	styled,
	useTheme,
} from "@mui/material/styles";
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
	ADMIN_ROLE_ID,
	DEVELOPER_ROLE_ID,
	MARKET_MAPS_ROLE_ID,
	canReadFundManagement,
	canSeeHome,
	canSeeProcess,
	canSeeSignalModelPipeline,
	canSeeVAAIReports,
	canVoteOnFirstMeeting,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import AtreidesIcon from "@/ui/atoms/Icons/Atreides";
import Flake from "@/ui/atoms/Icons/Flake";
import VOSFull from "@/ui/atoms/Icons/VOSFull";
import { destinations } from "@/utils/destinations";

import { useUnvotedLastWeekReportsCount } from "@/components/FirstMeetings/useUnvotedReports";
import CmdK from "../CmdK";
import NotPrintable from "../NotPrintable";

const drawerWidth = 320;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
	backgroundColor: theme.palette.background.blue,
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	backgroundColor: theme.palette.background.blue,
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});
const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	position: "fixed",
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

function assignIdByDepth(arr, ids = []) {
	if (!arr) return arr;
	return arr.map((obj, index) => {
		let { children } = obj;
		const newIds = [...ids, index + 1];
		if (obj.children) {
			children = assignIdByDepth(obj.children, newIds);
		}
		return {
			...obj,
			id: newIds.join("."),
			children,
		};
	});
}

interface ListItemProps {
	isSelected: boolean;
}

const ListItem = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "disabled",
})<ListItemProps>(({ theme, isSelected, disabled }) => ({
	...theme.typography.subtitle1,
	height: "42px",
	paddingLeft: theme.spacing(1),
	color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
	borderRadius: theme.shape.borderRadius,
	backgroundColor: isSelected
		? `${theme.palette.background.paper} !important`
		: "transparent",
	"& svg": {
		color: isSelected
			? theme.palette.primary.main
			: theme.palette.text.secondary,
	},
	"&:hover > div > svg": {
		color: theme.palette.primary.main,
	},
	"&:selected > svg": {
		color: theme.palette.primary.main,
	},
	"&:hover": {
		color: !disabled && theme.palette.primary.main,
		backgroundColor: !disabled && theme.palette.background.paper,
	},
	"&:selected": {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
	},
}));

interface ListItemGroupProps {
	isOpen: boolean;
}

const ListItemGroup = styled(Box, {
	shouldForwardProp: (prop) => prop !== "isOpen",
})<ListItemGroupProps>(({ theme, isOpen }) => ({
	margin: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
	backgroundColor: isOpen ? theme.palette.background.paper : "transparent",
	color: isOpen ? theme.palette.primary.main : theme.palette.text.primary,
	size: "12px !important",
}));

interface IconChevronButtonProps {
	isOpen: boolean;
}

const IconChevronButton = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== "isOpen",
})<IconChevronButtonProps>(({ theme, isOpen }) => ({
	color: theme.palette.primary.main,
	marginRight: "4px",
	transition: "transform 0.2s",
	transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
	"& svg": {
		color: theme.palette.primary.main,
	},
	"&:hover": {
		backgroundColor: "transparent",
	},
	"&:selected": {
		backgroundColor: "transparent",
	},
}));

interface Props {
	open: boolean;
	handleDrawerOpen(...args: unknown[]): unknown;
	handleDrawerClose(...args: unknown[]): unknown;
}

export function NavDrawer(props: Props) {
	const { open, handleDrawerOpen, handleDrawerClose } = props;

	const unvotedBadgeCount = useUnvotedLastWeekReportsCount();

	const navigationOptions = useMemo(
		() =>
			assignIdByDepth([
				{
					...destinations.Home,
					cyAttribute: "nav__home",
					children: [],
					divideAfter: true,
				},
				{
					name: "Investment Meetings",
					Icon: MeetingRoom,
					path: null,
					cyAttribute: "nav__exec_meetings",
					children: [
						{
							...destinations["Weekly IC Meeting"],
							cyAttribute: "nav__exec__meetings",
						},
						{
							name: "Growth Fund",
							disabled: true,
							canSee: destinations["Weekly IC Meeting"].canSee,
						},
						{
							name: "VSV Team",
							disabled: true,
							canSee: destinations["Weekly IC Meeting"].canSee,
						},
						{
							name: "VAAI Team",
							disabled: true,
							canSee: destinations["Weekly IC Meeting"].canSee,
						},
					],
				},
				{
					name: "Investment Process",
					Icon: DynamicFeed,
					cyAttribute: "nav__exec__investments",
					canSee: (user) =>
						canSeeProcess(user, "deals") ||
						canVoteOnFirstMeeting(user) ||
						canSeeSignalModelPipeline(user),
					children: [
						destinations["New Opportunity Distribution"],
						destinations["New Opportunities - Pre OA"],
						{
							...destinations["Opportunity Assessments"],
							cyAttribute: "nav__investments__opportunity-assessment-reports",
							badgeContent: unvotedBadgeCount.count,
						},
						destinations["Considering for Term Sheet"],
						destinations["Under Term Sheet"],
						destinations["Post Closing Actions"],
						destinations["Closed Investments"],
						destinations.Following,
						destinations["Pass Follow Throughs"],
						{
							...destinations["Signal Model Opportunities"],
							cyAttribute: "nav__admin__signal-model-pipeline",
						},
						destinations["Signal Model Process"],
					],
				},
				{
					name: "Atreides / VAAI Reports",
					canSee: canSeeVAAIReports,
					Icon: AtreidesIcon,
					children: [
						destinations["VAAI Deals Reports"],
						destinations["VAAI Portfolio Reports"],
					],
				},
				{
					name: "Portfolio Co Mgt Process",
					Icon: Flake,
					cyAttribute: "nav__exec",
					children: [
						destinations["Fund Portfolio CRM"],
						{
							...destinations["IHE Changes"],
							cyAttribute: "nav__exec__ihe-change",
						},
						{
							label: "P2P Analysis",
							name: "P2P Analysis",
							path: destinations["P2P Analysis"].path,
							canSee: destinations["P2P Analysis"].canSee,
						},
						destinations["PortCo Financing Activity"],
					],
				},
				{
					name: "Fund Management",
					path: "/fund-management",
					canSee: (u) => canReadFundManagement(u),
					Icon: ShowChart,
					cyAttribute: "nav__fund_management",
					children: [],
				},
				{
					name: "Research",
					Icon: Biotech,
					cyAttribute: "nav__exec",
					children: [
						{
							cyAttribute: "nav__research__market-maps",
							...destinations["Market Maps"],
						},
						{
							...destinations["Research Projects"],
							cyAttribute: "nav__research-management-board",
						},
						{
							...destinations["Deal Search"],
							cyAttribute: "nav__investments__search",
						},
					],
				},
				{
					...destinations["vOS Opportunity CRM"],
					cyAttribute: "nav__home",
					divideAfter: true,
					children: [],
				},
				{
					name: "Scale Group",
					Icon: Construction,
					children: [
						destinations["Lean Growth"],
						destinations["Rev Gen Engagements"],
						destinations["Rev Gen Onboardings"],
						destinations["Revenue Generation"],
						destinations["Talent Acquisition"],
					],
				},
				{
					name: "Investor Relations Group",
					Icon: MonetizationOn,
					children: [
						destinations["Funds (Active / In Consideration)"],
						destinations["Investment Announcements"],
						destinations["LP / AB Meetings & Consents"],
						{
							name: "Fund / Portfolio Summaries",
							disabled: true,
						},
						{
							name: "Salesforce Reporting",
							disabled: true,
						},
					],
				},

				{
					name: "Infrastructure Group",
					Icon: AccountBalance,
					divideAfter: true,
					children: [
						destinations["Finance Tracker"],
						destinations.IT,
						destinations["IT (CEO/CIO)"],
						destinations.Legal,
						// destinations["Human Capital"],
						destinations["PCAP Audit"],
					],
				},
				{
					...destinations.Grok,
					cyAttribute: "nav__grok",
					children: [],
					divideAfter: true,
				},
				{
					...destinations.Calendar,
					children: [],
				},
				{
					name: "Sharepoint",
					Icon: CloudUpload,
					link: "https://valorep.sharepoint.com/SitePages/Home.aspx",
					canSee: canSeeHome,
					cyAttribute: "nav__home",
					children: [],
					divideAfter: true,
				},
				{
					name: "Management Reports",
					Icon: Key,
					children: [
						{
							cyAttribute: "nav__exec__portfolio-management",
							...destinations["Portfolio Surveys"],
						},
						destinations["Survey Management"],
						destinations["Meeting Recordings"],
						destinations["After Action Reviews"],
					],
					divideAfter: true,
				},
				{
					name: "CRISIL",
					Icon: SupervisorAccount,
					canSee: (user) =>
						[DEVELOPER_ROLE_ID, MARKET_MAPS_ROLE_ID, ADMIN_ROLE_ID].indexOf(
							user?.roleId,
						) !== -1,
					children: [
						{
							cyAttribute: "nav__research__market-maps",
							...destinations["Market Maps"],
						},

						destinations["Finance Metric Tasks"],
					],
				},
				{
					name: "Admin",
					Icon: AdminPanelSettings,
					cyAttribute: "nav__admin",
					children: [
						destinations["Create Organization"],
						{
							...destinations["User Management"],
							cyAttribute: "nav__admin__compliance",
						},
						{
							...destinations["Untagged Documents"],
							cyAttribute: "nav__admin__untagged-docs",
						},
						{
							...destinations["Sourcing Lists"],
							cyAttribute: "nav__investments__sourcing",
						},
					],
				},
			]),
		[unvotedBadgeCount.count],
	);

	const { user } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();

	const [openTab, setOpenTab] = useState(null);
	const [hoveredTab, setHoveredTab] = useState(null);
	const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

	const [selectedTabId, setSelectedTabId] = useLocalStorageState(
		null,
		"selectedTabId",
	);

	const [isDarkMode] = useLocalStorageState(false, "isDarkMode");

	function flattenOptions(options) {
		return options.flatMap((option) => {
			if (!option.children) {
				return option;
			}
			return [option, ...flattenOptions(option.children)];
		});
	}

	useEffect(() => {
		if (!selectedTabId) {
			// recursively find the first tab that with path that matches the current location
			const tab = flattenOptions(navigationOptions).find(
				(option) => option.path === location.pathname,
			);
			if (tab) {
				setSelectedTabId(tab.id);
			}
		} else {
			// unset if path != selected tabs path
			const selectedTab = flattenOptions(navigationOptions).find(
				(option) => option.id === selectedTabId,
			);
			if (selectedTab && !location.pathname.startsWith(selectedTab.path)) {
				setSelectedTabId(null);
			}
		}
	}, [location.pathname, selectedTabId, setSelectedTabId]);

	const onCloseDrawer = () => {
		setOpenTab(null);
		handleDrawerClose();
	};

	function hasOpenChild(opt, tab) {
		if (!opt.children) {
			return false;
		}
		return opt.children.some(
			(child) => child === tab || hasOpenChild(child, tab),
		);
	}

	function hasSelectedChild(opt, selectedId) {
		if (!opt.children) {
			return false;
		}
		return opt.children.some(
			(child) => child.id === selectedId || hasSelectedChild(child, selectedId),
		);
	}

	function handleNavClick(option, parent, isOpen, hasChildren) {
		if (option.disabled) {
			return;
		}
		if (isOpen) {
			setOpenTab(parent);
		} else if (hasChildren) {
			handleDrawerOpen();
			setOpenTab(option);
		}
		if (!hasChildren) {
			if (option.link) {
				window.open(option.link, "_blank");
			} else {
				navigate(option.path);
			}

			setSelectedTabId(option.id);
			if (isSmDown) {
				onCloseDrawer();
			}
		}
	}

	const renderChildren = (children, parent, depth = 1) => {
		if (children.length === 0) {
			return null;
		}
		return children
			.filter((child) => !child.canSee || child.canSee(user))
			.map((child) => {
				const isOpen = child === openTab || hasOpenChild(child, openTab);
				const hasChildren = child.children?.length > 0;
				const badgeCount = child.badgeContent;

				const isSelected = child.id === selectedTabId;
				const { disabled } = child;
				return (
					<>
						<ListItem
							key={child.id}
							data-cy={child.cyAttribute}
							isSelected={isSelected}
							onClick={() => handleNavClick(child, parent, isOpen, hasChildren)}
							sx={{
								color: isSelected
									? theme.palette.primary.main
									: theme.palette.text.primary,
								paddingLeft: `${(depth || 1) * 20}px`,
								opacity: disabled ? 0.5 : 1,
								userSelect: disabled ? "none" : undefined,
							}}
							onMouseEnter={() => setHoveredTab(child)}
							onMouseLeave={() => setHoveredTab(null)}
							disabled={disabled}
						>
							<ListItemText primary={child.name} sx={{ overflow: "hidden" }} />
							{badgeCount ? (
								<Box
									sx={{
										borderRadius: "50%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										color: "white !important",
										backgroundColor: theme.palette.error.main,
										width: "20px",
										height: "20px",
									}}
								>
									<Typography variant="fontSize">{badgeCount}</Typography>
								</Box>
							) : null}
							{/* {hasChildren && isHovered ? ( */}
							{hasChildren ? (
								<IconChevronButton
									isOpen={isOpen}
									sx={(t) => ({
										color: t.palette.primary.main,
									})}
								>
									<KeyboardArrowDown />
								</IconChevronButton>
							) : null}
						</ListItem>
						{child.children?.length ? (
							<Collapse in={isOpen}>
								{/* <Collapse> */}
								<List>{renderChildren(child.children, child, depth + 1)}</List>
							</Collapse>
						) : null}
					</>
				);
			});
	};

	const listOptions = () =>
		navigationOptions.flatMap((option) => {
			if (!option || (option.canSee && !option.canSee(user))) {
				return null;
			}
			const hasChildren = option.children.length > 0;
			const hasChildrenBadgeContent = option.children.some(
				(child) => child.badgeContent,
			);

			if (hasChildren) {
				const visibleChildren = option.children
					.filter((x) => x?.disabled !== true)
					.filter((child) =>
						typeof child.canSee === "function" ? child.canSee(user) : true,
					);
				if (visibleChildren.length === 0) {
					return null;
				}
			}

			const isOpen = option === openTab || hasOpenChild(option, openTab);
			const isSelected =
				option.id === selectedTabId || hasSelectedChild(option, selectedTabId);
			const isHovered = option === hoveredTab && !option.disabled;

			return [
				<ListItemGroup
					key={option.id}
					isOpen={isOpen || isSelected}
					onMouseEnter={() => setHoveredTab(option)}
					onMouseLeave={() => setHoveredTab(null)}
				>
					<ListItem
						isSelected={isOpen || isSelected}
						data-cy={option.cyAttribute}
						onClick={() => handleNavClick(option, null, isOpen, hasChildren)}
						selected={isSelected}
					>
						<ListItemIcon
							sx={{
								marginLeft: "4px",
								"& span > svg > g > path": {
									fill: "rgba(0, 0, 0, 0.54)",
								},
							}}
						>
							<Badge
								color="error"
								variant="dot"
								invisible={!hasChildrenBadgeContent}
							>
								<option.Icon />
							</Badge>
						</ListItemIcon>
						<ListItemText primary={option.name} />
						{hasChildren ? (
							<IconChevronButton
								sx={{
									visibility: isHovered ? "visible" : "hidden",
								}}
								isOpen={isOpen}
							>
								<KeyboardArrowDown />
							</IconChevronButton>
						) : null}
					</ListItem>

					{hasChildren ? (
						<Collapse in={isOpen}>
							<List>{renderChildren(option.children, option)}</List>
						</Collapse>
					) : null}
				</ListItemGroup>,
				option.divideAfter ? <Divider key={`divider-${option.id}`} /> : null,
			];
		});

	if (isSmDown) {
		return (
			<NotPrintable>
				<MuiDrawer
					variant="temporary"
					open={open}
					onClose={onCloseDrawer}
					PaperProps={{
						sx: {
							backgroundColor: theme.palette.background.blue,
							marginTop: "8px",
						},
					}}
				>
					<List>{listOptions()}</List>
				</MuiDrawer>
			</NotPrintable>
		);
	}
	return (
		<>
			<CmdK />
			<NotPrintable>
				<Drawer
					sx={{ zIndex: theme.zIndex.appBar + 10 }}
					variant="permanent"
					open={open}
					onMouseEnter={handleDrawerOpen}
					onMouseLeave={onCloseDrawer}
				>
					<Box
						sx={{
							height: theme.spacing(8),
							marginLeft: theme.spacing(1),
							marginTop: theme.spacing(1),
							marginBottom: theme.spacing(4),
						}}
						onClick={() => navigate("/home")}
					>
						<VOSFull isDarkMode={isDarkMode} />
					</Box>
					<List>{listOptions()}</List>
				</Drawer>
			</NotPrintable>
		</>
	);
}

export default NavDrawer;

export const renderCustomizedLabel = (props, formatter = (value) => value) => {
	const { x, y, width, height, value, fill } = props;

	if (value === 0 || value === null || value === undefined || height < 16)
		return null;

	return (
		<text
			x={x + width / 2}
			y={y + height / 2}
			fill={fill || "#000"}
			textAnchor="middle"
			dominantBaseline="middle"
			fontWeight="bold"
		>
			{formatter(value)}
		</text>
	);
};

export default renderCustomizedLabel;

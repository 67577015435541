import analytics from "@/shared/analytics";
import React, { useState } from "react";
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";

import { CssBaseline } from "@material-ui/core";

import useDebounce from "@/hooks/useDebounce";
import CenteredProgress from "@/ui/atoms/CenteredProgress";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "@/hooks/useAuth";
import { GlobalPopover } from "@/hooks/useGlobalPopover";

import { Header as NewHeader } from "./components/Header";
import { NavDrawer } from "./components/NavDrawer";

import FundManagement from "@/pages/FundManagement";
import useIsInTouchPWA from "./hooks/useIsInTouchPWA";
import AARDashboard from "./pages/AARDashboard";
import CRM from "./pages/CRM";
import CompliancePage from "./pages/Compliance";
import DevPage from "./pages/Developer";
import Grok from "./pages/Grok";
import HelpPage from "./pages/Help";
import HomePage from "./pages/Home";
import HumanCapital from "./pages/HumanCapital";
import IHEPorfolioChanges from "./pages/IHEChange";
import LPAudit from "./pages/LPAudit";
import LabelingPage from "./pages/Labeling";
import LoginPage from "./pages/Login/Login";
import MSLCommsDelayPage from "./pages/MSLSurvey";
import ManagementDashboardPage from "./pages/ManagementDashboard";
import Mantis from "./pages/Mantis";
import MarketMaps from "./pages/MarketMaps";
import Meetings from "./pages/Meetings";
import MetricTasks from "./pages/MetricTasks";
import NewUserPage from "./pages/NewUser";
import NewlyAddOpportunities from "./pages/NewlyAddedOpportunities";
import NotFoundPage from "./pages/NotFound";
import FirstMeetingReport from "./pages/OppAssessmentReports";
import FirstMeetingSlide from "./pages/OppAssessmentReports/SlideReport";
import Organization from "./pages/Org";
import CreateOrgForm from "./pages/Org/CreateForm";
import P2PAnalysis from "./pages/P2PAnalysis";
import Person from "./pages/Person";
import PortfolioHealth from "./pages/PortfolioHealth";
import ProcessManagement from "./pages/ProcessManagement";
import TaskRedirect from "./pages/ProcessManagement/TaskRedirect";
import PromptEvals from "./pages/PromptEvals";
import RecentMeetingsPage from "./pages/RecentMeetings";
import SearchPage from "./pages/Search";
import SourcingLists from "./pages/SourcingLists";
import TalentAcquisition from "./pages/TalentAcquisition";
import TasksPage from "./pages/Tasks";
import UserSettings from "./pages/UserSettings";
import VAAIReport from "./pages/VAAIReporting";
import VSVOps from "./pages/VSVOps";
import Verity from "./pages/Verity";
import {
	ClosedLastSevenDays,
	ConsideringForTSReport,
	FollowReport,
	InvestmentAnnouncementReport,
	NewOpportunitiesPreOAReport,
	NewOppsDistributionReport,
	PassFollowThrough,
	PostCloseActions,
	UnderTermSheet,
} from "./pages/ViewReports";
import FollowingPage from "./pages/Watchlist";
import WeeklyMeetingIC from "./pages/WeeklyMeetingIC";
import { FinancingReport } from "./pages/WeeklyMeetingIC/Notes/MeetingNotesPages";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ActionBar from "./components/ActionBar";
import {
	canCreateOrgs,
	canDealSearch,
	canReadFundManagement,
	canReadManagementDashboard,
	canSeeAARVertical,
	canSeeCRMLists,
	canSeeCompliance,
	canSeeDeals,
	canSeeDev,
	canSeeFirmList,
	canSeeFollows,
	canSeeHelp,
	canSeeHome,
	canSeeLLMChat,
	canSeeMarketMaps,
	canSeeMetricTasks,
	canSeeOrgs,
	canSeeP2PAnalysis,
	canSeePCAPAudit,
	canSeePortfolioHealth,
	canSeeRecentMeetings,
	canSeeRevGen,
	canSeeSignalModelPipeline,
	canSeeSurveyResults,
	canSeeTasksAndAlerts,
	canSeeUntaggedDocs,
	canSeeVAAIReports,
	canSeeVPO,
	canSeeWeeklyMeeting,
	canVoteOnFirstMeeting,
} from "./constants/Roles";
import FundManagementEnd from "./pages/FundManagementEnd";

analytics.init({
	defaultTracking: {
		attribution: false,
		pageViews: true,
		sessions: false,
		formInteractions: false,
		fileDownloads: false,
	},
});

const useStyles = makeStyles((theme) => ({
	appRoot: {
		display: "flex",
		overflowX: "hidden",
		background: theme.palette.background.blueGradient,
	},
	icon: {
		width: "128px",
		height: "32px",
		margin: theme.spacing(0, 0.5),
		padding: theme.spacing(1),
		fontSize: ".875rem",
		border: "1px solid white",
		borderRadius: "8px",
	},
	noStyle: {
		color: "inherit",
		textDecoration: "none",
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		display: "flex",
		flexDirection: "column",
		flexGrow: "1",
		overflowY: "auto",
	},
	page: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		boxSizing: "border-box",
		width: `calc(100vw - ${theme.spacing(7) + 1}px)`, // closed drawer width
		marginLeft: "64px",
		overflowY: "hidden",
		[theme.breakpoints.down("xs")]: {
			width: "100vw",
			marginLeft: 0,
		},
		"@media print": {
			height: "auto",
			width: "100vw",
			marginLeft: 0,
		},
	},
}));

function AuthApp() {
	const classes = useStyles();

	const { user, isAuthenticated, isLoading } = useAuth();
	const isInTouchPWA = useIsInTouchPWA();

	const [drawerOpen, setDrawerOpen] = useState(false);
	const debouncedDrawerOpen = useDebounce(drawerOpen, 100);
	const location = useLocation();
	const navigate = useNavigate();

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	const renderConditionalRoutes = React.useCallback((currentUser) => {
		const possibleRoutes = [
			{
				check: canSeeAARVertical,
				route: "/aar-dashboard/*",
				component: AARDashboard,
			},
			{
				check: canCreateOrgs,
				route: "/organization",
				component: CreateOrgForm,
			},
			{
				check: canSeeOrgs,
				route: "/:slug?/org/:id/*",
				component: Organization,
			},
			{
				check: canSeeOrgs,
				route: ["/:slug?/person/:id", "/person"],
				component: Person,
			},
			{
				check: canSeeCompliance,
				route: "/compliance-and-permissions/*",
				component: CompliancePage,
			},
			{
				check: canSeeDev,
				route: "/dev",
				component: DevPage,
			},
			{
				check: () => true,
				route: "/user-settings",
				component: UserSettings,
			},
			{
				check: canSeeDev,
				route: "/labeling",
				component: LabelingPage,
			},
			{
				check: canSeeFollows,
				route: "/following",
				component: FollowingPage,
			},
			{
				check: canSeeHelp,
				route: "/help",
				component: HelpPage,
			},
			{
				check: canSeeHome,
				route: "/home/:subPage?/:tab?",
				component: HomePage,
			},
			{
				check: canSeeHome,
				route: "/home/news",
				component: HomePage,
			},
			{
				check: canSeeHome,
				route: "/home/drafts",
				component: HomePage,
			},
			{
				check: canSeeHome,
				route: "/home/calendar",
				component: HomePage,
			},
			{
				check: canSeeHome,
				route: "/home/recently-viewed",
				component: HomePage,
			},
			{
				check: canSeeRecentMeetings,
				route: "/recent-meetings",
				component: RecentMeetingsPage,
			},
			{
				check: canReadManagementDashboard,
				route: "/ihe-changes",
				component: IHEPorfolioChanges,
			},
			{
				check: canReadManagementDashboard,
				route: "/management-dashboard",
				component: ManagementDashboardPage,
			},
			{
				check: canReadManagementDashboard,
				route: "/meetings",
				component: Meetings,
			},
			{
				check: canReadManagementDashboard,
				route: "/portfolio-monitoring",
				component: MSLCommsDelayPage,
			},
			{
				check: canSeeWeeklyMeeting,
				route: "/financing-report/:subPage?",
				component: FinancingReport,
			},
			{
				check: canSeeVAAIReports,
				route: "/vaai/:subPage?",
				component: VAAIReport,
			},
			{
				// check: canSeeProcessManagement,
				check: () => true,
				route: [
					"/process-management/:processId?",
					"/process-management/:processId?/new-task/*",
					"/process-management/:processId?/tasks/*",
				],
				component: ProcessManagement,
			},
			{
				check: () => true,
				route: "/redirect-task/:id",
				component: TaskRedirect,
			},
			{
				check: canSeePCAPAudit,
				route: "/pdf-pcap-audit",
				component: LPAudit,
			},
			{
				check: canSeeSignalModelPipeline,
				route: "/signal-model-pipeline/:activeTab?",
				component: Mantis,
			},
			{
				check: canSeeMetricTasks,
				route: "/metric-tasks/*",
				component: MetricTasks,
			},
			{
				check: canSeeRevGen,
				route: "/rev-ops/*",
				component: VSVOps,
			},
			{
				check: canDealSearch,
				route: "/search",
				component: SearchPage,
			},
			{
				check: canSeeFirmList,
				route: "/sourcing-lists",
				component: SourcingLists,
			},
			{
				check: canSeeTasksAndAlerts,
				route: "/tasks",
				component: TasksPage,
			},
			{
				check: canSeeUntaggedDocs,
				route: "/untagged-documents",
				component: Verity,
			},
			{
				check: canSeeVPO,
				route: "/human-capital",
				component: HumanCapital,
			},
			{
				check: canSeeVPO,
				route: "/talent-acquisition",
				component: TalentAcquisition,
			},
			{
				check: canSeeWeeklyMeeting,
				route: "/weekly-ic/*",
				component: WeeklyMeetingIC,
			},
			{
				check: canSeeMarketMaps,
				route: "/market-maps/*",
				component: MarketMaps,
			},
			{
				check: canVoteOnFirstMeeting,
				route: "/opportunity-assessment-reports",
				component: FirstMeetingReport,
			},
			{
				check: canSeeSurveyResults,
				route: "/opportunity-assessment-reports/:valorId",
				component: FirstMeetingSlide,
			},
			{
				check: canSeePortfolioHealth,
				route: "/portfolio-health",
				component: PortfolioHealth,
			},
			{
				check: canSeeCRMLists,
				route: "/crm",
				component: CRM,
			},
			{
				check: canSeeDeals,
				route: "/investment-process/newly-added-opps",
				component: NewlyAddOpportunities,
			},
			{
				check: canSeeDeals,
				route: "/investment-process/new-opps-pre-oa",
				component: NewOpportunitiesPreOAReport,
			},
			{
				check: canSeeDeals,
				route: "/investment-process/considering-for-ts",
				component: ConsideringForTSReport,
			},
			{
				check: canSeeDeals,
				route: "/investment-process/pass-follow-through",
				component: PassFollowThrough,
			},
			{
				check: canSeeDeals,
				route: "/investment-reports/closed-last-seven-days",
				component: ClosedLastSevenDays,
			},
			{
				check: canSeeDeals,
				route: "/investment-reports/post-closing-actions",
				component: PostCloseActions,
			},
			{
				check: canSeeDeals,
				route: "/investment-process/under-ts",
				component: UnderTermSheet,
			},
			{
				check: canSeeDeals,
				route: "/investment-reports/new-opps-distribution",
				component: NewOppsDistributionReport,
			},
			{
				check: canSeeDeals,
				route: "/investment-reports/company-follow-list",
				component: FollowReport,
			},
			{
				check: canSeeDeals,
				route: "/investment-reports/investment-announcements",
				component: InvestmentAnnouncementReport,
			},
			{
				check: canSeeLLMChat,
				route: "/grok/:section?/:id?",
				component: Grok,
			},
			{
				check: canSeeP2PAnalysis,
				route: "/p2p-analysis",
				component: P2PAnalysis,
			},
			{
				check: () => true,
				route: "/evals/:section?/:id?",
				component: PromptEvals,
			},
			{
				check: (user) => canReadFundManagement(user),
				route: "/fund-management",
				component: FundManagement,
			},
		];
		return possibleRoutes.filter((r) => r.check(currentUser));
	}, []);

	const renderedRoutes = React.useMemo(
		() =>
			renderConditionalRoutes(user).map((conditionalRoute) =>
				Array.isArray(conditionalRoute.route) ? (
					conditionalRoute.route.map((route) => (
						<Route
							key={route}
							path={route}
							element={<conditionalRoute.component />}
						/>
					))
				) : (
					<Route
						key={conditionalRoute.route}
						path={conditionalRoute.route}
						element={<conditionalRoute.component />}
					/>
				),
			),
		[user, renderConditionalRoutes],
	);

	const router = React.useMemo(
		() => (
			<Routes>
				<Route path="/" element={<Navigate to="/home" replace />} />
				<Route path="/new-user" element={<NewUserPage />} />
				<Route path="/home" element={<HomePage />} />
				<Route path="/login" element={<LoginPage />} />
				{renderedRoutes}
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		),
		[renderedRoutes],
	);

	if ((isAuthenticated && user === null) || isLoading) {
		return <CenteredProgress />;
	}

	if (!isAuthenticated && !isLoading && location.pathname !== "/login") {
		navigate(
			{
				pathname: "/login",
				search: location.search,
			},
			{
				state: { referrer: location.pathname },
			},
		);
	}

	return (
		<div className={classes.appRoot}>
			<CssBaseline />
			<GlobalPopover />
			<div style={{ display: "flex" }}>
				{isAuthenticated && user !== null && (
					<NavDrawer
						open={debouncedDrawerOpen}
						handleDrawerClose={handleDrawerClose}
						handleDrawerOpen={handleDrawerOpen}
					/>
				)}

				<div
					className={classes.page}
					style={{
						height: isInTouchPWA ? "calc(100vh - 64px)" : "100vh",
					}}
				>
					{isAuthenticated && user !== null && (
						<NewHeader
							drawerOpen={debouncedDrawerOpen}
							setDrawerOpen={() => setDrawerOpen((s) => !s)}
						/>
					)}
					<div
						id="root_content"
						data-cy="root__content"
						className={classes.content}
					>
						{router}
					</div>
					<ActionBar />
				</div>
			</div>
		</div>
	);
}

export default AuthApp;

import { formatAsMillions } from "@/utils/numberFormat";
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from "@mui/material";
import { useMemo } from "react";
import type Fund from "../Fund";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";

import type { getFundInvestments } from "@/api/FundManagement";

type GetFundInvestmentsResponse = ReturnType<typeof getFundInvestments>;
type InvestmentsResponse = Awaited<GetFundInvestmentsResponse>;
type Investment = InvestmentsResponse["data"][number];
type InvestmentCompany = Investment["organization"];

const TableCellWithTooltip = ({ text, maxWidth = "220px" }) => {
	// Check if text is truncated by comparing its length with a rough estimate
	const isTextTruncated = text && text.length > 20; // Adjust this threshold as needed

	return (
		<Tooltip
			title={text}
			placement="top"
			arrow
			// Only show tooltip if text is potentially truncated
			disableHoverListener={!isTextTruncated}
			disableFocusListener={!isTextTruncated}
		>
			<TableCell
				align="center"
				sx={{
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					maxWidth: maxWidth,
				}}
			>
				{text}
			</TableCell>
		</Tooltip>
	);
};

// risk ratings
function Slide8({
	fund,
	investmentCompanies,
}: {
	fund: Fund;
	investmentCompanies: Array<InvestmentCompany>;
}) {
	const riskRatingSortMap = {
		Ramp: 4,
		Improvement: 3,
		Sale: 2,
		Kuma: 0,
	};

	const primaryCompanies = useMemo(
		() =>
			investmentCompanies
				.filter(
					(company) =>
						company.fundInternalDesignation?.toLowerCase() === "primary" &&
						!company.hasExited,
				)
				.sort((a, b) => {
					const ratingA = riskRatingSortMap[a.riskRating] || 0;
					const ratingB = riskRatingSortMap[b.riskRating] || 0;

					if (ratingA === ratingB) {
						return (
							b.totalInvestedCapitalForFund - a.totalInvestedCapitalForFund
						);
					}
					return ratingB - ratingA;
				}),
		[investmentCompanies],
	);

	const tableData = primaryCompanies.map((company) => ({
		[`${fund.primaryInvestmentLabel} Company`]: company.name,
		"Units of Risk": (
			company.totalInvestedCapitalForFund / fund.unitsOfRiskPrimary
		).toFixed(1),
		"Projected MOIC": company.projectedGrossMoic?.toFixed(2),
		"Risk Rating": company.riskRating,
		"Lean/Growth": company.leangrowthPotentialProjects,
		"Revenue Generation": company.revGenPotentialProjects,
		"Human Capital": company.humanCapitalProjectedProjects,
	}));

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide disclaimer="All figures on this slide exclude realized investments.">
					<ContentSlide.Title>
						{fund.name}: {fund.primaryInvestmentLabel} Portfolio Risk Ratings
					</ContentSlide.Title>
					<ContentSlide.Content>
						<TableContainer sx={{ maxHeight: "100%" }}>
							<Table size="small">
								<TableHead
									sx={{
										"& > tr > th": {
											color: "white !important",
											fontWeight: "bold",
										},
									}}
								>
									{/* <TableRow sx={{ backgroundColor: "primary.main" }}>
                    <TableCell colSpan={4} />
                    <TableCell colSpan={3} align="center">
                      Scale Group Potential Projects
                    </TableCell>
                  </TableRow> */}
									<TableRow sx={{ backgroundColor: "primary.main" }}>
										<TableCell align="left">
											{fund.primaryInvestmentLabel} Company
										</TableCell>
										<TableCell align="center">
											Units of Risk ($
											{formatAsMillions(fund.unitsOfRiskPrimary)})
										</TableCell>
										<TableCell align="center">Projected MOIC</TableCell>
										<TableCell align="center">Risk Rating</TableCell>
										<TableCell align="center">
											Lean/Growth Potential Projects
										</TableCell>
										<TableCell align="center">
											Revenue Generation Potential Projects
										</TableCell>
										<TableCell align="center">
											Human Capital Potential Projects
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{primaryCompanies.map((company) => (
										<TableRow
											key={company.valorId}
											sx={{ borderBottom: "1px dotted" }}
										>
											<TableCell
												sx={{
													maxWidth: "180px",
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
											>
												{company.name}
											</TableCell>
											<TableCell align="center">
												{(
													company.totalInvestedCapitalForFund /
													fund.unitsOfRiskPrimary
												)?.toFixed(1)}
											</TableCell>
											<TableCell align="center">
												{company.projectedGrossMoic?.toFixed(1)}x
											</TableCell>
											<TableCell align="center">{company.riskRating}</TableCell>
											<TableCellWithTooltip
												text={company.leanGrowthPotentialProjects}
											/>
											<TableCellWithTooltip
												text={company.revGenPotentialProjects}
											/>
											<TableCellWithTooltip
												text={company.humanCapitalProjectedProjects}
											/>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>

			<SlideToggle.Data>
				<h2>
					{fund.name} - {fund.primaryInvestmentLabel} Portfolio Risk Ratings
				</h2>
				<table>
					<thead>
						<tr>
							<th>{fund.primaryInvestmentLabel} Company</th>
							<th>
								Units of Risk ({formatAsMillions(fund.unitsOfRiskPrimary)})
							</th>
							<th>Projected MOIC</th>
							<th>Risk Rating</th>
							<th>Lean/Growth</th>
							<th>Revenue Generation</th>
							<th>Human Capital</th>
						</tr>
					</thead>
					<tbody>
						{primaryCompanies.map((company) => (
							<tr key={company.valorId}>
								<td>{company.name}</td>
								<td>
									{(
										company.totalInvestedCapitalForFund /
										fund.unitsOfRiskPrimary
									)?.toFixed(1)}
								</td>
								<td>{company.projectedGrossMoic?.toFixed(2)}</td>
								<td>{company.riskRating}</td>
								<td>{company.leangrowthPotentialProjects}</td>
								<td>{company.revGenPotentialProjects}</td>
								<td>{company.humanCapitalProjectedProjects}</td>
							</tr>
						))}
					</tbody>
				</table>
			</SlideToggle.Data>
		</SlideToggle>
	);
}

export default Slide8;

import { Box, Paper, Typography } from "@mui/material";
import { Tooltip } from "recharts";

const CustomTooltip = (props) => {
	const { active, payload, label, formatter } = props;
	if (!active || !payload || !payload.length) return null;

	const formatValue = (value, name) => {
		if (formatter && typeof value === "number") {
			return formatter(value, name, props);
		}
		return typeof value === "number" ? value.toLocaleString() : value;
	};

	const tooltipItems = payload
		.map((entry, index) => {
			const dataItem = entry.payload || entry;
			const name = entry.name || dataItem.name;
			const value = entry.value || dataItem.value;
			const fillColor =
				dataItem[`${name}_color`] ||
				entry.fill ||
				dataItem.fill ||
				entry.color ||
				"#ccc";
			if (value === undefined || value === null || fillColor === "transparent")
				return null;

			return { name, value, fillColor, index };
		})
		.filter(Boolean);

	return (
		<Paper
			elevation={3}
			sx={{
				p: 1.5,
				borderRadius: 1,
				boxShadow: 1,
				bgcolor: "background.paper",
				maxWidth: 300,
			}}
		>
			{label && (
				<Typography variant="boldBody1" sx={{ mb: 1 }}>
					{label}
				</Typography>
			)}
			{tooltipItems.map(({ name, value, fillColor, index }) => (
				<Box
					key={index}
					sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
				>
					<Box
						sx={{
							width: 16,
							height: 16,
							backgroundColor: fillColor,
							border: "1px solid #ccc",
							mr: 1,
						}}
					/>
					<Typography variant="body2" sx={{ color: "text.primary" }}>
						{name}:{" "}
						<Typography variant="boldBody2" component="span">
							{formatValue(value, name)}
						</Typography>
					</Typography>
				</Box>
			))}
		</Paper>
	);
};

export default CustomTooltip;

import { formatAsMillions } from "@/utils/numberFormat";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ContentSlide from "./ContentSlide";
import EditableLiquidityTable from "./EditableLiquidityTable";
import SlideToggle from "./SlideToggle";

function Slide5({
  fund,
  asOfFund,
  investments,
  handleOverrideSubmit,
  handleOverrideDelete,
  onModalOpen,
  onModalClose,
}) {
  const investmentCompanies = investments.companies;
  const finalYear = Math.max(
    ...investmentCompanies.map((company) => company.effectiveLiquidityYear)
  );

  // filter investment companies that don't have an estimated liquidity year
  const filteredCompanies = investmentCompanies
    .filter(
      (company) =>
        company.effectiveLiquidityYear && company.effectiveExitValuation
    )
    .sort((a, b) => a.estimatedLiquidityYear - b.estimatedLiquidityYear);

  // Create company breakdown table data
  const companyTableData = [];
  filteredCompanies.forEach((company) => {
    const rowData = {
      Company: company.name,
    };

    Array.from(
      { length: Number(finalYear) - Number(fund.vintageYear) + 1 },
      (_, i) => Number(fund.vintageYear) + i
    ).forEach((year) => {
      rowData[year] =
        Number(company.estimatedLiquidityYear) === year
          ? formatAsMillions(
              company.probabilisticAdjustedEquityExitValuation *
                company.ownershipPercent
            )
          : "-";
    });

    companyTableData.push(rowData);
  });

  const [dimensions, setDimensions] = useState({ width: 800, height: 400 });
  const containerRef = useRef(null);

  // Format realized value to display in millions
  const totalRealizedValue = investments.currentRealizedValue;
  const formattedRealizedValue = formatAsMillions(totalRealizedValue);
  const yearlyLiquidity = investments.yearlyLiquidityProjections || []; // Sort yearlyLiquidity by year
  const sortedYearlyLiquidity = [...yearlyLiquidity].sort(
    (a, b) => a.year - b.year
  );
  const currentYear = Number(new Date().getFullYear());
  const filteredYearlyLiquidity = sortedYearlyLiquidity
    .filter((projection) => projection.year >= currentYear)
    .map((projection) => ({
      name: projection.year.toString(),
      value: projection.estimatedLiquidity / 1_000_000,
      displayValue: formatAsMillions(projection.estimatedLiquidity),
    }))
    .reduce((acc, curr, index) => {
      const previousSum =
        index === 0 ? totalRealizedValue / 1_000_000 : acc[index - 1].value;
      acc.push({
        ...curr,
        value: curr.value + previousSum,
        displayValue: formatAsMillions((curr.value + previousSum) * 1_000_000),
      });
      return acc;
    }, []);

  // Calculate totals
  const totalProjectedLiquidity =
    filteredYearlyLiquidity[filteredYearlyLiquidity.length - 1].value *
    1_000_000;

  // Chart data with actual realized value from fund
  const tempData = [
    {
      name: "Current Realized Value",
      value: totalRealizedValue / 1_000_000,
      displayValue: formattedRealizedValue,
    },
    ...filteredYearlyLiquidity,
    {
      name: "Total Projected Dollars Realized",
      value: totalProjectedLiquidity / 1_000_000,
      displayValue: formatAsMillions(totalProjectedLiquidity),
      isTotal: true,
    },
  ];

  // Layout constants
  const margin = { top: 40, right: 20, bottom: 80, left: 60 };
  const maxValue = Math.ceil(tempData[tempData.length - 1].value); // Maximum Y value

  // Update dimensions on mount
  useEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, []);

  // Chart area dimensions
  const chartWidth = dimensions.width - margin.left - margin.right;
  const chartHeight = dimensions.height - margin.top - margin.bottom;

  // Calculate bar width and spacing
  const barWidth = Math.min(40, chartWidth / (tempData.length * 2));
  const barSpacing = chartWidth / (tempData.length + 0.5);

  // Scale values to chart height
  const scaleY = (value) => chartHeight - (value / maxValue) * chartHeight;

  // Helper to generate Y-axis grid lines and labels
  const maxTick = Math.ceil(tempData[tempData.length - 1].value);
  const tickInterval = Math.ceil(maxTick / 5);
  const yAxisTicks = Array.from({ length: 6 }, (_, i) => i * tickInterval);

  return (
    <SlideToggle>
      <SlideToggle.Slide>
        <ContentSlide>
          <ContentSlide.Title>
            {fund.name}: Liquidity by Year Projection
          </ContentSlide.Title>
          <ContentSlide.Content>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-around">
                  <Box
                    mt={2}
                    py={1}
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "400px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="white !important"
                      textAlign="center"
                      width="100%"
                    >
                      Current MOIC: {Number(asOfFund.grossMoic)?.toFixed(2)}x
                      (As of Q{asOfFund.latestQuarter.quarter}{" "}
                      {asOfFund.latestQuarter.year})
                    </Typography>
                  </Box>
                  <Box
                    mt={2}
                    py={1}
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "400px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="white !important"
                      textAlign="center"
                      width="100%"
                    >
                      Projected Gross MOIC:{" "}
                      {Number(investments.projectedGrossMoic)?.toFixed(2)}x
                    </Typography>
                  </Box>
                </Box>
                <div className="w-5/6 relative" ref={containerRef}>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                    aria-labelledby="liquidityChart"
                    role="img"
                  >
                    <title id="liquidityChart">
                      Liquidity by Year Projection Chart
                    </title>
                    {/* Chart background */}
                    <rect
                      x={margin.left}
                      y={margin.top}
                      width={chartWidth}
                      height={chartHeight}
                      fill="white"
                    />

                    {/* Y-axis grid lines */}
                    {yAxisTicks.map((tick, i) => (
                      <g key={`y-tick-${tick}`}>
                        <line
                          x1={margin.left}
                          y1={margin.top + scaleY(tick)}
                          x2={margin.left + chartWidth}
                          y2={margin.top + scaleY(tick)}
                          stroke="#E5E5E5"
                          strokeDasharray="3,3"
                        />
                        <text
                          x={margin.left - 10}
                          y={margin.top + scaleY(tick)}
                          textAnchor="end"
                          dominantBaseline="middle"
                          fontSize="12"
                        >
                          ${tick === 0 ? "0M" : `${tick}M`}
                        </text>
                      </g>
                    ))}

                    {/* X-axis line */}
                    <line
                      x1={margin.left}
                      y1={margin.top + chartHeight}
                      x2={margin.left + chartWidth}
                      y2={margin.top + chartHeight}
                      stroke="black"
                    />

                    {/* Chart bars */}
                    {tempData.map((item, index) => {
                      const x = margin.left + index * barSpacing;
                      const y = margin.top + scaleY(item.value);
                      const previousTotal =
                        index > 0 ? tempData[index - 1].value : 0;
                      let height =
                        chartHeight - scaleY(item.value - previousTotal);

                      if (item.isTotal) {
                        height = chartHeight - scaleY(item.value);
                      }

                      return (
                        <g key={`bar-${item.name}`}>
                          {/* Bar */}
                          <rect
                            x={x}
                            y={y}
                            width={barWidth}
                            height={height}
                            fill={item.isTotal ? "white" : "#0047AB"}
                            stroke={item.isTotal ? "black" : "none"}
                            strokeWidth={item.isTotal ? 2 : 0}
                          />

                          {/* Bar value label */}
                          <text
                            x={x + barWidth / 2}
                            y={y - 10}
                            textAnchor="middle"
                            fontSize="12"
                            fontWeight="bold"
                          >
                            {item.displayValue}
                          </text>

                          {/* X-axis label */}
                          {item.name.length <= 10 ? (
                            <text
                              x={x + barWidth / 2}
                              y={margin.top + chartHeight + 20}
                              textAnchor="middle"
                              fontSize="12"
                            >
                              {item.name}
                            </text>
                          ) : // Special handling for multi-line labels
                          item.name === "Current Realized Value" ? (
                            <>
                              <text
                                x={x + barWidth / 2}
                                y={margin.top + chartHeight + 20}
                                textAnchor="middle"
                                fontSize="12"
                              >
                                Current
                              </text>
                              <text
                                x={x + barWidth / 2}
                                y={margin.top + chartHeight + 35}
                                textAnchor="middle"
                                fontSize="12"
                              >
                                Realized
                              </text>
                              <text
                                x={x + barWidth / 2}
                                y={margin.top + chartHeight + 50}
                                textAnchor="middle"
                                fontSize="12"
                              >
                                Value
                              </text>
                            </>
                          ) : item.name ===
                            "Total Projected Dollars Realized" ? (
                            <>
                              <text
                                x={x + barWidth / 2}
                                y={margin.top + chartHeight + 20}
                                textAnchor="middle"
                                fontSize="12"
                              >
                                Total
                              </text>
                              <text
                                x={x + barWidth / 2}
                                y={margin.top + chartHeight + 35}
                                textAnchor="middle"
                                fontSize="12"
                              >
                                Projected
                              </text>
                              <text
                                x={x + barWidth / 2}
                                y={margin.top + chartHeight + 50}
                                textAnchor="middle"
                                fontSize="12"
                              >
                                Dollars
                              </text>
                              <text
                                x={x + barWidth / 2}
                                y={margin.top + chartHeight + 65}
                                textAnchor="middle"
                                fontSize="12"
                              >
                                Realized
                              </text>
                            </>
                          ) : null}
                        </g>
                      );
                    })}
                  </svg>
                </div>
              </Grid>
            </Grid>
          </ContentSlide.Content>
        </ContentSlide>
      </SlideToggle.Slide>

      <SlideToggle.Data>
        <h2>Liquidity by Estimated Liquidity Year</h2>
        <EditableLiquidityTable
          fund={fund}
          investments={investments}
          onOverrideSubmit={handleOverrideSubmit}
          onOverrideDelete={handleOverrideDelete}
          onModalOpen={onModalOpen}
          onModalClose={onModalClose}
        />
      </SlideToggle.Data>
    </SlideToggle>
  );
}

export default Slide5;

import { formatAsMillions } from "@/utils/numberFormat";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import type Fund from "../Fund";
import type FundInvestments from "../FundInvestments";
import type InvestmentOrganization from "../InvestmentOrganization";

import { canWriteFundManagement } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";

const LIQUIDITY_YEAR_KEY = "liquidity_year";
const EXIT_VALUE_KEY = "exit_value";
const OWNERSHIP_PERCENT_KEY = "ownership_percent";

interface OverrideData {
  key: string;
  value: string;
}

interface EditableLiquidityTableProps {
  fund: Fund;
  investments: FundInvestments;
  onOverrideSubmit: (companyId: string, key: string, value: string) => void;
  onOverrideDelete: (companyId: string, key: string) => void;
  onModalOpen?: () => void;
  onModalClose?: () => void;
}

const EditableLiquidityTable: React.FC<EditableLiquidityTableProps> = ({
  fund,
  investments,
  onOverrideSubmit,
  onOverrideDelete,
  onModalOpen,
  onModalClose,
}) => {
  const { user } = useAuth();
  const [showAll, setShowAll] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [overrideData, setOverrideData] = useState<OverrideData[]>([]);

  const investmentCompanies = investments.companies;
  const finalYear = Math.max(
    ...investmentCompanies.map((company) => company.estimatedLiquidityYear)
  );
  const filteredCompanies = investmentCompanies
    .filter((company) => showAll || company.hasFutureLiquidity)
    .sort((a, b) => a.effectiveLiquidityYear - b.effectiveLiquidityYear);

  const handleCellClick = (company: InvestmentOrganization, year: number) => {
    if (!canWriteFundManagement(user)) {
      return;
    }

    setSelectedCompany(company);
    setOverrideData([
      { key: LIQUIDITY_YEAR_KEY, value: year },
      { key: EXIT_VALUE_KEY, value: company.effectiveExitValuation },
      { key: OWNERSHIP_PERCENT_KEY, value: company.effectiveOwnershipPercent },
    ]);
    onModalOpen?.();
    setIsDialogOpen(true);
  };

  const handleDeleteClick = (company: InvestmentOrganization) => {
    onOverrideDelete(company.valorId, EXIT_VALUE_KEY);
    onOverrideDelete(company.valorId, LIQUIDITY_YEAR_KEY);
    onOverrideDelete(company.valorId, OWNERSHIP_PERCENT_KEY);
  };

  const handleDialogClose = () => {
    onModalClose?.();
    setIsDialogOpen(false);
    setSelectedCompany(null);
  };

  // useMemo hooks for individual override data properties
  const exitValue = useMemo(() => {
    return overrideData.find((x) => x.key === EXIT_VALUE_KEY)?.value;
  }, [overrideData]);

  const liquidityYear = useMemo(() => {
    return overrideData.find((x) => x.key === LIQUIDITY_YEAR_KEY)?.value;
  }, [overrideData]);

  const ownershipPercent = useMemo(() => {
    return overrideData.find((x) => x.key === OWNERSHIP_PERCENT_KEY)?.value;
  }, [overrideData]);

  const [commentary, setCommentary] = useState("");
  React.useEffect(() => {
    setCommentary("");
  }, [selectedCompany]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedCompany) {
      overrideData.forEach(({ key, value }) => {
        if (
          (key === EXIT_VALUE_KEY &&
            value !== selectedCompany.effectiveExitValuation) ||
          (key === OWNERSHIP_PERCENT_KEY &&
            value !== selectedCompany.effectiveOwnershipPercent) ||
          (key === LIQUIDITY_YEAR_KEY &&
            value !== selectedCompany.effectiveLiquidityYear)
        ) {
          onOverrideSubmit(selectedCompany.valorId, key, value, commentary);
        }
      });
    }
    handleDialogClose();
  };

  const toggleCompanies = () => {
    setShowAll(!showAll);
  };

  const hasChanged = useMemo(() => {
    return (
      exitValue !== selectedCompany?.effectiveExitValuation ||
      liquidityYear !== selectedCompany?.effectiveLiquidityYear ||
      ownershipPercent !== selectedCompany?.effectiveOwnershipPercent
    );
  }, [exitValue, selectedCompany, liquidityYear, ownershipPercent]);

  const onChange = (key: string, value: any) => {
    setOverrideData((prev) => [
      ...prev.filter((item) => item.key !== key),
      { key, value },
    ]);
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleCompanies}
        sx={{ mb: 2 }}
      >
        {showAll ? "Show Companies w/ Liquidity" : "Show All"}
      </Button>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Company</TableCell>
              {Array.from(
                { length: Number(finalYear) - Number(fund.vintageYear) + 1 },
                (_, i) => Number(fund.vintageYear) + i
              ).map((year) => (
                <TableCell key={year}>{year}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCompanies.map((company) => (
              <TableRow
                key={company.id}
                sx={{
                  backgroundColor:
                    company.manualReview === "Yes" ? "#FFFF99" : "inherit",
                }}
              >
                <TableCell>{company.name}</TableCell>
                {Array.from(
                  {
                    length: Number(finalYear) - Number(fund.vintageYear) + 1,
                  },
                  (_, i) => Number(fund.vintageYear) + i
                ).map((year) => (
                  <TableCell
                    key={year}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        onClick={() => handleCellClick(company, year)}
                        sx={{
                          width: "100%",
                          height: "100%",
                          "&:hover": { backgroundColor: "action.hover" },
                        }}
                      >
                        {Number(company.effectiveLiquidityYear) === year
                          ? formatAsMillions(company.projectedRealizedValue)
                          : "-"}
                      </Box>
                      {company.hasOverrides &&
                        year === company.effectiveLiquidityYear && (
                          <IconButton
                            onClick={() => handleDeleteClick(company)}
                            size="small"
                            sx={{ transform: "scale(80%)" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          Edit Liquidity Data for {selectedCompany?.name}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Box>
              Invested Capital from {fund.name}: $
              {formatAsMillions(selectedCompany?.totalInvestedCapitalForFund)}
            </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Liquidity Year"
                type="number"
                value={liquidityYear}
                onChange={(e) => onChange(LIQUIDITY_YEAR_KEY, e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Exit Value"
                type="text"
                value={exitValue}
                onChange={(e) => {
                  if (e.target.value === "") {
                    onChange(EXIT_VALUE_KEY, null);
                  } else {
                    onChange(EXIT_VALUE_KEY, e.target.value);
                  }
                }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Ownership Percentage"
                type="text"
                value={ownershipPercent}
                onChange={(e) => {
                  if (e.target.value === "") {
                    onChange(OWNERSHIP_PERCENT_KEY, null);
                  } else {
                    onChange(OWNERSHIP_PERCENT_KEY, e.target.value);
                  }
                }}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Commentary"
                type="text"
                multiline
                value={commentary}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setCommentary(null);
                  } else {
                    setCommentary(e.target.value);
                  }
                }}
                fullWidth
                margin="normal"
                disabled={!hasChanged}
                rows={4} // Sets initial number of rows
                helperText="Add any additional comments or notes here"
              />
              <DialogActions>
                <Button onClick={handleDialogClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>

            <Box>
              {selectedCompany?.overrideHistory.map(
                ({
                  id,
                  key,
                  value,
                  author,
                  commentary,
                  deleted,
                  deletedAt,
                  createdAt,
                }) => (
                  <Box
                    key={id}
                    sx={{
                      opacity: deleted ? 0.5 : 1,
                      mb: 1,
                    }}
                  >
                    <Tooltip
                      title={
                        deleted &&
                        "Value has been overwritten by a more recent edit."
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                        }}
                      >
                        <Box>{dayjs(createdAt).format("MMM DD")}</Box>
                        <Box>
                          <Typography variant="body2">
                            {`${author.firstName} ${author.lastName}`} updated{" "}
                            {key.replace(/_/g, " ")} to{" "}
                            {[EXIT_VALUE_KEY].includes(key)
                              ? Number(value).toLocaleString()
                              : value}
                            .
                          </Typography>
                          <Typography variant="caption">
                            {commentary}
                          </Typography>
                        </Box>
                      </Box>
                    </Tooltip>
                  </Box>
                )
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditableLiquidityTable;

import { Box, Popover } from "@mui/material";
import React from "react";

interface OverflowBoxProps {
	open: boolean;
	anchorEl: HTMLElement | null;
	children: React.ReactNode;
	onClose: () => void;
}
const OverflowBox = React.memo(
	({ open, anchorEl, children, onClose }: OverflowBoxProps) => {
		return (
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={onClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<Box
					display="flex"
					flexDirection="row"
					flexWrap="wrap"
					gap={1}
					bgcolor="background.paper"
					border={(theme) => `1px solid ${theme.palette.divider}`}
					padding={1}
					borderRadius={0.5}
					boxShadow={1}
					zIndex={(t) => t.zIndex.modal}
					whiteSpace="normal"
					sx={{
						overflow: "auto",
						maxWidth: {
							xs: "100%",
							sm: 320,
							md: 600,
						},
						maxHeight: {
							xs: 480,
							sm: 480,
							md: 600,
						},
					}}
				>
					{children}
				</Box>
			</Popover>
		);
	},
);

OverflowBox.displayName = "OverflowBox";

export default OverflowBox;

import { formatAsMillions } from "@/utils/numberFormat";
import { Grid } from "@mui/material";
import React from "react";
import type Fund from "../Fund";
import { PRIMARY_COLOR, SECONDARY_COLOR, colors } from "../constants";
import ContentSlide from "./ContentSlide";
import SlideToggle from "./SlideToggle";
import WaterfallChart from "./shared/WaterfallChart";

type Props = {
	fund: Fund;
};

export default function Slide9({ fund }: Props) {
	const waterfallColors = {
		Capital: PRIMARY_COLOR,
		"+": SECONDARY_COLOR,
		"-": "#D3D3D3",
		total: "#000000",
	};
	const waterFallData = [
		{
			xLabel: "Total Fund Size",
			Capital: fund.totalFundSize,
			"+": 0,
			"-": 0,
			total: fund.totalFundSize,
			spacer: 0,
		},
		{
			xLabel: "(-) Non Mgmt. Fee Paying Commitments (LP/GP)",
			"-": fund.nonFeePayingCapitalCommitted,
			"+": 0,
			total: -1 * fund.nonFeePayingCapitalCommitted,
			spacer: fund.totalFundSize - fund.nonFeePayingCapitalCommitted,
		},
		{
			xLabel: "Management Fee Paying Commitments",
			Capital: fund.feePayingCapitalCommitted,
			"-": 0,
			total: fund.feePayingCapitalCommitted,
			spacer: 0,
		},
		{
			xLabel: "(-) Life of Fund Mgmt. Fee Estimate",
			"+": 0,
			"-": fund.lifeOfFundFeeEstimate,

			total: -1 * fund.lifeOfFundFeeEstimate,
			spacer: fund.feePayingCapitalCommitted - fund.lifeOfFundFeeEstimate,
		},
		{
			xLabel: "Mgmt. Fee Paying LP Commitments Less Mgmt. Fee",
			"-": 0,
			Capital: fund.feePayingCapitalCommitted - fund.lifeOfFundFeeEstimate,
			total: fund.feePayingCapitalCommitted - fund.lifeOfFundFeeEstimate,
			spacer: 0,
		},
		{
			xLabel: "(+) Non Mgmt. Fee Paying Commitments (LP/GP)",
			"+": fund.nonFeePayingCapitalCommitted,
			"-": 0,

			total: fund.nonFeePayingCapitalCommitted,
			spacer: fund.feePayingCapitalCommitted - fund.lifeOfFundFeeEstimate,
		},
		{
			xLabel: "(-) Non Mgmt. Fee Paying Commitments Reduction for Mgmt. Fees",
			"+": 0,
			"-": fund.nonFeePayingCommitmentFundExpenseReduction,

			total: -1 * fund.nonFeePayingCommitmentFundExpenseReduction,
			spacer:
				fund.feePayingCapitalCommitted -
				fund.lifeOfFundFeeEstimate +
				fund.nonFeePayingCapitalCommitted -
				fund.nonFeePayingCommitmentFundExpenseReduction,
		},
		{
			xLabel: "Commitments Available for Investments and Fund Expenses",
			Capital: fund.commitmentsAvailableForInvestmentAndFundExpenses,
			"-": 0,
			spacer: 0,
			total: fund.commitmentsAvailableForInvestmentAndFundExpenses,
		},
		{
			xLabel: "(-) Life of Fund Expense Estimate",
			"+": 0,
			"-": fund.lifeOfFundExpensesEstimate,
			total: -1 * Number(fund.lifeOfFundExpensesEstimate),

			spacer:
				fund.commitmentsAvailableForInvestmentAndFundExpenses -
				fund.lifeOfFundExpensesEstimate,
		},
		{
			xLabel:
				"Capital Available for Investments Including Post Investment Period Reserves",
			Capital:
				fund.capitalAvailableForInvestmentIncludingPostInvestmentReserves,
			"-": 0,
			spacer: 0,
			total: fund.capitalAvailableForInvestmentIncludingPostInvestmentReserves,
		},
	];

	return (
		<SlideToggle>
			<SlideToggle.Slide>
				<ContentSlide>
					<ContentSlide.Title>
						{fund.name}: Capital Available for Investments
						<div style={{ display: "flex", justifyContent: "center" }}>
							<WaterfallChart
								data={waterFallData}
								colors={waterfallColors}
								height={700}
								rotateLabels={false}
								showTotal
								formatter={(value, key) =>
									value < 0
										? `($${formatAsMillions(Math.abs(value), 0)})`
										: `$${formatAsMillions(value, 0)}`
								}
							/>
						</div>
					</ContentSlide.Title>
					<ContentSlide.Content></ContentSlide.Content>
				</ContentSlide>
			</SlideToggle.Slide>

			<SlideToggle.Data>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<table style={{ width: "100%" }}>
							<thead>
								<tr>
									<th style={{ textAlign: "left", width: "70%" }}>Property</th>
									<th style={{ textAlign: "right", width: "30%" }}>Value</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={{ padding: "8px 4px" }}>Total Fund Size</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										${formatAsMillions(fund.totalFundSize)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: "8px 4px" }}>
										(-) Non Mgmt. Fee Paying Commitments (LP/GP)
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										${formatAsMillions(fund.nonFeePayingCapitalCommitted)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: "8px 4px", fontWeight: "bold" }}>
										Mgmt. Fee Paying Commitments
									</td>
									<td
										style={{
											textAlign: "right",
											padding: "8px 4px",
											fontWeight: "bold",
										}}
									>
										${formatAsMillions(fund.feePayingCapitalCommitted)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: "8px 4px" }}>
										(-) Life of Fund Mgmt. Fee Estimate
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										${formatAsMillions(fund.lifeOfFundFeeEstimate)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: "8px 4px", fontWeight: "bold" }}>
										Mgmt. Fee Paying Lp Commitments Less Mgmt. Fees
									</td>
									<td
										style={{
											textAlign: "right",
											padding: "8px 4px",
											fontWeight: "bold",
										}}
									>
										$
										{formatAsMillions(
											fund.feePayingCommitmentsMinusEstimateFundExpenses,
										)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: "8px 4px" }}>
										(+) Non Mgmt. Fee Paying Commitments (LP/GP)
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										${formatAsMillions(fund.nonFeePayingCapitalCommitted)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: "8px 4px" }}>
										(-) Non Mgmt. Fee Paying Commitments (LP/GP) Reduction for
										Mgmt. Fees
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										$
										{formatAsMillions(
											fund.nonFeePayingCommitmentFundExpenseReduction,
										)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: "8px 4px", fontWeight: "bold" }}>
										Commitments Available for Investments and Fund Expenses
									</td>
									<td
										style={{
											textAlign: "right",
											padding: "8px 4px",
											fontWeight: "bold",
										}}
									>
										$
										{formatAsMillions(
											fund.commitmentsAvailableForInvestmentAndFundExpenses,
										)}
									</td>
								</tr>
								<tr>
									<td style={{ padding: "8px 4px" }}>
										(-) Life of Fund Expense Estimate
									</td>
									<td style={{ textAlign: "right", padding: "8px 4px" }}>
										${formatAsMillions(fund.lifeOfFundExpensesEstimate)}
									</td>
								</tr>
								<tr>
									<td
										style={{
											padding: "8px 4px",
											fontWeight: "bold",
											backgroundColor: "#f5f5f5",
										}}
									>
										Capital Available for Investments Including Post Investment
										Period Reserves
									</td>
									<td
										style={{
											textAlign: "right",
											padding: "8px 4px",
											fontWeight: "bold",
											backgroundColor: "#f5f5f5",
										}}
									>
										$
										{formatAsMillions(
											fund.capitalAvailableForInvestmentIncludingPostInvestmentReserves,
										)}
									</td>
								</tr>
							</tbody>
						</table>
					</Grid>
				</Grid>
			</SlideToggle.Data>
		</SlideToggle>
	);
}
